<div class="main-container">
  <div class="row" style="margin-bottom: 12px;">
    <div class="datepicker-container">
      <mat-form-field floatLabel="never" appearance="outline">
        <mat-datepicker #dpRef [disabled]="false"></mat-datepicker>
        <mat-datepicker-toggle matSuffix [for]="dpRef"></mat-datepicker-toggle>
        <input matInput [matDatepicker]="dpRef" [formControl]="dateCtrl">
      </mat-form-field>
    </div>

    <div class="scope-selector-container">
      <!-- SCOPE SELECTOR -->
      <mat-button-toggle-group aria-label="Search scope" [formControl]="scopeSelectorCtrl">
        <mat-button-toggle [value]="SearchScope.Organization">Organization</mat-button-toggle>
        <mat-button-toggle [value]="SearchScope.Site" selected>Site</mat-button-toggle>
        <mat-button-toggle [value]="SearchScope.Department">Department</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>

  <div class="row">
    <div class="search-container">
      <mat-form-field floatLabel="never" appearance="outline">
        <mat-icon matPrefix>search</mat-icon>
        <input matInput placeholder="Search for patient's visit..." autocomplete="off" [formControl]="searchCtrl"
          (keydown)="onKeydown($event)" cdkFocusInitial>
      </mat-form-field>
    </div>
  </div>

  <ng-container *ngIf="srv.cmptState$ | async as state;">
    <div class="row">
      <ng-container *ngIf="state.loading">
        <div class="container skeleton-container">
          <skeleton-visit-search-result></skeleton-visit-search-result>
        </div>
      </ng-container>

      <ng-container *ngIf="!state.loading">
        <div class="container no-search-container" *ngIf="!state.results">
          <span class="title">Find the patient visit you are looking for</span>
          <span>Select the date you are interested in and search by patient name, MRN or badge
            information.</span>
          <span>The search should be at least 3 characters long.</span>
        </div>
        <div class="container no-result-container" *ngIf="state.results?.length === 0">
          <span>No results found.</span>
        </div>
        <div class="container results-container" *ngIf="state.results?.length">
          <div *ngFor="let item of state.results; trackBy: trackByFn" class="result-item" title="{{item.dataStr}}"
            (click)="openVisitDetailDialog(item.id)">
            <div class="vertical-line"></div>
            <div class="indicator" [ngClass]="item.ongoing ? 'green-indicator' : 'grey-indicator'"></div>
            <span class="data">
              <span *ngFor="let value of item.data">{{value}}</span>
            </span>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>