import gql from 'graphql-tag';

export interface DepartmentNetworkModel {
  department: {
    id: number;
    care_table_configuration: string[];
    waiting_table_configuration: string[];
    site_id: number;
    site: {
      timezone: { id: string };
    };
  }[];
}

export const DEPARTMENT_DATA_QUERY = gql(`
subscription departmentData($departmentId: Int!) {
    department(where: {id: {_eq: $departmentId}}) {
      id
      care_table_configuration
      waiting_table_configuration
      site_id
      site {
        timezone {
          id
        }
      }
    }
  }
`);
