import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SharedUiSkeletonFormModule } from '@frontend/common/skeletons';
import { ConfirmEndVisitsDialogComponent } from './confirm-end-visits-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatSlideToggleModule,
    SharedUiSkeletonFormModule,
  ],
  declarations: [ConfirmEndVisitsDialogComponent],
  exports: [ConfirmEndVisitsDialogComponent],
})
export class ConfirmEndVisitsDialogModule {}
