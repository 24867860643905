import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { AbstractState } from '@frontend/shared/base-store';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
import { Patient } from './patient.model';
import { patientsActions } from './store/patients.actions';
import { patientsSelectors } from './store/patients.selectors';

@Injectable()
export class PatientResolver implements Resolve<Patient> {
  constructor(private store: Store<AbstractState<Patient>>) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Patient> {
    return this.store.pipe(
      select(patientsSelectors.getOneByStateParam),
      tap(
        (patient) =>
          patient || this.store.dispatch(patientsActions.loadOne(route.params.patient_id))
      ),
      filter((patient) => !!patient),
      first()
    );
  }
}
