import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { ChangeDepartmentService } from './change-department.service';

@Component({
  selector: 'frontend-change-department',
  templateUrl: './change-department.component.html',
  styleUrls: ['./change-department.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ChangeDepartmentService],
})
export class ChangeDepartmentComponent {
  subscriptions: Subscription[] = [];

  form: FormGroup = this._fb.group({
    department: this._fb.control(null, [Validators.required]),
  });

  loading$ = this.srv.loading$;
  departments$ = this.srv.departments$;
  initialDepartmentId = null;

  constructor(
    private _fb: FormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: { patientId: number },
    private srv: ChangeDepartmentService
  ) {
    this.srv.getData(this.data.patientId);
    this.subscriptions.push(
      this.srv.patient$.pipe(first()).subscribe((patient) => {
        this.initialDepartmentId = patient.department_id;
      })
    );
  }

  submit() {
    this.form.disable();
    const departmentId = this.form.value.department.id;
    const departmentName = this.form.value.department.name;
    this.srv.changeDepartment(this.data.patientId, departmentId).subscribe(
      () => {
        const msg = $localize`Patient was successfully created in ${departmentName}.`;
        this.snackBar.open(msg, null, { duration: 3000 });
        this.dialog.closeAll();
      },
      () => {
        this.snackBar.open($localize`There was an unexpected error.`, $localize`Close`, {
          duration: 10000,
        });
        this.form.enable();
      }
    );
  }
}
