import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getParams } from '@frontend/common/ph-router-store';
import { SocketService } from '@frontend/common/util';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { merge, of, race, throwError } from 'rxjs';
import { catchError, filter, first, ignoreElements, map, mergeMap } from 'rxjs/operators';
import * as uuid from 'uuid/v4';
import { SecurableService } from './securable.service';

export interface TagNetworkModel {
  tag: {
    id: number;
    tag_value: string;
    label: string;
    current_tag_location: {
      non_reporting_since_utc: string;
      low_battery_since_utc: string;
    };
  }[];
}

export const TAG_DATA_QUERY = gql(`
query TagByTagValue($tagValue: String!) {
  tag(where: {tag_value: {_eq: $tagValue}}) {
    id
    tag_value
    label
    current_tag_location {
     non_reporting_since_utc
     low_battery_since_utc
    }
  }
}
`);

@Injectable()
export class CreatePatientService {
  constructor(
    private apollo: Apollo,
    private store: Store,
    private securableSrv: SecurableService,
    private http: HttpClient,
    private socketSrv: SocketService
  ) {}

  create(formData, force) {
    return this.store.select(getParams).pipe(
      first(),
      map((params) => params?.department_id),
      mergeMap((department_id) =>
        this.securableSrv.create({ ...formData, department_id }).pipe(
          mergeMap((p) => this.getTagData(p, force)),
          mergeMap((p) => this.createPatient(p))
        )
      )
    );
  }

  createPatient(patientWithPHI) {
    const correlation_id = 'correlation|' + uuid();
    const { id, last_name, mrn, phone_number, ...patientWithoutPHI } = patientWithPHI;
    const payload = { ...patientWithoutPHI, correlation_id };
    return merge(
      this.http.post('patients', payload).pipe(
        ignoreElements(),
        catchError((err) =>
          of(err?.error?.message).pipe(mergeMap((message) => throwError({ id, message })))
        )
      ),
      race(
        this.socketSrv
          .fromLocalSuccess<any>('patients.create')
          .pipe(filter((ws) => ws.correlation_id === correlation_id)),
        this.socketSrv.fromLocalError('patients.create').pipe(
          filter((ws) => ws.correlation_id === correlation_id),
          mergeMap(({ message }) => throwError({ id, message }))
        )
      ).pipe(first())
    );
  }

  getTagData(patient, force) {
    if (!patient.tag_id) {
      return of({ ...patient, tag_id: null });
    }
    return this.apollo
      .subscribe<TagNetworkModel>({
        query: TAG_DATA_QUERY,
        variables: { tagValue: patient.tag_id },
      })
      .pipe(
        map(({ data }) => {
          const tag = data.tag?.[0];
          if (!tag) {
            throw { expectedError: $localize`Badge doesn't exist` };
          }
          if (tag?.current_tag_location?.non_reporting_since_utc && !force) {
            throw {
              expectedError: 'tag_is_non_reporting',
            };
          }
          if (tag?.current_tag_location?.low_battery_since_utc && !force) {
            throw {
              expectedError: 'tag_is_low_battery',
            };
          }
          return {
            ...patient,
            tag_id: tag?.id,
            label: tag?.label,
            tag_value: tag?.tag_value,
          };
        })
      );
  }
}
