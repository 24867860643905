import * as configActions from './config.actions';

export interface ConfigState {
  overlayCreated: boolean;
  open: boolean;
}

export function configReducer(
  state = {
    overlayCreated: false,
    open: false,
  },
  action: configActions.ConfigActions
): ConfigState {
  switch (action.type) {
    case configActions.CREATE_OVERLAY: {
      return { ...state, overlayCreated: true, open: true };
    }
    case configActions.DESTROY_OVERLAY: {
      return { ...state, overlayCreated: false, open: false };
    }
    case configActions.TOGGLE: {
      return { ...state, open: !state.open };
    }
    case configActions.OPEN: {
      return { ...state, open: true };
    }
    default: {
      return state;
    }
  }
}
