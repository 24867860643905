import { ActionTypes, BaseActions } from '@frontend/shared/base-store';
import { Location } from '../../locations/location.model';
import { Department } from '../department.model';

export interface DepartmentsActionTypes extends ActionTypes {
  LOAD_AVAILABLE: string;
  LOAD_AVAILABLE_SUCCESS: string;
  LOAD_AVAILABLE_FAIL: string;
  LOAD_LOCATIONS: string;
  LOAD_LOCATIONS_SUCCESS: string;
  LOAD_LOCATIONS_FAIL: string;
  ADD_LOCATION: string;
  ADD_LOCATION_SUCCESS: string;
  ADD_LOCATION_FAIL: string;
  REMOVE_LOCATION: string;
  REMOVE_LOCATION_SUCCESS: string;
  REMOVE_LOCATION_FAIL: string;
}

class DepartmentsActions extends BaseActions<Department> {
  public types: DepartmentsActionTypes;

  constructor() {
    super(Department);
    this.types = {
      ...this.types,
      LOAD_AVAILABLE: this.getTypeString('LOAD_AVAILABLE'),
      LOAD_AVAILABLE_SUCCESS: this.getTypeString('LOAD_AVAILABLE_SUCCESS'),
      LOAD_AVAILABLE_FAIL: this.getTypeString('LOAD_AVAILABLE_FAIL'),
      LOAD_LOCATIONS: this.getTypeString('LOAD_LOCATIONS'),
      LOAD_LOCATIONS_SUCCESS: this.getTypeString('LOAD_LOCATIONS_SUCCESS'),
      LOAD_LOCATIONS_FAIL: this.getTypeString('LOAD_LOCATIONS_FAIL'),
      ADD_LOCATION: this.getTypeString('ADD_LOCATION'),
      ADD_LOCATION_SUCCESS: this.getTypeString('ADD_LOCATION_SUCCESS'),
      ADD_LOCATION_FAIL: this.getTypeString('ADD_LOCATION_FAIL'),
      REMOVE_LOCATION: this.getTypeString('REMOVE_LOCATION'),
      REMOVE_LOCATION_SUCCESS: this.getTypeString('REMOVE_LOCATION_SUCCESS'),
      REMOVE_LOCATION_FAIL: this.getTypeString('REMOVE_LOCATION_FAIL'),
    };
  }

  loadAvailable() {
    return { type: this.types.LOAD_AVAILABLE };
  }

  loadAvailableSuccess(payload: Department[]) {
    return { type: this.types.LOAD_AVAILABLE_SUCCESS, payload };
  }

  loadAvailableFail(payload) {
    return { type: this.types.LOAD_AVAILABLE_FAIL, payload };
  }

  loadLocations(payload: { id: number }) {
    return { type: this.types.LOAD_LOCATIONS, payload };
  }

  loadLocationsSuccess(payload: { id: number; locations: Location[] }) {
    return { type: this.types.LOAD_LOCATIONS_SUCCESS, payload };
  }

  loadLocationsFail(payload) {
    return { type: this.types.LOAD_LOCATIONS_FAIL, payload };
  }

  addLocation(payload: { department: Department; location: Location }) {
    return { type: this.types.ADD_LOCATION, payload: this.correlate(payload) };
  }

  addLocationSuccess(payload) {
    return { type: this.types.ADD_LOCATION_SUCCESS, payload };
  }

  addLocationFail(payload) {
    return { type: this.types.ADD_LOCATION_FAIL, payload };
  }

  removeLocation(payload: { department: Department; location: Location }) {
    return { type: this.types.REMOVE_LOCATION, payload: this.correlate(payload) };
  }

  removeLocationSuccess(payload) {
    return { type: this.types.REMOVE_LOCATION_SUCCESS, payload };
  }

  removeLocationFail(payload) {
    return { type: this.types.REMOVE_LOCATION_FAIL, payload };
  }
}

export const departmentsActions = new DepartmentsActions();
