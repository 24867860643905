import { ChangeDetectionStrategy, Component, OnDestroy, ViewChild } from '@angular/core';
import { ChatFormComponent } from '@frontend/common/chat';
import { TextMessageWsActionTypes } from '@frontend/shared/text-message/data-access/src';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LoadChatSmart, MarkAllSeen, SendTextMessage } from './chat-smart.actions';
import {
  getChatLoading,
  getChatSending,
  getPatientId,
  getState,
} from './chat-smart.selectors';

@UntilDestroy()
@Component({
  selector: 'frontend-chat-smart',
  templateUrl: './chat-smart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatSmartComponent implements OnDestroy {
  loading$ = this.store.select(getChatLoading);
  sending$ = this.store.select(getChatSending);
  state$ = this.store.select(getState);

  @ViewChild(ChatFormComponent) chatFormComponent: ChatFormComponent;

  constructor(private store: Store, private actions$: Actions) {
    this.store
      .select(getPatientId)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.store.dispatch(new LoadChatSmart()));

    this.actions$
      .pipe(
        ofType(TextMessageWsActionTypes.LocalCreateTextMessageSuccessWs),
        untilDestroyed(this)
      )
      .subscribe(() => this.chatFormComponent?.reset());
  }

  ngOnDestroy(): void {}

  onMarkAllSeen() {
    this.store.dispatch(new MarkAllSeen());
  }

  onSend(message) {
    this.store.dispatch(new SendTextMessage({ message, is_to_patient: true }));
  }
}
