import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SearchBoxModule } from '@frontend/common/search-box';
import { EffectsModule } from '@ngrx/effects';
import { TagChirpingComponent } from './tag-chirping.component';
import { TagChirpingEffects } from './tag-chirping.effects';
import { TagChirpingService } from './tag-chirping.service';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatSnackBarModule,
    SearchBoxModule,
    EffectsModule.forFeature([TagChirpingEffects]),
  ],
  declarations: [TagChirpingComponent],
  exports: [TagChirpingComponent],
  providers: [TagChirpingService],
})
export class TagChirpingModule {}
