import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { EditAppointmentComponent } from './edit-appointment.component';

@Component({
  selector: 'edit-appointment-routed',
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditAppointmentRoutedComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.dialog
      .open(EditAppointmentComponent, { width: '369px' })
      .afterClosed()
      .subscribe((route = '../../../') =>
        this.router.navigate([route], { relativeTo: this.route })
      );
  }
}
