<frontend-simple-toolbar [title]="title$ | async">
  <search-box (searchChanges)="onSearchChange($event)"></search-box>
  <recipients-error-popout [errors$]="errors$"></recipients-error-popout>
  <button mat-button mat-icon-button routerLink="new">
    <mat-icon>add</mat-icon>
  </button>
</frontend-simple-toolbar>

<mat-card class="mat-elevation-z1">
  <mat-card-content>
    <skeleton-table *ngIf="loading$ | async"></skeleton-table>
    <ng-container *ngIf="!(loading$ | async)">
      <frontend-recipients-table [collection]="recipients$ | async" [filter]="searchValue$ | async">
      </frontend-recipients-table>
    </ng-container>
  </mat-card-content>
</mat-card>
<router-outlet></router-outlet>