import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmationDialogService } from '@frontend/common/confirmation-dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { Delete } from '../reducers/recipient/recipient-api.actions';
import {
  DeleteRecipient,
  UpdateRecipient,
} from '../reducers/recipient/recipient.actions';
import { State } from '../reducers/recipient/recipient.reducer';
import { RecipientTableItem } from './recipients-table-item.model';

@UntilDestroy()
@Component({
  selector: 'frontend-recipients-table',
  templateUrl: './recipients-table.component.html',
  styleUrls: ['./recipients-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecipientsTableComponent implements OnDestroy, OnInit {
  columnNames: string[] = ['fullName', 'relationship', 'cellPhone', 'actions_column'];
  dataSource: MatTableDataSource<RecipientTableItem> = new MatTableDataSource();

  @Input()
  set filter(value: string) {
    this.dataSource.filter = value.trim().toLowerCase();
  }
  @Input()
  set collection(data: RecipientTableItem[]) {
    this.dataSource.data = data;
  }

  @ViewChild(MatPaginator, { static: true }) paginatorRef: MatPaginator;
  @ViewChild(MatSort, { static: true }) sortRef: MatSort;

  constructor(
    private store: Store<State>,
    private confirmationDialog: ConfirmationDialogService
  ) {}

  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.dataSource.paginator = this.paginatorRef;
    this.dataSource.sort = this.sortRef;
  }

  discardItem(item) {
    if (item.old) {
      const recipient = {
        id: item.id,
        changes: { ...item.old, pending: false, error: null },
      };
      this.store.dispatch(new UpdateRecipient({ recipient }));
    } else {
      this.store.dispatch(new DeleteRecipient(item));
    }
  }

  trackBy(index, item) {
    return item.id;
  }

  removeRecipient({ id, correlation_id }) {
    this.confirmationDialog
      .open(ConfirmationDialogService.defaultConfirm)
      .pipe(untilDestroyed(this), first())
      .subscribe(
        (result) => result && this.store.dispatch(new Delete({ id, correlation_id }))
      );
  }
}
