<ng-container *ngIf="loading$ | async">
  <skeleton-form></skeleton-form>
</ng-container>

<ng-container *ngIf="!(loading$ | async)">
  <h2 mat-dialog-title i18n>Confirm operation</h2>
  <div mat-dialog-content class="mat-body-1">
    <p i18n>
      You are about to end the visit for {{ this.srv.amountToEnd$ | async }}
      {{ this.plural$ | async }}. This action cannot be undone. Are you sure you want to
      proceed?
    </p>
  </div>
  <mat-slide-toggle class="dialog-slide-toggle" (change)="isChecked = $event.checked" i18n>
    Please turn on toggle to confirm
  </mat-slide-toggle>
  <div mat-dialog-actions align="end">
    <button mat-button [disabled]="saving || !isChecked" (click)="confirm()" i18n>
      PROCEED
    </button>
    <button mat-button mat-dialog-close [disabled]="saving" i18n>CANCEL</button>
  </div>
</ng-container>
