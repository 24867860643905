import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EndVisitService } from './end-visit.service';
import { PatientService } from './patient.service';

export interface CardData {
  patient: Patient;
}

export interface Patient {
  visitId: number;
  departmentId: string;
  nameVersions?: string;
  tagState?: 'red' | 'yellow' | 'green';
  tagStateSince?: string;
  tagVersions?: string;
  mrn?: string;
  phone_number?: string;
  note1?: string;
  note2?: string;
}

@Component({
  selector: 'frontend-visit-detail-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['../common.scss', './patient.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PatientService, EndVisitService],
})
export class PatientComponent {
  @Input() visitCompleted?: boolean;

  constructor(public srv: PatientService) {}
}
