import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { BulkUploaderModule } from '@frontend/common/bulk-uploader';
import { CallToStationModule } from '@frontend/common/call-to-station';
import { SearchBoxModule } from '@frontend/common/search-box';
import { SharedUiSimpleToolbarModule } from '@frontend/common/simple-toolbar';
import { SharedUiSkeletonTableModule } from '@frontend/common/skeletons';
import { UsersCacheService } from './users.cache.service';
import { StoreModule } from '@ngrx/store';
import { BulkUploadInstructionsModule } from '../bulk-upload-instructions/bulk-upload-instructions.module';
import { CareTableModule } from '../care-table/care-table.module';
import { ChangeDepartmentModule } from '../change-department/change-department.module';
import { ChangePhaseModule } from '../change-phase/change-phase.module';
import { ChatModule } from '../chat/chat.module';
import { ConfirmEndVisitsDialogModule } from '../confirm-end-visits-dialog/confirm-end-visits-dialog.module';
import { ReassignPatientDialogModule } from '../reassign-patient-dialog/reassign-patient-dialog.module';
import { CareFiltersDialogModule } from '../table-filters/care-filters-dialog/care-filters-dialog.module';
import { WaitingFiltersDialogModule } from '../table-filters/waiting-filters-dialog/waiting-filters-dialog.module';
import { TagChirpingModule } from '../tag-chirping/tag-chirping.module';
import { UnreadMessagesMenuModule } from '../unread-messages-menu/unread-messages-menu.module';
import { WaitingTableModule } from '../waiting-table/waiting-table.module';
import { PageComponent } from './page.component';
import * as fromPatientsPage from './state/page.reducer';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatCardModule,
    MatChipsModule,
    MatExpansionModule,
    MatIconModule,
    MatMenuModule,
    MatSnackBarModule,
    MatToolbarModule,
    SharedUiSimpleToolbarModule,
    SharedUiSkeletonTableModule,
    CareTableModule,
    WaitingTableModule,
    TagChirpingModule,
    UnreadMessagesMenuModule,
    ChangeDepartmentModule,
    ChangePhaseModule,
    ChatModule,
    BulkUploadInstructionsModule,
    BulkUploaderModule,
    ReassignPatientDialogModule,
    CareFiltersDialogModule,
    WaitingFiltersDialogModule,
    SearchBoxModule,
    ConfirmEndVisitsDialogModule,
    StoreModule.forFeature('patientsPage', fromPatientsPage.reducers, {
      metaReducers: fromPatientsPage.metaReducers,
    }),
    CallToStationModule,
  ],
  declarations: [PageComponent],
  providers: [UsersCacheService],
  exports: [PageComponent],
})
export class PageModule {}
