import gql from 'graphql-tag';

export interface VisitDataQuery {
  patient: Visit;
}

export interface Visit {
  id: number;
  securable_id: number;
  secured_patient_name: string;
  tag?: {
    id: number;
    tag_value: string;
    label: string;
    current_tag_location?: {
      non_reporting_since_utc: string;
      low_battery_since_utc: string;
    };
  };
  note: string;
  additional_note: string;
  department: {
    id: string;
    site: {
      timezone: {
        id: string;
      };
    };
  };
}

export const VisitDataQuery = gql(`
query VisitDataQuery($visitId: String!) {
  patient(id: $visitId) {
    id
    securable_id
    secured_patient_name
    tag {
      id
      tag_value
      label
      current_tag_location {
        non_reporting_since_utc
        low_battery_since_utc
      }
    }
    note
    additional_note
    department {
      id
      site {
        timezone {
          id
        }
      }
    }
  }
}
`);
