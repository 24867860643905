import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedUiSkeletonVisitDetailModuleModule } from '@frontend/common/skeletons';
import { MilestonesComponent } from './milestones.component';

@NgModule({
  imports: [CommonModule, MatDialogModule, SharedUiSkeletonVisitDetailModuleModule],
  declarations: [MilestonesComponent],
  exports: [MilestonesComponent],
})
export class MilestonesModule {}
