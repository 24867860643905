<ng-container *ngIf="loading$ | async">
  <skeleton-form></skeleton-form>
</ng-container>

<ng-container *ngIf="!(loading$ | async)">
  <div [formGroup]="form">
    <div mat-dialog-title i18n>Filters</div>

    <mat-dialog-content>
      <div class="content-wrapper">
        <div class="column">
          <multi-select-search
            formControlName="location_groups"
            entityName="Groups of Locations"
            i18n-entityName
            label="label"
            [items]="location_groups"
          ></multi-select-search>
        </div>
        <div class="column">
          <mat-form-field floatLabel="never" appearance="outline">
            <mat-label i18n>Providers</mat-label>
            <mat-select multiple formControlName="providers">
              <mat-option value="null" i18n>None</mat-option>
              <ng-container *ngFor="let item of providers">
                <mat-option [value]="item.id">{{ item.label }}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <mat-form-field floatLabel="never" appearance="outline">
            <mat-label i18n>Appt. Types</mat-label>
            <mat-select multiple formControlName="appointment_types">
              <mat-option value="null" i18n>None</mat-option>
              <ng-container *ngFor="let item of appointment_types">
                <mat-option [value]="item.id">{{ item.label }}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <mat-form-field floatLabel="never" appearance="outline">
            <mat-label i18n>Workflows</mat-label>
            <mat-select multiple formControlName="workflows">
              <mat-option value="null" i18n>Unassigned</mat-option>
              <ng-container *ngFor="let item of workflows">
                <mat-option [value]="item.id">{{ item.label }}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <mat-form-field floatLabel="never" appearance="outline">
            <mat-label i18n>Phases</mat-label>
            <mat-select multiple formControlName="phases">
              <ng-container *ngFor="let item of phases">
                <mat-optgroup [label]="item.label">
                  <mat-option *ngFor="let child of item.children" [value]="child.id">{{
                    child.label
                  }}</mat-option>
                </mat-optgroup>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <mat-form-field floatLabel="never" appearance="outline">
            <mat-label i18n>Locations</mat-label>
            <mat-select multiple formControlName="locations">
              <mat-option value="null" i18n>None</mat-option>
              <ng-container *ngFor="let item of locations">
                <mat-option [value]="item.id">{{ item.label }}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </mat-dialog-content>

    <div mat-dialog-actions>
      <button mat-button [disabled]="form.disabled" (click)="save()">
        <span i18n>CLEAR</span>
      </button>
      <button mat-button [disabled]="form.disabled" (click)="save(form.value)">
        <span i18n>APPLY</span>
      </button>
    </div>
  </div>
</ng-container>
