import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LocationNetworkModel, PatientNetworkModel } from '../data-models';

const MULTIDIMENSIONAL_COLUMNS = [
  'workflowName',
  'timeInWorkflow',
  'phaseName',
  'phaseUpdatedUTC',
  'unattendedSinceUtc',
];

const SUPPORTED_COLUMNS = [
  ...MULTIDIMENSIONAL_COLUMNS,
  'securedPatientName',
  'fullName',
  'durationInLocation',
  'mrn',
  'tagValue',
  'tagLabel',
  'appointmentTimeUTC',
  'appointmentType',
  'provider',
  'timeInDepartment',
  'recipients',
  'note',
  'additional_note',
  'attendedSinceUtc',
  'unattendedSinceUtc',
  'staffName',
  'securedStaffName',
  'status',
  'badgeStatus',
];

@Component({
  selector: 'frontend-care-table',
  templateUrl: './care-table.component.html',
  styleUrls: ['./care-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CareTableComponent implements AfterViewInit, OnInit {
  columnSet = [];
  multidimensional = false;
  highlightLocationId: number = null;
  highlightPatientId: string = null;

  dataSource = new MatTableDataSource<LocationNetworkModel>();

  @ViewChild(MatPaginator) paginatorRef: MatPaginator;
  @ViewChild(MatSort) sortRef: MatSort;

  @Input() set columns(columns: string[]) {
    if (columns) {
      const cols = columns?.filter((c) => SUPPORTED_COLUMNS.indexOf(c) > -1);
      this.multidimensional = cols.some((c) => MULTIDIMENSIONAL_COLUMNS.indexOf(c) > -1);
      this.columnSet = ['locationName', ...cols, 'actionsColumn'];
    }
  }

  @Input() set collection(collection: LocationNetworkModel[]) {
    if (collection) {
      this.dataSource.data = collection;
    }
  }

  @Output() advancePhase = new EventEmitter();
  @Output() changeDepartment = new EventEmitter();
  @Output() openVisitDetail = new EventEmitter();
  @Output() changePhase = new EventEmitter();
  @Output() endVisit = new EventEmitter();
  @Output() deletePatient = new EventEmitter();
  @Output() updateLocation = new EventEmitter();

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginatorRef;
    this.dataSource.sort = this.sortRef;
  }

  ngOnInit() {
    this.dataSource.sortData = (data, { active, direction }: MatSort) => {
      const order = direction === 'asc' ? 1 : -1;
      const inverseOrder = direction === 'asc' ? -1 : 1;
      switch (active) {
        case 'locationName': {
          return genericSort(
            data,
            (o) => o?.alias_name,
            (A, B) => A.localeCompare(B),
            order
          );
        }
        case 'fullName': {
          const locations = data.map((loc) => {
            const patients = genericSort(
              loc.patients,
              (o) => o?.securableData?.fullName,
              (A, B) => A.localeCompare(B),
              order
            );
            return { ...loc, patients };
          });
          return genericSort(
            locations,
            (o) => o?.patients?.[0]?.securableData?.fullName,
            (A, B) => A.localeCompare(B),
            order
          );
        }
        case 'workflowName': {
          const locations = data.map((loc) => {
            const patients = loc.patients.map((patient) => {
              return {
                ...patient,
                patient_workflows: genericSort(
                  patient.patient_workflows,
                  (o) => o?.workflow?.name,
                  (A, B) => A.localeCompare(B),
                  order
                ),
              };
            });
            return {
              ...loc,
              patients: genericSort(
                patients,
                (o) => o?.patient_workflows?.[0]?.workflow?.name,
                (A, B) => A.localeCompare(B),
                order
              ),
            };
          });
          return genericSort(
            locations,
            (o) => o?.patients?.[0]?.patient_workflows?.[0]?.workflow?.name,
            (A, B) => A.localeCompare(B),
            order
          );
        }
        case 'securedPatientName': {
          const locations = data.map((loc) => {
            const patients = genericSort(
              loc.patients,
              (o) => o?.secured_patient_name,
              (A, B) => A.localeCompare(B),
              order
            );
            return { ...loc, patients };
          });
          return genericSort(
            locations,
            (o) => o?.patients?.[0]?.secured_patient_name,
            (A, B) => A.localeCompare(B),
            order
          );
        }
        case 'note': {
          const locations = data.map((loc) => {
            const patients = genericSort(
              loc.patients,
              (o) => o?.note,
              (A, B) => A.localeCompare(B),
              order
            );
            return { ...loc, patients };
          });
          return genericSort(
            locations,
            (o) => o?.patients?.[0]?.note,
            (A, B) => A.localeCompare(B),
            order
          );
        }
        case 'additional_note': {
          const locations = data.map((loc) => {
            const patients = genericSort(
              loc.patients,
              (o) => o?.additional_note,
              (A, B) => A.localeCompare(B),
              order
            );
            return { ...loc, patients };
          });
          return genericSort(
            locations,
            (o) => o?.patients?.[0]?.additional_note,
            (A, B) => A.localeCompare(B),
            order
          );
        }
        case 'mrn': {
          const locations = data.map((loc) => {
            const patients = genericSort(
              loc.patients,
              (o) => o?.securableData.mrn,
              (A, B) => A.localeCompare(B),
              order
            );
            return { ...loc, patients };
          });
          return genericSort(
            locations,
            (o) => o?.patients?.[0]?.securableData.mrn,
            (A, B) => A.localeCompare(B),
            order
          );
        }
        case 'tagValue': {
          const locations = data.map((loc) => {
            const patients = genericSort(
              loc.patients,
              (o) => o?.tag?.tag_value,
              (A, B) => A.localeCompare(B),
              order
            );
            return { ...loc, patients };
          });
          return genericSort(
            locations,
            (o) => o?.patients?.[0]?.tag?.tag_value,
            (A, B) => A.localeCompare(B),
            order
          );
        }
        case 'tagLabel': {
          const locations = data.map((loc) => {
            const patients = genericSort(
              loc.patients,
              (o) => o?.tag?.label,
              (A, B) => A.localeCompare(B),
              order
            );
            return { ...loc, patients };
          });
          return genericSort(
            locations,
            (o) => o?.patients?.[0]?.tag?.label,
            (A, B) => A.localeCompare(B),
            order
          );
        }
        case 'phaseName': {
          const locations = data.map((loc) => {
            const patients = loc.patients.map((patient) => {
              const patient_workflows = genericSort(
                patient.patient_workflows,
                (o) => o?.patient_workflow_phase_milestones?.[0]?.workflow_phase?.name,
                (A, B) => A.localeCompare(B),
                order
              );
              return { ...patient, patient_workflows };
            });
            return {
              ...loc,
              patients: genericSort(
                patients,
                (o) =>
                  o?.patient_workflows?.[0]?.patient_workflow_phase_milestones?.[0]
                    ?.workflow_phase?.name,
                (A, B) => A.localeCompare(B),
                order
              ),
            };
          });
          return genericSort(
            locations,
            (o) =>
              o?.patients?.[0]?.patient_workflows?.[0]
                ?.patient_workflow_phase_milestones?.[0]?.workflow_phase?.name,
            (A, B) => A.localeCompare(B),
            order
          );
        }
        case 'appointmentType': {
          const sortPatientWorkflows = function (patient: PatientNetworkModel) {
            return genericSort(
              patient.patient_workflows,
              (o) => {
                if (o.workflow.workflow_type_id === 1) {
                  return o?.patient_appointment?.appointment_type?.name;
                }
                return patient.nextAppointment?.appointment_type?.name;
              },
              (A, B) => A.localeCompare(B),
              order
            );
          };
          const sortPatients = function (arr: PatientNetworkModel[]) {
            return genericSort(
              arr,
              (o) => {
                if (o.patient_workflows?.[0]?.workflow.workflow_type_id === 1) {
                  return o?.patient_workflows?.[0]?.patient_appointment?.appointment_type
                    ?.name;
                }
                return o.nextAppointment?.appointment_type?.name;
              },
              (A, B) => A.localeCompare(B),
              order
            );
          };
          const sortLocations = function (arr: LocationNetworkModel[]) {
            return genericSort(
              arr,
              (o) => {
                if (
                  o.patients?.[0]?.patient_workflows?.[0]?.workflow.workflow_type_id === 1
                ) {
                  return o?.patients?.[0]?.patient_workflows?.[0]?.patient_appointment
                    ?.appointment_type?.name;
                }
                return o.patients?.[0]?.nextAppointment?.appointment_type?.name;
              },
              (A, B) => A.localeCompare(B),
              order
            );
          };
          const sortWorkflowsOfPatient = function (patient: PatientNetworkModel) {
            const patient_workflows = sortPatientWorkflows(patient);
            return { ...patient, patient_workflows };
          };
          const sortPatientsOfLocation = function (location: LocationNetworkModel) {
            const patients = sortPatients(location.patients.map(sortWorkflowsOfPatient));
            return { ...location, patients };
          };
          const locations = data.map(sortPatientsOfLocation);
          return sortLocations(locations);
        }
        case 'attendedSinceUtc': {
          const locations = data.map((loc) => {
            const patients = genericSort(
              loc.patients,
              (o) => o?.attendedSinceUtc,
              (A, B) => A.localeCompare(B),
              inverseOrder
            );
            return { ...loc, patients };
          });
          return genericSort(
            locations,
            (o) => o?.patients?.[0]?.attendedSinceUtc,
            (A, B) => A.localeCompare(B),
            inverseOrder
          );
        }
        case 'unattendedSinceUtc': {
          const locations = data.map((loc) => {
            const patients = genericSort(
              loc.patients,
              (o) => o?.unattendedSinceUtc,
              (A, B) => A.localeCompare(B),
              inverseOrder
            );
            return { ...loc, patients };
          });
          return genericSort(
            locations,
            (o) => o?.patients?.[0]?.unattendedSinceUtc,
            (A, B) => A.localeCompare(B),
            inverseOrder
          );
        }
        case 'provider': {
          const sortPatientWorkflows = function (patient: PatientNetworkModel) {
            return genericSort(
              patient.patient_workflows,
              (o) => {
                if (o.workflow.workflow_type_id === 1) {
                  return o?.patient_appointment?.provider?.fullName;
                }
                return patient.nextAppointment?.provider?.fullName;
              },
              (A, B) => A.localeCompare(B),
              order
            );
          };
          const sortPatients = function (arr: PatientNetworkModel[]) {
            return genericSort(
              arr,
              (o) => {
                if (o.patient_workflows?.[0]?.workflow.workflow_type_id === 1) {
                  return o?.patient_workflows?.[0]?.patient_appointment?.provider
                    ?.fullName;
                }
                return o.nextAppointment?.provider?.fullName;
              },
              (A, B) => A.localeCompare(B),
              order
            );
          };
          const sortLocations = function (arr: LocationNetworkModel[]) {
            return genericSort(
              arr,
              (o) => {
                if (
                  o.patients?.[0]?.patient_workflows?.[0]?.workflow.workflow_type_id === 1
                ) {
                  return o?.patients?.[0]?.patient_workflows?.[0]?.patient_appointment
                    ?.provider?.fullName;
                }
                return o.patients?.[0]?.nextAppointment?.provider?.fullName;
              },
              (A, B) => A.localeCompare(B),
              order
            );
          };
          const sortWorkflowsOfPatient = function (patient: PatientNetworkModel) {
            const patient_workflows = sortPatientWorkflows(patient);
            return { ...patient, patient_workflows };
          };
          const sortPatientsOfLocation = function (location: LocationNetworkModel) {
            const patients = sortPatients(location.patients.map(sortWorkflowsOfPatient));
            return { ...location, patients };
          };
          const locations = data.map(sortPatientsOfLocation);
          return sortLocations(locations);
        }
        case 'recipients': {
          const locations = data.map((loc) => {
            return {
              ...loc,
              patients: genericSort(
                loc.patients,
                (o) => o?.recipientsNames,
                (A, B) => A.localeCompare(B),
                order
              ),
            };
          });
          return genericSort(
            locations,
            (o) => o?.patients?.[0]?.recipientsNames,
            (A, B) => A.localeCompare(B),
            order
          );
        }
        case 'appointmentTimeUTC': {
          const sortPatientWorkflows = function (patient: PatientNetworkModel) {
            return genericSort(
              patient.patient_workflows,
              (o) => {
                if (o.workflow.workflow_type_id === 1) {
                  return o?.patient_appointment?.appointment_time_utc;
                }
                return patient.nextAppointment?.appointment_time_utc;
              },
              (A, B) => A.localeCompare(B),
              order
            );
          };
          const sortPatients = function (arr: PatientNetworkModel[]) {
            return genericSort(
              arr,
              (o) => {
                if (o.patient_workflows?.[0]?.workflow.workflow_type_id === 1) {
                  return o?.patient_workflows?.[0]?.patient_appointment
                    ?.appointment_time_utc;
                }
                return o.nextAppointment?.appointment_time_utc;
              },
              (A, B) => A.localeCompare(B),
              order
            );
          };
          const sortLocations = function (arr: LocationNetworkModel[]) {
            return genericSort(
              arr,
              (o) => {
                if (
                  o.patients?.[0]?.patient_workflows?.[0]?.workflow.workflow_type_id === 1
                ) {
                  return o?.patients?.[0]?.patient_workflows?.[0]?.patient_appointment
                    ?.appointment_time_utc;
                }
                return o.patients?.[0]?.nextAppointment?.appointment_time_utc;
              },
              (A, B) => A.localeCompare(B),
              order
            );
          };
          const sortWorkflowsOfPatient = function (patient: PatientNetworkModel) {
            const patient_workflows = sortPatientWorkflows(patient);
            return { ...patient, patient_workflows };
          };
          const sortPatientsOfLocation = function (location: LocationNetworkModel) {
            const patients = sortPatients(location.patients.map(sortWorkflowsOfPatient));
            return { ...location, patients };
          };
          const locations = data.map(sortPatientsOfLocation);
          return sortLocations(locations);
        }
        case 'durationInLocation': {
          const locations = data.map((loc) => {
            const patients = genericSort(
              loc.patients,
              (o) => o?.sensed_utc,
              (A, B) => A.localeCompare(B),
              inverseOrder
            );
            return { ...loc, patients };
          });
          return genericSort(
            locations,
            (o) => o?.patients?.[0]?.sensed_utc,
            (A, B) => A.localeCompare(B),
            inverseOrder
          );
        }
        case 'timeInDepartment': {
          const locations = data.map((loc) => {
            const patients = genericSort(
              loc.patients,
              (o) => o?.created_utc,
              (A, B) => A.localeCompare(B),
              inverseOrder
            );
            return { ...loc, patients };
          });
          return genericSort(
            locations,
            (o) => o?.patients?.[0]?.created_utc,
            (A, B) => A.localeCompare(B),
            inverseOrder
          );
        }
        case 'timeInWorkflow': {
          const locations = data.map((loc) => {
            const patients = loc.patients.map((patient) => {
              return {
                ...patient,
                patient_workflows: genericSort(
                  patient.patient_workflows,
                  (o) => o?.created_utc,
                  (A, B) => A.localeCompare(B),
                  inverseOrder
                ),
              };
            });
            return {
              ...loc,
              patients: genericSort(
                patients,
                (o) => o?.patient_workflows?.[0]?.created_utc,
                (A, B) => A.localeCompare(B),
                inverseOrder
              ),
            };
          });
          return genericSort(
            locations,
            (o) => o?.patients?.[0]?.patient_workflows?.[0]?.created_utc,
            (A, B) => A.localeCompare(B),
            inverseOrder
          );
        }
        case 'phaseUpdatedUTC': {
          const locations = data.map((loc) => {
            const patients = loc.patients.map((patient) => {
              return {
                ...patient,
                patient_workflows: genericSort(
                  patient.patient_workflows,
                  (o) => o?.patient_workflow_phase_milestones?.[0].created_utc,
                  (A, B) => A.localeCompare(B),
                  inverseOrder
                ),
              };
            });
            return {
              ...loc,
              patients: genericSort(
                patients,
                (o) =>
                  o?.patient_workflows?.[0]?.patient_workflow_phase_milestones?.[0]
                    .created_utc,
                (A, B) => A.localeCompare(B),
                inverseOrder
              ),
            };
          });
          return genericSort(
            locations,
            (o) =>
              o?.patients?.[0]?.patient_workflows?.[0]
                ?.patient_workflow_phase_milestones?.[0].created_utc,
            (A, B) => A.localeCompare(B),
            inverseOrder
          );
        }
        case 'staffName': {
          const locations = data.map((loc) => {
            const patients = genericSort(
              loc.patients,
              (o) => o?.displayStaff,
              (A, B) => A.localeCompare(B),
              order
            );
            return { ...loc, patients };
          });
          return genericSort(
            locations,
            (o) => o?.patients?.[0]?.displayStaff,
            (A, B) => A.localeCompare(B),
            order
          );
        }
        case 'securedStaffName': {
          const locations = data.map((loc) => {
            const patients = genericSort(
              loc.patients,
              (o) => o?.securedStaff,
              (A, B) => A.localeCompare(B),
              order
            );
            return { ...loc, patients };
          });
          return genericSort(
            locations,
            (o) => o?.patients?.[0]?.securedStaff,
            (A, B) => A.localeCompare(B),
            order
          );
        }
        case 'status': {
          const locations = data.map((loc) => {
            const patients = genericSort(
              loc.patients,
              (o) => o?.status,
              (A, B) => A.localeCompare(B),
              order
            );
            return { ...loc, patients };
          });
          return genericSort(
            locations,
            (o) => o?.patients?.[0]?.status,
            (A, B) => A.localeCompare(B),
            order
          );
        }
        case 'badgeStatus': {
          const locations = data.map((loc) => {
            const patients = genericSort(
              loc.patients,
              (o) => o?.tag?.status,
              (A, B) => A.localeCompare(B),
              order
            );
            return { ...loc, patients };
          });
          return genericSort(
            locations,
            (o) => o?.patients?.[0]?.tag?.status,
            (A, B) => A.localeCompare(B),
            order
          );
        }
        default:
          console.warn(
            `A sorting function was not specified for "${active}" column. Using string by default.`
          );
          return data;
      }
    };
  }

  highlightLocation(locationId) {
    this.highlightPatientId = null;
    if (this.highlightLocationId === locationId) {
      this.highlightLocationId = null;
    } else {
      this.highlightLocationId = locationId;
    }
  }

  highlightPatient(locationId, patientId) {
    const locationPatientId = [locationId, patientId].join('_');
    if (this.highlightLocationId === locationId) {
      if (this.highlightPatientId === locationPatientId) {
        this.highlightPatientId = null;
        this.highlightLocationId = null;
      } else {
        this.highlightPatientId = locationPatientId;
      }
    } else {
      this.highlightLocationId = locationId;
      this.highlightPatientId = locationPatientId;
    }
  }

  isPatientHighlighted(locationId, patientId) {
    const locationPatientId = [locationId, patientId].join('_');
    const locationSelected = this.highlightLocationId === locationId;
    const patientSelected = this.highlightPatientId === locationPatientId;
    const noPatientSelected = this.highlightPatientId === null;
    if (!locationSelected) {
      return false;
    }
    return patientSelected || noPatientSelected;
  }

  onAdvancePhase(patientWorkflowId, workflowId, workflowName, patientVisitId) {
    this.advancePhase.emit({
      patientWorkflowId,
      workflowId,
      workflowName,
      patientVisitId,
    });
  }

  onChangeDepartment(id) {
    this.changeDepartment.emit(id);
  }

  onOpenVisitDetail(id: number) {
    this.openVisitDetail.emit(id.toString());
  }

  onChangePhase(patientWorkflowId, workflowId) {
    this.changePhase.emit({ patientWorkflowId, workflowId });
  }

  onEndVisit(id) {
    this.endVisit.emit(id);
  }

  onDeletePatient(id) {
    this.deletePatient.emit(id);
  }

  onUpdateLocation(id, newStatus) {
    this.updateLocation.emit({ id, newStatus });
  }

  trackByFn(index, item) {
    return item.id;
  }
}

function genericSort<T = unknown, U = unknown>(
  data: T[],
  valueAccessor: (item: T) => U,
  sortFn: (a: U, b: U) => number,
  order: 1 | -1
): T[] {
  return data.slice().sort((a, b) => {
    const A = valueAccessor(a) || null;
    const B = valueAccessor(b) || null;
    if (A === B) {
      return 0;
    }
    if (A === null) {
      return 1;
    }
    if (B === null) {
      return -1;
    }
    return sortFn(A, B) * order;
  });
}
