export const StaffTypeColorTranslation: { [key: number]: string } = {
  7: '#5A3795',
  8: '#5A3795',
  9: '#21A3DD',
  10: '#21A3DD',
  11: '#21A3DD',
  12: '#21A3DD',
  13: '#21A3DD',
  14: '#21A3DD',
  15: '#21A3DD',
  16: '#B1E0F4',
  17: '#B1E0F4',
  18: '#B1E0F4',
  19: '#76879C',
  20: '#76879C',
  21: '#76879C',
  22: '#059DA8',
  23: '#B6D444',
  24: '#B6D444',
  25: '#B6D444',
  26: '#B6D444',
  27: '#B6D444',
  28: '#B6D444',
  29: '#B6D444',
  30: '#B6D444',
  31: '#21A3DD',
  32: '#B6D444',
  33: '#21A3DD',
  34: '#21A3DD',
  35: '#5A3795',
  36: '#21A3DD',
  37: '#B6D444',
  38: '#B6D444',
  39: '#76879C',
  40: '#B6D444',
  41: '#B6D444',
  42: '#B6D444',
  43: '#B6D444',
  44: '#B6D444',
  45: '#B1E0F4',
  46: '#76879C',
  47: '#5A3795',
  48: '#B1E0F4',
  49: '#5A3795',
  50: '#B6D444',
};
