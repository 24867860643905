import { ActionReducerMap } from '@ngrx/store';

import { configReducer, ConfigState } from './config/config.reducer';
import { operationsReducer, OperationsState } from './operations/operations.reducer';

export interface BulkUploaderState {
  operations: OperationsState;
  config: ConfigState;
}

export const reducers: ActionReducerMap<BulkUploaderState> = {
  operations: operationsReducer,
  config: configReducer,
};
