import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { SharedUiSkeletonVisitDetailModuleModule } from '@frontend/common/skeletons';
import { AppointmentsComponent } from './appointments.component';

@NgModule({
  imports: [
    CommonModule,
    SharedUiSkeletonVisitDetailModuleModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
  ],
  declarations: [AppointmentsComponent],
  exports: [AppointmentsComponent],
})
export class AppointmentsModule {}
