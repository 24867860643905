import { createAction, createReducer, on } from '@ngrx/store';

export interface ApiFetchState {
  loading: boolean;
  loaded: boolean;
}

export const apiFetchInitialState: ApiFetchState = {
  loading: false,
  loaded: false,
};

export const apiFetchLoad = (prefix: string) =>
  createAction(`${prefix} [Api Fetch] Load`);
export const apiFetchLoadSuccess = (prefix: string) =>
  createAction(`${prefix} [Api Fetch] Load Success`);
export const apiFetchLoadFailure = (prefix: string) =>
  createAction(`${prefix} [Api Fetch] Load Failure`);
export const apiFetchReset = (prefix: string) =>
  createAction(`${prefix} [Api Fetch] Reset`);

export const load = (state) => Object.assign({}, state, { loading: true, loaded: false });
export const loadSuccess = (state) =>
  Object.assign({}, state, { loading: false, loaded: true });
export const loadFailure = (state) => Object.assign({}, state, { loading: false });
export const reset = () => apiFetchInitialState;

export const apiFetchReducer = (prefix) => {
  if (typeof prefix !== 'string') {
    throw new Error('Reducer missing configuration');
  }
  return createReducer(
    apiFetchInitialState,
    on(apiFetchLoad(prefix), (state) => load(state)),
    on(apiFetchLoadSuccess(prefix), (state) => loadSuccess(state)),
    on(apiFetchLoadFailure(prefix), (state) => loadFailure(state)),
    on(apiFetchReset(prefix), () => reset())
  );
};
