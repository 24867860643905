import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@frontend/shared/base-store';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { Patient } from './patient.model';

@Injectable()
export class PatientsService extends BaseService<Patient> {
  constructor(http: HttpClient) {
    super(http, 'patients');
  }

  public create(entity: Patient): Observable<object> {
    return this.saveSecureData(entity).pipe(
      concatMap((securable_id: { id: string }) => {
        const keys = [
          'securable_id',
          'secured_patient_name',
          'location_alias_name',
          'tag_id',
          'department_id',
          'correlation_id',
          'current_workflow_ids',
          'appointment_time_utc',
          'provider',
          'first_name',
          'note',
          'additional_note',
          'assigned_staff_id',
          'appointment_type_id',
        ];
        const payload = keys.reduce((acc, key) => ({ ...acc, [key]: entity[key] }), {
          securable_id: securable_id.id,
        });
        return this.http.post('patients', payload);
      })
    );
  }

  public edit(entity): Observable<object> {
    return this.saveSecureData(entity).pipe(
      concatMap((securable_id: { id: string }) => {
        const keys = [
          'id',
          'securable_id',
          'secured_patient_name',
          'location_alias_name',
          'tag_id',
          'department_id',
          'correlation_id',
          'current_workflow_ids',
          'appointment_time_utc',
          'provider',
          'first_name',
          'note',
          'additional_note',
          'assigned_staff_id',
          'appointment_type_id',
        ];
        const payload = keys.reduce((acc, key) => ({ ...acc, [key]: entity[key] }), {
          securable_id: securable_id.id,
        });
        return this.http.put(`patients/${entity.id}`, payload);
      })
    );
  }

  public getSecure(id): Observable<object> {
    return this.http.get(`securable/${id}`);
  }

  public getSecureMultiple(id): Observable<object> {
    return this.http.post('securable/get-bulk', { id });
  }

  public getWorkflowEvents(patient_department_visit_workflow_id): Observable<any[]> {
    return this.http.get<object[]>(`patient-workflow-events`, {
      params: { patient_department_visit_workflow_id },
    });
  }

  private saveSecureData({ first_name, last_name, mrn, phone_number }) {
    const secure = { first_name, last_name, mrn, phone_number };
    return this.http.post('securable', secure);
  }

  public endVisit(id, correlation_id) {
    return this.http.patch(
      `patients/${id}/complete`,
      { correlation_id },
      { params: { id } }
    );
  }

  public endVisitAndReassignDepartment(id, department_id, correlation_id) {
    return this.http.patch(
      `patients/${id}/complete`,
      { correlation_id, reassign_department_id: department_id },
      { params: { id } }
    );
  }
}
