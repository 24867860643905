<ng-template #skeletonTmpl>
  <skeleton-visit-detail-module></skeleton-visit-detail-module>
</ng-template>

<ng-container *ngIf="srv.cardData$| async as cardData else skeletonTmpl">
  <div class="module-content">
    <div class="header-line">
      <h4 i18n class="module-title">Workflows</h4>

      <div>
        <ng-container *ngIf="
            cardData.patientWorkflows.length;
            then multiActionTmpl;
            else singleActionTmpl
          "></ng-container>
        <ng-template #multiActionTmpl>
          <button mat-button mat-icon-button disableRipple [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item
              [mat-dialog-close]="[cardData.departmentId ,'patients', srv.data.visitId, 'manage-workflows']">
              <span i18n>Manage Workflows</span>
            </button>
            <ng-template #changePhaseDirect>
              <button mat-menu-item (click)="
                  openChangePhaseDialog(
                    cardData.patientWorkflows[0].id,
                    cardData.patientWorkflows[0].workflowId
                  )
                ">
                <span i18n>Change Phase...</span>
              </button>
            </ng-template>
            <ng-template #changePhaseMulti>
              <button mat-menu-item [matMenuTriggerFor]="changePhaseMenu" *ngIf="cardData.patientWorkflows.length">
                <span i18n>Change Phase...</span>
              </button>
              <mat-menu #changePhaseMenu="matMenu">
                <ng-container *ngFor="let pw of cardData.patientWorkflows; trackBy: trackByFn">
                  <button mat-menu-item (click)="openChangePhaseDialog(pw.id, pw.workflowId)">
                    <span>
                      <span i18n>...from </span>
                      <b>{{ pw.name }}</b>
                    </span>
                  </button>
                </ng-container>
              </mat-menu>
            </ng-template>
            <ng-container *ngIf="cardData.patientWorkflows.length">
              <ng-template *ngIf="
                  cardData.patientWorkflows.length > 1;
                  then changePhaseMulti;
                  else changePhaseDirect
                "></ng-template>
            </ng-container>
          </mat-menu>
        </ng-template>
        <ng-template #singleActionTmpl>
          <a mat-icon-button
            [mat-dialog-close]="[cardData.departmentId ,'patients', srv.data.visitId, 'manage-workflows']">
            <mat-icon>edit</mat-icon>
          </a>
        </ng-template>
      </div>
    </div>

    <ng-container *ngIf="
        cardData.patientWorkflows.length;
        then patientWorkflowsTmpl;
        else noPatientWorkflows
      "></ng-container>

    <ng-template #patientWorkflowsTmpl>
      <div class="workflows-list">
        <ng-container *ngFor="let pw of cardData.patientWorkflows; let last = last; trackBy: trackByFn">
          <div class="workflows-list-item">
            <div class="text-line-ellipsis primary-text">
              <!--workflow info -->
              <div [title]="pw.name" class="text-line-ellipsis">
                ({{ pw.workflowDuration }}) {{ pw.name }}
              </div>
              <!--current phase info-->
              <div class="text-line-ellipsis">
                <ng-container [ngSwitch]="pw.phaseState">
                  <ng-container *ngSwitchCase="'red'">
                    <span class="indicator red-indicator"></span>
                  </ng-container>
                  <ng-container *ngSwitchCase="'yellow'">
                    <span class="indicator yellow-indicator"></span>
                  </ng-container>
                  <ng-container *ngSwitchCase="'blank'">
                    <span class="indicator" [ngStyle]="{ 'background-color': null }"></span>
                  </ng-container>
                </ng-container>

                <span> ({{ pw.phaseDuration }}) </span>
                <span [title]="pw.phaseName">
                  {{ pw.phaseName }}
                </span>
              </div>
              <!--call event info-->
              <div *ngIf="pw.calledBy" class="text-line-ellipsis">
                <span class="indicator"></span>
                <span [title]="pw.calledBy + '&nbsp;' + pw.stationName">
                  ({{ pw.calledSinceDuration }})&nbsp;{{ pw.calledBy }}&nbsp;{{
                  pw.stationName
                  }}
                </span>
              </div>
            </div>

            <div *ngIf="pw.isCallablePhase">
              <button mat-button mat-icon-button disableRipple title="Call Patient" i18n-title
                (click)="onCallPatient(pw.id, pw.phaseId)">
                <mat-icon>notifications_active</mat-icon>
              </button>
            </div>
          </div>
          <div class="divider spacer" *ngIf="!last"></div>
        </ng-container>
      </div>
    </ng-template>

    <ng-template #noPatientWorkflows>
      <div>
        <span class="secondary-text" i18n>No active workflows</span>
      </div>
    </ng-template>
  </div>
</ng-container>