import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getParams } from '@frontend/common/ph-router-store';
import { ExpectedError, SocketService } from '@frontend/common/util';
import { createSelector, Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import {
  combineLatest,
  EMPTY,
  forkJoin,
  merge,
  Observable,
  of,
  race,
  throwError,
} from 'rxjs';
import {
  catchError,
  filter,
  first,
  ignoreElements,
  map,
  mapTo,
  mergeMap,
  shareReplay,
  startWith,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import * as uuid from 'uuid/v4';
import {
  LocationDataQuery,
  LocationItem,
  Patient,
  PatientDataQuery,
  WorkflowDataQuery,
  WorkflowItem,
} from './manage-workflows-dialog.graphql';
import { SecurableService } from './securable.service';

interface QueryVariables {
  department_id: number;
  patient_id: number;
}

const dialogInput = createSelector(getParams, ({ department_id, patient_id }) => ({
  department_id,
  patient_id,
}));

@Injectable()
export class ManageWorkflowsService {
  _param: Observable<QueryVariables>;
  loading: Observable<boolean>;
  patientRaw: Observable<Patient>;
  patient: Observable<Patient>;
  workflowList: Observable<WorkflowItem[]>;
  locationList: Observable<LocationItem[]>;

  constructor(
    private store: Store,
    private apollo: Apollo,
    private securableSrv: SecurableService,
    private http: HttpClient,
    private socketSrv: SocketService
  ) {
    this._param = this.store.select(dialogInput).pipe(first());
    this.patientRaw = this._param.pipe(
      switchMap((params) => this._getPatientById(params.patient_id)),
      shareReplay(1)
    );
    this.patient = this.patientRaw.pipe(catchError(() => EMPTY));
    this.workflowList = this._param.pipe(
      switchMap((params) => this._getWorkflowsByDepartmentId(params.department_id)),
      shareReplay(1)
    );
    this.locationList = this.patient.pipe(
      withLatestFrom(this._param),
      switchMap(([patient, params]) =>
        this._getAvailableCareLocations(
          params.department_id,
          patient.assigned_location_id
        )
      ),
      shareReplay(1)
    );
    this.loading = merge(
      this._param.pipe(mapTo(true)),
      combineLatest([this.patient, this.workflowList, this.locationList]).pipe(
        mapTo(false)
      )
    ).pipe(startWith(true));
  }

  save(id, assigned_location_id, start, end) {
    const patientOp = this._updatePatient(id, assigned_location_id);
    if (!start.length && !end.length) {
      return patientOp;
    }
    const workflowsOp = this._updateWorkflows(id, start, end);
    return forkJoin([patientOp].concat(workflowsOp));
  }

  private _getPatientById(patientId: number) {
    return this.apollo
      .subscribe<PatientDataQuery>({
        query: PatientDataQuery,
        variables: { patientId },
      })
      .pipe(
        map((result) => {
          if (!result.data.patient_department_visit.length) {
            throw new ExpectedError($localize`Patient does not exist.`);
          }
          return result.data.patient_department_visit[0];
        }),
        switchMap((patient) => this.securableSrv.get(patient))
      );
  }

  private _getWorkflowsByDepartmentId(departmentId: number) {
    return this.apollo
      .subscribe<WorkflowDataQuery>({
        query: WorkflowDataQuery,
        variables: { departmentId },
      })
      .pipe(map((result) => result.data.workflow));
  }

  private _getAvailableCareLocations(departmentId: number, currentLocationId: number) {
    return this.apollo
      .subscribe<LocationDataQuery>({
        query: LocationDataQuery,
        variables: { departmentId, currentLocationId: currentLocationId || [] },
      })
      .pipe(map((result) => result.data.department_location));
  }

  _updatePatient(id: number, assigned_location_id: number) {
    const correlation_id = 'correlation|' + uuid();
    const payload = { id, assigned_location_id, correlation_id };
    return merge(
      this.http.put(`patients/${id}`, payload).pipe(
        ignoreElements(),
        catchError((err) =>
          of(err?.error?.message).pipe(mergeMap((message) => throwError({ id, message })))
        )
      ),
      race(
        this.socketSrv
          .fromLocalSuccess<any>('patients.update')
          .pipe(filter((ws) => ws.correlation_id === correlation_id)),
        this.socketSrv.fromLocalError('patients.update').pipe(
          filter((ws) => ws.correlation_id === correlation_id),
          mergeMap(({ message }) => throwError({ id, message }))
        )
      ).pipe(first())
    );
  }

  _updateWorkflows(pdvId: number, start: number[] = [], end: number[] = []) {
    const endOp = end.map((pwId) => this._endWorkflow(pwId));
    const startOp = start.map((wId) => this._startWorkflow(pdvId.toString(), wId));
    return forkJoin([].concat(endOp, startOp));
  }

  _startWorkflow(patient_department_visit_id: string, workflow_id: number) {
    const correlation_id = 'correlation|' + uuid();
    return this.http.post(`patient-workflows`, {
      correlation_id,
      patient_department_visit_id,
      workflow_id,
    });
  }

  _endWorkflow(pwId: number) {
    const correlation_id = 'correlation|' + uuid();
    return this.http.patch(`patient-workflows/${pwId}/complete`, { correlation_id });
  }
}
