import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import {
  CreateWorkflowApi,
  CreateWorkflowFailureApi,
  CreateWorkflowSuccessApi,
  DeleteWorkflowApi,
  DeleteWorkflowFailureApi,
  DeleteWorkflowSuccessApi,
  DisableWorkflowApi,
  DisableWorkflowFailureApi,
  DisableWorkflowSuccessApi,
  DuplicateWorkflowApi,
  DuplicateWorkflowFailureApi,
  DuplicateWorkflowSuccessApi,
  EnableWorkflowApi,
  EnableWorkflowFailureApi,
  EnableWorkflowSuccessApi,
  LoadWorkflowApi,
  LoadWorkflowFailureApi,
  LoadWorkflowsApi,
  LoadWorkflowsFailureApi,
  LoadWorkflowsSuccessApi,
  LoadWorkflowSuccessApi,
  UpdateWorkflowApi,
  UpdateWorkflowFailureApi,
  UpdateWorkflowSuccessApi,
  WorkflowApiActionTypes,
} from './workflow-api.actions';
import { WorkflowApiService } from './workflow-api.service';

@Injectable()
export class WorkflowApiEffects {
  loadWorkflowsApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkflowApiActionTypes.LoadWorkflowsApi),
      switchMap(({ payload: { params } }: LoadWorkflowsApi) =>
        this.srv.get(params).pipe(
          map((workflows) => new LoadWorkflowsSuccessApi({ workflows })),
          catchError((err) => of(new LoadWorkflowsFailureApi(err)))
        )
      )
    )
  );

  loadOneWorkflowsApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkflowApiActionTypes.LoadWorkflowApi),
      switchMap(({ payload: { id } }: LoadWorkflowApi) =>
        this.srv.getOne(id).pipe(
          map((workflow) => new LoadWorkflowSuccessApi({ workflow })),
          catchError(({ message }) => of(new LoadWorkflowFailureApi({ id, message })))
        )
      )
    )
  );

  createWorkflowApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkflowApiActionTypes.CreateWorkflowApi),
      mergeMap(({ payload }: CreateWorkflowApi) =>
        this.srv.create(payload.workflow).pipe(
          map((workflow) => new CreateWorkflowSuccessApi({ workflow })),
          catchError((error) => of(new CreateWorkflowFailureApi(error)))
        )
      )
    )
  );

  updateWorkflowApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkflowApiActionTypes.UpdateWorkflowApi),
      mergeMap(({ payload }: UpdateWorkflowApi) =>
        this.srv.update(payload.workflow).pipe(
          map((workflow) => new UpdateWorkflowSuccessApi({ workflow })),
          catchError((error) => of(new UpdateWorkflowFailureApi(error)))
        )
      )
    )
  );

  deleteWorkflowApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkflowApiActionTypes.DeleteWorkflowApi),
      mergeMap(({ payload }: DeleteWorkflowApi) =>
        this.srv.delete(payload.id).pipe(
          map(({ id }) => new DeleteWorkflowSuccessApi({ id })),
          catchError((err) => of(new DeleteWorkflowFailureApi(err)))
        )
      )
    )
  );

  duplicateWorkflowApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkflowApiActionTypes.DuplicateWorkflowApi),
      mergeMap((action: DuplicateWorkflowApi) =>
        this.srv.duplicate(action.payload.id, action.payload.correlation_id).pipe(
          map((workflow) => new DuplicateWorkflowSuccessApi({ workflow })),
          catchError((error) => of(new DuplicateWorkflowFailureApi(error)))
        )
      )
    )
  );

  enableWorkflowApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkflowApiActionTypes.EnableWorkflowApi),
      mergeMap(({ payload }: EnableWorkflowApi) =>
        this.srv.enable(payload.id).pipe(
          map(({ id }) => new EnableWorkflowSuccessApi({ id })),
          catchError((err) => of(new EnableWorkflowFailureApi(err)))
        )
      )
    )
  );

  disableWorkflowApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkflowApiActionTypes.DisableWorkflowApi),
      mergeMap(({ payload }: DisableWorkflowApi) =>
        this.srv.disable(payload.id).pipe(
          map(({ id }) => new DisableWorkflowSuccessApi({ id })),
          catchError((err) => of(new DisableWorkflowFailureApi(err)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private srv: WorkflowApiService) {}
}
