<h1 mat-dialog-title i18n>Bulk Upload Patients</h1>

<div mat-dialog-content class="mat-typography">
  <p i18n>
    Upload all the patients with appointments for the day in a single step. Patients will
    receive an appointment confirmation text message as soon as they are added to the
    system.
  </p>
  <p i18n>
    To begin the upload process,
    <a href="javascript:void(0)" class="mat-body-2" (click)="srv.downloadInstructions()">
      click here for instructions</a>,<br />
    and
    <a href="javascript:void(0)" class="mat-body-2" (click)="downloadTemplate()">
      click here to download the CSV template. </a><br />
    A CSV spreadsheet will be downloaded to your computer.
    <br />
  </p>
  <p i18n>
    <strong>Note</strong>
    <br />
    The instructions file contains necessary information to fill out the template.
  </p>
</div>

<div mat-dialog-actions align="end">
  <div>
    <input hidden type="file" #csvFileInput (change)="onFileUpload($event.target.files)" />
    <button mat-raised-button color="primary" (click)="csvFileInput.click()">
      <mat-icon>file_upload</mat-icon>
      <span i18n>Choose File to Upload</span>
    </button>
  </div>
</div>
