import { MilestoneType, MilestoneTypes } from '@frontend/common/util';
import { Department, Tag, User } from './common-interfaces';

export interface CreatePatientFragment {
  __typename: MilestoneType;
  created_utc: string;
  created_by: string;
  tag?: Tag;
  department: Department;
  user?: User;
}

export const CREATE_PATIENT_FRAGMENT = `
  fragment ${MilestoneTypes.CreatePatientMilestone} on ${MilestoneTypes.CreatePatientMilestone} {
    __typename
    created_utc
    created_by
    tag(with_deleted: true) {
      label
      tag_value
    }
    department {
      name
    }
    user(with_deleted: true) {
      last_name
      first_name
      email
    }
  }
`;
