<ng-template #skeletonTmpl>
  <skeleton-visit-detail-module></skeleton-visit-detail-module>
</ng-template>

<ng-container *ngIf="srv.cardData$ | async as cardData; else skeletonTmpl">
  <div class="module-content">
    <div class="header-line">
      <h4 i18n class="module-title">Status</h4>
    </div>

    <div>
      <span class="secondary-text" i18n>Department | Site</span>
      <div class="value primary-text">
        <span>({{cardData.departmentDuration}}) {{cardData.departmentName}} / {{cardData.siteName}}</span>
      </div>
    </div>

    <ng-container *ngIf="cardData.locationName">
      <div class="divider spacer"></div>

      <div>
        <span class="secondary-text" i18n>Location</span>
        <div class="value primary-text">
          <span>({{cardData.locationDuration}}) {{cardData.locationName}}</span>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="cardData.isInCareLocation">
      <div class="divider spacer"></div>

      <ng-container *ngIf="cardData.attentions?.length then attendedTmpl; else unattendedTmpl"></ng-container>

      <ng-template #attendedTmpl>
        <div>
          <span class="secondary-text" i18n>Attended</span>
          <div class="value primary-text">
            <p *ngFor="let attention of cardData.attentions; trackBy: trackByFn">
              <span class="indicator"
                [ngStyle]="{ 'background-color': attention.indicatorColor }"></span>({{attention.attentionDuration}})
              {{attention.status}}: {{attention.staffFullName}}
            </p>
          </div>
        </div>
      </ng-template>

      <ng-template #unattendedTmpl>
        <div>
          <span class="secondary-text" i18n>Unattended</span>
          <div class="value primary-text">
            <span i18n><span class="indicator unattended"></span>({{cardData.unattendedDuration}}) Waiting for
              service</span>
          </div>
        </div>
      </ng-template>
    </ng-container>

  </div>
</ng-container>