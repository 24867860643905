import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ChatMessage } from './chat-message.model';

@Component({
  selector: 'frontend-chat-message-list',
  templateUrl: 'chat-message-list.component.html',
  styleUrls: ['chat-message-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMessageListComponent {
  @Input() messages: ChatMessage[];
}
