import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Apollo } from 'apollo-angular';
import { combineLatest, EMPTY, Observable, ReplaySubject, Subject } from 'rxjs';
import { catchError, map, pluck, startWith } from 'rxjs/operators';
import { dataManipulation } from './data-manipulation';
import { CmptData } from './department-selector.component';
import { DepartmentSelectorDataQuery } from './department-selector.graphql';

@Injectable()
export class DepartmentSelectorService {
  searchValue$ = new Subject<string>();
  _cmptDataRaw$: ReplaySubject<CmptData> = new ReplaySubject(1);
  _cmptData$ = this._cmptDataRaw$.pipe(catchError(() => EMPTY));
  _departments$ = this._cmptData$.pipe(pluck('departments'));
  filteredItems$ = combineLatest([
    this._departments$,
    this.searchValue$.pipe(startWith('')),
  ]).pipe(
    map(([items, str]) =>
      items.filter((item) =>
        [item.name, item.siteName].join(' ').toLowerCase().includes(str.toLowerCase())
      )
    )
  );

  constructor(private apollo: Apollo, private snackBar: MatSnackBar) {
    this._getDepartments().subscribe(this._cmptDataRaw$);

    this._cmptDataRaw$.subscribe({
      error: (err) => {
        const message = $localize`There was an unexpected error.`;
        this.snackBar.open(message, $localize`Close`, { duration: 10000 });
        throw err;
      },
    });
  }

  _getDepartments(): Observable<CmptData> {
    return this.apollo
      .query<DepartmentSelectorDataQuery>({
        query: DepartmentSelectorDataQuery,
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((result) => {
          if (!result.data) {
            throw new Error('Error getting department (result.data was falsy)');
          }
          return result.data;
        }),
        map(dataManipulation)
      );
  }
}
