import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { InformationBoxComponent } from './information-box/information-box.component';

@NgModule({
  imports: [CommonModule, MatCardModule, MatIconModule],
  declarations: [InformationBoxComponent],
  exports: [InformationBoxComponent],
})
export class InformationBoxModule {}
