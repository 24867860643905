import { ActionTypes, BaseActions } from '@frontend/shared/base-store';
import { Location } from '../../locations/location.model';
import { Phase } from '../phase.model';

export interface PhasesActionTypes extends ActionTypes {
  LOAD_LOCATIONS: string;
  LOAD_LOCATIONS_SUCCESS: string;
  LOAD_LOCATIONS_FAIL: string;
  ADD_LOCATION: string;
  ADD_LOCATION_SUCCESS: string;
  ADD_LOCATION_FAIL: string;
  REMOVE_LOCATION: string;
  REMOVE_LOCATION_SUCCESS: string;
  REMOVE_LOCATION_FAIL: string;
  SET_SORT_ORDER: string;
}

class PhasesActions extends BaseActions<Phase> {
  public types: PhasesActionTypes;

  constructor() {
    super(Phase);
    this.types = {
      ...this.types,
      LOAD_LOCATIONS: this.getTypeString('LOAD_LOCATIONS'),
      LOAD_LOCATIONS_SUCCESS: this.getTypeString('LOAD_LOCATIONS_SUCCESS'),
      LOAD_LOCATIONS_FAIL: this.getTypeString('LOAD_LOCATIONS_FAIL'),
      ADD_LOCATION: this.getTypeString('ADD_LOCATION'),
      ADD_LOCATION_SUCCESS: this.getTypeString('ADD_LOCATION_SUCCESS'),
      ADD_LOCATION_FAIL: this.getTypeString('ADD_LOCATION_FAIL'),
      REMOVE_LOCATION: this.getTypeString('REMOVE_LOCATION'),
      REMOVE_LOCATION_SUCCESS: this.getTypeString('REMOVE_LOCATION_SUCCESS'),
      REMOVE_LOCATION_FAIL: this.getTypeString('REMOVE_LOCATION_FAIL'),
      SET_SORT_ORDER: this.getTypeString('SET_SORT_ORDER'),
    };
  }

  loadLocations(payload: { id: number }) {
    return { type: this.types.LOAD_LOCATIONS, payload };
  }

  loadLocationsSuccess(payload: { id: number; locations: Location[] }) {
    return { type: this.types.LOAD_LOCATIONS_SUCCESS, payload };
  }

  loadLocationsFail(payload) {
    return { type: this.types.LOAD_LOCATIONS_FAIL, payload };
  }

  addLocation(payload: { phase: Phase; location: Location }) {
    return { type: this.types.ADD_LOCATION, payload: this.correlate(payload) };
  }

  addLocationSuccess(payload) {
    return { type: this.types.ADD_LOCATION_SUCCESS, payload };
  }

  addLocationFail(payload) {
    return { type: this.types.ADD_LOCATION_FAIL, payload };
  }

  removeLocation(payload: { phase: Phase; location: Location }) {
    return { type: this.types.REMOVE_LOCATION, payload: this.correlate(payload) };
  }

  removeLocationSuccess(payload) {
    return { type: this.types.REMOVE_LOCATION_SUCCESS, payload };
  }

  removeLocationFail(payload) {
    return { type: this.types.REMOVE_LOCATION_FAIL, payload };
  }

  setSortOrder(payload: Phase) {
    return { type: this.types.SET_SORT_ORDER, payload: this.correlate(payload) };
  }
}

export const phasesActions = new PhasesActions();
