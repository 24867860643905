import gql from 'graphql-tag';

export const UNASSIGNED_VISITS_DATA_QUERY = gql(`
query UnassignedVisitsData($departmentId: Int!) {
	patient_department_visit_aggregate(
		where: {
			department_id: { _eq: $departmentId }
			completed_utc: { _is_null: true }
			deleted_utc: { _is_null: true }
			_not: {
				patient_department_visit_workflows: { completed_utc: { _is_null: true } }
			}
		}
	) {
		aggregate {
			count
		}
	}
}
`);
