<ng-container
  *ngIf="loading$ | async; then skeletonTmpl; else contentTmpl"
></ng-container>

<ng-template #skeletonTmpl>
  <skeleton-chat-right></skeleton-chat-right>
</ng-template>

<ng-template #contentTmpl>
  <div style="display: flex; flex-direction: column; height: 580px">
    <frontend-chat-header
      [fullName]="(state$ | async).fullName"
      [totalMessages]="(state$ | async).totalMessages"
      style="display: flex; align-items: center; flex-shrink: 0; height: 80px"
    ></frontend-chat-header>

    <div
      *ngIf="!((state$ | async).totalMessages > 0)"
      style="display: flex; flex-grow: 1; justify-content: center; align-items: center"
    >
      <frontend-chat-no-messages></frontend-chat-no-messages>
    </div>

    <frontend-chat-message-list
      *ngIf="(state$ | async).totalMessages > 0"
      [messages]="(state$ | async).messages"
      style="display: flex; flex-grow: 1; align-items: flex-end; overflow-y: auto"
    ></frontend-chat-message-list>

    <frontend-chat-mark-all
      (markAll)="onMarkAllSeen()"
      *ngIf="(state$ | async).totalUnseen > 0"
    ></frontend-chat-mark-all>

    <frontend-chat-form
      style="padding: 16px; display: flex"
      [quickMessages]="(state$ | async).quickMessages"
      [disabled]="sending$ | async"
      (send)="onSend($event)"
    ></frontend-chat-form>
  </div>
</ng-template>
