import { Action } from '@ngrx/store';

export enum AppActionTypes {
  LoadApp = '[App] Load',
  LoadAppSuccess = '[App] Load Success',
  LoadAppFailure = '[App] Load Failure',
  ShowNotification = '[App] Show Notification',
}

export class LoadApp implements Action {
  readonly type = AppActionTypes.LoadApp;
}

export class LoadAppSuccess implements Action {
  readonly type = AppActionTypes.LoadAppSuccess;
}

export class LoadAppFailure implements Action {
  readonly type = AppActionTypes.LoadAppFailure;
}

export type AppActions = LoadApp | LoadAppSuccess | LoadAppFailure;
