import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DowntimeNotificationComponent } from '@frontend/common/downtime-notification';
import { IdleService } from '@frontend/common/idle';
import { PhAuthService } from '@frontend/common/ph-auth';
import { PhConfigLoaderService } from '@frontend/common/ph-config-loader';
import { getParams, Go } from '@frontend/common/ph-router-store';
import {
  CurrentDepartmentService,
  CurrentUserService,
  Document360Service,
  ProductId,
  ProductTranslation,
  SocketService,
  UserNotificationService,
} from '@frontend/common/util';
import { customersSelectors, departmentsSelectors } from '@frontend/entity';
import { PatientSearchComponent } from '@frontend/workflow-rt/feature/patient-search/src';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { utc } from 'moment';
import { Observable } from 'rxjs';
import {
  defaultIfEmpty,
  distinctUntilChanged,
  filter,
  first,
  map,
  mergeMap,
  share,
} from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AppContentWrapperService } from './app-content-wrapper.service';

@UntilDestroy()
@Component({
  selector: 'wrt-content-wrapper',
  templateUrl: './app-content-wrapper.component.html',
  styleUrls: ['./app-content-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [Document360Service, AppContentWrapperService],
})
export class AppContentWrapperComponent implements OnDestroy, OnInit {
  ProductTranslation = ProductTranslation;
  ProductId = ProductId;

  departmentId$: Observable<number>;
  isSwitchButtonVisible$: Observable<boolean>;
  department$: any;
  isAllowedToReadReports$: Observable<boolean>;
  currentDate = utc().format('YYYY');
  hasNotifications$ = this.userNotificationSrv.notification$;
  version = environment.version;

  accessReports$ = this.phAuthSrv.scopes$.pipe(
    map((scopes) => scopes.includes('ui:wrt:reports'))
  );
  accessContactTracing$ = this.phAuthSrv.scopes$.pipe(
    map((scopes) => scopes.includes('ui:wrt:contact-tracing'))
  );

  constructor(
    public srv: AppContentWrapperService,
    private store: Store,
    private config: PhConfigLoaderService,
    private idleSrv: IdleService,
    private socketService: SocketService,
    private renderer: Renderer2,
    private document360Srv: Document360Service,
    private currentUserSrv: CurrentUserService,
    private userNotificationSrv: UserNotificationService,
    private bottomSheet: MatBottomSheet,
    private phAuthSrv: PhAuthService,
    private currentDepartmentSrv: CurrentDepartmentService,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) =>
      this.currentDepartmentSrv.load(params.department_id)
    );
    this.departmentId$ = this.store.pipe(
      select(getParams),
      map((params) => parseInt(params.department_id, 10)),
      filter((id) => !!id),
      distinctUntilChanged()
    );
    this.department$ = this.departmentId$.pipe(
      mergeMap((id) =>
        this.store.pipe(
          select(departmentsSelectors.getAll),
          map((deps: { id: number }[]) => deps.find((dep) => dep.id === id))
        )
      )
    );
    this.isSwitchButtonVisible$ = this.store.pipe(
      select(departmentsSelectors.getTotal),
      map((t) => t > 1)
    );
    this.department$.pipe(untilDestroyed(this)).subscribe((department) => {
      if (!department) {
        this.store.dispatch(
          new Go({
            path: [''],
            extras: { skipLocationChange: true },
          })
        );
      }
    });
    const customer$ = this.store.pipe(
      select(customersSelectors.getCurrentCustomer),
      filter((customer) => !!customer),
      first(),
      share(),
      untilDestroyed(this)
    );
    customer$.subscribe(({ auto_logout_seconds }) => {
      this.idleSrv.timeout = auto_logout_seconds * 1000;
      this.idleSrv.setupListeners(this.renderer);
      this.idleSrv.idle.subscribe(() => this.phAuthSrv.logout());
    });
    this.socketService.fromEvent('logout').subscribe(() => this.phAuthSrv.logout());
    this.document360Srv.load(this.config.getConfig('DOCUMENT_360_API_KEY'));

    this.userNotificationSrv.notification$
      .pipe(
        mergeMap((data) =>
          this.currentUserSrv.getPreference(`ca_notification_${data.id}_read`).pipe(
            first(),
            filter((read) => !read),
            mergeMap(() => this.openDowntimeNotification(data))
          )
        )
      )
      .subscribe(
        (id) => id && this.currentUserSrv.save(`ca_notification_${id}_read`, true)
      );
  }

  ngOnDestroy() {}

  openNotification() {
    this.userNotificationSrv.notification$
      .pipe(defaultIfEmpty(null))
      .subscribe((data) => this.openDowntimeNotification(data));
  }

  openPatientSearch() {
    this.dialog.open(PatientSearchComponent, { width: '696px' });
  }

  private openDowntimeNotification(userNotification) {
    return this.bottomSheet
      .open(DowntimeNotificationComponent, {
        disableClose: true,
        hasBackdrop: false,
        data: { userNotification },
      })
      .afterDismissed();
  }
}
