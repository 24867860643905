import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ExpectedError } from '@frontend/common/util';
import { EMPTY, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Patient } from './manage-workflows-dialog.graphql';
import { ManageWorkflowsService } from './manage-workflows.service';
import { SecurableService } from './securable.service';

interface FormData {
  id: number;
  current_workflow_ids: number[];
  assigned_location_id: number;
}

@Component({
  selector: 'frontend-app-manage-workflows',
  templateUrl: 'manage-workflows.component.html',
  styleUrls: ['manage-workflows.component.scss'],
  providers: [ManageWorkflowsService, SecurableService],
})
export class ManageWorkflowsComponent implements OnDestroy {
  _subscriptions: Subscription[] = [];
  _ongoingWorkflows: Patient['patient_department_visit_workflows'];
  form: FormGroup;

  constructor(
    public srv: ManageWorkflowsService,
    private dialogRef: MatDialogRef<ManageWorkflowsComponent>,
    private snackBar: MatSnackBar
  ) {
    this.form = new FormGroup({
      id: new FormControl(),
      current_workflow_ids: new FormControl(),
      assigned_location_id: new FormControl(),
    });
    this._subscriptions.push(
      this.srv.patient
        .pipe(map((p) => p.patient_department_visit_workflows))
        .subscribe((ow) => (this._ongoingWorkflows = ow))
    );
    this._subscriptions.push(
      this.srv.patient
        .pipe(map(patientManipulation))
        .subscribe((formData: FormData) => this.form.setValue(formData))
    );
    this._subscriptions.push(
      this.srv.patientRaw.subscribe({
        error: (err) => {
          this.dialogRef.close();
          const snackBarConfig = { duration: 10000 };
          if (err instanceof ExpectedError) {
            this.snackBar.open(err.expectedError, $localize`Close`, snackBarConfig);
            return EMPTY;
          }
          const message = $localize`There was an unexpected error.`;
          this.snackBar.open(message, $localize`Close`, snackBarConfig);
          throw err;
        },
      })
    );
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  close(route) {
    this.dialogRef.close(route);
  }

  save(route) {
    this.form.disable();
    const { id, current_workflow_ids, assigned_location_id } = this.form.value;
    const selectedWorkflows = current_workflow_ids;
    const startIds = selectedWorkflows.filter(
      (selectedId) =>
        this._ongoingWorkflows.map((ow) => ow.workflow_id).indexOf(selectedId) === -1
    );
    const endIds = this._ongoingWorkflows
      .filter((ow) => selectedWorkflows.indexOf(ow.workflow_id) === -1)
      .map((w) => w.id);
    this.srv
      .save(id, assigned_location_id, startIds, endIds)
      .pipe(
        catchError((err) => {
          this.form.enable();
          const snackBarConfig = { duration: 10000 };
          if (err instanceof ExpectedError) {
            this.snackBar.open(err.expectedError, $localize`Close`, snackBarConfig);
            return EMPTY;
          }
          const message = $localize`There was an unexpected error.`;
          this.snackBar.open(message, $localize`Close`, snackBarConfig);
          throw err;
        })
      )
      .subscribe(() => {
        this.snackBar.open($localize`Visit information was successfully updated.`, null, {
          duration: 3000,
        });
        this.dialogRef.close(route);
      });
  }
}

function patientManipulation(patient: Patient): FormData {
  const { id, assigned_location_id, patient_department_visit_workflows } = patient;
  const current_workflow_ids = patient_department_visit_workflows.map(
    (wVisit) => wVisit.workflow_id
  );
  return { id, assigned_location_id, current_workflow_ids };
}
