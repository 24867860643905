import { LocationTypeId, StaffTypeId } from '@frontend/common/util';
import gql from 'graphql-tag';

export interface StatusDataQuery {
  patient: Visit;
}

export interface Tag {
  id: number;
  sensed_utc: string;
  current_location?: {
    id: number;
    name: string;
    alias_name: string;
    location_type_id: LocationTypeId;
  };
}

export interface Department {
  id: number;
  name: string;
  site: {
    id: number;
    name: string;
    timezone: {
      id: string;
    };
  };
}

export interface AttentionMilestone {
  id: number;
  is_attended: boolean;
  created_utc: string;
  patient_staff_contacts: StaffContact[];
}

export interface StaffContact {
  id: number;
  created_utc: string;
  completed_utc: string;
  staff: {
    id: number;
    first_name: string;
    last_name: string;
    staff_type_id: StaffTypeId;
  };
}

export interface Visit {
  id: number;
  created_utc: string;
  tag?: Tag;
  department: Department;
  patient_attended_milestones: AttentionMilestone[];
}

export const StatusDataQuery = gql(`
query StatusDataQuery($visitId: String!) {
  patient(id: $visitId) {
    id
    created_utc
    tag {
      id
      sensed_utc
      current_location {
        id
        location_type_id
        name
        alias_name
      }
    }
    department {
      id
      name
      site {
        id
        name
        timezone {
          id
        }
      }
    }
    patient_attended_milestones {
      created_utc
      patient_staff_contacts {
        id
        created_utc
        completed_utc
        staff {
          id
          first_name
          last_name
          staff_type_id
        }
      }
    }
  }
}
`);
