import { createSelector, MemoizedSelector } from '@ngrx/store';

import { BaseSelectors } from '@frontend/shared/base-store';
import { UserType } from '../user-type.model';

class UserTypesSelectors extends BaseSelectors<UserType> {
  constructor() {
    super(UserType);
    this.getWritableUserTypes = createSelector(this.getAll, (userTypes) =>
      userTypes.filter((userType) => userType.isWritable)
    );
  }

  public getWritableUserTypes: MemoizedSelector<{}, UserType[]>;
}

export const userTypesSelectors = new UserTypesSelectors();
