import { LocationNetworkModel, PatientNetworkModel } from '../data-models';

export function filterCareData(
  locations: LocationNetworkModel[],
  filter: string
): LocationNetworkModel[] {
  if (!filter) {
    return locations;
  }
  return locations.map((loc) => ({
    ...loc,
    patients: loc.patients.filter(filterPatients(filter.toLocaleLowerCase())),
  }));
}

export function filterWaitingData(
  patients: PatientNetworkModel[],
  filter: string
): PatientNetworkModel[] {
  if (!filter) {
    return patients;
  }
  return patients.filter(filterPatients(filter.toLocaleLowerCase()));
}

function filterPatients(filter: string): (p: PatientNetworkModel) => boolean {
  return (p) =>
    [
      p.securableData?.fullName,
      p.securableData?.mrn,
      p.tag?.tag_value,
      p.tag?.label,
      p.secured_patient_name,
      p.displayStaff,
      ...p.patient_workflows.map((pw) => pw?.patient_appointment?.provider?.fullName),
      ...p.patient_workflows.map((pw) => pw?.patient_appointment?.appointment_type?.name),
      ...p.patient_workflows.map((pw) => pw?.patient_appointment?.appointment_time_utc),
      p.nextAppointment?.provider?.fullName,
      p.nextAppointment?.appointment_type?.name,
      p.nextAppointment?.appointment_time_utc,
      p.recipientsNames,
      p.status,
      p.note,
      p.additional_note,
      ...p.patient_workflows.map(
        (pw) =>
          pw?.patient_workflow_phase_milestones?.[0]?.call_events?.[0]?.station?.name
      ),
      ...p.patient_workflows.map(
        (pw) => pw?.patient_workflow_phase_milestones?.[0]?.calledBy
      ),
    ]
      .join('\n')
      .toLowerCase()
      .indexOf(filter) > -1;
}
