import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { VisitDetailService } from './visit-detail.service';

@Component({
  selector: 'frontend-visit-detail',
  templateUrl: './visit-detail.component.html',
  styleUrls: ['./visit-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [VisitDetailService],
})
export class VisitDetailComponent implements OnDestroy {
  _subscriptions: Subscription[] = [];

  statusCtrl = new FormControl();
  workflowsCtrl = new FormControl();
  appointmentsCtrl = new FormControl();
  milestonesCtrl = new FormControl();
  recipientsCtrl = new FormControl();

  modulesForm = new FormGroup({
    status: this.statusCtrl,
    workflows: this.workflowsCtrl,
    appointments: this.appointmentsCtrl,
    milestones: this.milestonesCtrl,
    recipients: this.recipientsCtrl,
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { visitId: number },
    public srv: VisitDetailService
  ) {
    this._subscriptions.push(
      this.srv
        .getSelectedModules()
        .subscribe((modules) => this.modulesForm.setValue(modules))
    );
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  onClose(): void {
    this.srv.saveSelectedModules(this.modulesForm.value);
  }
}
