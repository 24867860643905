import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { RecipientApiActions, RecipientApiActionTypes } from './recipient-api.actions';
import { RecipientActions, RecipientActionTypes } from './recipient.actions';
import { Recipient } from './recipient.model';

export interface State extends EntityState<Recipient> {
  loaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<Recipient> = createEntityAdapter<Recipient>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
});

export function reducer(
  state = initialState,
  action: RecipientActions | RecipientApiActions
): State {
  switch (action.type) {
    case RecipientApiActionTypes.Load: {
      return { ...state, loading: true };
    }

    case RecipientApiActionTypes.LoadFailure: {
      return { ...state, loading: false };
    }

    case RecipientApiActionTypes.Update: {
      const id = action.payload.recipient.id;
      const changes = {
        old: state.entities[action.payload.recipient.id],
      };
      return adapter.updateOne({ id, changes }, state);
    }

    case RecipientActionTypes.AddRecipient: {
      return adapter.addOne(action.payload.recipient, state);
    }

    case RecipientActionTypes.UpsertRecipient: {
      return adapter.upsertOne(action.payload.recipient, state);
    }

    case RecipientActionTypes.AddRecipients: {
      return adapter.addMany(action.payload.recipients, state);
    }

    case RecipientActionTypes.UpsertRecipients: {
      return adapter.upsertMany(action.payload.recipients, state);
    }

    case RecipientActionTypes.UpdateRecipient: {
      return adapter.updateOne(action.payload.recipient, state);
    }

    case RecipientActionTypes.UpdateRecipients: {
      return adapter.updateMany(action.payload.recipients, state);
    }

    case RecipientActionTypes.DeleteRecipient: {
      return adapter.removeOne(action.payload.id, state);
    }

    case RecipientActionTypes.DeleteRecipients: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case RecipientActionTypes.LoadRecipients: {
      const s = { ...state, loading: false, loaded: true };
      return adapter.setAll(action.payload.recipients, s);
    }

    case RecipientActionTypes.ClearRecipients: {
      const s = { ...state, loading: false, loaded: false };
      return adapter.removeAll(s);
    }

    default: {
      return state;
    }
  }
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const getLoaded = (state: State) => state.loaded;

export const getLoading = (state: State) => state.loading;
