<ng-template #skeletonTmpl>
  <skeleton-visit-detail-module></skeleton-visit-detail-module>
</ng-template>

<ng-container *ngIf="srv.cardData$ | async as cardData; else skeletonTmpl">
  <div class="module-content">
    <div class="header-line">
      <h4 i18n class="module-title">Appointments</h4>
      <div class="buttons" *ngIf="!visitCompleted">
        <button mat-button mat-icon-button
          [mat-dialog-close]="[cardData.departmentId ,'patients', srv.data.visitId, 'appointments']">
          <mat-icon>edit</mat-icon>
        </button>
      </div>
    </div>

    <ng-container *ngIf="cardData.appointments.length; then appointmentsTmpl; else noAppointmentsTmpl">
    </ng-container>

    <ng-template #appointmentsTmpl>
      <ng-container *ngFor="let pa of cardData.appointments; let last = last; trackBy: trackByFn">
        <div class="primary-text">
          <div>
            <span>{{ pa.time || '—' }}</span>&nbsp;<span>{{ pa.type || '—' }}</span>
          </div>
          <div>
            <span>{{ pa.provider || '—' }}</span>
          </div>
        </div>
        <div *ngIf="!last" class="divider spacer"></div>
      </ng-container>
    </ng-template>

    <ng-template #noAppointmentsTmpl>
      <div>
        <span class="secondary-text" i18n>No records available</span>
      </div>
    </ng-template>
  </div>
</ng-container>