import { BaseEntity, Entity, Feature } from '@frontend/shared/base-store';

@Entity('phase')
@Feature('phases')
export class Phase extends BaseEntity {
  id: number;
  name: string;
  workflow_id: number;
  sort_order: number;
  is_skip_allowed: boolean;
  has_priority: boolean;
  location_ids: number[];
  is_locked: boolean;
  is_workflow_locked: boolean;
  is_first: boolean;
  is_last: boolean;
  max_phase_duration_minutes_low: number;
  max_phase_duration_minutes_high: number;
  notification_message: string;
  is_call_patient_enabled: boolean;
  is_calculate_duration_enabled: boolean;
  estimation_range_min: number;
  min_time_in_phase_seconds: number;
  milestones_count: number;
}
