<mat-table [dataSource]="dataSource">
  <ng-container *ngFor="let name of displayedColumns" matColumnDef="{{ name }}">
    <mat-header-cell *matHeaderCellDef class="fixIE11verticallyCentered">
      <skeleton-item [ngStyle]="{ width: getWidth() }"></skeleton-item>
    </mat-header-cell>
    <mat-cell *matCellDef="let element" class="fixIE11verticallyCentered">
      <skeleton-item [ngStyle]="{ width: getWidth() }"></skeleton-item>
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>
