<mat-card [ngClass]="{ open: open$ | async }">
  <mat-toolbar color="primary">
    <span i18n
      >{{ pendingOperations$ | async }} upload{{
        (pendingOperations$ | async) === 1 ? '' : 's'
      }}
      in progress</span
    >
    <button mat-button mat-icon-button (click)="toggle()">
      <mat-icon>keyboard_arrow_up</mat-icon>
    </button>
  </mat-toolbar>
  <mat-list role="list">
    <mat-list-item
      role="listitem"
      *ngFor="let operation of operationsStats$ | async; trackBy: trackByFn"
    >
      <bulk-uploader-operation
        [op]="operation"
        (clearOperation)="onClearOperation($event)"
        (exportErrors)="onExportErrors($event)"
      ></bulk-uploader-operation>
    </mat-list-item>
  </mat-list>
</mat-card>
