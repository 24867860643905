import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  first,
  map,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs/operators';

interface MultiSelectSearchItem {
  id: string | number;
  label: string;
}

@UntilDestroy()
@Component({
  selector: 'multi-select-search',
  styleUrls: ['multi-select-search.component.scss'],
  templateUrl: 'multi-select-search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectSearchComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MultiSelectSearchComponent),
      multi: true,
    },
  ],
})
export class MultiSelectSearchComponent
  implements ControlValueAccessor, OnDestroy, OnInit
{
  @Input() entityName = '';
  @Input() items: MultiSelectSearchItem[] = [];
  @Input() enableAll = true;
  @Input() info = null;
  @Input() disablePreselectedInputs?: boolean;

  _componentInstanceID = Math.floor(Math.random() * 10 ** 5);
  private _ctrl = new FormControl();
  public _searchCtrl = new FormControl('');
  public _disabled$ = new ReplaySubject<boolean>(1);
  private _items$ = new ReplaySubject<any[]>(1);
  public _allCount$ = this._items$.pipe(map((items) => items.length));
  public _selectedCount$ = this._items$.pipe(
    map((items) => items.filter(({ ___selected }) => ___selected).length)
  );

  private _filter$: Observable<string> = this._searchCtrl.valueChanges.pipe(
    debounceTime(400),
    startWith(''),
    shareReplay(1),
    distinctUntilChanged(),
    untilDestroyed(this)
  );

  public _filteredItems$ = this._items$.pipe(
    switchMap((items) =>
      this._filter$.pipe(
        map((str) =>
          items.filter((item) => {
            const fullLabel = [item.label, item.secondaryLabel]
              .filter((parts) => !!parts)
              .join(' ');
            return fullLabel.toLowerCase().indexOf(str.toLocaleLowerCase()) > -1;
          })
        )
      )
    ),
    shareReplay(1)
  );
  public _allComplete$ = this._filteredItems$.pipe(
    map((items) => items.every((item) => item.___selected))
  );
  public _showingCount$ = this._filteredItems$.pipe(map((items) => items.length));
  public _someComplete$ = this._filteredItems$.pipe(
    map((items) => {
      if (!items?.length) {
        this._disabled$.next(true);
        return true;
      }
      this._disabled$.next(false);
      let firstSelected;
      return !items.every((item, i) => {
        if (i === 0) {
          firstSelected = item.___selected;
          return true;
        }
        return firstSelected === item.___selected;
      });
    })
  );

  public _disableAll$ = this._items$.pipe(
    map((items) => {
      return items.every(item => { return item.___disabled } )
    })
  );

  set value(preselected: MultiSelectSearchItem['id'][]) {
    const newItems = this.items.map((item) => {
      const ___selected = preselected.indexOf(item.id) > -1;
      const ___disabled = this.disablePreselectedInputs && ___selected;
      return { ...item, ___selected, ___disabled };
    });
    this._items$.next(newItems);
  }

  ngOnDestroy() {}

  ngOnInit() {
    this._items$.next(this.items);
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }
    if (value === null) {
      this._ctrl.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  validate() {
    return this._ctrl.valid ? null : { patient: { valid: false } };
  }

  setDisabledState(isDisabled) {
    this._disabled$.next(isDisabled);
    if (isDisabled) {
      this._searchCtrl.disable();
    } else {
      this._searchCtrl.enable();
    }
  }

  public _onKeydown(event: KeyboardEvent) {
    if (event.code === 'Escape') {
      this._clear();
      event.stopPropagation();
    } else if (event.code === 'Enter') {
      event.preventDefault();
    }
  }

  public _clear() {
    this._searchCtrl.setValue('');
  }

  public _setAll(targetState: boolean) {
    this._filteredItems$.pipe(first()).subscribe((items) => {
      if (items == null) {
        return;
      }
      const all = items.filter(item => !item.___disabled).map((item) => item.id);
      this._update(all, targetState);
    });
  }

  public _update(updatedItems: number[], ___selected: boolean) {
    this._items$.pipe(first()).subscribe((items) => {
      const updated = items.map((item) => {
        if (updatedItems.indexOf(item.id) > -1) {
          return { ...item, ___selected };
        }
        return item;
      });
      this._items$.next(updated);
      this._reportChange();
    });
  }

  private _reportChange() {
    this._items$
      .pipe(
        first(),
        map((items) =>
          items.filter(({ ___selected }) => ___selected).map((item) => item.id)
        )
      )
      .subscribe((selected) => {
        this.onChange(selected);
        this.onTouched();
      });
  }
}
