import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SocketService } from '@frontend/common/util';
import { merge, Observable, race, throwError } from 'rxjs';
import { catchError, filter, first, ignoreElements, mergeMap } from 'rxjs/operators';
import * as uuid from 'uuid/v4';
import { Workflow } from '../workflow/workflow.model';

@Injectable({
  providedIn: 'root',
})
export class WorkflowApiService {
  constructor(private http: HttpClient, private socketSrv: SocketService) {}

  public create(entity: Workflow) {
    const correlation_id = entity.id;
    return merge(
      this.http.post('workflows', { ...entity, correlation_id }).pipe(ignoreElements()),
      race(
        this.socketSrv
          .fromLocalSuccess<any>('workflows.create')
          .pipe(filter((ws) => ws.correlation_id === correlation_id)),
        this.socketSrv.fromLocalError('workflows.create').pipe(
          filter((ws) => ws.correlation_id === correlation_id),
          mergeMap((ws) => throwError(ws))
        )
      ).pipe(first())
    ).pipe(catchError(({ message }) => throwError({ id: entity.id, message })));
  }

  public delete(id) {
    const correlation_id = 'correlation|' + uuid();
    return merge(
      this.http
        .request('delete', `workflows/${id}`, { body: { correlation_id } })
        .pipe(ignoreElements()),
      race(
        this.socketSrv
          .fromLocalSuccess<any>('workflows.remove')
          .pipe(filter((ws) => ws.correlation_id === correlation_id)),
        this.socketSrv.fromLocalError('workflows.remove').pipe(
          filter((ws) => ws.correlation_id === correlation_id),
          mergeMap((ws) => throwError(ws))
        )
      ).pipe(first())
    ).pipe(catchError(({ message }) => throwError({ id, message })));
  }

  public get(params?: { [key: string]: string | string[] }): Observable<Workflow[]> {
    return this.http.get<Workflow[]>('workflows', { params });
  }

  public getOne(id) {
    return this.http.get<Workflow>(`workflows/${id}`);
  }

  public getPickupLocations(id) {
    return this.http.get<Workflow>(`workflows/${id}/locations`);
  }

  public update(entity) {
    const correlation_id = 'correlation|' + uuid();
    return merge(
      this.http
        .put(`workflows/${entity.id}`, { ...entity, correlation_id })
        .pipe(ignoreElements()),
      race(
        this.socketSrv
          .fromLocalSuccess<any>('workflows.update')
          .pipe(filter((ws) => ws.correlation_id === correlation_id)),
        this.socketSrv.fromLocalError('workflows.update').pipe(
          filter((ws) => ws.correlation_id === correlation_id),
          mergeMap((ws) => throwError(ws))
        )
      ).pipe(first())
    ).pipe(catchError(({ message }) => throwError({ id: entity.id, message })));
  }

  public duplicate(id: Workflow['id'], correlation_id: string) {
    return merge(
      this.http.post(`workflows/${id}/copy`, { correlation_id }).pipe(ignoreElements()),
      race(
        this.socketSrv
          .fromLocalSuccess<any>('workflows.copy')
          .pipe(filter((ws) => ws.correlation_id === correlation_id)),
        this.socketSrv.fromLocalError('workflows.copy').pipe(
          filter((ws) => ws.correlation_id === correlation_id),
          mergeMap((ws) => throwError(ws))
        )
      ).pipe(first())
    ).pipe(catchError(({ message }) => throwError({ id, message })));
  }

  public enable(id: Workflow['id']) {
    const correlation_id = 'correlation|' + uuid();
    return merge(
      this.http
        .patch(`workflows/${id}/activate`, { correlation_id })
        .pipe(ignoreElements()),
      race(
        this.socketSrv
          .fromLocalSuccess<any>('workflows.activate')
          .pipe(filter((ws) => ws.correlation_id === correlation_id)),
        this.socketSrv.fromLocalError('workflows.activate').pipe(
          filter((ws) => ws.correlation_id === correlation_id),
          mergeMap((ws) => throwError(ws))
        )
      ).pipe(first())
    ).pipe(catchError(({ message }) => throwError({ id, message })));
  }

  public disable(id: Workflow['id']) {
    const correlation_id = 'correlation|' + uuid();
    return merge(
      this.http
        .patch(`workflows/${id}/deactivate`, { correlation_id })
        .pipe(ignoreElements()),
      race(
        this.socketSrv
          .fromLocalSuccess<any>('workflows.deactivate')
          .pipe(filter((ws) => ws.correlation_id === correlation_id)),
        this.socketSrv.fromLocalError('workflows.deactivate').pipe(
          filter((ws) => ws.correlation_id === correlation_id),
          mergeMap((ws) => throwError(ws))
        )
      ).pipe(first())
    ).pipe(catchError(({ message }) => throwError({ id, message })));
  }
}
