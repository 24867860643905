import { BaseSelectors } from '@frontend/shared/base-store';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { MemoizedSelectorWithProps } from '@ngrx/store/src/selector';
import { Location } from '../../locations/location.model';
import { locationsSelectors } from '../../locations/store/locations.selectors';
import { workflowsSelectors } from '../../workflows/store/workflows.selectors';
import { Phase } from '../phase.model';

const addProps = (p: Phase, len: number, wLocked: boolean) => {
  const is_first = p.sort_order === 1;
  const is_last = p.sort_order === len;
  const is_locked = is_first || is_last || wLocked;
  return { ...p, is_first, is_last, is_locked, is_workflow_locked: wLocked };
};

class PhasesSelectors extends BaseSelectors<Phase> {
  constructor() {
    super(Phase);

    this.getCurrentPhaseLocations = createSelector(
      this.getOneByStateParam,
      locationsSelectors.getAll,
      (phase, locations) =>
        phase && phase.location_ids
          ? locations.filter((l) => phase.location_ids.some((pl) => pl === l.id))
          : []
    );

    this.getAll = createSelector(
      this.getAll,
      workflowsSelectors.getOneByStateParam,
      (phases, workflow) =>
        phases.map((item, i, arr) =>
          addProps(item, arr.length, workflow && workflow.is_locked)
        )
    );

    this.getOneByStateParam = createSelector(
      this.getOneByStateParam,
      this.getTotal,
      workflowsSelectors.getOneByStateParam,
      (phase, total, workflow) => {
        if (!phase) {
          return null;
        }
        return addProps(phase, total, workflow && workflow.is_locked);
      }
    );

    this.getPhasesByWorkflowId = createSelector(this.getAll, (phases, workflowId) =>
      phases.filter((p) => p.workflow_id === workflowId)
    );
  }

  public getPhasesByWorkflowId: MemoizedSelectorWithProps<{}, any, Phase[]>;

  public getCurrentPhaseLocations: MemoizedSelector<{}, Location[]>;
}

export const phasesSelectors = new PhasesSelectors();
