import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

function generateTimestamps() {
  const times = ['12', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11'];
  const quarters = ['00', '15', '30', '45'];
  return times.reduce((acc, h) => [...acc, ...quarters.map((q) => `${h}:${q}`)], []);
}

const TIMESTAMPS = generateTimestamps();

@Component({
  selector: 'time-picker-ampm',
  templateUrl: './time-picker-ampm.component.html',
  styleUrls: ['./time-picker-ampm.component.scss'],
})
export class TimePickerAmPmComponent implements OnInit {
  timestamps: Observable<string[]>;

  @Input() form: FormGroup;
  @Input() timezone: string;

  ngOnInit() {
    this.timestamps = this.form.controls.time.valueChanges.pipe(
      startWith(''),
      map((at) => TIMESTAMPS.filter((option) => option.indexOf(at) > -1))
    );
  }
}
