import { LocationTypeId } from '@frontend/common/util';
import { BaseEntity, Entity, Feature } from '@frontend/shared/base-store';

@Entity('location')
@Feature('locations')
export class Location extends BaseEntity {
  id: number;
  name: string;
  alias_name: string;
  location_type_id: LocationTypeId;
}
