import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { utc } from 'moment';
import { Observable, of } from 'rxjs';
import { formatDiff } from './format-diff';

@Pipe({ name: 'timeDiff' })
@Injectable()
export class TimeDiffPipe implements PipeTransform {
  transform(value: string): Observable<string> {
    if (!value) {
      return of('-');
    }
    const m = utc(value, 'YYYY-MM-DD-HH-mm-ss.SSSZ');
    return formatDiff('live', m);
  }
}
