import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getParams } from '@frontend/common/ph-router-store';
import { ExpectedError, SocketService, StaffTypeId } from '@frontend/common/util';
import { createSelector, Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { combineLatest, merge, Observable, of, race, throwError } from 'rxjs';
import {
  catchError,
  filter,
  first,
  ignoreElements,
  map,
  mapTo,
  mergeMap,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs/operators';
import * as uuid from 'uuid/v4';
import { appointmentManipulation, FormGroupValue } from './appointment-manipulation';
import {
  Appointment,
  AppointmentDataQuery,
  AppointmentTypeItem,
  AppointmentTypesDataQuery,
  DepartmentDataQuery,
  ProviderItem,
  ProvidersDataQuery,
} from './edit-appointment.graphql';

interface QueryVariables {
  department_id: number;
  patient_id: number;
  appointment_id: number;
}

interface ManipulatedProviderItem extends ProviderItem {
  name: string;
}

const dialogInput = createSelector(
  getParams,
  ({ department_id, patient_id, appointment_id }) => ({
    department_id,
    patient_id,
    appointment_id,
  })
);

@Injectable()
export class EditAppointmentService {
  private _param: Observable<QueryVariables>;

  loading: Observable<boolean>;
  appointment: Observable<FormGroupValue>;
  timezone: Observable<string>;
  providerList: Observable<ProviderItem[]>;
  appointmentTypeList: Observable<AppointmentTypeItem[]>;

  constructor(
    private store: Store,
    private apollo: Apollo,
    private http: HttpClient,
    private socketSrv: SocketService
  ) {
    this._param = this.store.select(dialogInput).pipe(first());
    this.timezone = this._param.pipe(
      switchMap((params) => this._getTimezone(params.department_id)),
      shareReplay(1)
    );
    this.appointment = combineLatest([this._param, this.timezone]).pipe(
      mergeMap(([params, timezone]) =>
        this._getAppointmentById(params.appointment_id, timezone)
      ),
      shareReplay(1)
    );
    this.providerList = this._param.pipe(
      switchMap((params) => this._getProviders(params.department_id)),
      shareReplay(1)
    );
    this.appointmentTypeList = this._param.pipe(
      switchMap((params) => this._getAppointmentTypes(params.department_id)),
      shareReplay(1)
    );
    this.loading = merge(
      this._param.pipe(mapTo(true)),
      combineLatest([
        this.appointment,
        this.timezone,
        this.providerList,
        this.appointmentTypeList,
      ]).pipe(mapTo(false))
    ).pipe(startWith(true));
  }

  updateAppointment(appointment: Appointment) {
    const correlation_id = 'correlation|' + uuid();
    const payload = {
      correlation_id,
      ...appointment,
    };
    return merge(
      this.http.put(`patient-appointments/${appointment.id}`, payload).pipe(
        ignoreElements(),
        catchError((err) =>
          of(err?.error?.message).pipe(
            mergeMap((message) => throwError({ id: correlation_id, message }))
          )
        )
      ),
      race(
        this.socketSrv
          .fromLocalSuccess<any>('patient-appointments.update')
          .pipe(filter((ws) => ws.correlation_id === correlation_id)),
        this.socketSrv.fromLocalError('patient-appointments.update').pipe(
          filter((ws) => ws.correlation_id === correlation_id),
          mergeMap(({ message }) => throwError({ id: correlation_id, message }))
        )
      ).pipe(first())
    );
  }

  _getAppointmentById(appointmentId: number, timezone: string) {
    return this.apollo
      .subscribe<AppointmentDataQuery>({
        query: AppointmentDataQuery,
        variables: { appointmentId },
      })
      .pipe(
        map((result) => {
          if (!result.data.patient_appointment.length) {
            throw new ExpectedError($localize`Appointment does not exist.`);
          }
          return result.data.patient_appointment[0];
        }),
        map((data: Appointment) => appointmentManipulation(data, timezone))
      );
  }

  _getTimezone(departmentId: number) {
    return this.apollo
      .subscribe<DepartmentDataQuery>({
        query: DepartmentDataQuery,
        variables: { departmentId },
      })
      .pipe(map(({ data }) => data?.department?.[0].site.timezone.id));
  }

  _getProviders(departmentId: number) {
    return this.apollo
      .subscribe<ProvidersDataQuery>({
        query: ProvidersDataQuery,
        variables: { departmentId, parentStaffTypeId: StaffTypeId.Parent_Provider },
      })
      .pipe(map((patient) => providersManipulation(patient.data.staff)));
  }

  _getAppointmentTypes(departmentId) {
    return this.apollo
      .subscribe<AppointmentTypesDataQuery>({
        query: AppointmentTypesDataQuery,
        variables: { departmentId },
      })
      .pipe(map((patient) => patient.data.appointment_type));
  }
}

function providersManipulation(providers: ProviderItem[]): ManipulatedProviderItem[] {
  return providers
    .map((provider) => {
      const name: string = [provider?.last_name, provider?.first_name]
        .filter((s) => !!s)
        .join(', ');
      return { ...provider, name };
    })
    .sort((a, b) => a.name.localeCompare(b.name));
}
