import { BaseEntity, Entity, Feature } from '@frontend/shared/base-store';

@Entity('sensor')
@Feature('sensors')
export class Sensor extends BaseEntity {
  id: string;
  sensor_value: string;
  label: string;
  rtls_vendor_id: string;
}
