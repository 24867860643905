import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'frontend-chat-no-messages',
  styleUrls: ['chat-no-messages.component.scss'],
  template: `
    <div class="mat-typography" style="display:flex;">
      <div>
        <div style="margin-top: 4px">
          <mat-icon>message</mat-icon>
        </div>
      </div>
      <div style="margin-left: 8px">
        <div style="margin-bottom: 8px;">
          <span id="text1">No messages yet</span>
        </div>
        <div>
          <p id="text2">Messages sent will appear here</p>
        </div>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatNoMessagesComponent {}
