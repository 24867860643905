import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
import { departmentsActions } from './store/departments.actions';
import { departmentsSelectors } from './store/departments.selectors';

@Injectable()
export class DepartmentsAvailableResolver implements Resolve<boolean> {
  constructor(private store: Store<any>) {}

  resolve(): Observable<boolean> {
    return this.store.pipe(
      select(departmentsSelectors.getCollectionLoaded),
      tap((loaded) => loaded || this.store.dispatch(departmentsActions.loadAvailable())),
      filter((loaded) => loaded),
      first()
    );
  }
}
