import { Action } from '@ngrx/store';
import { Patient } from '../patient/patient.model';

export enum PatientApiActionTypes {
  LoadPatientsApi = '[Patient API] Load Patients',
  LoadPatientsSuccessApi = '[Patient API] Load Patients Success',
  LoadPatientsFailureApi = '[Patient API] Load Patients Failure',
  LoadPatientApi = '[Patient API] Load Patient',
  LoadPatientSuccessApi = '[Patient API] Load Patient Success',
  LoadPatientFailureApi = '[Patient API] Load Patient Failure',
  LoadCompletedPatientsApi = '[Patient API] Load Completed Patients',
  LoadCompletedPatientsSuccessApi = '[Patient API] Load Completed Patients Success',
  LoadCompletedPatientsFailureApi = '[Patient API] Load Completed Patients Failure',
  CreatePatientApi = '[Patient API] Create Patient',
  CreatePatientSuccessApi = '[Patient API] Create Patient Success',
  CreatePatientFailureApi = '[Patient API] Create Patient Failure',
  UpdatePatientApi = '[Patient API] Update Patient',
  UpdatePatientSuccessApi = '[Patient API] Update Patient Success',
  UpdatePatientFailureApi = '[Patient API] Update Patient Failure',
  DeletePatientApi = '[Patient API] Delete Patient',
  DeletePatientSuccessApi = '[Patient API] Delete Patient Success',
  DeletePatientFailureApi = '[Patient API] Delete Patient Failure',
}

export class LoadPatientsApi implements Action {
  readonly type = PatientApiActionTypes.LoadPatientsApi;
  constructor(public payload: { params: any } = { params: {} }) {}
}

export class LoadPatientsSuccessApi implements Action {
  readonly type = PatientApiActionTypes.LoadPatientsSuccessApi;
  constructor(public payload: { patients: Patient[] }) {}
}

export class LoadPatientsFailureApi implements Action {
  readonly type = PatientApiActionTypes.LoadPatientsFailureApi;
  constructor(public payload: { message: string }) {}
}

export class LoadCompletedPatientsApi implements Action {
  readonly type = PatientApiActionTypes.LoadCompletedPatientsApi;
  constructor(public payload: { params: any } = { params: {} }) {}
}

export class LoadCompletedPatientsSuccessApi implements Action {
  readonly type = PatientApiActionTypes.LoadCompletedPatientsSuccessApi;
  constructor(public payload: { patients: Patient[] }) {}
}

export class LoadCompletedPatientsFailureApi implements Action {
  readonly type = PatientApiActionTypes.LoadCompletedPatientsFailureApi;
  constructor(public payload: { message: string }) {}
}

export class LoadPatientApi implements Action {
  readonly type = PatientApiActionTypes.LoadPatientApi;
  constructor(public payload: { id: string }) {}
}

export class LoadPatientSuccessApi implements Action {
  readonly type = PatientApiActionTypes.LoadPatientSuccessApi;
  constructor(public payload: { patient: Patient }) {}
}

export class LoadPatientFailureApi implements Action {
  readonly type = PatientApiActionTypes.LoadPatientFailureApi;
  constructor(public payload: { id: string; message: string }) {}
}

export class CreatePatientApi implements Action {
  readonly type = PatientApiActionTypes.CreatePatientApi;
  constructor(public payload: { patient: Patient }) {}
}

export class CreatePatientSuccessApi implements Action {
  readonly type = PatientApiActionTypes.CreatePatientSuccessApi;
  constructor(public payload: { patient: Patient }) {}
}

export class CreatePatientFailureApi implements Action {
  readonly type = PatientApiActionTypes.CreatePatientFailureApi;
  constructor(public payload: { id: string; message: string }) {}
}

export class UpdatePatientApi implements Action {
  readonly type = PatientApiActionTypes.UpdatePatientApi;
  constructor(public payload: { patient: Patient }) {}
}

export class UpdatePatientSuccessApi implements Action {
  readonly type = PatientApiActionTypes.UpdatePatientSuccessApi;
  constructor(public payload: { patient: Patient }) {}
}

export class UpdatePatientFailureApi implements Action {
  readonly type = PatientApiActionTypes.UpdatePatientFailureApi;
  constructor(public payload: { id: string; message: string }) {}
}

export class DeletePatientApi implements Action {
  readonly type = PatientApiActionTypes.DeletePatientApi;
  constructor(public payload: { id: string }) {}
}

export class DeletePatientSuccessApi implements Action {
  readonly type = PatientApiActionTypes.DeletePatientSuccessApi;
  constructor(public payload: { id: string }) {}
}

export class DeletePatientFailureApi implements Action {
  readonly type = PatientApiActionTypes.DeletePatientFailureApi;
  constructor(public payload: { id: string; message: string }) {}
}

export type PatientApiActions =
  | LoadPatientsApi
  | LoadPatientsSuccessApi
  | LoadPatientsFailureApi
  | LoadCompletedPatientsApi
  | LoadCompletedPatientsSuccessApi
  | LoadCompletedPatientsFailureApi
  | LoadPatientApi
  | LoadPatientSuccessApi
  | LoadPatientFailureApi
  | CreatePatientApi
  | CreatePatientSuccessApi
  | CreatePatientFailureApi
  | UpdatePatientApi
  | UpdatePatientSuccessApi
  | UpdatePatientFailureApi
  | DeletePatientApi
  | DeletePatientSuccessApi
  | DeletePatientFailureApi;
