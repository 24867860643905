<div class="main-wrapper">
  <div>
    <div class="chat-list-search-wrapper">
      <frontend-chat-list-search
        style="display: flex; width: 232px; align-items: center"
        (searchChange)="onSearchUpdate($event)"
      ></frontend-chat-list-search>
    </div>
  </div>
  <skeleton-chat-left *ngIf="!_list"></skeleton-chat-left>
  <ng-container *ngIf="_list">
    <div
      *ngIf="!_list.length"
      style="display: flex; flex-grow: 1; justify-content: center; margin-top: 32px"
    >
      <frontend-chat-list-empty-result></frontend-chat-list-empty-result>
    </div>
    <ng-container *ngIf="_list.length">
      <mat-action-list>
        <button mat-list-item *ngFor="let item of _list" [routerLink]="[item.id]">
          <div class="item">
            <span class="name">{{ item.name }}</span>
            <span class="duration">{{ item.duration }}</span>
            <span class="unread" *ngIf="item.unread">{{ item.unread }}</span>
          </div>
        </button>
      </mat-action-list>
    </ng-container>
  </ng-container>
</div>
