<div>
  <mat-table [dataSource]="dataSource">
    <ng-container *ngFor="let name of displayedColumns" matColumnDef="{{ name }}">
      <mat-header-cell
        *matHeaderCellDef
        class="fixIE11verticallyCentered"
        style="padding: 0"
      >
        <skeleton-item style="width: 60px"></skeleton-item>
      </mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        class="fixIE11verticallyCentered"
        style="padding: 0"
      >
        <skeleton-item style="width: 100%"></skeleton-item>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>

  <div
    style="
      display: flex;
      justify-content: flex-end;
      min-height: 52px;
      padding: 8px 0;
      align-items: center;
    "
  >
    <skeleton-item style="width: 100px"></skeleton-item>
    <div style="width: 40px"></div>
    <skeleton-item style="width: 100px"></skeleton-item>
  </div>
</div>
