import { Action } from '@ngrx/store';
import { QuickMessage } from '@frontend/shared/quick-message/data-access/src';

export enum QuickMessageWsActionTypes {
  LocalCreateQuickMessageSuccessWs = '[QuickMessage WS] Local Create QuickMessages Success',
  LocalCreateQuickMessageFailureWs = '[QuickMessage WS] Local Create QuickMessages Failure',
  LocalUpdateQuickMessageSuccessWs = '[QuickMessage WS] Local Update QuickMessages Success',
  LocalUpdateQuickMessageFailureWs = '[QuickMessage WS] Local Update QuickMessages Failure',
  LocalRemoveQuickMessageSuccessWs = '[QuickMessage WS] Local Remove QuickMessages Success',
  LocalRemoveQuickMessageFailureWs = '[QuickMessage WS] Local Remove QuickMessages Failure',
  GlobalCreateQuickMessageSuccessWs = '[QuickMessage WS] Global Create QuickMessages Success',
  GlobalUpdateQuickMessageSuccessWs = '[QuickMessage WS] Global Update QuickMessages Success',
  GlobalRemoveQuickMessageSuccessWs = '[QuickMessage WS] Global Remove QuickMessages Success',
}

export class LocalCreateQuickMessageSuccessWs implements Action {
  readonly type = QuickMessageWsActionTypes.LocalCreateQuickMessageSuccessWs;

  constructor(public payload: { id: string; correlation_id: string }) {}
}

export class LocalCreateQuickMessageFailureWs implements Action {
  readonly type = QuickMessageWsActionTypes.LocalCreateQuickMessageFailureWs;

  constructor(public payload: { correlation_id: string; message: string }) {}
}

export class LocalUpdateQuickMessageSuccessWs implements Action {
  readonly type = QuickMessageWsActionTypes.LocalUpdateQuickMessageSuccessWs;

  constructor(public payload: { id: string; correlation_id: string }) {}
}

export class LocalUpdateQuickMessageFailureWs implements Action {
  readonly type = QuickMessageWsActionTypes.LocalUpdateQuickMessageFailureWs;

  constructor(public payload: { correlation_id: string; message: string }) {}
}

export class LocalRemoveQuickMessageSuccessWs implements Action {
  readonly type = QuickMessageWsActionTypes.LocalRemoveQuickMessageSuccessWs;

  constructor(public payload: { correlation_id: string }) {}
}

export class LocalRemoveQuickMessageFailureWs implements Action {
  readonly type = QuickMessageWsActionTypes.LocalRemoveQuickMessageFailureWs;

  constructor(public payload: { correlation_id: string; message: string }) {}
}

export class GlobalCreateQuickMessageSuccessWs implements Action {
  readonly type = QuickMessageWsActionTypes.GlobalCreateQuickMessageSuccessWs;
  constructor(public payload: QuickMessage) {}
}

export class GlobalUpdateQuickMessageSuccessWs implements Action {
  readonly type = QuickMessageWsActionTypes.GlobalUpdateQuickMessageSuccessWs;
  constructor(public payload: QuickMessage) {}
}

export class GlobalRemoveQuickMessageSuccessWs implements Action {
  readonly type = QuickMessageWsActionTypes.GlobalRemoveQuickMessageSuccessWs;
  constructor(public payload: QuickMessage['id']) {}
}

export type QuickMessageWsActions =
  | LocalCreateQuickMessageSuccessWs
  | LocalCreateQuickMessageFailureWs
  | LocalUpdateQuickMessageSuccessWs
  | LocalUpdateQuickMessageFailureWs
  | LocalRemoveQuickMessageSuccessWs
  | LocalRemoveQuickMessageFailureWs
  | GlobalCreateQuickMessageSuccessWs
  | GlobalUpdateQuickMessageSuccessWs
  | GlobalRemoveQuickMessageSuccessWs;
