import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import {
  CreateTextMessageApi,
  CreateTextMessageFailureApi,
  CreateTextMessageSuccessApi,
  DeleteTextMessageApi,
  DeleteTextMessageFailureApi,
  DeleteTextMessageSuccessApi,
  LoadTextMessageApi,
  LoadTextMessageFailureApi,
  LoadTextMessagesApi,
  LoadTextMessagesFailureApi,
  LoadTextMessagesSuccessApi,
  LoadTextMessageSuccessApi,
  TextMessageApiActionTypes,
  UpdateTextMessageApi,
  UpdateTextMessageFailureApi,
  UpdateTextMessageSuccessApi,
} from './text-message-api.actions';
import { TextMessageApiService } from './text-message-api.service';

@Injectable()
export class TextMessageApiEffects {
  loadTextMessagesApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TextMessageApiActionTypes.LoadTextMessagesApi),
      switchMap(({ payload }: LoadTextMessagesApi) =>
        this.srv.get(payload.params).pipe(
          map((textMessages) => new LoadTextMessagesSuccessApi({ textMessages })),
          catchError((err) => of(new LoadTextMessagesFailureApi(err)))
        )
      )
    )
  );

  loadOneTextMessagesApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TextMessageApiActionTypes.LoadTextMessageApi),
      switchMap(({ payload: { id } }: LoadTextMessageApi) =>
        this.srv.getOne(id).pipe(
          map((textMessage) => new LoadTextMessageSuccessApi({ textMessage })),
          catchError(({ message }) => of(new LoadTextMessageFailureApi({ id, message })))
        )
      )
    )
  );

  createTextMessageApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TextMessageApiActionTypes.CreateTextMessageApi),
      mergeMap(({ payload }: CreateTextMessageApi) =>
        this.srv.create(payload.textMessage).pipe(
          map((textMessage) => new CreateTextMessageSuccessApi({ textMessage })),
          catchError((error) => of(new CreateTextMessageFailureApi(error)))
        )
      )
    )
  );

  updateTextMessageApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TextMessageApiActionTypes.UpdateTextMessageApi),
      mergeMap(({ payload }: UpdateTextMessageApi) =>
        this.srv.update(payload.textMessage).pipe(
          map((textMessage) => new UpdateTextMessageSuccessApi({ textMessage })),
          catchError((error) => of(new UpdateTextMessageFailureApi(error)))
        )
      )
    )
  );

  deleteTextMessageApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TextMessageApiActionTypes.DeleteTextMessageApi),
      mergeMap(({ payload }: DeleteTextMessageApi) =>
        this.srv.delete(payload.id).pipe(
          map(({ id }) => new DeleteTextMessageSuccessApi({ id: payload.id })),
          catchError((err) => of(new DeleteTextMessageFailureApi(err)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private srv: TextMessageApiService) {}
}
