<ng-template #skeletonTmpl>
  <skeleton-visit-detail-module></skeleton-visit-detail-module>
</ng-template>

<ng-container *ngIf="srv.cardData$ | async as cardData; else skeletonTmpl">
  <div class="module-content">
    <div class="header-line">
      <h4 i18n class="module-title">Recipients</h4>
      <div class="buttons">
        <ng-container *ngIf="cardData.recipients.length; then multiActionTmpl; else singleActionTmpl"></ng-container>

        <ng-template #singleActionTmpl>
          <button mat-button mat-icon-button
            [mat-dialog-close]="[cardData.departmentId ,'patients', srv.data.visitId, 'recipients']">
            <mat-icon>edit</mat-icon>
          </button>
        </ng-template>

        <ng-template #multiActionTmpl>
          <button mat-button mat-icon-button disableRipple [matMenuTriggerFor]="menu"
            *ngIf="cardData.recipients.length">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item
              [mat-dialog-close]="[cardData.departmentId ,'patients', srv.data.visitId, 'recipients']" i18n>
              Manage Recipients
            </button>
            <button mat-menu-item
              [mat-dialog-close]="[cardData.departmentId ,'patients', 'recipients-chat', srv.data.visitId]" i18n>
              Send Msg.
            </button>
          </mat-menu>
        </ng-template>

      </div>
    </div>

    <ng-container *ngIf="cardData.recipients.length; then recipientsTemplate; else noRecipientsTmpl"></ng-container>

    <ng-template #recipientsTemplate>
      <ng-container *ngFor="let r of cardData.recipients; let last = last; trackBy: trackByFn">
        <div class="primary-text">
          <span>{{ r.name }}</span>&nbsp;
          <span *ngIf="r.relationship">({{ r.relationship }})</span>
        </div>
        <div *ngIf="!last" class="divider spacer"></div>
      </ng-container>
    </ng-template>

    <ng-template #noRecipientsTmpl>
      <div>
        <span class="secondary-text" i18n>No records available</span>
      </div>
    </ng-template>
  </div>
</ng-container>