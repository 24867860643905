import { TextMessage } from '@frontend/shared/text-message/data-access/src';
import { Action } from '@ngrx/store';

export enum ChatSmartActionTypes {
  LoadChatSmart = '[Chat Smart] Load',
  LoadChatSmartSuccess = '[Chat Smart] Load Success',
  LoadChatSmartFailure = '[Chat Smart] Load Failure',
  SendTextMessage = '[Chat Smart] Send Text Message',
  SendTextMessageSuccess = '[Chat Smart] Send Text Message Success',
  SendTextMessageFailure = '[Chat Smart] Send Text Message Failure',
  MarkAllSeen = '[Chat Smart] Mark All Seen',
}

export class LoadChatSmart implements Action {
  readonly type = ChatSmartActionTypes.LoadChatSmart;
}

export class LoadChatSmartSuccess implements Action {
  readonly type = ChatSmartActionTypes.LoadChatSmartSuccess;
  constructor(
    public payload: {
      patientName: string;
      patient_department_visit_id: string;
    }
  ) {}
}

export class LoadChatSmartFailure implements Action {
  readonly type = ChatSmartActionTypes.LoadChatSmartFailure;
}

export class SendTextMessage implements Action {
  readonly type = ChatSmartActionTypes.SendTextMessage;
  constructor(public payload: { message: string; is_to_patient: boolean }) {}
}

export class SendTextMessageSuccess implements Action {
  readonly type = ChatSmartActionTypes.SendTextMessageSuccess;
  constructor(public payload: TextMessage) {}
}

export class SendTextMessageFailure implements Action {
  readonly type = ChatSmartActionTypes.SendTextMessageFailure;
}

export class MarkAllSeen implements Action {
  readonly type = ChatSmartActionTypes.MarkAllSeen;
}

export type ChatSmartActions =
  | LoadChatSmart
  | LoadChatSmartSuccess
  | LoadChatSmartFailure
  | SendTextMessage
  | SendTextMessageSuccess
  | SendTextMessageFailure
  | MarkAllSeen;
