import { Action } from '@ngrx/store';
import { Operation } from '../../models/operation.model';

export const CREATE_OPERATION = '[Bulk Uploader] CREATE_OPERATION';
export const EXPORT_ERRORS = '[Bulk Uploader] EXPORT_ERRORS';
export const FINISHED_OPERATION = '[Bulk Uploader] FINISHED_OPERATION';
export const OPERATION_UPDATE = '[Bulk Uploader] OPERATION_UPDATE';
export const PENDING_OPERATIONS_UPDATE = '[Bulk Uploader] PENDING_OPERATIONS_UPDATE';
export const PROCESSED_RESPONSE = '[Bulk Uploader] PROCESSED_RESPONSE';
export const RECEIVED_RESPONSE = '[Bulk Uploader] RECEIVED_RESPONSE';
export const RECEIVED_RESPONSE_ERROR = '[Bulk Uploader] RECEIVED_RESPONSE_ERROR';
export const RECEIVED_RESPONSE_SUCCESS = '[Bulk Uploader] RECEIVED_RESPONSE_SUCCESS';
export const REMOVE_OPERATION = '[Bulk Uploader] REMOVE_OPERATION';
export const UPLOAD_FILE_FAILED = '[Bulk Uploader] UPLOAD_FILE_FAILED';

export class CreateOperation implements Action {
  readonly type = CREATE_OPERATION;
  constructor(public payload: Partial<Operation>) {
    payload.total = payload.items.length;
    payload.pending = payload.items.length;
    payload.processed = 0;
    payload.error = 0;
    payload.success = 0;
    payload.progress = 0;
    payload.errorItems = [];
    delete payload.items;
  }
}
export class ExportErrors implements Action {
  readonly type = EXPORT_ERRORS;
  constructor(public payload: object[]) {}
}
export class FinishedOperation implements Action {
  readonly type = FINISHED_OPERATION;
  constructor(public payload: string) {}
}
export class OperationUpdate implements Action {
  readonly type = OPERATION_UPDATE;
  constructor(public payload: object) {}
}
export class PendingOperationUpdate implements Action {
  readonly type = PENDING_OPERATIONS_UPDATE;
  constructor(public payload: number) {}
}
export class ProcessedResponse implements Action {
  readonly type = PROCESSED_RESPONSE;
  constructor(public payload: Operation) {}
}
export class ReceivedResponse implements Action {
  readonly type = RECEIVED_RESPONSE;
  constructor(public payload: any) {}
}
export class ReceivedResponseError implements Action {
  readonly type = RECEIVED_RESPONSE_ERROR;
  constructor(public payload: Operation) {}
}
export class ReceivedResponseSuccess implements Action {
  readonly type = RECEIVED_RESPONSE_SUCCESS;
  constructor(public payload: Operation) {}
}
export class RemoveOperation implements Action {
  readonly type = REMOVE_OPERATION;
  constructor(public payload: string) {}
}
export class UploadFileFailed implements Action {
  readonly type = UPLOAD_FILE_FAILED;
  constructor(public payload: string) {}
}

export type OperationsActions =
  | CreateOperation
  | ExportErrors
  | FinishedOperation
  | OperationUpdate
  | PendingOperationUpdate
  | ProcessedResponse
  | ReceivedResponse
  | ReceivedResponseError
  | ReceivedResponseSuccess
  | RemoveOperation
  | UploadFileFailed;
