import { AbstractState, BaseAction, ReducerFactory } from '@frontend/shared/base-store';
import { Workflow } from '../workflow.model';
import { workflowsActions } from './workflows.actions';

class WorkflowsReducerFactory extends ReducerFactory<Workflow> {
  constructor() {
    super(Workflow);
  }

  getReducer() {
    return (
      state: AbstractState<Workflow> = this.adapter.getInitialState(this.initialState),
      a: BaseAction<Workflow>
    ) => {
      switch (a.type) {
        case workflowsActions.types.TOGGLE:
          return this.adapter.updateOne(
            {
              id: a.payload.id,
              changes: { is_activated: a.payload.is_activated, pending: true },
            },
            state
          );
        case workflowsActions.types.DUPLICATE:
          return this.adapter.addOne(
            { ...a.payload, id: a.payload.correlation_id, pending: true },
            state
          );
        default:
          return super.getReducer()(state, a);
      }
    };
  }
}

const reducerFunction = new WorkflowsReducerFactory().getReducer();

export function workflowsReducer(state, action) {
  return reducerFunction(state, action);
}
