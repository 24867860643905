import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DepartmentSelectorComponent } from '../department-selector/department-selector.component';
import { ToolbarService } from './toolbar.service';

@Component({
  selector: 'wrt-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  providers: [ToolbarService],
})
export class ToolbarComponent {
  constructor(private dialog: MatDialog, private srv: ToolbarService) {}

  get department$() {
    return this.srv.department$;
  }

  get user$() {
    return this.srv.user$;
  }

  openChangeDepartmentDialog() {
    this.dialog.open(DepartmentSelectorComponent, {
      closeOnNavigation: true,
      width: '500px',
      panelClass: ['no-padding'],
    });
  }
}
