import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { LocationNetworkModel } from '../../../data-models';
import * as CareDataActions from './care.actions';

export const featureKey = 'care';

export const adapter: EntityAdapter<LocationNetworkModel> = createEntityAdapter<
  LocationNetworkModel
>();

export interface State extends EntityState<LocationNetworkModel> {
  loading: boolean;
  columns: string[];
}

export const initialState: State = adapter.getInitialState({
  loading: true,
  columns: null,
});

export const reducer = createReducer(
  initialState,
  on(CareDataActions.loadCareData, (state, { columns, collection }) => ({
    ...adapter.setAll(collection, state),
    loading: false,
    columns,
  })),
  on(CareDataActions.clearCareData, () => initialState)
);

export const { selectAll } = adapter.getSelectors();
