import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { CallToStationModule } from '@frontend/common/call-to-station';
import { SharedUiSkeletonVisitDetailModuleModule } from '@frontend/common/skeletons';
import { WorkflowsComponent } from './workflows.component';

@NgModule({
  imports: [
    CommonModule,
    SharedUiSkeletonVisitDetailModuleModule,
    MatIconModule,
    MatDialogModule,
    MatMenuModule,
    MatButtonModule,
    MatBadgeModule,
    CallToStationModule,
  ],
  declarations: [WorkflowsComponent],
  exports: [WorkflowsComponent],
})
export class WorkflowsModule {}
