import { Inject, Injectable, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Apollo } from 'apollo-angular';
import { EMPTY, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { dataManipulation } from './data-manipulation';
import { CardData } from './recipients.component';
import { RecipientsDataQuery } from './recipients.graphql';
import { SecurableService } from './securable.service';

@Injectable()
export class RecipientsService implements OnDestroy {
  _subscriptions: Subscription[] = [];
  _cardDataRaw$: ReplaySubject<CardData> = new ReplaySubject(1);
  cardData$: Observable<CardData> = this._cardDataRaw$.pipe(catchError(() => EMPTY));

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { visitId: number },
    private apollo: Apollo,
    private snackBar: MatSnackBar,
    private securableSrv: SecurableService
  ) {
    this._subscriptions.push(
      this._getVisit(this.data.visitId.toString())
        .pipe(
          switchMap((visit) => {
            if (!visit.recipients.length) {
              return of(visit);
            }
            return this.securableSrv
              .getSecureData(visit.recipients)
              .pipe(map((recipients) => ({ ...visit, recipients })));
          }),
          map(dataManipulation)
        )
        .subscribe(this._cardDataRaw$)
    );

    this._subscriptions.push(
      this._cardDataRaw$.subscribe({
        error: (err) => {
          const message = $localize`There was an unexpected error.`;
          this.snackBar.open(message, $localize`Close`, { duration: 10000 });
          throw err;
        },
      })
    );
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  _getVisit(visitId: string): Observable<RecipientsDataQuery> {
    return this.apollo
      .query<RecipientsDataQuery>({
        query: RecipientsDataQuery,
        variables: { visitId },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((result) => {
          if (!result.data) {
            throw new Error("Error getting visit's information (result.data was falsy)");
          }
          return result.data;
        })
      );
  }
}
