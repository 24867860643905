<div style="flex-direction: column; padding: 8px 24px">
  <div>
    <skeleton-item style="height: 14px; margin: 21px 0; width: 70px"></skeleton-item>
  </div>
  <div>
    <skeleton-item style="height: 14px; margin: 21px 0; width: 130px"></skeleton-item>
  </div>
  <div>
    <skeleton-item style="height: 14px; margin: 21px 0; width: 100px"></skeleton-item>
  </div>
  <div>
    <skeleton-item style="height: 14px; margin: 21px 0; width: 70px"></skeleton-item>
  </div>
  <div>
    <skeleton-item style="height: 14px; margin: 21px 0; width: 140px"></skeleton-item>
  </div>
  <div>
    <skeleton-item style="height: 14px; margin: 21px 0; width: 130px"></skeleton-item>
  </div>
  <div>
    <skeleton-item style="height: 14px; margin: 21px 0; width: 100px"></skeleton-item>
  </div>
  <div>
    <skeleton-item style="height: 14px; margin: 21px 0; width: 70px"></skeleton-item>
  </div>
  <div>
    <skeleton-item style="height: 14px; margin: 21px 0; width: 140px"></skeleton-item>
  </div>
</div>
