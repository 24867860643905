import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SocketService } from '@frontend/common/util';
import { BehaviorSubject, merge, race, throwError } from 'rxjs';
import { concatMap, filter, first, ignoreElements, mergeMap, tap } from 'rxjs/operators';
import * as uuid from 'uuid/v4';
import { CallToStationDialogComponent } from './call-to-station-dialog/call-to-station-dialog.component';
import { Station } from './call-to-station-dialog/call-to-station-dialog.graphql';

@Injectable({ providedIn: 'root' })
export class CallToStationService {
  private _defaultStation: BehaviorSubject<Station> = new BehaviorSubject(null);
  public get defaultStation$() {
    return this._defaultStation.asObservable();
  }

  public get defaultStation() {
    return this._defaultStation.getValue();
  }

  public setDefaultStation(value: Station) {
    this._defaultStation.next(value);
  }

  constructor(
    private dialog: MatDialog,
    private socketSrv: SocketService,
    private http: HttpClient,
    private snackBar: MatSnackBar
  ) {}

  phaseCallPatient(patientWorkflowId: number, phaseId: number) {
    if (this.defaultStation) {
      return this._call(patientWorkflowId, this.defaultStation.id, phaseId);
    } else {
      return this.dialog
        .open(CallToStationDialogComponent, {
          width: '632px',
          closeOnNavigation: true,
        })
        .afterClosed()
        .pipe(
          filter((result) => !!result),
          tap((result) => {
            if (result.saveAsDefault) {
              this.setDefaultStation(result.selectedStation);
            }
          }),
          concatMap((result) =>
            this._call(patientWorkflowId, result.selectedStation.id, phaseId)
          )
        );
    }
  }

  _call(
    patient_department_visit_workflow_id: number,
    station_id: number,
    current_workflow_phase_id: number
  ) {
    const correlation_id = 'correlation|' + uuid();
    return merge(
      this.http
        .put(`patient-workflows/${patient_department_visit_workflow_id}/call`, {
          station_id,
          current_workflow_phase_id,
          patient_department_visit_workflow_id:
            patient_department_visit_workflow_id.toString(),
          correlation_id,
        })
        .pipe(ignoreElements()),
      race(
        this.socketSrv
          .fromLocalSuccess<any>('patient-workflows.call')
          .pipe(filter((ws) => ws.correlation_id === correlation_id)),
        this.socketSrv.fromLocalError('patient-workflows.call').pipe(
          filter((ws) => ws.correlation_id === correlation_id),
          mergeMap((ws) => {
            if (ws.errorCode === 'PATIENT_CALL_LIMIT_REACHED') {
              this.snackBar.open(
                $localize`Patient was already called to another station`,
                $localize`Close`
              );
            }
            return throwError(ws);
          })
        )
      ).pipe(first())
    );
  }
}
