import { Inject, Injectable, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Apollo } from 'apollo-angular';
import { EMPTY, Observable, ReplaySubject, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CardData } from './card-data';
import { dataManipulation } from './data-manipulation';
import { MilestonesDataQuery } from './milestones.graphql';

@Injectable()
export class MilestonesService implements OnDestroy {
  _subscriptions: Subscription[] = [];
  _cardDataRaw$: ReplaySubject<CardData> = new ReplaySubject(1);
  cardData$: Observable<CardData> = this._cardDataRaw$.pipe(catchError(() => EMPTY));

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { visitId: number },
    private apollo: Apollo,
    private snackBar: MatSnackBar
  ) {
    this._subscriptions.push(
      this._getMilestones(this.data.visitId.toString()).subscribe(this._cardDataRaw$)
    );

    this._subscriptions.push(
      this._cardDataRaw$.subscribe({
        error: (err) => {
          const message = $localize`There was an unexpected error.`;
          this.snackBar.open(message, $localize`Close`, { duration: 10000 });
          throw err;
        },
      })
    );
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  _getMilestones(visitId: string): Observable<CardData> {
    return this.apollo
      .query<MilestonesDataQuery>({
        query: MilestonesDataQuery,
        variables: { visitId },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((result) => {
          if (!result.data) {
            throw new Error(
              "Error getting milestones' information (result.data was falsy)"
            );
          }
          return result.data;
        }),
        map((data) => dataManipulation(data))
      );
  }
}
