<frontend-simple-toolbar title="Patients" i18n-title>
  <ng-container *ngIf="this.srv.defaultStation$ | async as station">
    <mat-chip-list>
      <mat-chip disableRipple selectable="false" selected (removed)="this.srv.setDefaultStation(null)">
        <span class="mat-typography" i18n>Work station: {{ station.name }} </span>
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </ng-container>
  <div class="spacer"></div>
  <search-box (searchChanges)="this.srv.searchValue$.next($event)"></search-box>
  <frontend-unread-messages-menu></frontend-unread-messages-menu>
  <button mat-button mat-icon-button title="Notify Staff" i18n-title [matMenuTriggerFor]="tagChirp">
    <mat-icon>notification_add</mat-icon>
  </button>
  <mat-menu #tagChirp="matMenu">
    <ng-template matMenuContent>
      <frontend-tag-chirping></frontend-tag-chirping>
    </ng-template>
  </mat-menu>
  <button mat-button mat-icon-button routerLink="chat/staff" title="Contact staff" i18n-title>
    <mat-icon>3p</mat-icon>
  </button>
  <button mat-button mat-icon-button routerLink="new" title="Add patient" i18n-title>
    <mat-icon>add</mat-icon>
  </button>
</frontend-simple-toolbar>

<div class="content-wrapper">
  <mat-card class="mat-elevation-z1" style="margin-bottom: 16px">
    <mat-card-content>
      <mat-expansion-panel #careExpansionPanel togglePosition="before" [expanded]="srv.careState$ | async">
        <mat-expansion-panel-header (click)="srv.setCarePanelState(careExpansionPanel.expanded)">
          <mat-toolbar color="primary" style="height: 48px; padding: 0 16px 0 0">
            <span i18n>Care</span>
            <span style="flex: 1 1 auto"></span>
            <div class="filters-applied-wrapper" *ngIf="
                careExpansionPanel.expanded && (this.srv.careFiltersApplied$ | async)
              " (click)="$event.stopPropagation()">
              <span class="filters-applied mat-typography" i18n>Filters applied</span>
            </div>
            <button mat-icon-button (click)="openCareFiltersDialog(); $event.stopPropagation()"
              *ngIf="careExpansionPanel.expanded">
              <mat-icon>filter_list</mat-icon>
            </button>
          </mat-toolbar>
        </mat-expansion-panel-header>
        <ng-container *ngIf="careLoading$ | async">
          <skeleton-table></skeleton-table>
        </ng-container>
        <frontend-care-table [ngStyle]="{ display: (careLoading$ | async) ? 'none' : 'inherit' }"
          [columns]="careColumns$ | async" [collection]="careCollection$ | async" (advancePhase)="advancePhase($event)"
          (changeDepartment)="openChangeDepartmentDialog($event)" (openVisitDetail)="openVisitDetailDialog($event)"
          (changePhase)="openChangePhaseDialog($event)" (endVisit)="openEndVisitDialog($event)"
          (deletePatient)="openDeletePatientDialog($event)" (updateLocation)="updateLocation($event)">
        </frontend-care-table>
      </mat-expansion-panel>
    </mat-card-content>
  </mat-card>

  <mat-card class="mat-elevation-z1">
    <mat-card-content>
      <mat-expansion-panel #patientsExpansionPanel togglePosition="before" [expanded]="srv.patientsState$| async">
        <mat-expansion-panel-header (click)="srv.setPatientsPanelState(patientsExpansionPanel.expanded)">
          <mat-toolbar color="primary" style="height: 48px; padding: 0 16px 0 0">
            <span i18n>Patients</span>
            <span style="flex: 1 1 auto"></span>
            <div class="filters-applied-wrapper" *ngIf="
                patientsExpansionPanel.expanded &&
                (this.srv.waitingFiltersApplied$ | async)
              " (click)="$event.stopPropagation()">
              <span class="filters-applied mat-typography" i18n>Filters applied</span>
            </div>
            <button mat-icon-button (click)="openBulkUploadInstructions(); $event.stopPropagation()"
              *ngIf="patientsExpansionPanel.expanded">
              <mat-icon>file_upload</mat-icon>
            </button>
            <button mat-icon-button (click)="openWaitingFiltersDialog(); $event.stopPropagation()"
              *ngIf="patientsExpansionPanel.expanded">
              <mat-icon>filter_list</mat-icon>
            </button>
            <button mat-button mat-icon-button disableRipple [matMenuTriggerFor]="menu"
              (click)="$event.stopPropagation()" *ngIf="patientsExpansionPanel.expanded">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="confirmEndUnassignedPatients()">
                <span i18n>End Unassigned Visits</span>
              </button>
            </mat-menu>
          </mat-toolbar>
        </mat-expansion-panel-header>

        <ng-container *ngIf="waitingLoading$ | async">
          <skeleton-table></skeleton-table>
        </ng-container>
        <frontend-waiting-table [ngStyle]="{ display: (waitingLoading$ | async) ? 'none' : 'inherit' }"
          [columns]="waitingColumns$ | async" [collection]="waitingCollection$ | async"
          (advancePhase)="advancePhase($event)" (callPatient)="onCallPatient($event)"
          (changeDepartment)="openChangeDepartmentDialog($event)" (openVisitDetail)="openVisitDetailDialog($event)"
          (changePhase)="openChangePhaseDialog($event)" (endVisit)="openEndVisitDialog($event)"
          (deletePatient)="openDeletePatientDialog($event)" (updateLocation)="updateLocation($event)">
        </frontend-waiting-table>
      </mat-expansion-panel>
    </mat-card-content>
  </mat-card>
</div>

<router-outlet></router-outlet>
