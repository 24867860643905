<ng-template #nullValue>
  <li><span>-</span></li>
</ng-template>
<ng-template #collapsible></ng-template>
<ng-template #space>
  <li><span>&nbsp;</span></li>
</ng-template>
<ng-template #emptyLocation>
  <li class="space-item"><span>&nbsp;</span></li>
</ng-template>
<ng-template #empty>
  <ng-template *ngIf="multidimensional; then space; else collapsible"></ng-template>
</ng-template>

<!-- sortData method requires usage of ngStyle here instead of ngIf -->
<div class="table-wrapper" [ngStyle]="{ display: dataSource.data?.length ? 'block' : 'none' }">
  <table mat-table [dataSource]="dataSource" [trackBy]="trackByFn" matSort matSortDisableClear
    matSortActive="locationName" matSortDirection="asc">
    <!-- location -->
    <ng-container matColumnDef="locationName">
      <th mat-header-cell *matHeaderCellDef class="fixIE11verticallyCentered location">
        <span mat-sort-header i18n>Location</span>
      </th>
      <td mat-cell *matCellDef="let row" class="fixIE11verticallyCentered location" (click)="highlightLocation(row.id)"
        [ngClass]="{ highlight: row.id === highlightLocationId }">
        <div>
          <ul>
            <li class="column-location-content-wrapper">
              <span class="blue-point" [ngClass]="{ 'active-point': row.location_status_id === 2 }"></span>
              <span style="margin: 0">{{ row.alias_name || row.name }}</span>
            </li>
          </ul>
        </div>
      </td>
    </ng-container>

    <!-- workflow -->
    <ng-container matColumnDef="workflowName">
      <th mat-header-cell *matHeaderCellDef class="fixIE11verticallyCentered">
        <span mat-sort-header i18n>Workflow</span>
      </th>
      <td mat-cell *matCellDef="let row" class="fixIE11verticallyCentered"
        [ngClass]="{ 'highlight-space': row.id === highlightLocationId }">
        <div>
          <ul>
            <ng-container *ngIf="row?.patients?.length; else emptyLocation">
              <ng-container *ngFor="let p of row?.patients; first as pIsFirst">
                <div [ngClass]="{
                    'fake-patient-row': !pIsFirst,
                    highlight: isPatientHighlighted(row.id, p.id)
                  }" (click)="highlightPatient(row.id, p.id)">
                  <ng-container *ngIf="p?.patient_workflows?.length; else nullValue">
                    <li *ngFor="let w of p?.patient_workflows">
                      <span>{{ w?.workflow?.name }}</span>
                    </li>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </td>
    </ng-container>

    <!-- workflow duration -->
    <ng-container matColumnDef="timeInWorkflow">
      <th mat-header-cell *matHeaderCellDef class="fixIE11verticallyCentered">
        <span mat-sort-header i18n>Time in Workflow</span>
      </th>
      <td mat-cell *matCellDef="let row" class="fixIE11verticallyCentered" [ngClass]="{
          'highlight-space': row.id === highlightLocationId
        }">
        <div>
          <ul>
            <ng-container *ngIf="row?.patients?.length; else emptyLocation">
              <ng-container *ngFor="let p of row?.patients; first as pIsFirst">
                <div [ngClass]="{
                    'fake-patient-row': !pIsFirst,
                    highlight: isPatientHighlighted(row.id, p.id)
                  }" (click)="highlightPatient(row.id, p.id)">
                  <ng-container *ngIf="p?.patient_workflows?.length; else nullValue">
                    <li *ngFor="let w of p?.patient_workflows">
                      <span>{{ w?.workflowDuration }}</span>
                    </li>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </td>
    </ng-container>

    <!-- phase -->
    <ng-container matColumnDef="phaseName">
      <th mat-header-cell *matHeaderCellDef class="fixIE11verticallyCentered">
        <span mat-sort-header i18n>Phase</span>
      </th>
      <td mat-cell *matCellDef="let row" class="fixIE11verticallyCentered" [ngClass]="{
          'highlight-space': row.id === highlightLocationId
        }">
        <div>
          <ul>
            <ng-container *ngIf="row?.patients?.length; else emptyLocation">
              <ng-container *ngFor="let p of row?.patients; first as pIsFirst">
                <div [ngClass]="{
                    'fake-patient-row': !pIsFirst,
                    highlight: isPatientHighlighted(row.id, p.id)
                  }" (click)="highlightPatient(row.id, p.id)">
                  <ng-container *ngIf="p?.patient_workflows?.length; else nullValue">
                    <ng-container *ngFor="let w of p?.patient_workflows">
                      <li *ngFor="let milestone of w.patient_workflow_phase_milestones">
                        <span>{{ milestone?.workflow_phase?.name }}</span>
                      </li>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </td>
    </ng-container>

    <!-- phase duration -->
    <ng-container matColumnDef="phaseUpdatedUTC">
      <th mat-header-cell *matHeaderCellDef class="fixIE11verticallyCentered">
        <span mat-sort-header i18n>Time in Phase</span>
      </th>
      <td mat-cell *matCellDef="let row" class="fixIE11verticallyCentered" [ngClass]="{
          'highlight-space': row.id === highlightLocationId
        }">
        <div>
          <ul>
            <ng-container *ngIf="row?.patients?.length; else emptyLocation">
              <ng-container *ngFor="let p of row?.patients; first as pIsFirst">
                <div [ngClass]="{
                    'fake-patient-row': !pIsFirst,
                    highlight: isPatientHighlighted(row.id, p.id)
                  }" (click)="highlightPatient(row.id, p.id)">
                  <ng-container *ngIf="p?.patient_workflows?.length; else nullValue">
                    <ng-container *ngFor="let w of p?.patient_workflows">
                      <li *ngFor="let milestone of w.patient_workflow_phase_milestones">
                        <span>
                          <wrt-duration-badge [label]="milestone?.phaseDuration"
                            [minutes]="milestone?.phaseDurationMinutes" [low]="
                              milestone?.workflow_phase?.max_phase_duration_minutes_low
                            " [high]="
                              milestone?.workflow_phase?.max_phase_duration_minutes_high
                            "></wrt-duration-badge>
                        </span>
                      </li>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </td>
    </ng-container>

    <!-- appointment type -->
    <ng-container matColumnDef="appointmentType">
      <th mat-header-cell *matHeaderCellDef class="fixIE11verticallyCentered">
        <span mat-sort-header i18n>Appt. Type</span>
      </th>
      <td mat-cell *matCellDef="let row" class="fixIE11verticallyCentered" [ngClass]="{
          'highlight-space': row.id === highlightLocationId
        }">
        <div>
          <ul>
            <ng-container *ngIf="row?.patients?.length; else emptyLocation">
              <ng-container *ngFor="let p of row?.patients; first as pIsFirst">
                <div [ngClass]="{
                    'fake-patient-row': !pIsFirst,
                    highlight: isPatientHighlighted(row.id, p.id)
                  }" (click)="highlightPatient(row.id, p.id)">
                  <ng-container *ngIf="p?.patient_workflows?.length; else nextApptType">
                    <ng-container *ngFor="let w of p?.patient_workflows">
                      <ng-container *ngIf="w?.workflow?.workflow_type_id === 1; else nextApptType">
                        <ng-container *ngIf="
                            w?.patient_appointment?.appointment_type?.name;
                            else nullValue
                          ">
                          <li>
                            <span>{{
                              w?.patient_appointment?.appointment_type?.name
                              }}</span>
                          </li>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-template #nextApptType>
                    <ng-container *ngIf="p?.nextAppointment?.appointment_type?.name; else nullValue">
                      <li>
                        <span>{{ p?.nextAppointment?.appointment_type?.name }}</span>
                      </li>
                    </ng-container>
                  </ng-template>
                </div>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </td>
    </ng-container>

    <!-- appointment time -->
    <ng-container matColumnDef="appointmentTimeUTC">
      <th mat-header-cell *matHeaderCellDef class="fixIE11verticallyCentered">
        <span mat-sort-header i18n>Appt. Time</span>
      </th>
      <td mat-cell *matCellDef="let row" class="fixIE11verticallyCentered" [ngClass]="{
          'highlight-space': row.id === highlightLocationId
        }">
        <div>
          <ul>
            <ng-container *ngIf="row?.patients?.length; else emptyLocation">
              <ng-container *ngFor="let p of row?.patients; first as pIsFirst">
                <div [ngClass]="{
                    'fake-patient-row': !pIsFirst,
                    highlight: isPatientHighlighted(row.id, p.id)
                  }" (click)="highlightPatient(row.id, p.id)">
                  <ng-container *ngIf="p?.patient_workflows?.length; else nullValue">
                    <ng-container *ngFor="let w of p?.patient_workflows">
                      <ng-container *ngIf="w?.workflow?.workflow_type_id === 1; else nextApptTime">
                        <ng-container *ngIf="
                            w?.patient_appointment?.appointmentTimeTz;
                            else nullValue
                          ">
                          <li>
                            <span>{{ w?.patient_appointment?.appointmentTimeTz }}</span>
                          </li>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-template #nextApptTime>
                    <ng-container *ngIf="p?.nextAppointment?.appointmentTimeTz; else nullValue">
                      <li>
                        <span>{{ p?.nextAppointment?.appointmentTimeTz }}</span>
                      </li>
                    </ng-container>
                  </ng-template>
                </div>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </td>
    </ng-container>

    <!-- provider -->
    <ng-container matColumnDef="provider">
      <th mat-header-cell *matHeaderCellDef class="fixIE11verticallyCentered">
        <span mat-sort-header i18n>Provider</span>
      </th>
      <td mat-cell *matCellDef="let row" class="fixIE11verticallyCentered" [ngClass]="{
          'highlight-space': row.id === highlightLocationId
        }">
        <div>
          <ul>
            <ng-container *ngIf="row?.patients?.length; else emptyLocation">
              <ng-container *ngFor="let p of row?.patients; first as pIsFirst">
                <div [ngClass]="{
                    'fake-patient-row': !pIsFirst,
                    highlight: isPatientHighlighted(row.id, p.id)
                  }" (click)="highlightPatient(row.id, p.id)">
                  <ng-container *ngIf="p?.patient_workflows?.length; else nullValue">
                    <ng-container *ngFor="let w of p?.patient_workflows">
                      <ng-container *ngIf="w?.workflow?.workflow_type_id === 1; else nextApptProvider">
                        <ng-container *ngIf="
                            w?.patient_appointment?.provider?.fullName;
                            else nullValue
                          ">
                          <li>
                            <span>{{ w?.patient_appointment?.provider?.fullName }}</span>
                          </li>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-template #nextApptProvider>
                    <ng-container *ngIf="p?.nextAppointment?.provider?.fullName; else nullValue">
                      <li>
                        <span>{{ p?.nextAppointment?.provider?.fullName }}</span>
                      </li>
                    </ng-container>
                  </ng-template>
                </div>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </td>
    </ng-container>

    <!-- patient -->
    <ng-container matColumnDef="fullName">
      <th mat-header-cell *matHeaderCellDef class="fixIE11verticallyCentered">
        <span mat-sort-header i18n>Patient</span>
      </th>
      <td mat-cell *matCellDef="let row" class="fixIE11verticallyCentered" [ngClass]="{
          'highlight-space': row.id === highlightLocationId
        }">
        <div>
          <ul>
            <ng-container *ngIf="row?.patients?.length; else emptyLocation">
              <ng-container *ngFor="let p of row?.patients; first as pIsFirst">
                <div [ngClass]="{
                    'fake-patient-row': !pIsFirst,
                    highlight: isPatientHighlighted(row.id, p.id)
                  }" (click)="highlightPatient(row.id, p.id)">
                  <ng-container *ngIf="p?.patient_workflows?.length; else nullValue">
                    <ng-container *ngFor="let w of p?.patient_workflows; first as wIsFirst">
                      <ng-container *ngIf="wIsFirst; else empty">
                        <ng-container *ngIf="p.securableData?.fullName; else nullValue">
                          <li>
                            <span>{{ p.securableData?.fullName }}</span>
                          </li>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </td>
    </ng-container>

    <!-- note -->
    <ng-container matColumnDef="note">
      <th mat-header-cell *matHeaderCellDef class="fixIE11verticallyCentered">
        <span mat-sort-header i18n>Notes</span>
      </th>
      <td mat-cell *matCellDef="let row" class="fixIE11verticallyCentered note" [ngClass]="{
          'highlight-space': row.id === highlightLocationId
        }">
        <div>
          <ul>
            <ng-container *ngIf="row?.patients?.length; else emptyLocation">
              <ng-container *ngFor="let p of row?.patients; first as pIsFirst">
                <div [ngClass]="{
                    'fake-patient-row': !pIsFirst,
                    highlight: isPatientHighlighted(row.id, p.id)
                  }" (click)="highlightPatient(row.id, p.id)">
                  <ng-container *ngIf="p?.patient_workflows?.length; else nullValue">
                    <ng-container *ngFor="let w of p?.patient_workflows; first as wIsFirst">
                      <ng-container *ngIf="wIsFirst; else empty">
                        <ng-container *ngIf="p.note; else nullValue">
                          <li>
                            <span [title]="p.note">{{ p.note }}</span>
                          </li>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </td>
    </ng-container>

    <!-- additional note -->
    <ng-container matColumnDef="additional_note">
      <th mat-header-cell *matHeaderCellDef class="fixIE11verticallyCentered">
        <span mat-sort-header i18n>Additional Notes</span>
      </th>
      <td mat-cell *matCellDef="let row" class="fixIE11verticallyCentered note" [ngClass]="{
          'highlight-space': row.id === highlightLocationId
        }">
        <div>
          <ul>
            <ng-container *ngIf="row?.patients?.length; else emptyLocation">
              <ng-container *ngFor="let p of row?.patients; first as pIsFirst">
                <div [ngClass]="{
                    'fake-patient-row': !pIsFirst,
                    highlight: isPatientHighlighted(row.id, p.id)
                  }" (click)="highlightPatient(row.id, p.id)">
                  <ng-container *ngIf="p?.patient_workflows?.length; else nullValue">
                    <ng-container *ngFor="let w of p?.patient_workflows; first as wIsFirst">
                      <ng-container *ngIf="wIsFirst; else empty">
                        <ng-container *ngIf="p.additional_note; else nullValue">
                          <li>
                            <span title="{{ p.additional_note }}">{{
                              p.additional_note
                              }}</span>
                          </li>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </td>
    </ng-container>

    <!-- tag id -->
    <ng-container matColumnDef="tagValue">
      <th mat-header-cell *matHeaderCellDef class="fixIE11verticallyCentered">
        <span mat-sort-header i18n>Badge ID</span>
      </th>
      <td mat-cell *matCellDef="let row" class="fixIE11verticallyCentered" [ngClass]="{
          'highlight-space': row.id === highlightLocationId
        }">
        <div>
          <ul>
            <ng-container *ngIf="row?.patients?.length; else emptyLocation">
              <ng-container *ngFor="let p of row?.patients; first as pIsFirst">
                <div [ngClass]="{
                    'fake-patient-row': !pIsFirst,
                    highlight: isPatientHighlighted(row.id, p.id)
                  }" (click)="highlightPatient(row.id, p.id)">
                  <ng-container *ngIf="p?.patient_workflows?.length; else nullValue">
                    <ng-container *ngFor="let w of p?.patient_workflows; first as wIsFirst">
                      <ng-container *ngIf="wIsFirst; else empty">
                        <ng-container *ngIf="p.tag?.tag_value; else nullValue">
                          <li>
                            <span>{{ p.tag?.tag_value }}</span>
                          </li>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </td>
    </ng-container>

    <!-- tag label -->
    <ng-container matColumnDef="tagLabel">
      <th mat-header-cell *matHeaderCellDef class="fixIE11verticallyCentered">
        <span mat-sort-header i18n>Badge Label</span>
      </th>
      <td mat-cell *matCellDef="let row" class="fixIE11verticallyCentered" [ngClass]="{
          'highlight-space': row.id === highlightLocationId
        }">
        <div>
          <ul>
            <ng-container *ngIf="row?.patients?.length; else emptyLocation">
              <ng-container *ngFor="let p of row?.patients; first as pIsFirst">
                <div [ngClass]="{
                    'fake-patient-row': !pIsFirst,
                    highlight: isPatientHighlighted(row.id, p.id)
                  }" (click)="highlightPatient(row.id, p.id)">
                  <ng-container *ngIf="p?.patient_workflows?.length; else nullValue">
                    <ng-container *ngFor="let w of p?.patient_workflows; first as wIsFirst">
                      <ng-container *ngIf="wIsFirst; else empty">
                        <ng-container *ngIf="p.tag?.label; else nullValue">
                          <li>
                            <span>{{ p.tag?.label }}</span>
                          </li>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </td>
    </ng-container>

    <!-- display name -->
    <ng-container matColumnDef="securedPatientName">
      <th mat-header-cell *matHeaderCellDef class="fixIE11verticallyCentered">
        <span mat-sort-header i18n>Patient</span>
      </th>
      <td mat-cell *matCellDef="let row" class="fixIE11verticallyCentered" [ngClass]="{
          'highlight-space': row.id === highlightLocationId
        }">
        <div>
          <ul>
            <ng-container *ngIf="row?.patients?.length; else emptyLocation">
              <ng-container *ngFor="let p of row?.patients; first as pIsFirst">
                <div [ngClass]="{
                    'fake-patient-row': !pIsFirst,
                    highlight: isPatientHighlighted(row.id, p.id)
                  }" (click)="highlightPatient(row.id, p.id)">
                  <ng-container *ngIf="p?.patient_workflows?.length; else nullValue">
                    <ng-container *ngFor="let w of p?.patient_workflows; first as wIsFirst">
                      <ng-container *ngIf="wIsFirst; else empty">
                        <ng-container *ngIf="p.secured_patient_name; else nullValue">
                          <li>
                            <span>{{ p.secured_patient_name }}</span>
                          </li>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </td>
    </ng-container>

    <!-- department duration -->
    <ng-container matColumnDef="timeInDepartment">
      <th mat-header-cell *matHeaderCellDef class="fixIE11verticallyCentered">
        <span mat-sort-header i18n>Time in Department</span>
      </th>
      <td mat-cell *matCellDef="let row" class="fixIE11verticallyCentered" [ngClass]="{
          'highlight-space': row.id === highlightLocationId
        }">
        <div>
          <ul>
            <ng-container *ngIf="row?.patients?.length; else emptyLocation">
              <ng-container *ngFor="let p of row?.patients; first as pIsFirst">
                <div [ngClass]="{
                    'fake-patient-row': !pIsFirst,
                    highlight: isPatientHighlighted(row.id, p.id)
                  }" (click)="highlightPatient(row.id, p.id)">
                  <ng-container *ngIf="p?.patient_workflows?.length; else nullValue">
                    <ng-container *ngFor="let w of p?.patient_workflows; first as wIsFirst">
                      <ng-container *ngIf="wIsFirst; else empty">
                        <ng-container *ngIf="p.departmentDuration; else nullValue">
                          <li>
                            <span>{{ p.departmentDuration }}</span>
                          </li>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </td>
    </ng-container>

    <!-- location duration -->
    <ng-container matColumnDef="durationInLocation">
      <th mat-header-cell *matHeaderCellDef class="fixIE11verticallyCentered">
        <span mat-sort-header i18n>Time in Location</span>
      </th>
      <td mat-cell *matCellDef="let row" class="fixIE11verticallyCentered" [ngClass]="{
          'highlight-space': row.id === highlightLocationId
        }">
        <div>
          <ul>
            <ng-container *ngIf="row?.patients?.length; else emptyLocation">
              <ng-container *ngFor="let p of row?.patients; first as pIsFirst">
                <div [ngClass]="{
                    'fake-patient-row': !pIsFirst,
                    highlight: isPatientHighlighted(row.id, p.id)
                  }" (click)="highlightPatient(row.id, p.id)">
                  <ng-container *ngIf="p?.patient_workflows?.length; else nullValue">
                    <ng-container *ngFor="let w of p?.patient_workflows; first as wIsFirst">
                      <ng-container *ngIf="wIsFirst; else empty">
                        <ng-container *ngIf="p.locationDuration; else nullValue">
                          <li>
                            <span>{{ p.locationDuration }}</span>
                          </li>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </td>
    </ng-container>

    <!-- mrn -->
    <ng-container matColumnDef="mrn">
      <th mat-header-cell *matHeaderCellDef class="fixIE11verticallyCentered">
        <span mat-sort-header i18n>MRN #</span>
      </th>
      <td mat-cell *matCellDef="let row" class="fixIE11verticallyCentered" [ngClass]="{
          'highlight-space': row.id === highlightLocationId
        }">
        <div>
          <ul>
            <ng-container *ngIf="row?.patients?.length; else emptyLocation">
              <ng-container *ngFor="let p of row?.patients; first as pIsFirst">
                <div [ngClass]="{
                    'fake-patient-row': !pIsFirst,
                    highlight: isPatientHighlighted(row.id, p.id)
                  }" (click)="highlightPatient(row.id, p.id)">
                  <ng-container *ngIf="p?.patient_workflows?.length; else nullValue">
                    <ng-container *ngFor="let w of p?.patient_workflows; first as wIsFirst">
                      <ng-container *ngIf="wIsFirst; else empty">
                        <ng-container *ngIf="p.securableData?.mrn; else nullValue">
                          <li>
                            <span>{{ p.securableData?.mrn }}</span>
                          </li>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </td>
    </ng-container>

    <!-- recipients -->
    <ng-container matColumnDef="recipients">
      <th mat-header-cell *matHeaderCellDef class="fixIE11verticallyCentered">
        <span mat-sort-header i18n>Recipients</span>
      </th>
      <td mat-cell *matCellDef="let row" class="fixIE11verticallyCentered" [ngClass]="{
          'highlight-space': row.id === highlightLocationId
        }">
        <div>
          <ul>
            <ng-container *ngIf="row?.patients?.length; else emptyLocation">
              <ng-container *ngFor="let p of row?.patients; first as pIsFirst">
                <div [ngClass]="{
                    'fake-patient-row': !pIsFirst,
                    highlight: isPatientHighlighted(row.id, p.id)
                  }" (click)="highlightPatient(row.id, p.id)">
                  <ng-container *ngIf="p?.patient_workflows?.length; else nullValue">
                    <ng-container *ngFor="let w of p?.patient_workflows; first as wIsFirst">
                      <ng-container *ngIf="wIsFirst; else empty">
                        <ng-container *ngIf="p.recipientsNames; else nullValue">
                          <li>
                            <span [title]="p.recipientsNames">{{
                              p.recipientsNames
                              }}</span>
                          </li>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </td>
    </ng-container>

    <!-- attended time -->
    <ng-container matColumnDef="attendedSinceUtc">
      <th mat-header-cell *matHeaderCellDef class="fixIE11verticallyCentered">
        <span mat-sort-header i18n>Attended Time</span>
      </th>
      <td mat-cell *matCellDef="let row" class="fixIE11verticallyCentered" [ngClass]="{
          'highlight-space': row.id === highlightLocationId
        }">
        <div>
          <ul>
            <ng-container *ngIf="row?.patients?.length; else emptyLocation">
              <ng-container *ngFor="let p of row?.patients; first as pIsFirst">
                <div [ngClass]="{
                    'fake-patient-row': !pIsFirst,
                    highlight: isPatientHighlighted(row.id, p.id)
                  }" (click)="highlightPatient(row.id, p.id)">
                  <ng-container *ngIf="p?.patient_workflows?.length; else nullValue">
                    <ng-container *ngFor="let w of p?.patient_workflows; first as wIsFirst">
                      <ng-container *ngIf="wIsFirst; else empty">
                        <ng-container *ngIf="p?.attendedDuration; else nullValue">
                          <li>
                            <span>{{ p?.attendedDuration }}</span>
                          </li>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </td>
    </ng-container>

    <!-- unattended time -->
    <ng-container matColumnDef="unattendedSinceUtc">
      <th mat-header-cell *matHeaderCellDef class="fixIE11verticallyCentered">
        <span mat-sort-header i18n>Unattended Time</span>
      </th>
      <td mat-cell *matCellDef="let row" class="fixIE11verticallyCentered" [ngClass]="{
          'highlight-space': row.id === highlightLocationId
        }">
        <div>
          <ul>
            <ng-container *ngIf="row?.patients?.length; else emptyLocation">
              <ng-container *ngFor="let p of row?.patients; first as pIsFirst">
                <div [ngClass]="{
                    'fake-patient-row': !pIsFirst,
                    highlight: isPatientHighlighted(row.id, p.id)
                  }" (click)="highlightPatient(row.id, p.id)">
                  <ng-container *ngIf="p?.patient_workflows?.length; else nullValue">
                    <ng-container *ngFor="let w of p?.patient_workflows">
                      <ng-container *ngIf="p?.unattendedDuration; else nullValue">
                        <li>
                          <wrt-duration-badge [label]="p?.unattendedDuration" [minutes]="p?.unattendedDurationInMinutes"
                            [low]="w?.workflow?.max_unattended_low_min" [high]="w?.workflow?.max_unattended_high_min">
                          </wrt-duration-badge>
                        </li>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </td>
    </ng-container>

    <!-- staff -->
    <ng-container matColumnDef="staffName">
      <th mat-header-cell *matHeaderCellDef class="fixIE11verticallyCentered">
        <span mat-sort-header i18n>Staff</span>
      </th>
      <td mat-cell *matCellDef="let row" class="fixIE11verticallyCentered" [ngClass]="{
          'highlight-space': row.id === highlightLocationId
        }">
        <div>
          <ul>
            <ng-container *ngIf="row?.patients?.length; else emptyLocation">
              <ng-container *ngFor="let p of row?.patients; first as pIsFirst">
                <div [ngClass]="{
                    'fake-patient-row': !pIsFirst,
                    highlight: isPatientHighlighted(row.id, p.id)
                  }" (click)="highlightPatient(row.id, p.id)">
                  <ng-container *ngIf="p?.patient_workflows?.length; else nullValue">
                    <ng-container *ngFor="let w of p?.patient_workflows; first as wIsFirst">
                      <ng-container *ngIf="wIsFirst; else empty">
                        <ng-container *ngIf="p?.displayStaff; else nullValue">
                          <li>
                            <div class="green-point" [ngClass]="{
                                'active-point': p?.isWithStaff,
                                'passive-point': !p?.isWithStaff
                              }"></div>
                            <span [matTooltip]="p?.tooltipDisplayStaff" [matTooltipDisabled]="!p?.tooltipDisplayStaff"
                              matTooltipPosition="right" matTooltipClass="info-list-tooltip"
                              [ngClass]="{ disabled: !p?.isWithStaff }">{{ p?.displayStaff }}</span>
                          </li>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </td>
    </ng-container>

    <!-- secured staff -->
    <ng-container matColumnDef="securedStaffName">
      <th mat-header-cell *matHeaderCellDef class="fixIE11verticallyCentered">
        <span mat-sort-header i18n>Staff</span>
      </th>
      <td mat-cell *matCellDef="let row" class="fixIE11verticallyCentered" [ngClass]="{
          'highlight-space': row.id === highlightLocationId
        }">
        <div>
          <ul>
            <ng-container *ngIf="row?.patients?.length; else emptyLocation">
              <ng-container *ngFor="let p of row?.patients; first as pIsFirst">
                <div [ngClass]="{
                    'fake-patient-row': !pIsFirst,
                    highlight: isPatientHighlighted(row.id, p.id)
                  }" (click)="highlightPatient(row.id, p.id)">
                  <ng-container *ngIf="p?.patient_workflows?.length; else nullValue">
                    <ng-container *ngFor="let w of p?.patient_workflows; first as wIsFirst">
                      <ng-container *ngIf="wIsFirst; else empty">
                        <ng-container *ngIf="p?.securedStaff; else nullValue">
                          <li>
                            <div class="green-point" [ngClass]="{
                                'active-point': p?.isWithStaff,
                                'passive-point': !p?.isWithStaff
                              }"></div>
                            <span [matTooltip]="p?.tooltipSecuredStaff" [matTooltipDisabled]="!p?.tooltipSecuredStaff"
                              matTooltipPosition="right" matTooltipClass="info-list-tooltip"
                              [ngClass]="{ disabled: !p?.isWithStaff }">{{ p?.securedStaff }}</span>
                          </li>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </td>
    </ng-container>

    <!-- status -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef class="fixIE11verticallyCentered">
        <span mat-sort-header i18n>Patient Status</span>
      </th>
      <td mat-cell *matCellDef="let row" class="fixIE11verticallyCentered" [ngClass]="{
          'highlight-space': row.id === highlightLocationId
        }">
        <div>
          <ul>
            <ng-container *ngIf="row?.patients?.length; else emptyLocation">
              <ng-container *ngFor="let p of row?.patients; first as pIsFirst">
                <div [ngClass]="{
                    'fake-patient-row': !pIsFirst,
                    highlight: isPatientHighlighted(row.id, p.id)
                  }" (click)="highlightPatient(row.id, p.id)">
                  <ng-container *ngIf="p?.patient_workflows?.length; else nullValue">
                    <ng-container *ngFor="let w of p?.patient_workflows; first as wIsFirst">
                      <ng-container *ngIf="wIsFirst; else empty">
                        <li>
                          <span class="chip" [matTooltip]="p?.tooltipDisplayStatuses"
                            [matTooltipDisabled]="!p?.tooltipDisplayStatuses" matTooltipPosition="right"
                            matTooltipClass="info-list-tooltip"
                            [ngStyle]="{ 'background-color': p?.statusBgColor, 'color': p?.statusTextColor }">
                            {{ p?.status }}
                          </span>
                        </li>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </td>
    </ng-container>

    <!-- badge status -->
    <ng-container matColumnDef="badgeStatus">
      <th mat-header-cell *matHeaderCellDef class="fixIE11verticallyCentered">
        <span mat-sort-header i18n>Badge Status</span>
      </th>
      <td mat-cell *matCellDef="let row" class="fixIE11verticallyCentered" [ngClass]="{
          'highlight-space': row.id === highlightLocationId
        }">
        <div>
          <ul>
            <ng-container *ngIf="row?.patients?.length; else emptyLocation">
              <ng-container *ngFor="let p of row?.patients; first as pIsFirst">
                <div [ngClass]="{
                    'fake-patient-row': !pIsFirst,
                    highlight: isPatientHighlighted(row.id, p.id)
                  }" (click)="highlightPatient(row.id, p.id)">
                  <ng-container *ngIf="p?.patient_workflows?.length; else nullValue">
                    <ng-container *ngFor="let w of p?.patient_workflows; first as wIsFirst">
                      <ng-container *ngIf="wIsFirst; else empty">
                        <ng-container *ngIf="p?.tag; else nullValue">
                          <li>
                            <ng-container [ngTemplateOutlet]="
                                p?.tag?.nonReportingFormatted
                                  ? tagRedTmpl
                                  : p?.tag?.lowBatteryFormatted
                                  ? tagYellowTmpl
                                  : tagGreenTmpl
                              "></ng-container>
                            <ng-template #tagRedTmpl>
                              <div class="medium-point red-point" title="Non-reporting since: {{
                                  p.tag?.nonReportingFormatted
                                }}" i18n-title></div>
                            </ng-template>
                            <ng-template #tagYellowTmpl>
                              <div class="medium-point yellow-point" title="Low battery since: {{
                                  p.tag?.lowBatteryFormatted
                                }}" i18n-title></div>
                            </ng-template>
                            <ng-template #tagGreenTmpl>
                              <div class="medium-point green-point"></div>
                            </ng-template>
                          </li>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </td>
    </ng-container>

    <!-- actions -->
    <ng-container matColumnDef="actionsColumn" stickyEnd>
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" class="fixIE11verticallyCentered" [ngClass]="{
          'highlight-space': row.id === highlightLocationId
        }">
        <div>
          <ul>
            <ng-template #locationWithoutPatients>
              <div [ngClass]="{ highlight: row.id === highlightLocationId }">
                <li>
                  <button mat-button mat-icon-button disableRipple [matMenuTriggerFor]="locationActionsMenu"
                    [disabled]="row.current_patients_count.aggregate.count > 0">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #locationActionsMenu="matMenu">
                    <ng-container *ngIf="row.location_status_id === 1">
                      <button mat-menu-item (click)="onUpdateLocation(row.id, 2)">
                        <span i18n>Update {{ row.alias_name }} status to Occupied.</span>
                      </button>
                    </ng-container>
                    <ng-container *ngIf="row.location_status_id === 2">
                      <button mat-menu-item (click)="onUpdateLocation(row.id, 1)">
                        <span i18n>Update {{ row.alias_name }} status to Available.</span>
                      </button>
                    </ng-container>
                  </mat-menu>
                </li>
              </div>
            </ng-template>
            <ng-container *ngIf="row?.patients?.length; else locationWithoutPatients">
              <ng-container *ngFor="let p of row?.patients; first as pIsFirst">
                <div [ngClass]="{
                    'fake-patient-row': !pIsFirst,
                    highlight: isPatientHighlighted(row.id, p.id)
                  }">
                  <ng-container *ngIf="p?.patient_workflows?.length; else empty">
                    <ng-container *ngFor="let w of p?.patient_workflows; first as wIsFirst">
                      <ng-container *ngIf="wIsFirst; else empty">
                        <li>
                          <ng-template #advanceDirect>
                            <button mat-button mat-icon-button disableRipple title="Advance" i18n-title (click)="
                                onAdvancePhase(
                                  (p?.patient_workflows)[0]?.id,
                                  (p?.patient_workflows)[0]?.workflow?.id,
                                  (p?.patient_workflows)[0]?.workflow?.name,
                                  p?.id
                                )
                              ">
                              <mat-icon>arrow_forward</mat-icon>
                            </button>
                          </ng-template>
                          <ng-template #advanceMulti>
                            <button mat-button mat-icon-button disableRipple title="Advance" i18n-title
                              [matMenuTriggerFor]="advancePhaseMenu">
                              <mat-icon>arrow_forward</mat-icon>
                            </button>
                            <mat-menu #advancePhaseMenu="matMenu">
                              <ng-container *ngFor="let w of p?.patient_workflows">
                                <button mat-menu-item (click)="
                                    onAdvancePhase(
                                      w?.id,
                                      w?.workflow?.id,
                                      w?.workflow?.name,
                                      p?.id
                                    )
                                  ">
                                  <span>{{ w?.workflow?.name }}</span>
                                </button>
                              </ng-container>
                            </mat-menu>
                          </ng-template>
                          <ng-container *ngIf="p?.patient_workflows?.length">
                            <ng-template *ngIf="
                                p?.patient_workflows?.length > 1;
                                then advanceMulti;
                                else advanceDirect
                              "></ng-template>
                          </ng-container>

                          <ng-template #chatButtonTmpl>
                            <mat-icon [matBadgeHidden]="
                                !p.unseen_messages_aggregate?.aggregate?.count
                              " [matBadge]="p.unseen_messages_aggregate?.aggregate?.count" matBadgeColor="warn">textsms
                            </mat-icon>
                          </ng-template>
                          <button mat-button mat-icon-button disableRipple title="Chat" i18n-title
                            *ngIf="p.securableData?.phone_number && !p.recipients?.length"
                            [routerLink]="['chat', p.id]">
                            <ng-container *ngTemplateOutlet="chatButtonTmpl"></ng-container>
                          </button>
                          <button mat-button mat-icon-button disableRipple title="Chat" i18n-title
                            *ngIf="!p.securableData?.phone_number && p.recipients?.length"
                            [routerLink]="['recipients-chat', p.id]">
                            <ng-container *ngTemplateOutlet="chatButtonTmpl"></ng-container>
                          </button>
                          <button mat-button mat-icon-button disableRipple title="Chat" i18n-title
                            *ngIf="p.securableData?.phone_number && p.recipients?.length"
                            [matMenuTriggerFor]="chatMenu">
                            <ng-container *ngTemplateOutlet="chatButtonTmpl"></ng-container>
                          </button>
                          <mat-menu #chatMenu="matMenu">
                            <button mat-menu-item [routerLink]="['chat', p.id]">
                              <span i18n>Contact patient</span>
                            </button>
                            <button mat-menu-item [routerLink]="['recipients-chat', p.id]" i18n>
                              Contact recipients
                            </button>
                          </mat-menu>

                          <button mat-button mat-icon-button disableRipple title="Edit" i18n-title [routerLink]="p.id">
                            <mat-icon>edit</mat-icon>
                          </button>
                          <button mat-button mat-icon-button disableRipple [matMenuTriggerFor]="menu">
                            <mat-icon>more_vert</mat-icon>
                          </button>
                          <mat-menu #menu="matMenu">
                            <ng-template #changePhaseDirect>
                              <button mat-menu-item (click)="
                                  onChangePhase(
                                    (p?.patient_workflows)[0]?.id,
                                    (p?.patient_workflows)[0]?.workflow?.id
                                  )
                                ">
                                <span i18n>Change Phase...</span>
                              </button>
                            </ng-template>
                            <ng-template #changePhaseMulti>
                              <button mat-menu-item [matMenuTriggerFor]="changePhaseMenu"
                                *ngIf="p?.patient_workflows?.length">
                                <span i18n>Change Phase...</span>
                              </button>
                              <mat-menu #changePhaseMenu="matMenu">
                                <ng-container *ngFor="let w of p?.patient_workflows">
                                  <button mat-menu-item (click)="onChangePhase(w?.id, w?.workflow?.id)">
                                    <span>
                                      <span i18n>...from </span>
                                      <b>{{ w?.workflow?.name }}</b>
                                    </span>
                                  </button>
                                </ng-container>
                              </mat-menu>
                            </ng-template>
                            <ng-container *ngIf="p?.patient_workflows?.length">
                              <ng-template *ngIf="
                                  p?.patient_workflows?.length > 1;
                                  then changePhaseMulti;
                                  else changePhaseDirect
                                "></ng-template>
                            </ng-container>

                            <button mat-menu-item [routerLink]="[p.id, 'manage-workflows']">
                              <span i18n>Manage Workflows</span>
                            </button>
                            <button mat-menu-item [routerLink]="[p.id, 'appointments']">
                              <span i18n>Manage Appointments</span>
                            </button>
                            <button mat-menu-item (click)="onOpenVisitDetail(p.id)">
                              <span i18n>Patient’s Visit Detail</span>
                            </button>
                            <button mat-menu-item (click)="onChangeDepartment(p.id)">
                              <span i18n>Change Department</span>
                            </button>
                            <button mat-menu-item [routerLink]="[p.id, 'recipients']">
                              <span i18n>Manage Recipients</span>
                            </button>
                            <button mat-menu-item (click)="onEndVisit(p.id)">
                              <span i18n>End Visit</span>
                            </button>
                            <button mat-menu-item (click)="onDeletePatient(p.id)">
                              <span i18n>Delete Patient</span>
                            </button>
                          </mat-menu>
                        </li>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnSet"></tr>
    <tr mat-row *matRowDef="let row; columns: columnSet"></tr>
  </table>
</div>

<mat-paginator [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons
  [ngStyle]="{ visibility: dataSource.data?.length ? 'visible' : 'hidden' }"></mat-paginator>

<ng-container *ngIf="!dataSource.data?.length">
  <div class="empty">
    <span class="mat-body-1" i18n>There are no records here yet.</span>
  </div>
</ng-container>
