import { createAction, props } from '@ngrx/store';
import { State } from './unread-messages-menu.reducer';

export const loadData = createAction(
  '[Unread Messages Menu] Load Data',
  props<Pick<State, 'list' | 'unread'>>()
);
export const setFilter = createAction(
  '[Unread Messages Menu] Set Filter',
  props<{ str: string }>()
);
export const reset = createAction('[Unread Messages Menu] Reset');
