import { Injectable } from '@angular/core';
import { CurrentUserService } from '@frontend/common/src/lib/util';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class AppContentWrapperService {
  isSidenavOpen$ = new BehaviorSubject(true);
  _preference$ = this.currentUserSrv
    .getPreference('wrt_sidenav_open')
    .pipe(filter((x) => typeof x === 'boolean'));

  constructor(private currentUserSrv: CurrentUserService) {
    this._preference$.subscribe(this.isSidenavOpen$);
  }

  toggleState() {
    const newState = !this.isSidenavOpen$.getValue();
    this.currentUserSrv.save('wrt_sidenav_open', newState);
  }
}
