import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EMPTY, Observable, ReplaySubject } from 'rxjs';
import { catchError } from 'rxjs/operators';

export interface CustomerDataQuery {
  customer: Customer[];
}

export interface Customer {
  id: number;
  full_name: string;
  alias_name: string;
  subdomain: string;
  default_timezone: string;
  crm_account_id: string;
  crm_site_id: string;
  license_info: {
    is_workflow_rt_enabled: boolean;
  };
}

@Injectable()
export class CurrentCustomerService {
  customerRaw$: ReplaySubject<Customer> = new ReplaySubject(1);

  constructor(private http: HttpClient, private snackBar: MatSnackBar) {
    this._getCustomer().subscribe(this.customerRaw$);

    this.customerRaw$.subscribe({
      error: (err) => {
        const message = $localize`There was an unexpected error.`;
        this.snackBar.open(message, $localize`Close`, { duration: 10000 });
        throw err;
      },
    });
  }

  get customer$() {
    return this.customerRaw$.pipe(catchError(() => EMPTY));
  }

  _getCustomer(): Observable<Customer> {
    return this.http.get<Customer>('customers/current');
  }
}
