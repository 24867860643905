import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  selectAll,
  selectEntities,
  State,
  selectIds,
} from './lib/patient/patient.reducer';

export * from './lib/patient-api/patient-api.actions';
export * from './lib/patient-api/patient-api.service';
export * from './lib/patient-ws/patient-ws.actions';
export * from './lib/patient/patient.actions';
export * from './lib/patient/patient.model';
export * from './lib/shared-patient-data-access.module';

export const getState = createFeatureSelector<State>('patient');

export const getPatients = createSelector(getState, selectAll);

export const getPatientEntities = createSelector(getState, selectEntities);

export const getPatientsIds = createSelector(getState, selectIds);

export const getPatientById = createSelector(
  getPatientEntities,
  (entities, id) => entities[id]
);
