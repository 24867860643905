import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AUTH_FLOW_LOGOUT_ROUTES,
  AUTH_FLOW_SDK_ROUTES,
  PhAuthGuard,
  ScopesGuard,
} from '@frontend/common/ph-auth';
import { CustomerResolver, DepartmentsAvailableResolver } from '@frontend/entity';
import { AccountDisableGuard } from '@frontend/ui/account-disable';
import {
  RecipientDialogComponent,
  RecipientsListComponent,
} from '@frontend/ui/patient-recipients/src';
import { AppContentWrapperComponent } from './app-content-wrapper/app-content-wrapper.component';
import { RoutedDepartmentSelectorComponent } from './app-content-wrapper/department-selector/routed.component';
import { AppCurrentDepartmentResolver } from './app-current-department.resolver';
import { AppGuard } from './app.guard';
import { DepartmentSelectedGuard } from './department-selected.guard';
import { GraphQlGuard } from './graphql.guard';

const routes: Routes = [
  ...AUTH_FLOW_LOGOUT_ROUTES,
  ...AUTH_FLOW_SDK_ROUTES,
  {
    path: '',
    data: {
      requiredScopes: ['access:wrt'],
    },
    canActivate: [AccountDisableGuard, PhAuthGuard, ScopesGuard, GraphQlGuard, AppGuard],
    resolve: {
      customerLoaded: CustomerResolver,
      departmentsLoaded: DepartmentsAvailableResolver,
    },
    children: [
      {
        path: 'department-selector',
        canActivate: [DepartmentSelectedGuard],
        component: RoutedDepartmentSelectorComponent,
      },
      {
        path: ':department_id',
        resolve: { currentDepartment: AppCurrentDepartmentResolver },
        component: AppContentWrapperComponent,
        children: [
          {
            path: 'patients',
            loadChildren: () =>
              import('@frontend/workflow-rt/feature/patients').then(
                (m) => m.WorkflowRtFeaturePatientsModule
              ),
          },
          {
            path: 'staff',
            loadChildren: () =>
              import('@frontend/workflow-rt/feature/staff').then(
                (m) => m.WorkflowRtFeatureStaffModule
              ),
          },
          {
            path: 'today-stats',
            loadChildren: () =>
              import('@frontend/workflow-rt/feature/today-stats').then(
                (m) => m.WorkflowRtFeatureTodayStatsModule
              ),
          },
          {
            path: 'rooms',
            loadChildren: () =>
              import('@frontend/workflow-rt/feature/rooms').then(
                (m) => m.WorkflowRtFeatureRoomsModule
              ),
          },
          {
            path: 'contact-tracing',
            loadChildren: () =>
              import('@frontend/workflow-rt/feature/contact-tracing').then(
                (m) => m.WorkflowRtFeatureContactTracingModule
              ),
          },
          {
            path: 'reports',
            loadChildren: () =>
              import('@frontend/workflow-rt/feature/reports').then(
                (m) => m.WorkflowRtFeatureReportsModule
              ),
          },
          {
            path: 'patients/:patient_id/recipients',
            component: RecipientsListComponent,
            children: [
              {
                path: 'new',
                component: RecipientDialogComponent,
                data: {
                  isEditOperation: false,
                },
              },
              {
                path: ':recipient_id',
                component: RecipientDialogComponent,
                data: {
                  isEditOperation: true,
                },
              },
            ],
          },
          { path: '**', redirectTo: 'patients', pathMatch: 'full' },
        ],
      },
      { path: '**', redirectTo: 'department-selector', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  providers: [AppCurrentDepartmentResolver, DepartmentSelectedGuard],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
