import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getParams } from '@frontend/common/ph-router-store';
import { QuickMessageTypeId } from '@frontend/common/util';
import {
  AddPatient,
  LoadPatientApi,
  LoadPatientSuccessApi,
  PatientApiActionTypes,
} from '@frontend/shared/patient/data-access/src';
import {
  DeleteQuickMessage,
  GlobalCreateQuickMessageSuccessWs,
  GlobalRemoveQuickMessageSuccessWs,
  GlobalUpdateQuickMessageSuccessWs,
  LoadQuickMessages,
  LoadQuickMessagesApi,
  LoadQuickMessagesSuccessApi,
  QuickMessageApiActionTypes,
  QuickMessageWsActionTypes,
  UpsertQuickMessage,
} from '@frontend/shared/quick-message/data-access';
import {
  CreateTextMessageApi,
  getTextMessages,
  GlobalCreateTextMessageSuccessWs,
  GlobalUpdateTextMessageSuccessWs,
  LoadTextMessages,
  LoadTextMessagesApi,
  LoadTextMessagesSuccessApi,
  LocalCreateTextMessageSuccessWs,
  TextMessage,
  TextMessageApiActionTypes,
  TextMessageWsActionTypes,
  UpdateTextMessage,
  UpdateTextMessageApi,
  UpsertTextMessage,
} from '@frontend/shared/text-message/data-access/src';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { forkJoin } from 'rxjs';
import {
  filter,
  first,
  map,
  mapTo,
  mergeMap,
  switchMapTo,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import * as uuid from 'uuid/v4';
import {
  ChatSmartActionTypes,
  LoadChatSmartFailure,
  LoadChatSmartSuccess,
  SendTextMessage,
  SendTextMessageFailure,
  SendTextMessageSuccess,
} from './chat-smart.actions';

@Injectable()
export class ChatSmartEffects {
  loadChatSmart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatSmartActionTypes.LoadChatSmart),
      withLatestFrom(this.store.select(getParams)),
      map(([_, params]) => params),
      filter((params) => params && !!params.patient_id && !!params.department_id),
      mergeMap(({ patient_id, department_id }) => [
        new LoadPatientApi({ id: patient_id }),
        new LoadTextMessagesApi({
          params: { patient_department_visit_id: patient_id, is_to_patient: true },
        }),
        new LoadQuickMessagesApi({
          params: {
            department_id,
            quick_message_template_type_id: QuickMessageTypeId.Patient,
          },
        }),
      ])
    )
  );

  loadChatSmartSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatSmartActionTypes.LoadChatSmart),
      switchMapTo(
        forkJoin([
          this.actions$.pipe(
            ofType(PatientApiActionTypes.LoadPatientSuccessApi),
            first()
          ),
          this.actions$.pipe(
            ofType(TextMessageApiActionTypes.LoadTextMessagesSuccessApi),
            first()
          ),
          this.actions$.pipe(
            ofType(QuickMessageApiActionTypes.LoadQuickMessagesSuccessApi),
            first()
          ),
        ])
      ),
      map(([{ payload }]: [LoadPatientSuccessApi, any, LoadQuickMessagesSuccessApi]) => {
        const patientName = [payload?.patient?.last_name, payload?.patient?.first_name]
          .filter((str) => !!str)
          .join(', ');
        return new LoadChatSmartSuccess({
          patientName,
          patient_department_visit_id: payload.patient.id,
        });
      })
    )
  );

  loadChatSmartFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        TextMessageApiActionTypes.LoadTextMessagesFailureApi,
        PatientApiActionTypes.LoadPatientFailureApi
      ),
      mapTo(new LoadChatSmartFailure())
    )
  );

  loadPatient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PatientApiActionTypes.LoadPatientsSuccessApi),
      map(({ payload }: LoadPatientSuccessApi) => new AddPatient(payload))
    )
  );

  loadTextMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TextMessageApiActionTypes.LoadTextMessagesSuccessApi),
      map(({ payload }: LoadTextMessagesSuccessApi) => new LoadTextMessages(payload))
    )
  );

  loadQuickMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuickMessageApiActionTypes.LoadQuickMessagesSuccessApi),
      map(({ payload }: LoadQuickMessagesSuccessApi) => new LoadQuickMessages(payload))
    )
  );

  onChatSmartFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChatSmartActionTypes.LoadChatSmartFailure),
        tap(() => {
          this.dialog.closeAll();
          this.snackBar.open(
            $localize`There was an error loading patient's chat.`,
            $localize`Close`,
            {
              duration: 10000,
            }
          );
        })
      ),
    { dispatch: false }
  );

  sendTextMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatSmartActionTypes.SendTextMessage),
      withLatestFrom(this.store.select(getParams)),
      map(
        ([{ payload }, params]: [SendTextMessage, any]) =>
          new CreateTextMessageApi({
            textMessage: {
              id: 'correlation|' + uuid(),
              patient_department_visit_id: params.patient_id.toString(),
              text: payload.message,
              is_to_patient: true,
            } as any,
          })
      )
    )
  );

  onSendTextMessageSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TextMessageWsActionTypes.LocalCreateTextMessageSuccessWs),
      map(
        ({ payload }: LocalCreateTextMessageSuccessWs) =>
          new SendTextMessageSuccess(payload)
      )
    )
  );

  onSendTextMessageFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TextMessageWsActionTypes.LocalCreateTextMessageFailureWs),
      tap(() => {
        this.snackBar.open(
          $localize`There was an error sending the message.`,
          $localize`Close`,
          {
            duration: 10000,
          }
        );
      }),
      mapTo(new SendTextMessageFailure())
    )
  );

  upsertTextMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ChatSmartActionTypes.SendTextMessageSuccess,
        TextMessageWsActionTypes.GlobalCreateTextMessageSuccessWs,
        TextMessageWsActionTypes.GlobalUpdateTextMessageSuccessWs
      ),
      map(
        (
          action:
            | SendTextMessageSuccess
            | GlobalCreateTextMessageSuccessWs
            | GlobalUpdateTextMessageSuccessWs
        ) => new UpsertTextMessage({ textMessage: action.payload })
      )
    )
  );

  upsertQuickMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        QuickMessageWsActionTypes.GlobalCreateQuickMessageSuccessWs,
        QuickMessageWsActionTypes.GlobalUpdateQuickMessageSuccessWs
      ),
      filter(
        (action: GlobalCreateQuickMessageSuccessWs | GlobalUpdateQuickMessageSuccessWs) =>
          action.payload.quick_message_template_type_id === QuickMessageTypeId.Recipients
      ),
      map(
        (action: GlobalCreateQuickMessageSuccessWs | GlobalUpdateQuickMessageSuccessWs) =>
          new UpsertQuickMessage({ quickMessage: action.payload })
      )
    )
  );

  deleteQuickMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuickMessageWsActionTypes.GlobalRemoveQuickMessageSuccessWs),
      map(
        ({ payload: id }: GlobalRemoveQuickMessageSuccessWs) =>
          new DeleteQuickMessage({ id })
      )
    )
  );

  markAllSeen$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatSmartActionTypes.MarkAllSeen),
      withLatestFrom(this.store.select(getTextMessages)),
      map(([_, items]: [any, TextMessage[]]) =>
        items.filter((item) => !item.is_seen).map((item) => item.id)
      ),
      mergeMap((ids: string[]) =>
        ids
          .map((id) => [
            new UpdateTextMessage({
              textMessage: { id, changes: { is_seen: true } },
            }),
            new UpdateTextMessageApi({
              textMessage: { id, is_seen: true } as TextMessage,
            }),
          ])
          .reduce((acc, arr) => [...acc, ...arr], [])
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}
}
