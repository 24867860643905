<div [formGroup]="form">
  <div class="time-picker-field">
    <mat-form-field appearance="outline">
      <mat-label i18n>Time</mat-label>
      <input matInput formControlName="_at" [matAutocomplete]="auto" maxlength="5" />
      <mat-hint i18n>Time format hh:mm</mat-hint>
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let ts of ts$ | async" [value]="ts">{{ ts }}</mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
</div>
