import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { timer } from 'rxjs';
import { mapTo, switchMap } from 'rxjs/operators';
import { ChatWrapperService } from './chat-wrapper.service';
import { ConversationWithName } from './conversation.model';

@UntilDestroy()
@Component({
  selector: 'frontend-chat-wrapper',
  templateUrl: './chat-wrapper.component.html',
  styles: [
    `
      :host {
        display: flex;
        height: 100%;
        overflow: hidden;
      }
    `,
  ],
  providers: [ChatWrapperService],
})
export class ChatWrapperComponent implements OnDestroy, OnInit {
  list: ConversationWithName[];

  constructor(private srv: ChatWrapperService) {}

  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.srv.onChange$
      .pipe(
        switchMap((data) => timer(0, 60 * 1000).pipe(mapTo(data))),
        untilDestroyed(this)
      )
      .subscribe((data) => (this.list = [...data]));
  }
}
