import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'frontend-no-conversation',
  template: ` <frontend-no-chat-selected></frontend-no-chat-selected> `,
  styles: [
    `
      :host {
        display: flex;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoConversationComponent {}
