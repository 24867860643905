import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AbstractState } from '@frontend/shared/base-store';
import { Customer } from './customer.model';
import { customersActions } from './store/customers.actions';
import { customersSelectors } from './store/customers.selectors';

@Injectable()
export class CustomerResolver implements Resolve<boolean> {
  constructor(private store: Store<AbstractState<Customer>>) {}

  resolve(route: ActivatedRouteSnapshot): Promise<boolean> | Observable<boolean> {
    this.store
      .pipe(
        select(customersSelectors.getCurrentCustomerLoaded),
        filter((loaded) => !loaded)
      )
      .subscribe(() => this.store.dispatch(customersActions.loadOne('')));
    return of(true);
  }
}
