import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { AppointmentsModule } from './appointments/appointments.module';
import { MilestonesModule } from './milestones/milestones.module';
import { PatientModule } from './patient/patient.module';
import { RecipientsModule } from './recipients/recipients.module';
import { StatusModule } from './status/status.module';
import { VisitDetailComponent } from './visit-detail.component';
import { WorkflowsModule } from './workflows/workflows.module';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
    MatMenuModule,
    ReactiveFormsModule,
    PatientModule,
    StatusModule,
    AppointmentsModule,
    RecipientsModule,
    WorkflowsModule,
    MilestonesModule,
  ],
  declarations: [VisitDetailComponent],
  exports: [VisitDetailComponent],
})
export class WorkflowRtFeatureVisitDetailModule {}
