import { formatDurationFromUtc } from '@frontend/common/component-tools';
import {
  LocationTypeId,
  StaffTypeAliasTranslation,
  StaffTypeColorTranslation,
  StaffTypeTranslation,
} from '@frontend/common/util';
import { Attention, CardData } from './status.component';
import { StaffContact, Visit } from './status.graphql';

export function dataManipulation(visit: Visit): CardData {
  const visitId = visit.id;
  const departmentName = visit.department.name;
  const siteName = visit.department.site.name;
  const departmentDuration = formatDurationFromUtc(visit.created_utc);
  const locationName =
    visit.tag?.current_location?.alias_name || visit.tag?.current_location?.name;
  const locationDuration =
    visit.tag?.sensed_utc && formatDurationFromUtc(visit.tag?.sensed_utc);
  const isInCareLocation =
    visit.tag?.current_location?.location_type_id === LocationTypeId.Care;
  const cardData: CardData = {
    visitId,
    departmentName,
    siteName,
    departmentDuration,
    locationName,
    locationDuration,
    isInCareLocation,
  };
  if (!isInCareLocation) {
    return cardData;
  }
  const locationAttentions = visit.patient_attended_milestones[0];
  const unattendedDuration = formatDurationFromUtc(locationAttentions?.created_utc);
  const attentions = _contactsManipulation(locationAttentions?.patient_staff_contacts);
  return {
    ...cardData,
    unattendedDuration,
    attentions,
  };
}

export function _contactsManipulation(contacts: StaffContact[]): Attention[] {
  return contacts.map((c) => _contactManipulation(c));
}

export function _contactManipulation(contact: StaffContact): Attention {
  const id = contact.id;
  const indicatorColor = StaffTypeColorTranslation[contact.staff.staff_type_id];
  const attentionDuration = formatDurationFromUtc(contact.created_utc);
  const status =
    StaffTypeAliasTranslation[contact.staff.staff_type_id] ||
    StaffTypeTranslation[contact.staff.staff_type_id];
  const staffFullName = _getFullName(contact.staff);
  return {
    id,
    indicatorColor,
    attentionDuration,
    status,
    staffFullName,
  };
}

export function _getFullName(item: { first_name: string; last_name: string }): string {
  return [item?.last_name, item?.first_name].filter((s) => !!s).join(', ');
}
