import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CurrentDepartmentService } from '@frontend/common/util';
import { Apollo } from 'apollo-angular';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  Observable,
  of,
  ReplaySubject,
} from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { dataManipulation, paramsManipulation } from './data-manipulation';
import { CmptState, SearchScope } from './patient-search';
import {
  PatientSearchDataQuery,
  PatientSearchQueryParams,
} from './patient-search.graphql';

@Injectable()
export class PatientSearchService {
  _search$ = new ReplaySubject<string>(1);
  _date$ = new ReplaySubject<string>(1);
  _scope$ = new BehaviorSubject<SearchScope>(SearchScope.Site);
  _departmentId$ = this.currentDepartmentSrv.department$.pipe(map((dep) => dep.id));
  _siteId$ = this.currentDepartmentSrv.department$.pipe(map((dep) => dep.site.id));
  _params$ = combineLatest([
    this._search$,
    this._date$,
    this._departmentId$,
    this._siteId$,
    this._scope$,
  ]).pipe(map(paramsManipulation));
  _cmptStateRaw$: ReplaySubject<CmptState> = new ReplaySubject(1);
  cmptState$ = this._cmptStateRaw$.pipe(catchError(() => EMPTY));

  get scope$() {
    return this._scope$;
  }

  constructor(
    private apollo: Apollo,
    private snackBar: MatSnackBar,
    private currentDepartmentSrv: CurrentDepartmentService
  ) {
    this._params$
      .pipe(
        switchMap((params) => {
          if (params.search.length >= 3) {
            return this._getSearchResults(params).pipe(startWith({ loading: true }));
          }
          return of({ loading: false });
        }),
        startWith({ loading: false })
      )
      .subscribe(this._cmptStateRaw$);

    this._cmptStateRaw$.subscribe({
      error: (err) => {
        const message = $localize`There was an unexpected error.`;
        this.snackBar.open(message, $localize`Close`, { duration: 10000 });
        throw err;
      },
    });
  }

  setDate(val: string) {
    this._date$.next(val);
  }

  setSearch(val: string) {
    this._search$.next(val);
  }

  setScope(val: SearchScope) {
    this._scope$.next(val);
  }

  _getSearchResults(variables: PatientSearchQueryParams): Observable<CmptState> {
    return this.apollo
      .query<PatientSearchDataQuery>({
        query: PatientSearchDataQuery,
        variables,
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((result) => {
          if (!result.data) {
            throw new Error(
              'Error getting patient search results (result.data was falsy)'
            );
          }
          return result.data;
        }),
        map(dataManipulation)
      );
  }
}
