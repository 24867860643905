import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Patient } from '@frontend/entity/src';

export class Securable<T> {
  id: string;
  customer: number;
  body: T;
}

@Injectable()
export class PatientsService {
  constructor(private http: HttpClient) {}

  public get(id) {
    return this.http.get<Patient>(`patients/${id}`);
  }

  public getSecure(id) {
    return this.http.get<Securable<Patient>>(`securable/${id}`);
  }
}
