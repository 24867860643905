import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map, mapTo, mergeMap, switchMap } from 'rxjs/operators';
import {
  BulkUploadStaffApi,
  BulkUploadStaffFailureApi,
  BulkUploadStaffSuccessApi,
  CreateStaffApi,
  CreateStaffFailureApi,
  CreateStaffSuccessApi,
  DeleteStaffApi,
  DeleteStaffFailureApi,
  DeleteStaffSuccessApi,
  LoadStaffApi,
  LoadStaffFailureApi,
  LoadStaffsApi,
  LoadStaffsFailureApi,
  LoadStaffsSuccessApi,
  LoadStaffSuccessApi,
  StaffApiActionTypes,
  UpdateStaffApi,
  UpdateStaffFailureApi,
  UpdateStaffSuccessApi,
} from './staff-api.actions';
import { StaffApiService } from './staff-api.service';

@Injectable()
export class StaffApiEffects {
  bulkUploadStaffsApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StaffApiActionTypes.BulkUploadStaffApi),
      concatMap(({ payload }: BulkUploadStaffApi) =>
        this.srv.createBulk(payload).pipe(
          mapTo(new BulkUploadStaffSuccessApi()),
          catchError(() => of(new BulkUploadStaffFailureApi(payload)))
        )
      )
    )
  );

  loadStaffsApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StaffApiActionTypes.LoadStaffsApi),
      switchMap(({ payload: { params } }: LoadStaffsApi) =>
        this.srv.get(params).pipe(
          map((staffs) => new LoadStaffsSuccessApi({ staffs })),
          catchError((err) => of(new LoadStaffsFailureApi(err)))
        )
      )
    )
  );

  loadOneStaffsApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StaffApiActionTypes.LoadStaffApi),
      switchMap(({ payload: { id } }: LoadStaffApi) =>
        this.srv.getOne(id).pipe(
          map((staff) => new LoadStaffSuccessApi({ staff })),
          catchError(({ message }) => of(new LoadStaffFailureApi({ id, message })))
        )
      )
    )
  );

  createStaffApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StaffApiActionTypes.CreateStaffApi),
      mergeMap(({ payload }: CreateStaffApi) =>
        this.srv.create(payload.staff).pipe(
          map((staff) => new CreateStaffSuccessApi({ staff })),
          catchError((error) => of(new CreateStaffFailureApi(error)))
        )
      )
    )
  );

  updateStaffApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StaffApiActionTypes.UpdateStaffApi),
      mergeMap(({ payload }: UpdateStaffApi) =>
        this.srv.update(payload.staff).pipe(
          map((staff) => new UpdateStaffSuccessApi({ staff })),
          catchError((error) => of(new UpdateStaffFailureApi(error)))
        )
      )
    )
  );

  deleteStaffApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StaffApiActionTypes.DeleteStaffApi),
      mergeMap(({ payload }: DeleteStaffApi) =>
        this.srv.delete(payload.id).pipe(
          map(({ id }) => new DeleteStaffSuccessApi({ id })),
          catchError((err) => of(new DeleteStaffFailureApi(err)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private srv: StaffApiService) {}
}
