<ng-template #skeletonTmpl>
  <skeleton-form></skeleton-form>
</ng-template>

<ng-container *ngIf="srv.cardData$ | async as cardData; else skeletonTmpl">
  <div class="module-content">
    <div class="header-line">
      <h4 i18n class="module-title">Patient</h4>

      <div>
        <ng-template #ongoingTmpl>
          <div class="chip ongoing">Active</div>
        </ng-template>
        <ng-template #completedTmpl>
          <div class="chip completed">Completed</div>
        </ng-template>
        <ng-container *ngIf="visitCompleted; then completedTmpl else ongoingTmpl"></ng-container>

        <button mat-button mat-icon-button disableRipple [matMenuTriggerFor]="menu" *ngIf="!visitCompleted">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item
            [mat-dialog-close]="[cardData.patient.departmentId, 'patients', cardData.patient.visitId]">
            <span i18n>Manage Patient</span>
          </button>
          <button mat-menu-item (click)="srv.onChangeDepartment(cardData.patient.visitId)">
            <span i18n>Change Department</span>
          </button>
          <button mat-menu-item
            [mat-dialog-close]="[cardData.patient.departmentId, 'patients', 'chat', cardData.patient.visitId]"
            [disabled]="!cardData.patient.phone_number">
            <span i18n>Send Msg.</span>
          </button>
          <button mat-menu-item (click)="srv.onEndVisit(cardData.patient.visitId)">
            <span i18n>End Visit</span>
          </button>
          <button mat-menu-item (click)="srv.onDeletePatient(cardData.patient.visitId)">
            <span i18n>Delete</span>
          </button>
        </mat-menu>
      </div>
    </div>

    <div class="row primary-text">
      <span class="spaced" *ngIf="cardData.patient.nameVersions">{{ cardData.patient.nameVersions }}</span>

      <ng-container [ngSwitch]="cardData.patient.tagState">
        <ng-container *ngSwitchCase="'red'">
          <div class="indicator red-indicator" i18n-title
            title="Non-reporting since: {{ cardData.patient.tagStateSince }}">
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'yellow'">
          <div class="indicator yellow-indicator" i18n-title
            title="Low battery since: {{ cardData.patient.tagStateSince }}">
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'green'">
          <div class="indicator green-indicator"></div>
        </ng-container>
      </ng-container>

      <span class="spaced" *ngIf="cardData.patient.tagVersions">{{ cardData.patient.tagVersions }}</span>
      <span class="spaced" *ngIf="cardData.patient.mrn">{{ cardData.patient.mrn }}</span>
      <span class="spaced" *ngIf="cardData.patient.phone_number">{{ cardData.patient.phone_number }}</span>
    </div>

    <div class="row" *ngIf="cardData.patient.note1">
      <p class="note secondary-text">{{ cardData.patient.note1 }}</p>
    </div>

    <div class="row" *ngIf="cardData.patient.note2">
      <p class="note secondary-text">{{ cardData.patient.note2 }}</p>
    </div>
  </div>
</ng-container>