import { DynamicConditionIds, DynamicConditionNull } from '@frontend/common/util';

interface GraphQlCareQueryParams {
  locationIds: string | null;
  locationStatusIds: number[] | null;
  workflowIds: number[] | null;
  phaseIds: number[] | null;
  locationGroupIds: string | null;
  // TODO correct types after making sure that the solution is legit
  appointmentTypeFilter: any;
  providerFilter: any;
}

interface GraphQlWaitingQueryParams {
  locationIds: string | null;
  providerIds: string | null;
  appointmentTypeIds: string | null;
  workflowIds: string | null;
  phaseIds: string | null;
  workflowIdsArray: number[] | null;
  locationGroupIds: string | null;
}

interface CareFiltersNetworkModel {
  providers: (number | 'null')[];
  locations: number[];
  location_status: number[];
  workflows: number[];
  phases: number[];
  appointment_types: (number | 'null')[];
  location_groups: number[];
}

interface WaitingFiltersNetworkModel {
  providers: (number | 'null')[];
  locations: (number | 'null')[];
  workflows: (number | 'null')[];
  phases: number[];
  appointment_types: (number | 'null')[];
  location_groups: number[];
}

function getDynamicConditions(key: string, filters: unknown[]) {
  const all = { [key]: {} };
  const none = { [key]: { _is_null: true } as DynamicConditionNull };
  const byId = {
    [key]: {
      _in: atLeastOneArr(<number[]>filters?.filter((id) => id !== 'null')),
    } as DynamicConditionIds,
  };
  // NO FILTER
  if (!filters?.length) {
    return all;
  }
  // ONLY NONE
  if (filters?.length === 1 && filters[0] === 'null') {
    return none;
  }
  // NONE AND ITEMS
  if (filters?.some((item) => item === 'null')) {
    return { _or: [none, byId] };
  }
  // ONLY ITEMS
  return byId;
}

function getDynamicConditionsObjectRelation(
  parentRelation: string,
  key: string,
  filters: unknown[]
) {
  const all = {
    _or: [
      {
        [parentRelation]: {},
      },
      { _not: { [parentRelation]: {} } },
    ],
  };
  const none = { _not: { [parentRelation]: {} } };
  const byId = {
    [parentRelation]: {
      [key]: {
        _in: atLeastOneArr(<number[]>filters?.filter((id) => id !== 'null')),
      } as DynamicConditionIds,
    },
  };
  // NO FILTER
  if (!filters?.length) {
    return all;
  }
  // ONLY NONE
  if (filters?.length === 1 && filters[0] === 'null') {
    return none;
  }
  // NONE AND ITEMS
  if (filters?.some((item) => item === 'null')) {
    return { _or: [none, byId] };
  }
  // ONLY ITEMS
  return byId;
}

export function careFiltersManipulation(
  filters: CareFiltersNetworkModel
): GraphQlCareQueryParams {
  return {
    locationIds: atLeastOneStr(filters?.locations),
    locationStatusIds: atLeastOneArr(filters?.location_status),
    workflowIds: atLeastOneArr(filters?.workflows),
    phaseIds: atLeastOneArr(filters?.phases),
    locationGroupIds: atLeastOneStr(filters?.location_groups),
    appointmentTypeFilter: getDynamicConditionsObjectRelation(
      'patient_appointment',
      'appointment_type_id',
      filters?.appointment_types
    ),
    providerFilter: getDynamicConditionsObjectRelation(
      'patient_appointment',
      'provider_id',
      filters?.providers
    ),
  };
}

export function waitingFiltersManipulation(
  filters: WaitingFiltersNetworkModel
): GraphQlWaitingQueryParams {
  return {
    locationIds: atLeastOneStr(filters?.locations),
    providerIds: atLeastOneStr(filters?.providers),
    appointmentTypeIds: atLeastOneStr(filters?.appointment_types),
    phaseIds: atLeastOneStr(filters?.phases),
    workflowIds: atLeastOneStr(filters?.workflows),
    workflowIdsArray: atLeastOneArr(
      <number[]>filters?.workflows?.filter((id) => id !== 'null')
    ),
    locationGroupIds: atLeastOneStr(filters?.location_groups),
  };
}

function atLeastOneArr(ids?: number[]): number[] | null {
  if (ids?.length > 0) {
    return ids;
  }
  return null;
}

function atLeastOneStr(ids?: (number | string)[]): string | null {
  if (ids?.length > 0) {
    return `{ ${ids.join(', ')} }`;
  }
  return null;
}
