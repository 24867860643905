import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Go } from '@frontend/common/ph-router-store';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, switchMap, tap } from 'rxjs/operators';
import { tagsActions } from './store/tags.actions';
import { tagsSelectors } from './store/tags.selectors';
import { Tag } from './tag.model';

@Injectable()
export class TagResolver implements Resolve<Tag> {
  constructor(private store: Store<any>) {}

  resolve(): Observable<Tag> {
    return this.store.pipe(
      select(tagsSelectors.getCollectionLoaded),
      tap((loaded) => loaded || this.store.dispatch(tagsActions.load())),
      filter((loaded) => loaded),
      switchMap(() => this.store.pipe(select(tagsSelectors.getOneByStateParam))),
      tap((tag) => tag || this.store.dispatch(new Go({ path: ['/tags'] }))),
      filter((exist) => !!exist),
      first()
    );
  }
}
