import { Component, Input } from '@angular/core';
import { formatDurationFromUtc } from '@frontend/common/component-tools';
import { ConversationWithName } from '../chat-wrapper/conversation.model';

@Component({
  selector: 'frontend-conversation-list',
  templateUrl: './conversation-list.component.html',
  styleUrls: ['./conversation-list.component.scss'],
})
export class ConversationListComponent {
  _searchStr = '';
  _list: ConversationWithName[];
  _original = [];

  @Input() set list(data: ConversationWithName[]) {
    if (data) {
      this._original = data;
      this._list = this.filterFunction(this._original, this._searchStr);
    }
  }

  onSearchUpdate(str) {
    this._searchStr = str;
    this._list = this.filterFunction(this._original, this._searchStr);
  }

  filterFunction(collection = [], str = '') {
    return collection
      .filter((item) => item.name.toLowerCase().indexOf(str.toLocaleLowerCase()) > -1)
      .map((item) => ({ ...item, duration: formatDurationFromUtc(item.duration) }));
  }
}
