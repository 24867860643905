import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SearchBoxModule } from '@frontend/common/search-box';
import { MaterialModule } from './material.module';
import { MenuComponent } from './menu.component';
import { UnreadMessagesMenuComponent } from './unread-messages-menu.component';

@NgModule({
  imports: [CommonModule, RouterModule, MaterialModule, SearchBoxModule],
  declarations: [UnreadMessagesMenuComponent, MenuComponent],
  exports: [UnreadMessagesMenuComponent],
})
export class UnreadMessagesMenuModule {}
