import gql from 'graphql-tag';

export interface CallToStationDialogDataQuery {
  station: Station[];
}

export interface Station {
  id: number;
  name: string;
}

export const STATIONS_QUERY = gql(`
query stations(
  $departmentId: Int!
) {
  station(
    where: {
      department_id: { _eq: $departmentId }
    }
    order_by: {name: asc_nulls_last}
  ) {
    id
    name
  }
}
`);
