import { Injectable } from '@angular/core';
import { BaseAction, BaseActions } from '@frontend/shared/base-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Department } from '../department.model';
import { DepartmentsService } from '../departments.service';
import { departmentsActions } from './departments.actions';

@Injectable()
export class DepartmentsEffects {
  constructor(private actions$: Actions, private service: DepartmentsService) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(departmentsActions.types.LOAD),
      mergeMap((action: any) =>
        this.service.get(action.payload || {}).pipe(
          map((entities) => departmentsActions.loadSuccess(entities)),
          catchError((error) => of(departmentsActions.loadFail(error)))
        )
      )
    )
  );

  loadOne$ = createEffect(() =>
    this.actions$.pipe(
      ofType(departmentsActions.types.LOAD_ONE),
      mergeMap((action: BaseAction<Department>) =>
        this.service.getOne(action.payload).pipe(
          map((entity) => departmentsActions.loadOneSuccess(entity)),
          catchError((error) => of(departmentsActions.loadOneFail(error)))
        )
      )
    )
  );

  add$ = createEffect(() =>
    this.actions$.pipe(
      ofType(departmentsActions.types.ADD),
      mergeMap((action: BaseAction<Department>) =>
        this.service.create(action.payload).pipe(
          map(() => BaseActions.void()),
          catchError((error) => of(departmentsActions.addFail(error)))
        )
      )
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(departmentsActions.types.DELETE),
      mergeMap((action: BaseAction<Department>) =>
        this.service.delete(action.payload).pipe(
          map(() => BaseActions.void()),
          catchError((error) => of(departmentsActions.deleteFail(error)))
        )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(departmentsActions.types.UPDATE),
      mergeMap((action: BaseAction<Department>) =>
        this.service.edit(action.payload).pipe(
          map(() => BaseActions.void()),
          catchError((error) => of(departmentsActions.updateFail(error)))
        )
      )
    )
  );

  loadAvailable = createEffect(() =>
    this.actions$.pipe(
      ofType(departmentsActions.types.LOAD_AVAILABLE),
      switchMap((action: BaseAction<Department>) =>
        this.service.getAvailable().pipe(
          map((entities) => departmentsActions.loadAvailableSuccess(entities)),
          catchError((error) => of(departmentsActions.loadAvailableFail(error)))
        )
      )
    )
  );

  loadDepartmentLocations = createEffect(() =>
    this.actions$.pipe(
      ofType(departmentsActions.types.LOAD_LOCATIONS),
      switchMap((action: BaseAction<Department>) =>
        this.service.getLocations(action.payload).pipe(
          map((entities) =>
            departmentsActions.loadLocationsSuccess({
              id: action.payload.id,
              locations: entities,
            })
          ),
          catchError((error) => of(departmentsActions.loadLocationsFail(error)))
        )
      )
    )
  );

  addDepartmentLocations = createEffect(() =>
    this.actions$.pipe(
      ofType(departmentsActions.types.ADD_LOCATION),
      mergeMap((action: BaseAction<Department>) =>
        this.service
          .addLocation({
            department_id: action.payload.department.id,
            location_id: action.payload.location.id,
            correlation_id: action.payload.correlation_id,
          })
          .pipe(
            map(() => BaseActions.void()),
            catchError((error) => of(departmentsActions.addLocationFail(error)))
          )
      )
    )
  );

  removeDepartmentLocations = createEffect(() =>
    this.actions$.pipe(
      ofType(departmentsActions.types.REMOVE_LOCATION),
      mergeMap((action: BaseAction<Department>) =>
        this.service
          .removeLocation({
            department_id: action.payload.department.id,
            location_id: action.payload.location.id,
            correlation_id: action.payload.correlation_id,
          })
          .pipe(
            map(() => BaseActions.void()),
            catchError((error) => of(departmentsActions.removeLocationFail(error)))
          )
      )
    )
  );
}
