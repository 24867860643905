import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SocketService } from '@frontend/common/util';
import { merge, Observable, of, pipe, race, throwError } from 'rxjs';
import {
  catchError,
  filter,
  first,
  ignoreElements,
  map,
  mergeMap,
  switchMap,
} from 'rxjs/operators';
import * as uuid from 'uuid/v4';
import { TextMessage } from '../text-message/text-message.model';

@Injectable({
  providedIn: 'root',
})
export class TextMessageApiService {
  constructor(private http: HttpClient, private socketSrv: SocketService) {}

  private withSecurableData = pipe(
    switchMap((textMessages: TextMessage[]) => {
      const securableIds = textMessages.map((p) => p.body_securable_id);
      return this.getSecureMultiple(securableIds).pipe(
        map((secData: { id: string; body: any }[]): any[] =>
          textMessages.map((tm) => ({
            ...tm,
            text: secData.find((s) => s.id === tm.body_securable_id)?.body?.text,
          }))
        )
      );
    })
  );

  public create(entity: TextMessage) {
    const { text } = entity;
    const correlation_id = entity.id;
    return this.http.post('securable', { text }).pipe(
      mergeMap(({ id: body_securable_id }: { id: string }) =>
        merge(
          this.http
            .post('text-messages', { ...entity, body_securable_id, correlation_id })
            .pipe(
              ignoreElements(),
              catchError((err) =>
                of(err?.error?.message).pipe(
                  mergeMap((message) => throwError({ id: entity.id, message }))
                )
              )
            ),
          race(
            this.socketSrv
              .fromLocalSuccess<any>('text-messages.create')
              .pipe(filter((ws) => ws.correlation_id === correlation_id)),
            this.socketSrv.fromLocalError('text-messages.create').pipe(
              filter((ws) => ws.correlation_id === correlation_id),
              mergeMap(({ message }) => throwError({ id: entity.id, message }))
            )
          ).pipe(first())
        )
      )
    );
  }

  public delete(id) {
    const correlation_id = 'correlation|' + uuid();
    return merge(
      this.http
        .request('delete', `text-messages/${id}`, { body: { correlation_id } })
        .pipe(
          ignoreElements(),
          catchError((err) =>
            of(err?.error?.message).pipe(
              mergeMap((message) => throwError({ id, message }))
            )
          )
        ),
      race(
        this.socketSrv
          .fromLocalSuccess<any>('text-messages.remove')
          .pipe(filter((ws) => ws.correlation_id === correlation_id)),
        this.socketSrv.fromLocalError('text-messages.remove').pipe(
          filter((ws) => ws.correlation_id === correlation_id),
          mergeMap(({ message }) => throwError({ id, message }))
        )
      ).pipe(first())
    );
  }

  public get(params?: { [key: string]: string | string[] }): Observable<TextMessage[]> {
    return this.http
      .get<TextMessage[]>('text-messages', { params })
      .pipe(this.withSecurableData);
  }

  public getOne(id) {
    return this.http.get<TextMessage>(`text-messages/${id}`);
  }

  public getSecureMultiple(id: string[]) {
    return this.http.post('securable/get-bulk', { id });
  }

  public update(entity) {
    const correlation_id = 'correlation|' + uuid();
    return merge(
      this.http.put(`text-messages/${entity.id}`, { ...entity, correlation_id }).pipe(
        ignoreElements(),
        catchError((err) =>
          of(err?.error?.message).pipe(
            mergeMap((message) => throwError({ id: entity.id, message }))
          )
        )
      ),
      race(
        this.socketSrv
          .fromLocalSuccess<any>('text-messages.update')
          .pipe(filter((ws) => ws.correlation_id === correlation_id)),
        this.socketSrv.fromLocalError('text-messages.update').pipe(
          filter((ws) => ws.correlation_id === correlation_id),
          mergeMap(({ message }) => throwError({ id: entity.id, message }))
        )
      ).pipe(first())
    );
  }
}
