import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { duration, DurationInputArg2 } from 'moment-timezone';
import { formatDuration } from './format-diff';

@Pipe({ name: 'formatTime' })
@Injectable()
export class FormatTimePipe implements PipeTransform {
  transform(
    amount: number,
    unit: DurationInputArg2 = 'minutes',
    minimum?: string
  ): string {
    if (amount === 0 || amount > 0) {
      return formatDuration(duration(amount, unit), minimum);
    }
    return '-';
  }
}
