import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { downloadCsv } from '@frontend/common/component-tools';
import { utc } from 'moment-timezone';
import * as Papa from 'papaparse';
import { BulkUploadInstructionsService } from './bulk-upload-instructions.service';

@Component({
  selector: 'frontend-app-bulk-upload-instructions',
  templateUrl: 'bulk-upload-instructions.component.html',
  styleUrls: ['bulk-upload-instructions.component.scss'],
  providers: [BulkUploadInstructionsService],
})
export class BulkUploadInstructionComponent {
  constructor(
    public srv: BulkUploadInstructionsService,
    private dialogRef: MatDialogRef<BulkUploadInstructionComponent>
  ) {}
  @ViewChild('csvFileInput') fileInputRef: ElementRef;

  downloadTemplate() {
    const appointment_date = utc().local().format('MM/DD/YYYY');
    const template = [
      {
        mrn: '1234567',
        first_name: 'John',
        last_name: 'Doe',
        phone_number: '1234567890',
        appointment_type_id: 1,
        appointment_date,
        appointment_time: '12:02 PM',
        provider_id: 1,
      },
      {
        mrn: '3784732',
        first_name: 'Mary',
        last_name: 'Doe',
        phone_number: '5558984434',
        appointment_type_id: 1,
        appointment_date,
        appointment_time: '2:20 PM',
        provider_id: 2,
      },
    ];
    downloadCsv(Papa.unparse(template), 'patients-template.csv');
  }

  onFileUpload(files) {
    const csv = files?.[0];
    this.fileInputRef.nativeElement.value = null;
    if (csv) {
      this.dialogRef.close(csv);
    }
  }
}
