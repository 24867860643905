import gql from 'graphql-tag';

export interface PatientSearchDataQuery {
  patients: PatientDataQuery[];
}

export interface PatientDataQuery {
  id: number;
  completed_utc: string;
  deleted_utc: string;
  department: {
    name: string;
    site: {
      name: string;
    };
  };
  tag?: {
    label: string;
    tag_value: string;
  };
  securable: {
    mrn: string;
    first_name: string;
    last_name: string;
    phone_number: string;
  };
}

export interface PatientSearchQueryParams {
  siteId?: number;
  departmentId?: number;
  search: string;
  minDate: string;
  maxDate: string;
}

export const PatientSearchDataQuery = gql(`
query PatientSearchDataQuery(
  $siteId: Int
  $departmentId: Int
  $search: String!
  $minDate: DateTime!
  $maxDate: DateTime!
) {
  patients(
    securable_search: { search: $search, minDate: $minDate, maxDate: $maxDate }
    site_id: $siteId
    department_id: $departmentId
    limit: 20
  ) {
    id
    completed_utc
    deleted_utc
    department {
      name
      site {
        name
      }
    }
    tag {
      tag_value
      label
    }
    securable {
      mrn
      first_name
      last_name
      phone_number
    }
  }
}
`);
