import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { SharedUiSkeletonFormModule } from '@frontend/common/skeletons';
import { ChangePhaseComponent } from './change-phase.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatListModule,
    MatRadioModule,
    SharedUiSkeletonFormModule,
  ],
  declarations: [ChangePhaseComponent],
  exports: [ChangePhaseComponent],
})
export class ChangePhaseModule {}
