import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Go } from '@frontend/common/ph-router-store';
import { Department, departmentsSelectors } from '@frontend/entity';
import { AbstractState } from '@frontend/shared/base-store';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';

@Injectable()
export class AppCurrentDepartmentResolver implements Resolve<Department> {
  constructor(private store: Store<AbstractState<Department>>) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Department> {
    return this.store.pipe(
      select(departmentsSelectors.getOneByStateParam),
      map((current) => {
        if (current) {
          return current;
        }
        this.store.dispatch(new Go({ path: ['department-selector'] }));
      }),
      filter((d) => !!d),
      first()
    );
  }
}
