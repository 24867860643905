import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SearchBoxModule } from '@frontend/common/search-box';
import { DepartmentSelectorComponent } from './department-selector.component';
import { RoutedDepartmentSelectorComponent } from './routed.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatIconModule,
    MatListModule,
    MatTableModule,
    MatToolbarModule,
    SearchBoxModule,
  ],
  declarations: [RoutedDepartmentSelectorComponent, DepartmentSelectorComponent],
  exports: [RoutedDepartmentSelectorComponent, DepartmentSelectorComponent],
})
export class DepartmentSelectorModule {}
