import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { getRouterState } from '@frontend/common/ph-router-store';
import { AbstractState } from '@frontend/shared/base-store';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { first } from 'rxjs/operators';
import { Department } from './department.model';
import { departmentsActions } from './store/departments.actions';

@Injectable()
export class DepartmentLocationsResolver implements Resolve<boolean> {
  constructor(private store: Store<AbstractState<Department>>) {}

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    this.store
      .pipe(select(getRouterState), first())
      .subscribe((router) =>
        this.store.dispatch(
          departmentsActions.loadLocations({ id: router.state.params.department_id })
        )
      );
    return of(true);
  }
}
