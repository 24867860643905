import { Action } from '@ngrx/store';
import { Patient } from '../patient/patient.model';

export enum PatientWsActionTypes {
  LocalCreatePatientSuccessWs = '[Patient WS] Local Create Patients Success',
  LocalCreatePatientFailureWs = '[Patient WS] Local Create Patients Failure',
  LocalUpdatePatientSuccessWs = '[Patient WS] Local Update Patients Success',
  LocalUpdatePatientFailureWs = '[Patient WS] Local Update Patients Failure',
  LocalRemovePatientSuccessWs = '[Patient WS] Local Remove Patients Success',
  LocalRemovePatientFailureWs = '[Patient WS] Local Remove Patients Failure',
  LocalCompletePatientSuccessWs = '[Patient WS] Local Complete Patients Success',
  LocalCompletePatientFailureWs = '[Patient WS] Local Complete Patients Failure',
  LocalNotificationSendSuccessWs = '[Patient WS] Local Notification Send Success',
  GlobalCreatePatientSuccessWs = '[Patient WS] Global Create Patients Success',
  GlobalUpdatePatientSuccessWs = '[Patient WS] Global Update Patients Success',
  GlobalRemovePatientSuccessWs = '[Patient WS] Global Remove Patients Success',
  GlobalCompletePatientSuccessWs = '[Patient WS] Global Complete Patients Success',
  GlobalMovePatientSuccessWs = '[Patient WS] Global Move Patients Success',
  GlobalNotificationSendSuccessWs = '[Patient WS] Global Notification Send Success',
}

export class LocalNotificationSendSuccessWs implements Action {
  readonly type = PatientWsActionTypes.LocalNotificationSendSuccessWs;
  constructor(
    public payload: { patient_department_visit_id: string; created_utc: Date }
  ) {}
}

export class GlobalNotificationSendSuccessWs implements Action {
  readonly type = PatientWsActionTypes.GlobalNotificationSendSuccessWs;
  constructor(
    public payload: { patient_department_visit_id: string; created_utc: Date }
  ) {}
}

export class LocalCreatePatientSuccessWs implements Action {
  readonly type = PatientWsActionTypes.LocalCreatePatientSuccessWs;

  constructor(public payload: { id: string; correlation_id: string }) {}
}

export class LocalCreatePatientFailureWs implements Action {
  readonly type = PatientWsActionTypes.LocalCreatePatientFailureWs;

  constructor(public payload: { correlation_id: string; message: string }) {}
}

export class LocalUpdatePatientSuccessWs implements Action {
  readonly type = PatientWsActionTypes.LocalUpdatePatientSuccessWs;

  constructor(public payload: { id: string; correlation_id: string }) {}
}

export class LocalUpdatePatientFailureWs implements Action {
  readonly type = PatientWsActionTypes.LocalUpdatePatientFailureWs;

  constructor(public payload: { correlation_id: string; message: string }) {}
}

export class LocalRemovePatientSuccessWs implements Action {
  readonly type = PatientWsActionTypes.LocalRemovePatientSuccessWs;

  constructor(public payload: { correlation_id: string }) {}
}

export class LocalRemovePatientFailureWs implements Action {
  readonly type = PatientWsActionTypes.LocalRemovePatientFailureWs;

  constructor(public payload: { correlation_id: string; message: string }) {}
}

export class LocalCompletePatientSuccessWs implements Action {
  readonly type = PatientWsActionTypes.LocalCompletePatientSuccessWs;

  constructor(public payload: { correlation_id: string }) {}
}

export class LocalCompletePatientFailureWs implements Action {
  readonly type = PatientWsActionTypes.LocalCompletePatientFailureWs;

  constructor(public payload: { correlation_id: string; message: string }) {}
}

export class GlobalCreatePatientSuccessWs implements Action {
  readonly type = PatientWsActionTypes.GlobalCreatePatientSuccessWs;
  constructor(public payload: Patient) {}
}

export class GlobalUpdatePatientSuccessWs implements Action {
  readonly type = PatientWsActionTypes.GlobalUpdatePatientSuccessWs;
  constructor(public payload: Patient) {}
}

export class GlobalRemovePatientSuccessWs implements Action {
  readonly type = PatientWsActionTypes.GlobalRemovePatientSuccessWs;
  constructor(public payload: Patient['id']) {}
}

export class GlobalCompletePatientSuccessWs implements Action {
  readonly type = PatientWsActionTypes.GlobalCompletePatientSuccessWs;
  constructor(public payload: Patient['id']) {}
}

export class GlobalMovePatientSuccessWs implements Action {
  readonly type = PatientWsActionTypes.GlobalMovePatientSuccessWs;
  constructor(public payload: Patient) {}
}

export type PatientWsActions =
  | LocalCreatePatientSuccessWs
  | LocalCreatePatientFailureWs
  | LocalUpdatePatientSuccessWs
  | LocalUpdatePatientFailureWs
  | LocalRemovePatientSuccessWs
  | LocalRemovePatientFailureWs
  | LocalCompletePatientSuccessWs
  | LocalCompletePatientFailureWs
  | LocalNotificationSendSuccessWs
  | GlobalCreatePatientSuccessWs
  | GlobalUpdatePatientSuccessWs
  | GlobalRemovePatientSuccessWs
  | GlobalCompletePatientSuccessWs
  | GlobalMovePatientSuccessWs
  | GlobalNotificationSendSuccessWs;
