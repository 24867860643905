import { Injectable } from '@angular/core';
import {
  CurrentDepartmentService,
  CurrentUserService,
  User,
} from '@frontend/common/util';
import { map, shareReplay } from 'rxjs/operators';

@Injectable()
export class ToolbarService {
  department$ = this.currentDepartmentSrv.department$;
  user$ = this.currentUserSrv.user$.pipe(map(userManipulation), shareReplay(1));

  constructor(
    private currentUserSrv: CurrentUserService,
    private currentDepartmentSrv: CurrentDepartmentService
  ) {}
}

export function userManipulation(user: User) {
  const name = [user.first_name, user.last_name].filter((item) => !!item).join(' ');
  const email = user.email;
  return { name, email };
}
