import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SocketService } from '@frontend/common/util';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { EMPTY, forkJoin, merge, of, race, Subject, throwError } from 'rxjs';
import {
  catchError,
  filter,
  first,
  ignoreElements,
  map,
  mapTo,
  mergeMap,
} from 'rxjs/operators';
import * as uuid from 'uuid/v4';

export interface PhasesNetworkModel {
  workflow: {
    workflow_phases: { id: number; name: string }[];
  }[];
}

export interface PatientPhaseNetworkModel {
  patient_department_visit: {
    patient_department_visit_workflows: { current_phase_id: number }[];
  }[];
}

export const PHASES_DATA_QUERY = gql(`
query phasesData($workflowId: Int!) {
    workflow(where: {id: {_eq: $workflowId}}) {
      workflow_phases(where: {deleted_utc: {_is_null: true}}, order_by: {sort_order: asc}) {
        id
        name
      }
    }
  }
`);

export const PATIENT_PHASE_DATA_QUERY = gql(`
query Patient($patientWorkflowId: bigint!, $workflowId: Int!) {
  patient_department_visit(where: {patient_department_visit_workflows: {id: {_eq: $patientWorkflowId}}}) {
    patient_department_visit_workflows(where: {workflow_id: {_eq: $workflowId}, completed_utc: {_is_null: true}}) {
      current_phase_id
    }
  }
}
`);

@Injectable()
export class ChangePhaseService {
  private _data$ = new Subject<{ currentPhaseId: number; phases: any[] }>();

  get loading$() {
    return this._data$.pipe(map((p) => !p));
  }

  get currentPhaseId$() {
    return this._data$.pipe(
      filter((data) => !!data?.currentPhaseId),
      map(({ currentPhaseId }) => currentPhaseId)
    );
  }

  get phases$() {
    return this._data$.pipe(
      filter((data) => !!data?.phases),
      map(({ phases }) => phases)
    );
  }

  constructor(
    private apollo: Apollo,
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private socketSrv: SocketService
  ) {}

  advance(data: { patientWorkflowId; workflowId }) {
    return this.getNextPhase(data).pipe(
      mergeMap((nextPhase) =>
        this.changePhase(data.patientWorkflowId, nextPhase.id).pipe(mapTo(nextPhase))
      )
    );
  }

  getData(patientWorkflowId, workflowId) {
    forkJoin({
      phases: this._getPhases(workflowId),
      currentPhaseId: this._getPatientWorkflowPhaseId(patientWorkflowId, workflowId),
    }).subscribe(this._data$);
  }

  getNextPhase({ patientWorkflowId, workflowId }) {
    return forkJoin({
      phases: this._getPhases(workflowId),
      currentPhaseId: this._getPatientWorkflowPhaseId(patientWorkflowId, workflowId),
    }).pipe(
      map(({ currentPhaseId, phases }) => {
        try {
          const currentPhaseIndex = phases.findIndex(
            (item) => item.id === currentPhaseId
          );
          const nextPhaseIndex = currentPhaseIndex + 1;
          const nextPhaseIsLastPhase = nextPhaseIndex === phases.length - 1;
          const nextPhase = phases[nextPhaseIndex];
          return { ...nextPhase, isLastPhase: nextPhaseIsLastPhase };
        } catch (error) {
          this.snackBar.open($localize`There was an unexpected error.`, $localize`Close`);
          return null;
        }
      }),
      filter((data) => !!data)
    );
  }

  private _getPhases(workflowId) {
    return this.apollo
      .subscribe<PhasesNetworkModel>({
        query: PHASES_DATA_QUERY,
        variables: { workflowId },
      })
      .pipe(
        map(({ data }) => data?.workflow?.[0]?.workflow_phases),
        catchError(() => {
          this.snackBar.open(
            $localize`There was an unexpected error.`,
            $localize`Close`,
            {
              duration: 10000,
            }
          );
          return EMPTY;
        })
      );
  }

  private _getPatientWorkflowPhaseId(patientWorkflowId, workflowId) {
    return this.apollo
      .subscribe<PatientPhaseNetworkModel>({
        query: PATIENT_PHASE_DATA_QUERY,
        variables: { patientWorkflowId, workflowId },
      })
      .pipe(
        map(
          ({ data }) =>
            data?.patient_department_visit?.[0]?.patient_department_visit_workflows?.[0]
              ?.current_phase_id
        ),
        catchError(() => {
          this.snackBar.open($localize`There was an unexpected error.`, $localize`Close`);
          return EMPTY;
        })
      );
  }

  changePhase(id, current_phase_id) {
    const correlation_id = 'correlation|' + uuid();
    return merge(
      this.http
        .put(
          `patient-workflows/${id}`,
          { correlation_id, current_phase_id, is_force: true },
          { params: { id } }
        )
        .pipe(
          ignoreElements(),
          catchError((err) =>
            of(err?.error?.message).pipe(
              mergeMap((message) => throwError({ id, message }))
            )
          )
        ),
      race(
        this.socketSrv
          .fromLocalSuccess<any>('patient-workflows.update')
          .pipe(filter((ws) => ws.correlation_id === correlation_id)),
        this.socketSrv.fromLocalError('patient-workflows.update').pipe(
          filter((ws) => ws.correlation_id === correlation_id),
          mergeMap(({ message }) => throwError({ id, message }))
        )
      ).pipe(first())
    );
  }
}
