import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { BaseService } from '@frontend/shared/base-store';
import { Location } from '../locations/location.model';
import { Department } from './department.model';

@Injectable()
export class DepartmentsService extends BaseService<Department> {
  constructor(http: HttpClient) {
    super(http, 'departments');
  }

  getAvailable(): Observable<Department[]> {
    return this.http.get<Department[]>(`${this.route}/available`);
  }

  getLocations(department: { id: number }): Observable<Location[]> {
    return this.http.get<Location[]>(`${this.route}/${department.id}/locations`);
  }

  addLocation(departmentLocation: {
    department_id: number;
    location_id: number;
    correlation_id: string;
  }) {
    return this.http.post(
      `${this.route}/${departmentLocation.department_id}/locations/${departmentLocation.location_id}`,
      departmentLocation
    );
  }

  removeLocation(departmentLocation: {
    department_id: number;
    location_id: number;
    correlation_id: string;
  }) {
    return this.http.request(
      'delete',
      `${this.route}/${departmentLocation.department_id}/locations/${departmentLocation.location_id}`,
      {
        body: departmentLocation,
      }
    );
  }
}
