import { Injectable } from '@angular/core';
import { apiFetchLoad, apiFetchLoadSuccess } from '@frontend/common/util';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mapTo, mergeMap, switchMap, tap } from 'rxjs/operators';
import { chirp, init, loadItems } from './tag-chirping.actions';
import { TagChirpingService } from './tag-chirping.service';

@Injectable()
export class TagChirpingEffects {
  loadItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(init),
      switchMap(({ department_id }) =>
        this.srv
          .getStaffsByDep(department_id)
          .pipe(
            mergeMap((data) => [
              loadItems({ data }),
              apiFetchLoadSuccess('TAG_CHIRPING')(),
            ])
          )
      )
    )
  );

  loadApi$ = createEffect(() =>
    this.actions$.pipe(ofType(init), mapTo(apiFetchLoad('TAG_CHIRPING')()))
  );

  chirp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(chirp),
        tap(({ tag_id }) => this.srv.chirp(tag_id))
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private srv: TagChirpingService) {}
}
