import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationDialogService } from '@frontend/common/confirmation-dialog';
import { SocketService } from '@frontend/common/util';
import { merge, of, race, throwError } from 'rxjs';
import { catchError, filter, first, ignoreElements, mergeMap } from 'rxjs/operators';
import * as uuid from 'uuid/v4';
import { CompletePatientTypes } from './complete-patient-type-selector-option.model';

@Injectable()
export class EndVisitService {
  constructor(
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private socketSrv: SocketService,
    private confirmationDialog: ConfirmationDialogService
  ) {}

  openConfirmationDialog(patientId: number) {
    this.confirmationDialog
      .open({
        title: $localize`Confirm operation`,
        content: $localize`This action can not be undone. It will cause the closing of all active workflows and the removal of the patient from the system. Are you sure you want to proceed?`,
        confirm: $localize`PROCEED`,
        cancel: $localize`CANCEL`,
      })
      .subscribe((result) => {
        if (!result) {
          return;
        }
        this._endVisit(patientId).subscribe(
          () =>
            this.snackBar.open($localize`Patient has completed the visit.`, null, {
              duration: 5000,
            }),
          () =>
            this.snackBar.open(
              $localize`There was an unexpected error.`,
              $localize`Close`,
              {
                duration: 10000,
              }
            )
        );
      });
  }

  private _endVisit(id: number) {
    const correlation_id = 'correlation|' + uuid();
    return merge(
      this.http
        .patch(`patients/${id}/complete`, {
          correlation_id,
          completed_type_id: CompletePatientTypes.Manual,
        })
        .pipe(
          ignoreElements(),
          catchError((err) =>
            of(err?.error?.message).pipe(
              mergeMap((message) => throwError({ id, message }))
            )
          )
        ),
      race(
        this.socketSrv
          .fromLocalSuccess<any>('patients.complete')
          .pipe(filter((ws) => ws.correlation_id === correlation_id)),
        this.socketSrv.fromLocalError('patients.complete').pipe(
          filter((ws) => ws.correlation_id === correlation_id),
          mergeMap(({ message }) => throwError({ id, message }))
        )
      ).pipe(first())
    );
  }
}
