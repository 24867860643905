import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { TextMessageActions, TextMessageActionTypes } from './text-message.actions';
import { TextMessage } from './text-message.model';

export interface State extends EntityState<TextMessage> {}

export const adapter: EntityAdapter<TextMessage> = createEntityAdapter<TextMessage>({
  sortComparer: (a: TextMessage, b: TextMessage) =>
    a.created_utc.localeCompare(b.created_utc),
});

export const initialState: State = adapter.getInitialState({});

export function reducer(state = initialState, action: TextMessageActions): State {
  switch (action.type) {
    case TextMessageActionTypes.AddTextMessage: {
      return adapter.addOne(action.payload.textMessage, state);
    }

    case TextMessageActionTypes.UpsertTextMessage: {
      return adapter.upsertOne(action.payload.textMessage, state);
    }

    case TextMessageActionTypes.AddTextMessages: {
      return adapter.addMany(action.payload.textMessages, state);
    }

    case TextMessageActionTypes.UpsertTextMessages: {
      return adapter.upsertMany(action.payload.textMessages, state);
    }

    case TextMessageActionTypes.UpdateTextMessage: {
      return adapter.updateOne(action.payload.textMessage, state);
    }

    case TextMessageActionTypes.UpdateTextMessages: {
      return adapter.updateMany(action.payload.textMessages, state);
    }

    case TextMessageActionTypes.DeleteTextMessage: {
      return adapter.removeOne(action.payload.id, state);
    }

    case TextMessageActionTypes.DeleteTextMessages: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case TextMessageActionTypes.LoadTextMessages: {
      return adapter.setAll(action.payload.textMessages, state);
    }

    case TextMessageActionTypes.ClearTextMessages: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
