import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from '@frontend/shared/base-store';
import { Customer } from './customer.model';

@Injectable()
export class CustomersService extends BaseService<Customer> {
  constructor(http: HttpClient) {
    super(http, 'customers');
  }

  public changeStatus(customer: Customer): Observable<object> {
    return this.http.patch(`customers/${customer.is_blocked ? 'unblock' : 'block'}`, {
      correlation_id: customer.correlation_id,
    });
  }

  public edit(customer: Customer): Observable<object> {
    return this.http.put('customers', customer);
  }

  public get(): Observable<Customer[]> {
    return this.http.get<Customer[]>('customers/available');
  }

  public getOne(): Observable<Customer> {
    return this.http.get<Customer>('customers/current');
  }
}
