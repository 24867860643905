import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { PhConfigLoaderService } from '@frontend/common/ph-config-loader';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AccountDisableGuard implements CanActivate {
  constructor(private config: PhConfigLoaderService) {}

  canActivate(): Observable<boolean> {
    return this.config.getConfig$('CUSTOMER_ID').pipe(map((id) => id !== 'disabled'));
  }
}
