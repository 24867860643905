import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getParams } from '@frontend/common/ph-router-store';
import { ExpectedError, SocketService } from '@frontend/common/util';
import { createSelector, Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { EMPTY, merge, Observable, of, race, throwError } from 'rxjs';
import {
  catchError,
  filter,
  first,
  ignoreElements,
  map,
  mapTo,
  mergeMap,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs/operators';
import * as uuid from 'uuid/v4';
import { patientManipulation } from './data-manipulation';
import { SecurableService } from './securable.service';
import { CmptData } from './update-patient.component';

export interface PatientDialogNetworkModel {
  patient_department_visit: PatientNetworkModel[];
}

export interface TagNetworkModel {
  tag: {
    id: number;
    tag_value: string;
    label: string;
    current_tag_location: {
      non_reporting_since_utc: string;
      low_battery_since_utc: string;
    };
  }[];
}

export interface PatientNetworkModel {
  id: number;
  securable_id: number;
  secured_patient_name: string;
  tag: { id: number; tag_value: string };
  note: string;
  additional_note: string;
  patient_department_visit_workflows: VisitNetworkModel[];
  assigned_location_id: number;
}

export interface VisitNetworkModel {
  id: number;
  workflow_id: number;
  patient_department_visit_id: number;
}

export const PATIENT_DATA_QUERY = gql(`
query Patient($patientId: bigint!) {
  patient_department_visit(where: {id: {_eq: $patientId}}) {
    id
    securable_id
    secured_patient_name
    tag {
      id
      tag_value
    }
    note
    additional_note
    assigned_location_id
    patient_department_visit_workflows(where: {completed_utc: {_is_null: true}}) {
      id
      workflow_id
      patient_department_visit_id
    }
  }
}
`);

export const TAG_DATA_QUERY = gql(`
query TagByTagValue($tagValue: String!) {
  tag(where: {tag_value: {_eq: $tagValue}}) {
    id
    tag_value
    label
    current_tag_location {
     non_reporting_since_utc
     low_battery_since_utc
    }
  }
}
`);

interface QueryVariables {
  department_id: number;
  patient_id: number;
}

const dialogInput = createSelector(getParams, ({ department_id, patient_id }) => ({
  department_id,
  patient_id,
}));

@Injectable()
export class UpdatePatientService {
  _params$: Observable<QueryVariables>;
  loading$: Observable<boolean>;
  cmptDataRaw$: Observable<CmptData>;
  cmptData$: Observable<CmptData>;

  constructor(
    private apollo: Apollo,
    private store: Store,
    private snackBar: MatSnackBar,
    private securableSrv: SecurableService,
    private http: HttpClient,
    private socketSrv: SocketService
  ) {
    this._params$ = this.store.select(dialogInput).pipe(first());
    this.cmptDataRaw$ = this._params$.pipe(
      switchMap((params) => this._getPatientById(params.patient_id)),
      shareReplay(1)
    );
    this.cmptData$ = this.cmptDataRaw$.pipe(catchError(() => EMPTY));
    this.loading$ = this.cmptData$.pipe(mapTo(false), startWith(true));
  }

  private _getPatientById(patientId: number) {
    return this.apollo
      .subscribe<PatientDialogNetworkModel>({
        query: PATIENT_DATA_QUERY,
        variables: { patientId },
      })
      .pipe(
        map((result) => {
          if (!result.data.patient_department_visit.length) {
            throw new ExpectedError($localize`Patient does not exist.`);
          }
          return result.data.patient_department_visit[0];
        }),
        switchMap((patient) => this.securableSrv.get(patient)),
        map((patient) => patientManipulation(patient))
      );
  }

  save(id, formData, force) {
    return this.createSecureData({ ...formData, id }).pipe(
      mergeMap((p) => this.getTagData(p, force)),
      mergeMap((p) => this.updatePatient(p))
    );
  }

  createSecureData(data) {
    const secure = {
      first_name: data.first_name,
      last_name: data.last_name,
      mrn: data.mrn,
      phone_number: data.phone_number,
    };
    return this.http
      .post('securable', secure)
      .pipe(
        map((securable: { id: string }) => ({ ...data, securable_id: securable.id }))
      );
  }

  updatePatient(patientWithPHI) {
    const correlation_id = 'correlation|' + uuid();
    const { id, last_name, mrn, phone_number, ...patientWithoutPHI } = patientWithPHI;
    const payload = { ...patientWithoutPHI, correlation_id };
    return merge(
      this.http.put(`patients/${id}`, payload).pipe(
        ignoreElements(),
        catchError((err) =>
          of(err?.error?.message).pipe(mergeMap((message) => throwError({ id, message })))
        )
      ),
      race(
        this.socketSrv
          .fromLocalSuccess<any>('patients.update')
          .pipe(filter((ws) => ws.correlation_id === correlation_id)),
        this.socketSrv.fromLocalError('patients.update').pipe(
          filter((ws) => ws.correlation_id === correlation_id),
          mergeMap(({ message }) => throwError({ id, message }))
        )
      ).pipe(first())
    );
  }

  getTagData(patient, force) {
    if (!patient.tag_id) {
      return of({ ...patient, tag_id: null });
    }
    return this.apollo
      .subscribe<TagNetworkModel>({
        query: TAG_DATA_QUERY,
        variables: { tagValue: patient.tag_id },
      })
      .pipe(
        map(({ data }) => {
          const tag = data.tag?.[0];
          if (!tag) {
            throw { expectedError: $localize`Badge doesn't exist` };
          }
          if (tag?.current_tag_location?.non_reporting_since_utc && !force) {
            throw {
              expectedError: 'tag_is_non_reporting',
            };
          }
          if (tag?.current_tag_location?.low_battery_since_utc && !force) {
            throw {
              expectedError: 'tag_is_low_battery',
            };
          }
          return {
            ...patient,
            tag_id: tag?.id,
            label: tag?.label,
            tag_value: tag?.tag_value,
          };
        })
      );
  }
}
