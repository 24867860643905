import { Injectable, OnDestroy, Renderer2 } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject, Subject, timer } from 'rxjs';
import { mapTo, mergeMap, startWith, switchMapTo } from 'rxjs/operators';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class IdleService implements OnDestroy {
  private readonly subscriptions: VoidFunction[] = [];
  private readonly timeout$: ReplaySubject<number> = new ReplaySubject(1);
  private readonly interruption$: Subject<any> = new Subject();
  readonly idle: Observable<boolean> = this.interruption$.pipe(
    startWith(null),
    switchMapTo(
      this.timeout$.pipe(
        mergeMap((t) => timer(t).pipe(untilDestroyed(this), mapTo(true)))
      )
    )
  );

  ngOnDestroy() {
    this.subscriptions.forEach((unsubscribe) => unsubscribe());
  }

  set timeout(t: number) {
    this.timeout$.next(t);
  }

  setupListeners(renderer: Renderer2) {
    const events = ['click', 'keydown', 'mousemove', 'touchstart'];
    events.forEach((eventName) => {
      const sub = renderer.listen(document, eventName, (e) => this.interruption$.next(e));
      this.subscriptions.push(sub);
    });
  }
}
