import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { getParams } from '@frontend/common/ph-router-store';
import { AbstractState } from '@frontend/shared/base-store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { selectOneByStateParam } from '../reducers';
import { Add, Update } from '../reducers/recipient/recipient-api.actions';
import {
  DeleteRecipient,
  UpdateRecipient,
} from '../reducers/recipient/recipient.actions';
import { Recipient } from '../reducers/recipient/recipient.model';

@UntilDestroy()
@Component({
  selector: 'frontend-app-recipient-dialog',
  templateUrl: 'recipient-dialog.component.html',
})
export class RecipientDialogComponent implements OnDestroy, OnInit {
  @ViewChild('recipientFormWrapper', { static: true }) recipientFormWrapper;
  recipient: Recipient;
  private dialogRef: MatDialogRef<RecipientDialogComponent>;
  isEditOperation: boolean;
  private defaultModel: Partial<Recipient>;
  another = false;

  constructor(
    private dialog: MatDialog,
    private store: Store<AbstractState<Recipient>>,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnDestroy() {}

  ngOnInit() {
    this.store.pipe(select(getParams), untilDestroyed(this)).subscribe((p) => {
      this.defaultModel = { patient_department_visit_id: '' + p.patient_id };
    });
    this.store
      .pipe(select(selectOneByStateParam), first(), untilDestroyed(this))
      .subscribe((rec) => {
        this.isEditOperation = rec && !rec.id.toString().startsWith('correlation');
        this.recipient = rec;
        // Workaround to prevent ExpressionChangedAfterItHasBeenCheckedError
        // https://github.com/angular/angular/issues/15634
        setTimeout(() => this.openDialog());
      });
  }

  discard() {
    if (this.recipient.old) {
      // Discard changes after unsuccessful EDITION
      const recipient = {
        id: this.recipient.id,
        changes: { ...this.recipient.old, pending: false, error: null },
      };
      this.store.dispatch(new UpdateRecipient({ recipient }));
    } else {
      // Discard changes after unsuccessful CREATION
      this.store.dispatch(new DeleteRecipient(this.recipient));
    }
    this.dialogRef.close();
  }

  onSaved(formData: Partial<Recipient>) {
    const phone_number = formData.phone_number.replace(/[^0-9]/g, '');
    const recipient: Recipient = {
      ...this.defaultModel,
      ...this.recipient,
      ...formData,
      phone_number,
      pending: true,
      error: undefined,
    };
    if (this.isEditOperation) {
      this.store.dispatch(new Update({ recipient }));
    } else {
      this.recipient && this.store.dispatch(new DeleteRecipient(this.recipient));
      this.store.dispatch(new Add({ recipient }));
    }
    this.dialogRef.close();
  }

  onClose() {
    if (this.another) {
      this.another = false;
      this.openDialog();
    }
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  private openDialog() {
    this.dialogRef = this.dialog.open(this.recipientFormWrapper, {
      width: '500px',
    });
    this.dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(() => this.onClose());
  }
}
