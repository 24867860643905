import { Injectable } from '@angular/core';
import { BulkUploaderService } from '@frontend/common/bulk-uploader';
import { SocketService } from '@frontend/common/util';
import { BaseAction, BaseActions } from '@frontend/shared/base-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { merge, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { Location } from '../location.model';
import { LocationsService } from '../locations.service';
import { locationsActions } from './locations.actions';

@Injectable()
export class LocationsEffects {
  private entity: new () => Location;

  constructor(
    private actions$: Actions,
    private service: LocationsService,
    private bulkSrv: BulkUploaderService,
    private socketSrv: SocketService
  ) {
    this.entity = Location;
  }

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(locationsActions.types.LOAD),
      mergeMap((action: any) =>
        this.service.get(action.payload || {}).pipe(
          map((entities) => locationsActions.loadSuccess(entities)),
          catchError((error) => of(locationsActions.loadFail(error)))
        )
      )
    )
  );

  loadOne$ = createEffect(() =>
    this.actions$.pipe(
      ofType(locationsActions.types.LOAD_ONE),
      mergeMap((action: BaseAction<Location>) =>
        this.service.getOne(action.payload).pipe(
          map((entity) => locationsActions.loadOneSuccess(entity)),
          catchError((error) => of(locationsActions.loadOneFail(error)))
        )
      )
    )
  );

  add$ = createEffect(() =>
    this.actions$.pipe(
      ofType(locationsActions.types.ADD),
      mergeMap((action: BaseAction<Location>) =>
        this.service.create(action.payload).pipe(
          map(() => BaseActions.void()),
          catchError((error) => of(locationsActions.addFail(error)))
        )
      )
    )
  );

  bulkUpload$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(locationsActions.types.BULK_UPLOAD),
        tap((action: BaseAction<Location>) => {
          const entity = Reflect.getMetadata(
            'feature',
            this.entity.prototype.constructor
          );
          this.bulkSrv.upload$.next({
            source$: merge(
              this.socketSrv.fromEvent(`${entity}.create.success`),
              this.socketSrv.fromEvent(`${entity}.create.error`),
              this.socketSrv.fromEvent(`${entity}.update.success`),
              this.socketSrv.fromEvent(`${entity}.update.error`)
            ),
            operation: { ...action.payload, entity },
          });
        }),
        mergeMap((action: BaseAction<Location>) =>
          this.service
            .createBulk(action.payload)
            .pipe(
              catchError(() =>
                of(this.bulkSrv.markAsFailed(action.payload.correlation_id))
              )
            )
        )
      ),
    { dispatch: false }
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(locationsActions.types.DELETE),
      mergeMap((action: BaseAction<Location>) =>
        this.service.delete(action.payload).pipe(
          map(() => BaseActions.void()),
          catchError((error) => of(locationsActions.deleteFail(error)))
        )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(locationsActions.types.UPDATE),
      mergeMap((action: BaseAction<Location>) =>
        this.service.edit(action.payload).pipe(
          map(() => BaseActions.void()),
          catchError((error) => of(locationsActions.updateFail(error)))
        )
      )
    )
  );
}
