import { ChatSmartActions, ChatSmartActionTypes } from './chat-smart.actions';

export const chatFeatureKey = 'chat';

export interface State {
  loading: boolean;
  loaded: boolean;
  sending: boolean;
  patientName: string;
  patient_department_visit_id: string;
}

export const initialState: State = {
  loading: false,
  loaded: false,
  sending: false,
  patientName: '',
  patient_department_visit_id: null,
};

export function reducer(state = initialState, action: ChatSmartActions): State {
  switch (action.type) {
    case ChatSmartActionTypes.LoadChatSmart: {
      return { ...state, loading: true };
    }
    case ChatSmartActionTypes.LoadChatSmartSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        patientName: action.payload.patientName,
        patient_department_visit_id: action.payload.patient_department_visit_id,
      };
    }
    case ChatSmartActionTypes.LoadChatSmart: {
      return { ...state, loading: false, loaded: false };
    }
    case ChatSmartActionTypes.SendTextMessage: {
      return { ...state, sending: true };
    }
    case ChatSmartActionTypes.SendTextMessageSuccess:
    case ChatSmartActionTypes.SendTextMessageFailure: {
      return { ...state, sending: false };
    }
    default:
      return state;
  }
}
