import { ReadonlyReducerFactory } from '@frontend/shared/base-store';
import { TimeZone } from '../time-zone.model';

const reducerFunction = new ReadonlyReducerFactory(TimeZone).getReducer();

// TODO: have to do this because angular forRoot supports only exported functions but not lambda
// https://github.com/angular/angular/issues/14707
export function timeZonesReducer(state, action) {
  return reducerFunction(state, action);
}
