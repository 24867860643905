import { MilestoneTypes } from '@frontend/common/util';
import { utc } from 'moment-timezone';
import {
  CallPatient,
  CardData,
  CardDataMilestone,
  CompletePatient,
  CreatePatient,
  DeletePatient,
  EndWorkflow,
  EnterLocation,
  StartPhase,
  StartWorkflow,
  UpdateTag,
} from './card-data';
import {
  CallPatientFragment,
  CompletePatientFragment,
  CreatePatientFragment,
  EndWorkflowFragment,
  EnterLocationFragment,
  StartPhaseFragment,
  StartWorkflowFragment,
  Tag,
  UpdateTagFragment,
} from './fragments';
import { DeletePatientFragment } from './fragments/delete-patient-fragment.graphql';
import { Milestone, MilestonesDataQuery } from './milestones.graphql';

export function dataManipulation(data: MilestonesDataQuery): CardData {
  const milestones = data.patient.milestones.reduce(
    (acc: CardDataMilestone[], m: Milestone) => {
      const tz = data.patient.department.site.default_timezone;
      switch (m.__typename) {
        case MilestoneTypes.CreatePatientMilestone:
          return [
            ...acc,
            _createPatientMilestoneManipulation(m as CreatePatientFragment, tz),
          ];
        case MilestoneTypes.StartWorkflowMilestone:
          return [
            ...acc,
            _startWorkflowMilestoneManipulation(m as StartWorkflowFragment, tz),
          ];
        case MilestoneTypes.EndWorkflowMilestone:
          return [
            ...acc,
            _endWorkflowMilestoneManipulation(m as EndWorkflowFragment, tz),
          ];
        case MilestoneTypes.StartPhaseMilestone:
          return [...acc, _startPhaseMilestoneManipulation(m as StartPhaseFragment, tz)];
        case MilestoneTypes.UpdateTagMilestone:
          return [...acc, _updateTagMilestoneManipulation(m as UpdateTagFragment, tz)];
        case MilestoneTypes.EnterLocationMilestone:
          return [
            ...acc,
            _enterLocationMilestoneManipulation(m as EnterLocationFragment, tz),
          ];
        case MilestoneTypes.CallPatientMilestone:
          return [
            ...acc,
            _callPatientMilestoneManipulation(m as CallPatientFragment, tz),
          ];
        case MilestoneTypes.CompletePatientMilestone:
          return [
            ...acc,
            _completePatientMilestoneManipulation(m as CompletePatientFragment, tz),
          ];
        case MilestoneTypes.DeletePatientMilestone:
          return [
            ...acc,
            _deletePatientMilestoneManipulation(m as DeletePatientFragment, tz),
          ];
        default:
          console.warn('Unknown milestone type.');
          return acc;
      }
    },
    []
  );
  const cardData: CardData = { milestones };
  return cardData;
}

export function _createPatientMilestoneManipulation(
  m: CreatePatientFragment,
  tz: string
): CreatePatient {
  const type = MilestoneTypes.CreatePatientMilestone;
  const datetime = utc(m.created_utc).tz(tz).format('MM/DD/YYYY HH:mm:ss');
  const department = m.department.name;
  const manual = m.created_by.indexOf('[AUTO]') === -1;
  const tag = _tagManipulation(m.tag);
  const triggerBy = _getFullName(m.user, m.created_by);
  return { type, datetime, department, manual, tag, triggerBy };
}

export function _startWorkflowMilestoneManipulation(
  m: StartWorkflowFragment,
  tz: string
): StartWorkflow {
  const type = MilestoneTypes.StartWorkflowMilestone;
  const datetime = utc(m.created_utc).tz(tz).format('MM/DD/YYYY HH:mm:ss');
  const workflow = m.workflow.name;
  const manual = m.created_by.indexOf('[AUTO]') === -1;
  const triggerBy = _getFullName(m.user, m.created_by);
  return { type, datetime, workflow, manual, triggerBy };
}

export function _endWorkflowMilestoneManipulation(
  m: EndWorkflowFragment,
  tz: string
): EndWorkflow {
  const type = MilestoneTypes.EndWorkflowMilestone;
  const datetime = utc(m.created_utc).tz(tz).format('MM/DD/YYYY HH:mm:ss');
  const workflow = m.workflow.name;
  const manual = m.created_by.indexOf('[AUTO]') === -1;
  return { type, datetime, workflow, manual };
}

export function _startPhaseMilestoneManipulation(
  m: StartPhaseFragment,
  tz: string
): StartPhase {
  const type = MilestoneTypes.StartPhaseMilestone;
  const datetime = utc(m.created_utc).tz(tz).format('MM/DD/YYYY HH:mm:ss');
  const workflow = m.workflow.name;
  const phase = m.phase.name;
  const manual = m.created_by.indexOf('[AUTO]') === -1;
  const triggerBy = _getFullName(m.user, m.created_by);
  return { type, datetime, workflow, phase, manual, triggerBy };
}

export function _updateTagMilestoneManipulation(
  m: UpdateTagFragment,
  tz: string
): UpdateTag {
  const type = MilestoneTypes.UpdateTagMilestone;
  const datetime = utc(m.created_utc).tz(tz).format('MM/DD/YYYY HH:mm:ss');
  const tag = _tagManipulation(m.tag);
  const manual = m.created_by.indexOf('[AUTO]') === -1;
  const triggerBy = _getFullName(m.user, m.created_by);
  return { type, datetime, tag, manual, triggerBy };
}

export function _enterLocationMilestoneManipulation(
  m: EnterLocationFragment,
  tz: string
): EnterLocation {
  const type = MilestoneTypes.EnterLocationMilestone;
  const datetime = utc(m.created_utc).tz(tz).format('MM/DD/YYYY HH:mm:ss');
  const location = m.alias_name || m.location_name;
  return { type, datetime, location };
}

export function _callPatientMilestoneManipulation(
  m: CallPatientFragment,
  tz: string
): CallPatient {
  const type = MilestoneTypes.CallPatientMilestone;
  const datetime = utc(m.created_utc).tz(tz).format('MM/DD/YYYY HH:mm:ss');
  const station = m.station.name;
  const triggerBy = _getFullName(m.user);
  return { type, datetime, station, triggerBy };
}

export function _completePatientMilestoneManipulation(
  m: CompletePatientFragment,
  tz: string
): CompletePatient {
  const type = MilestoneTypes.CompletePatientMilestone;
  const datetime = utc(m.created_utc).tz(tz).format('MM/DD/YYYY HH:mm:ss');
  const department = m.department.name;
  const manual = m.created_by.indexOf('[AUTO]') === -1;
  const triggerBy = _getFullName(m.user, m.created_by);
  return { type, datetime, department, manual, triggerBy };
}

export function _deletePatientMilestoneManipulation(
  m: DeletePatientFragment,
  tz: string
): DeletePatient {
  const type = MilestoneTypes.DeletePatientMilestone;
  const datetime = utc(m.created_utc).tz(tz).format('MM/DD/YYYY HH:mm:ss');
  const manual = m.created_by.indexOf('[AUTO]') === -1;
  const triggerBy = _getFullName(m.user, m.created_by);
  return { type, datetime, manual, triggerBy };
}

export function _tagManipulation(tag?: Tag): string {
  return [tag?.tag_value, tag?.label].filter((s) => !!s).join(' | ');
}

export function _getFullName(
  item?: {
    first_name: string;
    last_name: string;
    email: string;
  },
  created_by?: string
): string | undefined {
  if (created_by?.includes('@clients')) {
    return 'Integration';
  }
  if (!item) {
    return;
  }
  const fullName = [item.last_name, item.first_name].filter((s) => !!s).join(', ');
  return fullName || item.email;
}
