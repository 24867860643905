import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationDialogService } from '@frontend/common/confirmation-dialog';
import { ExpectedError } from '@frontend/common/util';
import { EMPTY, of, Subscription } from 'rxjs';
import { catchError, first, mergeMap, withLatestFrom } from 'rxjs/operators';
import { PatientFormValue } from '../patient-form/patient-form.component';
import { SecurableService } from './securable.service';
import {
  PatientNetworkModel,
  UpdatePatientService,
  VisitNetworkModel,
} from './update-patient.service';

export interface CmptData {
  id: number;
  securable_id: number;
  secured_patient_name: string;
  tag: { id: number; tag_value: string };
  note: string;
  additional_note: string;
  patient_department_visit_workflows: VisitNetworkModel[];
  assigned_location_id: number;
  current_workflow_ids: number[];
  label: number;
  tag_id: string;
}

@Component({
  selector: 'update-patient',
  templateUrl: './update-patient.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UpdatePatientService, SecurableService],
})
export class UpdatePatientComponent implements AfterViewInit, OnDestroy {
  form: FormGroup;

  subscriptions: Subscription[] = [];

  constructor(
    public srv: UpdatePatientService,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<UpdatePatientComponent>,
    private confirmationDialog: ConfirmationDialogService
  ) {
    this.subscriptions.push(
      this.srv.cmptData$.subscribe((patient) => {
        this.form.controls.patient.setValue(patient);
      })
    );
    this.subscriptions.push(
      this.srv.cmptDataRaw$
        .pipe(
          catchError((err) => {
            this.dialogRef.close();
            const snackBarConfig = { duration: 10000 };
            if (err instanceof ExpectedError) {
              this.snackBar.open(err.expectedError, $localize`Close`, snackBarConfig);
              return EMPTY;
            }
            const message = $localize`There was an unexpected error.`;
            this.snackBar.open(message, $localize`Close`, snackBarConfig);
            throw err;
          })
        )
        .subscribe()
    );
  }

  ngAfterViewInit(): void {
    this.form = new FormGroup({ patient: new FormControl() });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  submit(route?: string, force?: boolean) {
    const phone_number =
      this.form.controls.patient.value.phone_number &&
      this.form.controls.patient.value.phone_number.replace(/[^0-9]/g, '');
    const data = {
      ...this.form.controls.patient.value,
      phone_number,
    };
    this.subscriptions.push(
      of(data)
        .pipe(
          withLatestFrom(this.srv.cmptData$),
          mergeMap(([formValue, patient]: [PatientFormValue, PatientNetworkModel]) => {
            return this.srv.save(patient.id, formValue, force);
          })
        )
        .subscribe(
          () => {
            const next = route && [route];
            this.dialogRef.close(next);
            this.snackBar.open($localize`Patient was successfully updated.`, null, {
              duration: 3000,
            });
          },
          (e) => {
            let message;
            if (
              e?.expectedError ||
              e?.message === $localize`This Badge is currently in use.`
            ) {
              message = e.expectedError || e.message;
            } else {
              console.error(e);
              message = $localize`There was an unexpected error.`;
            }
            if (message === 'tag_is_non_reporting' || message === 'tag_is_low_battery') {
              const content =
                message === 'tag_is_non_reporting'
                  ? $localize`This Badge is non-reporting; we recommend you to select a different badge.`
                  : $localize`This Badge has a low battery; we recommend you to select a different badge.`;
              this.confirmationDialog
                .open({
                  title: $localize`Confirm operation`,
                  content,
                  cancel: $localize`NEW BADGE`,
                  confirm: $localize`SAVE ANYWAY`,
                  invertButtons: true,
                })
                .pipe(first())
                .subscribe((confirm) => confirm && this.submit(route, true));
            } else {
              this.snackBar.open(message, $localize`Close`, { duration: 10000 });
            }
            this.form.enable();
          }
        )
    );
    this.form.disable();
  }
}
