import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store } from '@ngrx/store';

import { AbstractState } from '@frontend/shared/base-store';
import { timeZonesActions } from './store/time-zones.actions';
import { TimeZone } from './time-zone.model';

@Injectable()
export class TimeZonesResolver implements Resolve<boolean> {
  constructor(private store: Store<AbstractState<TimeZone>>) {}

  resolve(): boolean {
    this.store.dispatch(timeZonesActions.load());
    return true;
  }
}
