import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Chat } from './chat.model';

@Component({
  selector: 'frontend-chat-header',
  styleUrls: ['chat-header.component.scss'],
  template: `
    <div>
      <span class="mat-title">{{ fullName }}</span>
      <span class="mat-subheading-1">{{ subtitle }}</span>
      <span class="mat-body-1">{{ totalMessages }} Messages</span>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatHeaderComponent {
  @Input() fullName: Chat['fullName'];
  @Input() totalMessages: Chat['totalMessages'];
  @Input() subtitle: string;
}
