<skeleton-form *ngIf="srv.loading$ | async"></skeleton-form>

<ng-container *ngIf="!(srv.loading$ | async)">
  <form autocomplete="off" novalidate action [formGroup]="form" (ngSubmit)="submit()">
    <h1 mat-dialog-title>
      <span i18n>Edit Patient</span>
    </h1>

    <mat-dialog-content>
      <frontend-patient-form formControlName="patient"></frontend-patient-form>
    </mat-dialog-content>

    <div mat-dialog-actions align="end">
      <button
        mat-button
        type="submit"
        [disabled]="form.controls.patient.invalid || form.disabled"
        i18n
      >
        SAVE
      </button>
      <button
        mat-button
        type="button"
        (click)="submit('appointments')"
        [disabled]="form.controls.patient.invalid || form.disabled"
        i18n
      >
        MANAGE APPOINTMENTS
      </button>
      <button
        mat-button
        type="button"
        (click)="submit('manage-workflows')"
        [disabled]="form.controls.patient.invalid || form.disabled"
        i18n
      >
        MANAGE WORKFLOWS
      </button>
    </div>
  </form>
</ng-container>
