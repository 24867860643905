import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { of } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

function get24Hours() {
  const times24 = [];
  for (let i = 0; i <= 23; i++) {
    times24.push(i < 10 ? '0' + i.toString() : i.toString());
  }
  return times24;
}

function generateTimestamps() {
  const times = get24Hours();
  const quarters = ['00', '15', '30', '45'];
  return times.reduce((acc, h) => [...acc, ...quarters.map((q) => `${h}:${q}`)], []);
}

@Component({
  selector: 'time-picker-24hs',
  templateUrl: './time-picker-24hs.component.html',
  styleUrls: ['./time-picker-24hs.component.scss'],
})
export class TimePicker24hsComponent {
  @Input() form: FormGroup;

  ts$ = of(generateTimestamps()).pipe(
    switchMap((ts) =>
      this.form.controls._at.valueChanges.pipe(
        startWith(''),
        map((at) => ts.filter((option) => option.indexOf(at) > -1))
      )
    )
  );
}
