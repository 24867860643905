import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationDialogService } from '@frontend/common/confirmation-dialog';
import { SocketService } from '@frontend/common/util';
import { ChangeDepartmentComponent } from '@frontend/workflow-rt/feature/patients/src';
import { Apollo } from 'apollo-angular';
import {
  EMPTY,
  merge,
  Observable,
  of,
  race,
  ReplaySubject,
  Subscription,
  throwError,
} from 'rxjs';
import {
  catchError,
  filter,
  first,
  ignoreElements,
  map,
  mergeMap,
  switchMap,
} from 'rxjs/operators';
import * as uuid from 'uuid/v4';
import { VisitDetailComponent } from '../visit-detail.component';
import { dataManipulation } from './data-manipulation';
import { EndVisitService } from './end-visit.service';
import { CardData } from './patient.component';
import { VisitDataQuery } from './patient.graphql';

export interface SecureItem {
  id: string;
  body: SecureBody;
}

export interface SecureBody {
  first_name?: string;
  last_name?: string;
  mrn?: string;
  phone_number?: string;
}

@Injectable()
export class PatientService implements OnDestroy {
  _subscriptions: Subscription[] = [];
  _cardDataRaw$: ReplaySubject<CardData> = new ReplaySubject(1);
  cardData$: Observable<CardData> = this._cardDataRaw$.pipe(catchError(() => EMPTY));

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { visitId: number },
    private apollo: Apollo,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<VisitDetailComponent>,
    private confirmationDialog: ConfirmationDialogService,
    private socketSrv: SocketService,
    private endVisitSrv: EndVisitService
  ) {
    this._subscriptions.push(
      this._getVisit(this.data.visitId.toString()).subscribe(this._cardDataRaw$)
    );

    this._subscriptions.push(
      this._cardDataRaw$.subscribe({
        error: (err) => {
          const message = $localize`There was an unexpected error.`;
          this.snackBar.open(message, $localize`Close`, { duration: 10000 });
          throw err;
        },
      })
    );
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  _getVisit(visitId: string): Observable<CardData> {
    return this.apollo
      .query<VisitDataQuery>({
        query: VisitDataQuery,
        variables: { visitId },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((result) => {
          if (!result.data) {
            throw new Error("Error getting visit's information (result.data was falsy)");
          }
          return result.data.patient;
        }),
        switchMap((visit) => {
          if (!visit.securable_id) {
            return of(visit);
          }
          return this.http
            .get<SecureItem>(`securable/${visit.securable_id}`)
            .pipe(map((result) => (result ? { ...visit, ...result.body } : visit)));
        }),
        map((data) => dataManipulation(data))
      );
  }

  onChangeDepartment(visitId: number) {
    this.dialogRef.close();
    this.dialog.open(ChangeDepartmentComponent, {
      data: { patientId: visitId },
      closeOnNavigation: true,
    });
  }

  onEndVisit(visitId: number) {
    this.dialogRef.close();
    this.endVisitSrv.openConfirmationDialog(visitId);
  }

  onDeletePatient(visitId: number) {
    this.dialogRef.close();
    this.confirmationDialog
      .open(ConfirmationDialogService.defaultConfirm)
      .subscribe((result) => {
        if (!result) {
          return;
        }
        this._deletePatient(visitId).subscribe(
          () =>
            this.snackBar.open($localize`Patient was successfully removed.`, null, {
              duration: 5000,
            }),
          () =>
            this.snackBar.open(
              $localize`There was an unexpected error.`,
              $localize`Close`,
              {
                duration: 10000,
              }
            )
        );
      });
  }

  _deletePatient(id: number) {
    const correlation_id = 'correlation|' + uuid();
    return merge(
      this.http.request('delete', `patients/${id}`, { body: { correlation_id } }).pipe(
        ignoreElements(),
        catchError((err) =>
          of(err?.error?.message).pipe(mergeMap((message) => throwError({ id, message })))
        )
      ),
      race(
        this.socketSrv
          .fromLocalSuccess<any>('patients.remove')
          .pipe(filter((ws) => ws.correlation_id === correlation_id)),
        this.socketSrv.fromLocalError('patients.remove').pipe(
          filter((ws) => ws.correlation_id === correlation_id),
          mergeMap(({ message }) => throwError({ id, message }))
        )
      ).pipe(first())
    );
  }
}
