import { BaseEntity, Entity, Feature } from '@frontend/shared/base-store';

@Entity('patient-workflows')
@Feature('patient-workflows')
export class PatientWorkflow extends BaseEntity {
  id: string;
  patient_workflow_id: number;
  workflow_id: number;
  customer_id: number;
  patient_department_visit_id: string;
  created_utc: string;
  current_phase_id: number;
  current_phase_updated_utc: string;
  called_count: number;
}
