import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { WorkflowRtUiDurationBadgeModule } from '../duration-badge/workflow-rt-ui-duration-badge.module';
import { WaitingTableComponent } from './waiting-table.component';

@NgModule({
  declarations: [WaitingTableComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatBadgeModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    WorkflowRtUiDurationBadgeModule,
  ],
  exports: [WaitingTableComponent],
})
export class WaitingTableModule {}
