import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, Injectable, NgModule } from '@angular/core';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AuthClientConfig } from '@auth0/auth0-angular';
import { BulkUploaderModule } from '@frontend/common/bulk-uploader';
import { CorrelationIdModule } from '@frontend/common/correlation-id';
import { SharedUiDowntimeNotificationModule } from '@frontend/common/downtime-notification';
import { SharedIdleModule } from '@frontend/common/idle';
import { InterceptorModule } from '@frontend/common/interceptor';
import { LoadingModule } from '@frontend/common/loading';
import {
  PhAuthModule,
  PhAuthServiceConfig,
  PH_AUTH_SERVICE_CONFIG,
  provideBootstrapEffects,
} from '@frontend/common/ph-auth';
import {
  PhConfigLoaderModel,
  PhConfigLoaderModule,
  PhConfigLoaderService,
} from '@frontend/common/ph-config-loader';
import {
  CustomSerializer,
  RouterEffects,
  routerReducerInitialState,
  RouterStateUrl,
} from '@frontend/common/ph-router-store';
import { UpdaterConfig, UpdaterModule } from '@frontend/common/updater';
import {
  CurrentUserService,
  CustomMatPaginatorIntl,
  SecurableModule,
  setupSentry,
  UserNotificationService,
} from '@frontend/common/util';
import { CustomersModule, DepartmentsModule } from '@frontend/entity';
import { StaffApiEffects, StaffWsEffects } from '@frontend/shared/staff/data-access/src';
import {
  WorkflowApiEffects,
  WorkflowWsEffects,
} from '@frontend/shared/workflow/data-access/src';
import { UiAccountDisableModule } from '@frontend/ui/account-disable/src';
import { PatientRecipientsModule } from '@frontend/ui/patient-recipients';
import { WorkflowRtFeatureVisitDetailModule } from '@frontend/workflow-rt/feature/visit-detail/src';
import { EffectsModule } from '@ngrx/effects';
import * as fromRouter from '@ngrx/router-store';
import {
  DefaultRouterStateSerializer,
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NxModule } from '@nrwl/angular';
import * as Sentry from '@sentry/angular';
import { ApolloModule } from 'apollo-angular';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { AppContentWrapperModule } from './app-content-wrapper/app-content-wrapper.module';
import { DepartmentSelectorModule } from './app-content-wrapper/department-selector/department-selector.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppEffects } from './app.effects';
import { AppGuard } from './app.guard';
import * as fromApp from './app.reducer';
import { GraphQlGuard } from './graphql.guard';
import { GraphQlService } from './graphql.service';

interface State {
  routerReducer: fromRouter.RouterReducerState<RouterStateUrl>;
  app: fromApp.State;
}

export const reducers: ActionReducerMap<State> = {
  routerReducer: fromRouter.routerReducer,
  app: fromApp.reducer,
};

@Injectable()
export class PhConfigLoaderFactory {
  constructor(private http: HttpClient) {}
  cb = (config: PhConfigLoaderModel): Observable<PhConfigLoaderModel> =>
    this.http.get(config.APP_API_URL + 'customers/id').pipe(
      map((customer: { id: number; auth_org_id: string; locale: string }) => customer),
      catchError(() => of({ id: 'disabled', auth_org_id: null, locale: null })),
      map((customer) => ({
        ...config,
        CUSTOMER_ID: customer.id,
        AUTH_ORG_ID: customer.auth_org_id,
        LOCALE: customer.locale,
      })),
      tap(({ SENTRY_DSN }) => {
        if (environment.production) {
          setupSentry(
            SENTRY_DSN,
            window.location.href,
            environment.version,
            'workflow-rt'
          );
        }
      })
    );
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatSidenavModule,
    MatToolbarModule,
    NxModule.forRoot(),
    StoreModule.forRoot(reducers, {
      metaReducers: [],
      initialState: { routerReducer: routerReducerInitialState },
      runtimeChecks: { strictStateImmutability: true, strictActionImmutability: true },
    }),
    EffectsModule.forRoot([RouterEffects, AppEffects]),
    StoreRouterConnectingModule.forRoot({ serializer: DefaultRouterStateSerializer }),
    !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 100 }) : [],
    AppRoutingModule,
    UpdaterModule.forRoot(
      [PhConfigLoaderService],
      (appConfig: PhConfigLoaderService): UpdaterConfig => ({
        checkInterval: appConfig.getConfig('CHECK_RELEASE_AFTER_SEC'),
      })
    ),
    PhAuthModule,
    PhConfigLoaderModule.forRoot(PhConfigLoaderFactory),
    BulkUploaderModule.forRoot(),
    CorrelationIdModule,
    CustomersModule,
    UiAccountDisableModule,
    SharedIdleModule,
    PatientRecipientsModule,
    InterceptorModule,
    SharedUiDowntimeNotificationModule,
    ApolloModule,
    SecurableModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    LoadingModule,
    AppContentWrapperModule,
    DepartmentSelectorModule,
    DepartmentsModule,
    WorkflowRtFeatureVisitDetailModule,
  ],
  providers: [
    AppGuard,
    GraphQlGuard,
    GraphQlService,
    CurrentUserService,
    UserNotificationService,
    provideBootstrapEffects([
      WorkflowApiEffects,
      WorkflowWsEffects,
      StaffApiEffects,
      StaffWsEffects,
    ]),
    {
      provide: APP_INITIALIZER,
      deps: [PhConfigLoaderService, AuthClientConfig],
      useFactory: (ac: PhConfigLoaderService) => () => ac.load(),
      multi: true,
    },
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    {
      provide: ErrorHandler,
      useFactory: () => {
        if (environment.production) {
          return Sentry.createErrorHandler();
        }
        return new ErrorHandler();
      },
    },
    {
      provide: PH_AUTH_SERVICE_CONFIG,
      useFactory: phAuthServiceFactory,
      deps: [PhConfigLoaderService],
    },
    { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

function phAuthServiceFactory(configSrv: PhConfigLoaderService): PhAuthServiceConfig {
  return combineLatest([
    configSrv.getConfig$('APP_API_URL'),
    configSrv.getConfig$('AUTH_DOMAIN'),
    configSrv.getConfig$('AUTH_AUDIENCE'),
    configSrv.getConfig$('REFRESH_TOKEN_IN_ADVANCE_SEC'),
    configSrv.getConfig$('AUTH_LOGOUT_CALLBACK_URL'),
    configSrv.getConfig$('AUTH_ORG_ID'),
    configSrv.getConfig$('LOCALE'),
  ]).pipe(
    map(
      ([
        APP_API_URL,
        AUTH_DOMAIN,
        AUTH_AUDIENCE,
        REFRESH_TOKEN_IN_ADVANCE_SEC,
        AUTH_LOGOUT_CALLBACK_URL,
        AUTH_ORG_ID,
        LOCALE,
      ]) => ({
        APP_API_URL,
        AUTH_DOMAIN,
        AUTH_AUDIENCE,
        REFRESH_TOKEN_IN_ADVANCE_SEC,
        AUTH_LOGOUT_CALLBACK_URL,
        AUTH_ORG_ID,
        LOCALE,
      })
    )
  );
}
