<button mat-icon-button [matMenuTriggerFor]="menu" title="Unread messages" i18n-title>
  <mat-icon [matBadge]="unread" [matBadgeHidden]="!unread" matBadgeColor="warn">mark_chat_unread</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <div style="width: 280px">
    <div id="search-wrapper" *ngIf="!!unread" (click)="$event.stopPropagation()">
      <search-box placeholder="Search for Patient" i18n-placeholder (searchChanges)="onSearchUpdate($event)">
      </search-box>
    </div>
    <ng-container *ngIf="!unread">
      <span mat-menu-item disabled i18n>There are no unread messages.</span>
    </ng-container>
    <ng-container *ngIf="!!unread">
      <div class="item-wrapper">
        <button mat-menu-item *ngFor="let item of list" [routerLink]="['chat', item.id]">
          <div class="item">
            <span class="name">{{ item.name }}</span>
            <span class="duration">{{ item.duration }}</span>
            <span class="unread">{{ item.unread }}</span>
          </div>
        </button>
      </div>
    </ng-container>
    <span mat-menu-item [routerLink]="['chat']" i18n>Go to chat</span>
  </div>
</mat-menu>