import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BulkUploaderService } from '@frontend/common/bulk-uploader';
import { BaseService } from '@frontend/shared/base-store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { tagsActions } from './store/tags.actions';
import { Tag } from './tag.model';

@UntilDestroy()
@Injectable()
export class TagsService extends BaseService<Tag> implements OnDestroy {
  constructor(
    http: HttpClient,
    private bulkUploaderSrv: BulkUploaderService,
    private store: Store<any>
  ) {
    super(http, 'tags');

    this.bulkUploaderSrv.finishedEntityOperation$
      .pipe(
        untilDestroyed(this),
        filter((entity) => entity === 'badge')
      )
      .subscribe(() => this.store.dispatch(tagsActions.load()));
  }

  ngOnDestroy() {}
}
