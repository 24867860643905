import { Injectable } from '@angular/core';
import { downloadCsv } from '@frontend/common/component-tools';
import { errorProcessor } from '@frontend/common/util';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Dictionary } from '@ngrx/entity/src/models';
import { select, Store } from '@ngrx/store';
import * as Papa from 'papaparse';
import { filter, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { BulkUploaderService } from '../../bulk-uploader.service';
import { Operation } from '../../models/operation.model';
import * as configActions from '../config/config.actions';
import { getOverlayCreated } from '../config/config.selectors';
import * as operationsActions from './operations.actions';
import { OperationsState } from './operations.reducer';
import { getOperationsEntities, getOperationsTotal } from './operations.selectors';

@Injectable()
export class OperationsEffects {
  constructor(
    protected actions$: Actions,
    private store: Store<OperationsState>,
    private srv: BulkUploaderService
  ) {}

  createOperation = createEffect(() =>
    this.actions$.pipe(
      ofType(operationsActions.CREATE_OPERATION),
      withLatestFrom(this.store.pipe(select(getOverlayCreated))),
      map(([_, overlayCreated]) =>
        overlayCreated ? [] : [new configActions.CreateOverlay()]
      ),
      mergeMap((arr) => [
        new operationsActions.PendingOperationUpdate(1),
        new configActions.Open(),
        ...arr,
      ])
    )
  );

  exportErrors$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(operationsActions.EXPORT_ERRORS),
        tap((action: operationsActions.ExportErrors) => {
          downloadCsv(Papa.unparse(action.payload), $localize`errors.csv`);
        })
      ),
    { dispatch: false }
  );

  finishedOperation = createEffect(
    () =>
      this.actions$.pipe(
        ofType(operationsActions.FINISHED_OPERATION),
        map((action: operationsActions.FinishedOperation) =>
          this.srv.finishedEntityOperation$.next(action.payload)
        )
      ),
    { dispatch: false }
  );

  processedResponse = createEffect(() =>
    this.actions$.pipe(
      ofType(operationsActions.PROCESSED_RESPONSE),
      map((action: operationsActions.ProcessedResponse) => [
        action,
        ...[
          action.payload.pending === 0
            ? [
                new operationsActions.FinishedOperation(action.payload.entity),
                new operationsActions.PendingOperationUpdate(-1),
              ]
            : [],
        ],
      ]),
      mergeMap(([action, arr]: [operationsActions.ProcessedResponse, any[]]) => [
        new operationsActions.OperationUpdate(action.payload),
        ...arr,
      ])
    )
  );

  removeOperation = createEffect(() =>
    this.actions$.pipe(
      ofType(operationsActions.REMOVE_OPERATION),
      withLatestFrom(this.store.pipe(select(getOperationsTotal))),
      filter(([_, total]) => total === 0),
      map(() => new configActions.DestroyOverlay())
    )
  );

  receiveResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(operationsActions.RECEIVED_RESPONSE),
      withLatestFrom(this.store.pipe(select(getOperationsEntities))),
      map(
        ([action, ops]: [operationsActions.ReceivedResponse, Dictionary<Operation>]) => {
          const p = action.payload;
          const operation = { ...ops[p.correlation_id] };
          operation.processed += 1;
          operation.pending -= 1;
          operation.progress = (operation.processed / operation.total) * 100;
          if (p.error || p.errorCode) {
            operation.errorItems = [...operation.errorItems, manipulateErrorItem(p)];
            return new operationsActions.ReceivedResponseError(operation);
          }
          return new operationsActions.ReceivedResponseSuccess(operation);
        }
      )
    )
  );

  receiveResponseError = createEffect(() =>
    this.actions$.pipe(
      ofType(operationsActions.RECEIVED_RESPONSE_ERROR),
      map((action: operationsActions.ReceivedResponseError) => {
        const error = action.payload.error + 1;
        return new operationsActions.ProcessedResponse({ ...action.payload, error });
      })
    )
  );

  receiveResponseSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(operationsActions.RECEIVED_RESPONSE_SUCCESS),
      map((action: operationsActions.ReceivedResponseSuccess) => {
        const success = action.payload.success + 1;
        return new operationsActions.ProcessedResponse({ ...action.payload, success });
      })
    )
  );

  uploadFileFail = createEffect(() =>
    this.actions$.pipe(
      ofType(operationsActions.UPLOAD_FILE_FAILED),
      mergeMap((action: operationsActions.UploadFileFailed) => [
        new operationsActions.OperationUpdate({
          correlation_id: action.payload,
          failed: true,
        }),
        new operationsActions.PendingOperationUpdate(-1),
      ])
    )
  );
}

function manipulateErrorItem(item) {
  const { message } = errorProcessor.process(item);
  const {
    correlation_id,
    created_by,
    updated_by,
    error,
    customer_id,
    site_id,
    correlation_id_item,
    ...rest
  } = item.payload;
  const payloadAndMessage = { ...rest, message };
  return Object.entries(payloadAndMessage).reduce((acc, [currKey, currValue]) => {
    const val = currValue || null;
    switch (currKey) {
      case 'tag_value':
        switch (item.entity) {
          case 'tag':
            return { ...acc, [$localize`value`]: val };
          case 'asset':
            return { ...acc, [$localize`tag_value`]: val };
          default:
            return { ...acc, [$localize`badge_value`]: val };
        }
        return { ...acc, [currKey]: val };
      default:
        return { ...acc, [currKey]: val };
    }
  }, {});
}
