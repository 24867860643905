import { AbstractState, BaseAction, ReducerFactory } from '@frontend/shared/base-store';
import { User } from '../user.model';
import { usersActions } from './users.actions';

class UsersReducerFactory extends ReducerFactory<User> {
  constructor() {
    super(User);
  }

  getReducer() {
    return (
      state: AbstractState<User> = this.adapter.getInitialState(this.initialState),
      a: BaseAction<User>
    ) => {
      switch (a.type) {
        case usersActions.types.TOGGLE:
          return this.adapter.updateOne(
            { id: a.payload.id, changes: { ...a.payload, pending: true } },
            state
          );
        case usersActions.types.TOGGLE_SUCCESS:
          return this.adapter.updateOne(
            { id: a.payload.id, changes: { pending: false } },
            state
          );
        default:
          return super.getReducer()(state, a);
      }
    };
  }
}

const reducerFunction = new UsersReducerFactory().getReducer();

export function usersReducer(state, action) {
  return reducerFunction(state, action);
}
