import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { PhaseLocationsResolver } from './phase-locations.resolver';
import { PhaseResolver } from './phase.resolver';
import { PhasesCanDeactivate } from './phases.can-deactivate';
import { PhasesResolver } from './phases.resolver';
import { PhasesService } from './phases.service';
import { PhasesEffects } from './store/phases.effects';
import { phasesReducer } from './store/phases.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('phases', phasesReducer),
    EffectsModule.forFeature([PhasesEffects]),
  ],
  providers: [
    PhasesService,
    PhaseResolver,
    PhasesResolver,
    PhaseLocationsResolver,
    PhasesCanDeactivate,
  ],
})
export class PhasesModule {}
