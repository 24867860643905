import { StaffTypeTranslation } from '@frontend/common/util';
import { ProvidersDataQuery } from './bulk-upload-instructions.graphql';

interface InstructionLine {
  [key: string]: string;
}
type Instructions = InstructionLine[];

export const STATIC_INSTRUCTIONS: Instructions = [
  { 'Patient upload instructions': '', ' ': '', '': '' },
  {
    'Patient upload instructions': 'Use the following IDs for the provider_id field:',
  },
  { 'Patient upload instructions': '' },
  {
    'Patient upload instructions': 'Provider',
    ' ': 'Type',
    '': 'ID',
  },
];

export function dataManipulation(data: ProvidersDataQuery): Instructions {
  const dynamicInstructions = data.staff.map((provider) => {
    const name = [provider?.last_name, provider?.first_name]
      .filter((part) => !!part)
      .join(', ');
    const type = StaffTypeTranslation[provider.staff_type.id];
    return {
      'Patient upload instructions': name,
      ' ': type,
      '': provider.id.toString(),
    };
  });
  return [].concat(STATIC_INSTRUCTIONS, dynamicInstructions);
}
