import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { SharedUiChatModule } from '@frontend/common/chat';
import { SharedUiSkeletonChatModule } from '@frontend/common/skeletons';
import { SharedQuickMessageDataAccessModule } from '@frontend/shared/quick-message/data-access';
import { ChatRoutedComponent } from './chat-routed.component';
import { ChatSmartComponent } from './chat-smart/chat-smart.component';
import { ChatWrapperComponent } from './chat-wrapper/chat-wrapper.component';
import { ConversationListComponent } from './conversation-list/conversation-list.component';
import { NoConversationComponent } from './no-conversation.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatSnackBarModule,
    MatToolbarModule,
    SharedUiChatModule,
    SharedUiSkeletonChatModule,
    SharedQuickMessageDataAccessModule,
  ],
  declarations: [
    ChatSmartComponent,
    ChatWrapperComponent,
    ConversationListComponent,
    ChatRoutedComponent,
    NoConversationComponent,
  ],
  exports: [
    ChatSmartComponent,
    ChatWrapperComponent,
    ConversationListComponent,
    ChatRoutedComponent,
    NoConversationComponent,
  ],
})
export class ChatModule {}
