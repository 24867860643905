import { AbstractState, BaseAction, ReducerFactory } from '@frontend/shared/base-store';
import { Phase } from '../phase.model';
import { phasesActions } from './phases.actions';

class PhasesReducerFactory extends ReducerFactory<Phase> {
  constructor() {
    super(Phase);
  }

  getReducer() {
    return (
      state: AbstractState<Phase> = this.adapter.getInitialState(this.initialState),
      a: BaseAction<Phase>
    ) => {
      switch (a.type) {
        case this.actions.types.REMOVE: {
          const length = state.ids.length;
          const lastPhaseId = Object.keys(state.entities)
            .map((key) => state.entities[key])
            .find((item) => item.sort_order === length).id;

          const s = this.adapter.updateOne(
            {
              id: lastPhaseId,
              changes: {
                sort_order: length - 1,
              },
            },
            state
          );
          return this.adapter.removeOne(a.payload.id, s);
        }

        case this.actions.types.ADD: {
          const length = state.ids.length;
          const lastPhaseId = Object.keys(state.entities)
            .map((key) => state.entities[key])
            .find((item) => item.sort_order === length).id;
          const s = this.adapter.updateOne(
            {
              id: lastPhaseId,
              changes: {
                sort_order: length + 1,
              },
            },
            state
          );
          const entity = { ...a.payload, pending: true, sort_order: length };
          return this.adapter.addOne(entity, s);
        }
        case phasesActions.types.LOAD_LOCATIONS_SUCCESS:
          return this.adapter.updateOne(
            {
              id: a.payload.id,
              changes: { location_ids: a.payload.locations.map((l) => l.id) },
            },
            state
          );
        case phasesActions.types.ADD_LOCATION:
          return this.adapter.updateOne(
            {
              id: a.payload.phase.id,
              changes: {
                location_ids: [
                  ...state.entities[a.payload.phase.id].location_ids,
                  a.payload.location.id,
                ],
              },
            },
            state
          );
        case phasesActions.types.REMOVE_LOCATION:
          return this.adapter.updateOne(
            {
              id: a.payload.phase.id,
              changes: {
                location_ids: [
                  ...state.entities[a.payload.phase.id].location_ids.filter(
                    (lid) => lid !== a.payload.location.id
                  ),
                ],
              },
            },
            state
          );
        case phasesActions.types.SET_SORT_ORDER: {
          const oldPosition = state.entities[a.payload.id].sort_order;
          const newPosition = a.payload.sort_order;
          const affected = Object.keys(state.entities)
            .map((key) => state.entities[key])
            .find((item) => item.sort_order === newPosition);
          const updates = [
            { id: affected.id, changes: { sort_order: oldPosition } },
            { id: a.payload.id, changes: { sort_order: newPosition } },
          ];
          return this.adapter.updateMany(updates, state);
        }
        default:
          return super.getReducer()(state, a);
      }
    };
  }
}

const reducerFunction = new PhasesReducerFactory().getReducer();

export function phasesReducer(state, action) {
  return reducerFunction(state, action);
}
