import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { customersActions, customersSelectors } from '@frontend/entity/src';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

const NO_ACCESS_MSG = $localize`You do not have permissions to access this section.`;

@Injectable()
export class AppGuard {
  constructor(private store: Store, private snackBar: MatSnackBar) {}

  canActivate(): Observable<boolean> {
    this.store.dispatch(customersActions.loadOne(''));
    return this.store.pipe(
      select(customersSelectors.getCurrentCustomer),
      filter((c) => !!c),
      map((c) => c.license_info.is_workflow_rt_enabled),
      tap((enabled) => {
        if (!enabled) {
          this.snackBar.open(NO_ACCESS_MSG, $localize`Close`);
        }
      })
    );
  }
}
