import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, of, timer } from 'rxjs';
import { first, map, mergeMap, tap } from 'rxjs/operators';
import { clearCache, updateCache } from './actions';
import { Securable } from './model';
import { getEntities, getIds } from './reducer';

@Injectable()
export class SecurableCacheService {
  constructor(private http: HttpClient, private store: Store) {
    timer(10 * 60 * 1000).subscribe(() => this.store.dispatch(clearCache()));
  }

  get(ids: (string | number)[]) {
    return combineLatest([
      this.store.select(getIds),
      this.store.select(getEntities),
    ]).pipe(
      first(),
      mergeMap(([cachedIDs, cachedEntities]) => {
        const newIds = ids
          .map((id) => id.toString())
          .filter((id) => (<string[]>cachedIDs).indexOf(id) === -1);
        const cachedSecurables = ids.reduce((acc, curr) => {
          const cached = cachedEntities[curr];
          return cached ? [...acc, cached] : acc;
        }, []);
        if (newIds.length === 0) {
          return of(cachedSecurables);
        }
        return this.getFromNetwork(newIds).pipe(
          tap((freshSecurables) =>
            this.store.dispatch(updateCache({ securables: freshSecurables }))
          ),
          map((freshSecurables) => [...cachedSecurables, ...freshSecurables])
        );
      })
    );
  }

  private getFromNetwork(id: string[]) {
    return this.http.post<Securable[]>('securable/get-bulk', { id });
  }
}
