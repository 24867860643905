<mat-list>
  <ng-container *ngFor="let message of messages">
    <mat-divider></mat-divider>
    <mat-list-item [ngClass]="{ unseen: message.unseen }">
      <div mat-line>
        <div>
          <div>
            <span
              >From: {{ message.from }} <span *ngIf="message.unseen">(Unseen)</span></span
            >
            <span *ngIf="message.to">To: {{ message.to }} </span>
          </div>
          <span>{{ message.sentFormatted }}</span>
        </div>
      </div>
      <div mat-line>
        <div>
          <span>{{ message.body }}</span>
          <span
            class="unseen-visual-indicator"
            *ngIf="message.unseen !== undefined"
          ></span>
        </div>
      </div>
    </mat-list-item>
  </ng-container>
</mat-list>
