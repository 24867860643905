import { Injectable } from '@angular/core';
import { SocketService } from '@frontend/common/util';
import { createEffect } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import {
  GlobalCreateQuickMessageSuccessWs,
  GlobalRemoveQuickMessageSuccessWs,
  GlobalUpdateQuickMessageSuccessWs,
  LocalCreateQuickMessageFailureWs,
  LocalCreateQuickMessageSuccessWs,
  LocalRemoveQuickMessageFailureWs,
  LocalRemoveQuickMessageSuccessWs,
  LocalUpdateQuickMessageFailureWs,
  LocalUpdateQuickMessageSuccessWs,
} from './quick-message-ws.actions';

@Injectable()
export class QuickMessageWsEffects {
  LocalCreateQuickMessageSuccessWs$ = createEffect(() =>
    this.socketSrv
      .fromLocalSuccess('quick-messages.create')
      .pipe(
        map(
          ({ id, correlation_id }) =>
            new LocalCreateQuickMessageSuccessWs({ id, correlation_id })
        )
      )
  );

  LocalCreateQuickMessageFailureWs$ = createEffect(() =>
    this.socketSrv
      .fromLocalError('quick-messages.create')
      .pipe(
        map(
          ({ correlation_id, message }) =>
            new LocalCreateQuickMessageFailureWs({ correlation_id, message })
        )
      )
  );

  LocalUpdateQuickMessageSuccessWs$ = createEffect(() =>
    this.socketSrv
      .fromLocalSuccess('quick-messages.update')
      .pipe(
        map(
          ({ id, correlation_id }) =>
            new LocalUpdateQuickMessageSuccessWs({ id, correlation_id })
        )
      )
  );

  LocalUpdateQuickMessageFailureWs$ = createEffect(() =>
    this.socketSrv
      .fromLocalError('quick-messages.update')
      .pipe(
        map(
          ({ correlation_id, message }) =>
            new LocalUpdateQuickMessageFailureWs({ correlation_id, message })
        )
      )
  );

  LocalRemoveQuickMessageSuccessWs$ = createEffect(() =>
    this.socketSrv
      .fromLocalSuccess('quick-messages.remove')
      .pipe(
        map(
          ({ correlation_id }) => new LocalRemoveQuickMessageSuccessWs({ correlation_id })
        )
      )
  );

  LocalRemoveQuickMessageFailureWs$ = createEffect(() =>
    this.socketSrv
      .fromLocalError('quick-messages.remove')
      .pipe(
        map(
          ({ correlation_id, message }) =>
            new LocalRemoveQuickMessageFailureWs({ correlation_id, message })
        )
      )
  );

  GlobalCreateQuickMessageSuccessWs$ = createEffect(() =>
    this.socketSrv
      .fromGlobalSuccess('quick-messages.create')
      .pipe(
        map((quickMessage: any) => new GlobalCreateQuickMessageSuccessWs(quickMessage))
      )
  );

  GlobalUpdateQuickMessageSuccessWs$ = createEffect(() =>
    this.socketSrv
      .fromGlobalSuccess('quick-messages.update')
      .pipe(
        map((quickMessage: any) => new GlobalUpdateQuickMessageSuccessWs(quickMessage))
      )
  );

  GlobalRemoveQuickMessageSuccessWs$ = createEffect(() =>
    this.socketSrv
      .fromGlobalSuccess('quick-messages.remove')
      .pipe(map(({ id }: any) => new GlobalRemoveQuickMessageSuccessWs(id)))
  );

  constructor(private socketSrv: SocketService) {}
}
