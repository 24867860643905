import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { CurrentCustomerService, CurrentDepartmentService } from '@frontend/common/util';
import { WorkflowRtFeaturePatientSearchModule } from '@frontend/workflow-rt/feature/patient-search/src';
import { AppSwitcherModule } from '../app-switcher/app-switcher.module';
import { AppContentWrapperComponent } from './app-content-wrapper.component';
import { ToolbarModule } from './toolbar/toolbar.module';

@NgModule({
  declarations: [AppContentWrapperComponent],
  exports: [AppContentWrapperComponent],
  imports: [
    BrowserModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatSidenavModule,
    MatTooltipModule,
    ToolbarModule,
    WorkflowRtFeaturePatientSearchModule,
    AppSwitcherModule,
  ],
  providers: [
    CurrentDepartmentService,
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: { position: 'right' } },
    CurrentCustomerService,
  ],
})
export class AppContentWrapperModule {}
