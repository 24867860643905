import gql from 'graphql-tag';

export interface RecipientsDataQuery {
  patient: Visit;
  recipients: Recipient[];
}

export interface Recipient {
  id: number;
  securable_id: string;
}

export interface Visit {
  department_id: string;
}

export const RecipientsDataQuery = gql(`
query RecipientsDataQuery($visitId: String!) {
  patient(id: $visitId) {
    department_id
  }
  recipients(patient_department_visit_id: $visitId) {
    id
    securable_id
  }
}
`);
