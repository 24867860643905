import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedUiSkeletonFormModule } from '@frontend/common/skeletons';
import { ManageWorkflowsRoutedComponent } from './manage-workflows-routed.component';
import { ManageWorkflowsComponent } from './manage-workflows.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    MatTooltipModule,
    SharedUiSkeletonFormModule,
  ],
  declarations: [ManageWorkflowsRoutedComponent, ManageWorkflowsComponent],
  exports: [ManageWorkflowsRoutedComponent, ManageWorkflowsComponent],
})
export class ManageWorkflowsModule {}
