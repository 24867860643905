<mat-toolbar>
  <div class="left">
    <button mat-flat-button disableRipple (click)="openChangeDepartmentDialog()"
      *ngIf="department$ | async as department">
      <span>{{department.name}}</span>
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
  </div>
  <div class="right">
    <button mat-flat-button disableRipple [matMenuTriggerFor]="menu">
      <ng-container *ngIf="user$ | async as user">
        <ng-container *ngIf="user.name; then nameTmpl; else emailTmpl"></ng-container>
        <ng-template #nameTmpl>
          <span [matTooltip]="user.email" matTooltipPosition="below">{{user.name}}</span>
        </ng-template>
        <ng-template #emailTmpl>
          <span>{{user.email}}</span>
        </ng-template>
        <mat-icon>arrow_drop_down</mat-icon>
      </ng-container>
    </button>
  </div>
</mat-toolbar>

<mat-menu #menu="matMenu" xPosition="before">
  <button mat-menu-item routerLink="/auth/logout">
    <mat-icon>power_settings_new</mat-icon>
    <span>Sign Out</span>
  </button>
</mat-menu>