import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RecipientsService } from '../../services/recipients.service';
import {
  Add,
  Delete,
  Update,
  Load,
  LoadFailure,
  RecipientApiActionTypes,
} from './recipient-api.actions';
import { LoadRecipients } from './recipient.actions';

@Injectable()
export class RecipientApiEffects {
  loadRecipients$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(RecipientApiActionTypes.Load),
      switchMap((action: Load) =>
        this.srv.getSecurableRecipientByPatient(action.payload.patient_id).pipe(
          map((recipients) => new LoadRecipients({ recipients })),
          catchError(() => of(new LoadFailure()))
        )
      )
    )
  );

  addRecipient$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RecipientApiActionTypes.Add),
        map((a: Add) => a.payload.recipient),
        switchMap((recipient) => this.srv.create(recipient))
      ),
    { dispatch: false }
  );

  updateRecipient$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RecipientApiActionTypes.Update),
        map((a: Update) => a.payload.recipient),
        switchMap((recipient) => this.srv.update(recipient))
      ),
    { dispatch: false }
  );

  deleteRecipient$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RecipientApiActionTypes.Delete),
        switchMap((a: Delete) => this.srv.delete(a.payload))
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private srv: RecipientsService) {}
}
