import { Component, Inject, LOCALE_ID, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ExpectedError } from '@frontend/common/util';
import { EMPTY, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { manipulateFormOutput } from './appointment-manipulation';
import { EditAppointmentService } from './edit-appointment.service';

@Component({
  selector: 'frontend-app-edit-appointment',
  templateUrl: 'edit-appointment.component.html',
  styleUrls: ['edit-appointment.component.scss'],
  providers: [EditAppointmentService],
})
export class EditAppointmentComponent implements OnDestroy {
  _subscriptions: Subscription[] = [];
  _timezone: string;
  form: FormGroup;
  private formControlConfig = {
    id: null,
    date: null,
    time: [null, Validators.pattern('^$|^(([01][0-9])|(2[0-3])):[0-5][0-9]$')],
    period: [null, Validators.maxLength(1)],
    provider_id: null,
    appointment_type_id: null,
  };

  constructor(
    @Inject(LOCALE_ID) public localeId: string,
    public srv: EditAppointmentService,
    private dialogRef: MatDialogRef<EditAppointmentComponent>,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder
  ) {
    this.form = this.formBuilder.group(this.formControlConfig, {
      validators: [atLeastOneRequired, ifOneAllRequired],
    });
    this._subscriptions.push(
      this.srv.appointment.subscribe(
        (appointment) => {
          this.form.patchValue(appointment);
        },
        (err) => {
          this.dialogRef.close();
          const snackBarConfig = { duration: 10000 };
          if (err instanceof ExpectedError) {
            this.snackBar.open(err.expectedError, $localize`Close`, snackBarConfig);
            return EMPTY;
          }
          const message = $localize`There was an unexpected error.`;
          this.snackBar.open(message, $localize`Close`, snackBarConfig);
          throw err;
        }
      )
    );
    this._subscriptions.push(this.srv.timezone.subscribe((tz) => (this._timezone = tz)));
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  close(route) {
    this.dialogRef.close(route);
  }

  updateAppointment() {
    const payload = manipulateFormOutput(this.form.value, this._timezone);
    this.form.disable();
    this.srv
      .updateAppointment(payload)
      .pipe(
        catchError((err) => {
          const snackBarConfig = { duration: 10000 };
          if (err instanceof ExpectedError) {
            this.snackBar.open(err.expectedError, $localize`Close`, snackBarConfig);
            return EMPTY;
          }
          const message = $localize`There was an unexpected error.`;
          this.snackBar.open(message, $localize`Close`, snackBarConfig);
          throw err;
        })
      )
      .subscribe({
        next: () => {
          this.dialogRef.close();
          this.form.reset();
        },
        complete: () => {
          this.form.enable();
        },
      });
  }
}

const atLeastOneRequired: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const date = control.get('date').value;
  const time = control.get('time').value;
  const period = control.get('period').value?.length;
  const provider = control.get('provider_id').value;
  const type = control.get('appointment_type_id').value;
  if (date || time || period || provider || type) {
    return null;
  }
  return { atLeastOneRequired: true };
};

const ifOneAllRequired: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const date = control.get('date').value;
  const time = control.get('time').value;
  const period = control.get('period').value?.length;
  const someFilled = [date, time, period].some((val) => !!val);
  const someEmpty = [date, time, period].some((val) => !val);
  if (someFilled && someEmpty) {
    return { ifOneAllRequired: true };
  }
  return null;
};
