import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { getRouterState } from '@frontend/common/ph-router-store';
import { AbstractState } from '@frontend/shared/base-store';
import { select, Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { Department } from './department.model';
import { departmentsActions } from './store/departments.actions';

@Injectable()
export class DepartmentsResolver implements Resolve<boolean> {
  constructor(private store: Store<AbstractState<Department>>) {}

  resolve(): boolean {
    this.store.pipe(select(getRouterState), first()).subscribe((router) => {
      this.store.dispatch(
        departmentsActions.load({ site_id: router.state.params.site_id })
      );
    });
    return true;
  }
}
