import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SensorResolver } from './sensor.resolver';
import { SensorsCanDeactivate } from './sensors.can-deactivate';
import { SensorsResolver } from './sensors.resolver';
import { SensorsService } from './sensors.service';
import { SensorsEffects } from './store/sensors.effects';
import { sensorsReducer } from './store/sensors.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('sensors', sensorsReducer),
    EffectsModule.forFeature([SensorsEffects]),
  ],
  providers: [SensorsService, SensorResolver, SensorsResolver, SensorsCanDeactivate],
})
export class SensorsModule {}
