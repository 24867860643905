import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { featureKey, reducer } from './reducer';
import { SecurableCacheService } from './service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(featureKey, reducer, { metaReducers: [] }),
  ],
  providers: [SecurableCacheService],
})
export class SecurableModule {}
