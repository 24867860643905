import { Injectable } from '@angular/core';
import { PhAuthService } from '@frontend/common/ph-auth';
import { PhConfigLoaderService } from '@frontend/common/ph-config-loader';
import { Apollo } from 'apollo-angular';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { combineLatest } from 'rxjs';
import { filter, first, mapTo } from 'rxjs/operators';

@Injectable()
export class GraphQlService {
  private token: string;
  private hasuraUrl$ = this.config.getConfig$('GRAPHQL_URL').pipe(filter((uri) => !!uri));
  private caGraphqlUrl$ = this.config
    .getConfig$('CA_GRAPHQL_URL')
    .pipe(filter((uri) => !!uri));
  private token$ = this.phAuthSrv.token$.pipe(filter((token) => !!token));
  private urlsAndToken$ = combineLatest([
    this.hasuraUrl$,
    this.caGraphqlUrl$,
    this.token$,
  ]).pipe(first());

  isReady$ = this.urlsAndToken$.pipe(mapTo(true));

  constructor(
    private apollo: Apollo,
    private config: PhConfigLoaderService,
    private phAuthSrv: PhAuthService
  ) {
    this.token$.subscribe((token) => (this.token = token));
    this.urlsAndToken$.subscribe(([hasuraUrl, caGraphqlUrl]) => {
      const hasuraLink = new WebSocketLink({
        uri: hasuraUrl,
        options: {
          reconnect: true,
          connectionParams: () => ({
            headers: { Authorization: `Bearer ${this.token}` },
          }),
        },
      });

      const caGraphqlLink = new HttpLink({
        uri: caGraphqlUrl,
        headers: { Authorization: `Bearer ${this.token}` },
      });

      const cache = new InMemoryCache();

      const HASURA_OPTIONS = { link: hasuraLink, cache };
      const CA_OPTIONS = { link: caGraphqlLink, cache };

      this.apollo.createDefault(HASURA_OPTIONS);
      this.apollo.createNamed('HASURA', HASURA_OPTIONS);
      this.apollo.createNamed('CA', CA_OPTIONS);
    });
  }
}
