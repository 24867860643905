<div class="dialog-title-container">
  <h2 mat-dialog-title i18n>Patient’s Visit Detail</h2>

  <ng-container *ngIf="srv.crossModuleData$ | async as crossModuleData">
    <button mat-icon-button [matMenuTriggerFor]="moduleListMenu" *ngIf="!(crossModuleData.visitCompleted)">
      <mat-icon>settings</mat-icon>
    </button>
    <mat-menu #moduleListMenu="matMenu" [formGroup]="modulesForm" (closed)="onClose()">
      <label mat-menu-item (click)="$event.stopPropagation()">
        <mat-checkbox formControlName="status" i18n>Status</mat-checkbox>
      </label>
      <label mat-menu-item (click)="$event.stopPropagation()">
        <mat-checkbox formControlName="workflows" i18n>Workflows</mat-checkbox>
      </label>
      <label mat-menu-item (click)="$event.stopPropagation()">
        <mat-checkbox formControlName="appointments" i18n>Appointments</mat-checkbox>
      </label>
      <label mat-menu-item (click)="$event.stopPropagation()">
        <mat-checkbox formControlName="recipients" i18n>Recipients</mat-checkbox>
      </label>
      <label mat-menu-item (click)="$event.stopPropagation()">
        <mat-checkbox formControlName="milestones" i18n>Milestones</mat-checkbox>
      </label>
    </mat-menu>
  </ng-container>
</div>

<mat-dialog-content>
  <div class="modules-container">
    <ng-container *ngIf="srv.crossModuleData$ | async as crossModuleData">
      <frontend-visit-detail-patient [visitCompleted]="crossModuleData.visitCompleted"></frontend-visit-detail-patient>
      <div class="optional-modules">
        <div class="min-col-1" *ngIf="!crossModuleData.visitCompleted && statusCtrl.value">
          <frontend-visit-detail-status></frontend-visit-detail-status>
        </div>
        <div class="min-col-1" *ngIf="!crossModuleData.visitCompleted && workflowsCtrl.value">
          <frontend-visit-detail-workflows></frontend-visit-detail-workflows>
        </div>
        <div class="min-col-1" *ngIf="crossModuleData.visitCompleted || appointmentsCtrl.value">
          <frontend-visit-detail-appointments [visitCompleted]="crossModuleData.visitCompleted">
          </frontend-visit-detail-appointments>
        </div>
        <div class="min-col-1" *ngIf="!crossModuleData.visitCompleted && recipientsCtrl.value">
          <frontend-visit-detail-recipients></frontend-visit-detail-recipients>
        </div>
        <div class="min-col-2" *ngIf="crossModuleData.visitCompleted || milestonesCtrl.value">
          <frontend-visit-detail-milestones></frontend-visit-detail-milestones>
        </div>
      </div>
    </ng-container>
  </div>
</mat-dialog-content>