import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { getParams, Go } from '@frontend/common/ph-router-store';
import { SocketService } from '@frontend/common/util';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Update } from '@ngrx/entity';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { first, map, mergeMap, mergeMapTo } from 'rxjs/operators';
import { RecipientTableItem } from '../recipients-table/recipients-table-item.model';
import * as fromRoot from '../reducers';
import { Load } from '../reducers/recipient/recipient-api.actions';
import {
  ClearRecipients,
  UpdateRecipient,
} from '../reducers/recipient/recipient.actions';
import { Recipient } from '../reducers/recipient/recipient.model';
import { State } from '../reducers/recipient/recipient.reducer';
import { PatientsService } from '../services/patients.service';
import { selectRecipientTableItems } from './recipients-list.selectors';

@UntilDestroy()
@Component({
  selector: 'frontend-recipients-list',
  templateUrl: './recipients-list.component.html',
  styleUrls: ['./recipients-list.component.scss'],
})
export class RecipientsListComponent implements OnDestroy, OnInit {
  patientId$: Observable<string> = this.store.pipe(
    select(getParams),
    first(),
    untilDestroyed(this),
    map((p) => p.patient_id)
  );
  recipients$: Observable<RecipientTableItem[]> = this.store.pipe(
    select(selectRecipientTableItems)
  );
  searchValue$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  errors$: Observable<Recipient[]> = this.store.pipe(select(fromRoot.getAllWithError));
  loading$: Observable<boolean> = this.store.pipe(select(fromRoot.getRecipientsLoading));
  title$ = this.patientId$.pipe(
    mergeMap((patient_id) => this.patientSrv.get(patient_id)),
    mergeMap((patient) => this.patientSrv.getSecure(patient.securable_id)),
    map(({ body: { last_name, first_name } }) => `${last_name}, ${first_name} Recipients`)
  );

  constructor(
    private store: Store<State>,
    private patientSrv: PatientsService,
    private socket: SocketService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute
  ) {}

  ngOnDestroy(): void {
    this.store.dispatch(new ClearRecipients());
    this.snackBar.dismiss();
  }

  ngOnInit(): void {
    this.patientId$.subscribe((patient_id) =>
      this.store.dispatch(new Load({ patient_id }))
    );

    this.socket
      .fromLocalSuccess<Recipient>('recipients.create')
      .pipe(untilDestroyed(this))
      .subscribe((obj) => {
        const changes = { ...obj, pending: false };
        const recipient: Update<Recipient> = { id: obj.correlation_id, changes };
        this.store.dispatch(new UpdateRecipient({ recipient }));
        this.snackBar.open($localize`Recipient was successfully created.`, null, {
          duration: 3000,
        });
      });

    this.socket
      .fromLocalSuccess<Recipient>('recipients.update')
      .pipe(untilDestroyed(this))
      .subscribe((obj) => {
        const changes = { ...obj, pending: false };
        const recipient: Update<Recipient> = { id: obj.id, changes };
        this.store.dispatch(new UpdateRecipient({ recipient }));
        this.snackBar.open($localize`Recipient was successfully updated.`, null, {
          duration: 3000,
        });
      });

    this.socket
      .fromLocalSuccess<Recipient>('recipients.remove')
      .pipe(untilDestroyed(this))
      .subscribe(() =>
        this.snackBar.open($localize`Recipient was successfully removed.`, null, {
          duration: 3000,
        })
      );

    merge(
      this.socket.fromGlobalSuccess('recipients.create'),
      this.socket.fromGlobalSuccess('recipients.update'),
      this.socket.fromGlobalSuccess('recipients.remove')
    ).subscribe(() => this.snackRemoteChange());

    merge(
      this.socket.fromLocalError('recipients.create'),
      this.socket.fromLocalError('recipients.update')
    ).subscribe((error) => {
      this.snackbarError(error);
      const recipient = {
        id: error.payload.id || error.payload.correlation_id,
        changes: { error, pending: false },
      };
      this.store.dispatch(new UpdateRecipient({ recipient }));
    });
  }

  onSearchChange(search: string) {
    this.searchValue$.next(search);
  }

  private snackRemoteChange() {
    this.snackBar
      .open(
        $localize`Another user updated this table. Do you want to refresh?`,
        $localize`Refresh`,
        {
          duration: 6000,
        }
      )
      .onAction()
      .pipe(mergeMapTo(this.patientId$))
      .subscribe((patient_id) => this.store.dispatch(new Load({ patient_id })));
  }

  private snackbarError(error) {
    this.snackBar
      .open(error.message, $localize`Retry`, { duration: 10000 })
      .onAction()
      .subscribe(() => {
        this.store.dispatch(
          new Go({
            path: [error.payload.id || error.payload.correlation_id],
            extras: { relativeTo: { snapshot: this.route.snapshot } as ActivatedRoute },
          })
        );
      });
  }
}
