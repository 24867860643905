import { Injectable } from '@angular/core';
import { SecurableCacheService } from '@frontend/common/util';
import { select, Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { getDepartmentId } from './chat-wrapper.selectors';
import { Conversation, ConversationWithName } from './conversation.model';

type Secure<T = {}> = T & {
  securable_id: string;
};

interface SecureData<T = {}> {
  id: string;
  body: T;
}

interface Patient {
  first_name: string;
  last_name: string;
}

interface UnreadConversation {
  id: number;
  securable_id: number;
  unseen_messages_count: {
    aggregate: {
      count: number;
    };
  };
  oldest_unseen_message_utc: { created_utc: string }[];
}

interface ReadConversation {
  id: number;
  securable_id: number;
  newest_seen_message_utc: { created_utc: string }[];
}

const unreadConversationsQuery = gql(`
subscription unreadConversations($departmentId: Int!) {
  patient_department_visit(where: {text_messages: {is_seen: {_eq: false}}, _and: {department_id: {_eq: $departmentId}}}, order_by: {text_messages_aggregate: {max: {created_utc: asc}}}) {
    id
    securable_id
    unseen_messages_count: text_messages_aggregate(where: {is_seen: {_eq: false}}) {
      aggregate {
        count
      }
    }
    oldest_unseen_message_utc: text_messages(where: {is_seen: {_eq: false}}, order_by: {created_utc: asc}, limit: 1) {
      created_utc
    }
  }
}
`);

const readConversationsQuery = gql(`
subscription readConversations($departmentId: Int!) {
  patient_department_visit(where: {_and: {department_id: {_eq: $departmentId}, _not: {text_messages: {is_seen: {_eq: false}}}, text_messages: {is_seen: {_eq: true}}}}, order_by: {text_messages_aggregate: {max: {created_utc: desc}}}) {
    id
    securable_id
    newest_seen_message_utc: text_messages(where: {is_seen: {_eq: true}}, order_by: {created_utc: desc}, limit: 1) {
      created_utc
    }
  }
}
`);

@Injectable()
export class ChatWrapperService {
  constructor(
    private securableCache: SecurableCacheService,
    private apollo: Apollo,
    private store: Store
  ) {}

  get onChange$(): Observable<ConversationWithName[]> {
    return this.store.pipe(
      select(getDepartmentId),
      switchMap((departmentId) => this.execSubscription(departmentId))
    );
  }

  execSubscription(departmentId: number) {
    return combineLatest([
      this.apollo
        .subscribe<{ patient_department_visit: UnreadConversation[] }>({
          query: unreadConversationsQuery,
          variables: { departmentId },
        })
        .pipe(
          map(({ data }) =>
            data.patient_department_visit.map((conv) => ({
              id: conv.id,
              securable_id: conv.securable_id.toString(),
              unread: conv.unseen_messages_count.aggregate.count,
              duration: conv.oldest_unseen_message_utc.pop()?.created_utc,
            }))
          )
        ),
      this.apollo
        .subscribe<{ patient_department_visit: ReadConversation[] }>({
          query: readConversationsQuery,
          variables: { departmentId },
        })
        .pipe(
          map(({ data }) =>
            data.patient_department_visit.map((conv) => ({
              id: conv.id,
              securable_id: conv.securable_id.toString(),
              duration: conv.newest_seen_message_utc.pop()?.created_utc,
            }))
          )
        ),
    ]).pipe(
      map(([unreadData, readData]) => [...unreadData, ...readData]),
      switchMap((data) => this.replaceSecurableIds(data))
    );
  }

  replaceSecurableIds(data: Secure<Conversation>[]) {
    const securableIds = data.map(({ securable_id }) => securable_id);
    return this.securableCache
      .get(securableIds)
      .pipe(map((secData) => this.extractSecurableData(data, secData)));
  }

  extractSecurableData(
    data: Secure<Conversation>[],
    secData: SecureData<Patient>[]
  ): ConversationWithName[] {
    return data.map(({ securable_id, ...item }) => {
      const secItem = secData.find(({ id }) => id === securable_id);
      const name = [secItem.body.last_name, secItem.body.first_name]
        .filter((str) => !!str)
        .join(', ');
      return { ...item, name };
    });
  }
}
