import { getRouterState } from '@frontend/common/ph-router-store';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Dictionary } from '@ngrx/entity/src/models';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { BaseEntity } from './base.entity';
import { AbstractState } from './base.reducer';

export class BaseSelectors<
  TEntity extends BaseEntity,
  TState extends AbstractState<TEntity> = AbstractState<TEntity>
> {
  protected readonly entityName: string;
  protected readonly adapter: EntityAdapter<TEntity> = createEntityAdapter<TEntity>();

  constructor(entityType: new () => TEntity) {
    const instance = new entityType();
    this.entityName = Reflect.getMetadata('entityName', instance.constructor);
    const feature = Reflect.getMetadata('feature', instance.constructor);
    if (!feature || !this.entityName) {
      throw new Error(`${instance} @Feature and @Entity decorators are required`);
    }
    this.getState = createFeatureSelector<TState>(feature);
    this.getCollectionLoading = createSelector(
      this.getState,
      (state: TState) => state.loading
    );
    this.getCollectionLoaded = createSelector(
      this.getState,
      (state: TState) => state.loaded
    );
    this.getEntities = createSelector(
      this.getState,
      this.adapter.getSelectors().selectEntities
    );
    this.getAll = createSelector(this.getState, this.adapter.getSelectors().selectAll);
    this.getTotal = createSelector(
      this.getState,
      this.adapter.getSelectors().selectTotal
    );
    this.getOneByStateParam = createSelector(
      this.getEntities,
      getRouterState,
      (entities, router): TEntity => {
        if (entities) {
          return entities[router.state.params[`${this.entityName}_id`]];
        }
        return null;
      }
    );
    this.getAllWithError = createSelector(this.getAll, (items) =>
      items.filter((item) => item.error)
    );
    this.getAllPending = createSelector(this.getAll, (items) =>
      items.filter((item) => item.pending)
    );

    this.getEntitiesIds = createSelector(
      this.getState,
      this.adapter.getSelectors().selectIds
    );
  }

  getState: MemoizedSelector<{}, TState>;
  getEntitiesIds: MemoizedSelector<{}, any[]>;
  getCollectionLoading: MemoizedSelector<{}, boolean>;
  getCollectionLoaded: MemoizedSelector<{}, boolean>;
  getEntities: MemoizedSelector<{}, Dictionary<TEntity>>;
  getAll: MemoizedSelector<{}, TEntity[]>;
  getTotal: MemoizedSelector<{}, number>;
  getOneByStateParam: MemoizedSelector<{}, TEntity>;
  getAllWithError: MemoizedSelector<{}, TEntity[]>;
  getAllPending: MemoizedSelector<{}, TEntity[]>;
}
