import { Injectable } from '@angular/core';
import { SecurableCacheService } from '@frontend/common/util';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocationNetworkModel, PatientNetworkModel } from '../data-models';
@Injectable()
export class SecurableService {
  constructor(private securableCache: SecurableCacheService) {}

  getSecureDataFromLocations(locations: LocationNetworkModel[]) {
    const securableIds = this.getSecurableIdsFromLocations(locations);
    if (!securableIds.length) {
      return of(locations);
    }
    return this.securableCache.get(securableIds).pipe(
      map((securableItems) =>
        locations.map((loc) => {
          const patients = loc.patients
            .map((patient) => {
              const recipients = patient.recipients.map((rec) =>
                extractSecurableData(rec, securableItems)
              );
              return { ...patient, recipients };
            })
            .map((patient) => extractSecurableData(patient, securableItems));
          return { ...loc, patients };
        })
      )
    );
  }

  getSecureDataFromPatients(patients: PatientNetworkModel[]) {
    const securableIds = this.getSecurableIds(patients);
    if (!securableIds.length) {
      return of(patients);
    }
    return this.securableCache.get(securableIds).pipe(
      map((securableItems) =>
        patients
          .map((patient) => {
            const recipients = patient.recipients.map((rec) =>
              extractSecurableData(rec, securableItems)
            );
            return { ...patient, recipients };
          })
          .map((patient) => extractSecurableData(patient, securableItems))
      )
    );
  }

  getSecurableIdsFromLocations(locations: LocationNetworkModel[]) {
    return locations.reduce(
      (locationAcc, loc) => locationAcc.concat(this.getSecurableIds(loc.patients)),
      []
    );
  }

  getSecurableIds(patients: PatientNetworkModel[]) {
    return patients.reduce(
      (patientsAcc, patient) =>
        patientsAcc.concat(
          patient.recipients.reduce(
            (recipientsAcc, rec) => recipientsAcc.concat(rec.securable_id),
            [patient.securable_id]
          )
        ),
      []
    );
  }
}

function extractSecurableData(item, securableItems) {
  const securableItemRaw = securableItems.find(
    (securableItem) => securableItem.id === item.securable_id.toString()
  );
  const securableData = manipulateSecurableData(securableItemRaw?.body);
  return { ...item, securableData };
}

function manipulateSecurableData(data?: { first_name?: string; last_name?: string }) {
  const fullName = [data?.last_name, data?.first_name]
    .filter((part) => !!part)
    .join(', ');
  return { ...data, fullName };
}
