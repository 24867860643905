import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { QuickMessage } from './quick-message.model';

export enum QuickMessageActionTypes {
  LoadQuickMessages = '[QuickMessage] Load QuickMessages',
  AddQuickMessage = '[QuickMessage] Add QuickMessage',
  UpsertQuickMessage = '[QuickMessage] Upsert QuickMessage',
  AddQuickMessages = '[QuickMessage] Add QuickMessages',
  UpsertQuickMessages = '[QuickMessage] Upsert QuickMessages',
  UpdateQuickMessage = '[QuickMessage] Update QuickMessage',
  UpdateQuickMessages = '[QuickMessage] Update QuickMessages',
  DeleteQuickMessage = '[QuickMessage] Delete QuickMessage',
  DeleteQuickMessages = '[QuickMessage] Delete QuickMessages',
  ClearQuickMessages = '[QuickMessage] Clear QuickMessages',
}

export class LoadQuickMessages implements Action {
  readonly type = QuickMessageActionTypes.LoadQuickMessages;

  constructor(public payload: { quickMessages: QuickMessage[] }) {}
}

export class AddQuickMessage implements Action {
  readonly type = QuickMessageActionTypes.AddQuickMessage;

  constructor(public payload: { quickMessage: QuickMessage }) {}
}

export class UpsertQuickMessage implements Action {
  readonly type = QuickMessageActionTypes.UpsertQuickMessage;

  constructor(public payload: { quickMessage: QuickMessage }) {}
}

export class AddQuickMessages implements Action {
  readonly type = QuickMessageActionTypes.AddQuickMessages;

  constructor(public payload: { quickMessages: QuickMessage[] }) {}
}

export class UpsertQuickMessages implements Action {
  readonly type = QuickMessageActionTypes.UpsertQuickMessages;

  constructor(public payload: { quickMessages: QuickMessage[] }) {}
}

export class UpdateQuickMessage implements Action {
  readonly type = QuickMessageActionTypes.UpdateQuickMessage;

  constructor(public payload: { quickMessage: Update<QuickMessage> }) {}
}

export class UpdateQuickMessages implements Action {
  readonly type = QuickMessageActionTypes.UpdateQuickMessages;

  constructor(public payload: { quickMessages: Update<QuickMessage>[] }) {}
}

export class DeleteQuickMessage implements Action {
  readonly type = QuickMessageActionTypes.DeleteQuickMessage;
  constructor(public payload: { id: string }) {}
}

export class DeleteQuickMessages implements Action {
  readonly type = QuickMessageActionTypes.DeleteQuickMessages;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearQuickMessages implements Action {
  readonly type = QuickMessageActionTypes.ClearQuickMessages;
}

export type QuickMessageActions =
  | LoadQuickMessages
  | AddQuickMessage
  | UpsertQuickMessage
  | AddQuickMessages
  | UpsertQuickMessages
  | UpdateQuickMessage
  | UpdateQuickMessages
  | DeleteQuickMessage
  | DeleteQuickMessages
  | ClearQuickMessages;
