import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EstimatedDurationPipe } from './estimated-duration.pipe';
import { FormatTimePipe } from './format-time.pipe';
import { TimeDiffPipe } from './time-diff.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [TimeDiffPipe, EstimatedDurationPipe, FormatTimePipe],
  providers: [TimeDiffPipe, EstimatedDurationPipe, FormatTimePipe],
  exports: [TimeDiffPipe, EstimatedDurationPipe, FormatTimePipe],
})
export class ComponentToolsModule {}
