import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TextMessageApiEffects } from './text-message-api/text-message-api.effects';
import { TextMessageWsEffects } from './text-message-ws/text-message-ws.effects';
import * as fromTextMessage from './text-message/text-message.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('textMessage', fromTextMessage.reducer),
    EffectsModule.forFeature([TextMessageApiEffects, TextMessageWsEffects]),
  ],
})
export class SharedTextMessageDataAccessModule {}
