import { ChangeDetectionStrategy, Component, forwardRef, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export class PatientFormValue {
  public first_name: string;
  public last_name: string;
  public secured_patient_name: string;
  public phone_number: string;
  public mrn: string;
  public tag_id: number;
  public note: string;
  public additional_note: string;

  constructor(formValue: PatientFormValue) {
    return formValue;
  }
}

@Component({
  selector: 'frontend-patient-form',
  templateUrl: 'patient-form.component.html',
  styleUrls: ['patient-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PatientFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PatientFormComponent),
      multi: true,
    },
  ],
})
export class PatientFormComponent implements ControlValueAccessor, OnDestroy {
  form: FormGroup;

  subscriptions: Subscription[] = [];

  set value(value: PatientFormValue) {
    const val = new PatientFormValue(value);
    this.form.patchValue(val);
    this.onChange(val);
    this.onTouched();
  }

  constructor(private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      secured_patient_name: ['', Validators.required],
      phone_number: ['', this.phoneValidator()],
      mrn: [''],
      tag_id: ['', [Validators.pattern(/^\w+$/)]],
      note: ['', Validators.maxLength(250)],
      additional_note: ['', Validators.maxLength(250)],
    });

    this.subscriptions.push(
      this.form.valueChanges.subscribe((value) => {
        this.onChange(value);
        this.onTouched();
      })
    );

    this.subscriptions.push(
      this.form.controls.tag_id.valueChanges
        .pipe(debounceTime(500))
        .subscribe((val: string) => {
          if (val?.indexOf('T') > -1) {
            const asNumber = +val.split('T').pop();
            if (!isNaN(asNumber)) {
              this.form.controls.tag_id.patchValue(asNumber.toString());
            }
          }
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.form.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  validate() {
    return this.form.valid ? null : { patient: { valid: false } };
  }

  setDisabledState(isDisabled) {
    if (isDisabled) {
      Object.values(this.form.controls).forEach((ctrl) => ctrl.disable());
    } else {
      Object.values(this.form.controls).forEach((ctrl) => ctrl.enable());
    }
  }

  calculateSecureName() {
    const f = this.form.controls.first_name.value;
    const l = this.form.controls.last_name.value;
    const secure = [f?.substring(0, 3), l?.substring(0, 1)].join(' ').trim();
    this.form.controls.secured_patient_name.setValue(secure);
  }

  private phoneValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      return this.countNumbers(control.value) === 10
        ? null
        : { phoneNumberWrongFormat: { value: control.value } };
    };
  }

  countNumbers(phoneNumber: string): number {
    return phoneNumber.replace(/[^0-9]/g, '').length;
  }
}
