<form
  autocomplete="off"
  novalidate
  action
  [formGroup]="recipientForm"
  (ngSubmit)="submit()"
>
  <mat-card-content>
    <mat-form-field class="full-width">
      <input matInput placeholder="First name" i18n-placeholder formControlName="first_name" />
    </mat-form-field>
    <mat-form-field class="full-width">
      <input matInput placeholder="Last name" i18n-placeholder formControlName="last_name" />
    </mat-form-field>
    <mat-form-field class="full-width">
      <input matInput placeholder="Relationship" i18n-placeholder formControlName="relationship" />
    </mat-form-field>
    <mat-form-field class="full-width" hintLabel="10 digit US format" i18n-hintLabel>
      <input
        matInput
        #cellPhone
        placeholder="Cell phone"
        i18n-placeholder
        formControlName="phone_number"
      />
      <mat-hint align="end">{{ countNumbers(cellPhone.value) }}/10</mat-hint>
    </mat-form-field>
    <app-information-box
      class="error"
      *ngIf="recipient && recipient.error"
      [message]="recipient.error.message"
    >
    </app-information-box>
  </mat-card-content>
  <!-- button allows to submit form with several inputs on Enter -->
  <button type="submit" class="mock-submit-button">hidden submit</button>
</form>
