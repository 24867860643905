import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  selectAll,
  selectEntities,
  selectIds,
  selectTotal,
  State,
} from './lib/workflow/workflow.reducer';

export * from './lib/shared-workflow-data-access.module';
export * from './lib/workflow-api/workflow-api.actions';
export * from './lib/workflow-api/workflow-api.effects';
export * from './lib/workflow-ws/workflow-ws.actions';
export * from './lib/workflow-ws/workflow-ws.effects';
export * from './lib/workflow/workflow.actions';
export * from './lib/workflow/workflow.model';

export const getState = createFeatureSelector<State>('workflow');

export const getWorkflows = createSelector(getState, selectAll);

export const getWorkflowEntities = createSelector(getState, selectEntities);

export const getWorkflowsIds = createSelector(getState, selectIds);

export const getWorkflowsTotal = createSelector(getState, selectTotal);

export const getEnabledWorkflows = createSelector(getWorkflows, (workflows) =>
  workflows.filter((w) => w.is_activated)
);

export const getWorkflowsLoaded = createSelector(getWorkflowsTotal, (total) => total > 0);
