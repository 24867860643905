import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UpdatePatientComponent } from './update-patient.component';

@Component({
  selector: 'update-patient-routed',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdatePatientRoutedComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.dialog
      .open(UpdatePatientComponent, { width: '1024px' })
      .afterClosed()
      .subscribe((routeArr = ['../..']) =>
        this.router.navigate(routeArr, { relativeTo: this.route })
      );
  }
}
