import { Action } from '@ngrx/store';
import { TextMessage } from '../text-message/text-message.model';

export enum TextMessageApiActionTypes {
  LoadTextMessagesApi = '[TextMessage API] Load TextMessages',
  LoadTextMessagesSuccessApi = '[TextMessage API] Load TextMessages Success',
  LoadTextMessagesFailureApi = '[TextMessage API] Load TextMessages Failure',
  LoadTextMessageApi = '[TextMessage API] Load TextMessage',
  LoadTextMessageSuccessApi = '[TextMessage API] Load TextMessage Success',
  LoadTextMessageFailureApi = '[TextMessage API] Load TextMessage Failure',
  CreateTextMessageApi = '[TextMessage API] Create TextMessage',
  CreateTextMessageSuccessApi = '[TextMessage API] Create TextMessage Success',
  CreateTextMessageFailureApi = '[TextMessage API] Create TextMessage Failure',
  UpdateTextMessageApi = '[TextMessage API] Update TextMessage',
  UpdateTextMessageSuccessApi = '[TextMessage API] Update TextMessage Success',
  UpdateTextMessageFailureApi = '[TextMessage API] Update TextMessage Failure',
  DeleteTextMessageApi = '[TextMessage API] Delete TextMessage',
  DeleteTextMessageSuccessApi = '[TextMessage API] Delete TextMessage Success',
  DeleteTextMessageFailureApi = '[TextMessage API] Delete TextMessage Failure',
}

export class LoadTextMessagesApi implements Action {
  readonly type = TextMessageApiActionTypes.LoadTextMessagesApi;
  constructor(public payload: { params: any } = { params: {} }) {}
}

export class LoadTextMessagesSuccessApi implements Action {
  readonly type = TextMessageApiActionTypes.LoadTextMessagesSuccessApi;
  constructor(public payload: { textMessages: TextMessage[] }) {}
}

export class LoadTextMessagesFailureApi implements Action {
  readonly type = TextMessageApiActionTypes.LoadTextMessagesFailureApi;
  constructor(public payload: { message: string }) {}
}

export class LoadTextMessageApi implements Action {
  readonly type = TextMessageApiActionTypes.LoadTextMessageApi;
  constructor(public payload: { id: string }) {}
}

export class LoadTextMessageSuccessApi implements Action {
  readonly type = TextMessageApiActionTypes.LoadTextMessageSuccessApi;
  constructor(public payload: { textMessage: TextMessage }) {}
}

export class LoadTextMessageFailureApi implements Action {
  readonly type = TextMessageApiActionTypes.LoadTextMessageFailureApi;
  constructor(public payload: { id: string; message: string }) {}
}

export class CreateTextMessageApi implements Action {
  readonly type = TextMessageApiActionTypes.CreateTextMessageApi;
  constructor(public payload: { textMessage: TextMessage }) {}
}

export class CreateTextMessageSuccessApi implements Action {
  readonly type = TextMessageApiActionTypes.CreateTextMessageSuccessApi;
  constructor(public payload: { textMessage: TextMessage }) {}
}

export class CreateTextMessageFailureApi implements Action {
  readonly type = TextMessageApiActionTypes.CreateTextMessageFailureApi;
  constructor(public payload: { id: string; message: string }) {}
}

export class UpdateTextMessageApi implements Action {
  readonly type = TextMessageApiActionTypes.UpdateTextMessageApi;
  constructor(public payload: { textMessage: TextMessage }) {}
}

export class UpdateTextMessageSuccessApi implements Action {
  readonly type = TextMessageApiActionTypes.UpdateTextMessageSuccessApi;
  constructor(public payload: { textMessage: TextMessage }) {}
}

export class UpdateTextMessageFailureApi implements Action {
  readonly type = TextMessageApiActionTypes.UpdateTextMessageFailureApi;
  constructor(public payload: { id: string; message: string }) {}
}

export class DeleteTextMessageApi implements Action {
  readonly type = TextMessageApiActionTypes.DeleteTextMessageApi;
  constructor(public payload: { id: string }) {}
}

export class DeleteTextMessageSuccessApi implements Action {
  readonly type = TextMessageApiActionTypes.DeleteTextMessageSuccessApi;
  constructor(public payload: { id: string }) {}
}

export class DeleteTextMessageFailureApi implements Action {
  readonly type = TextMessageApiActionTypes.DeleteTextMessageFailureApi;
  constructor(public payload: { id: string; message: string }) {}
}

export type TextMessageApiActions =
  | LoadTextMessagesApi
  | LoadTextMessagesSuccessApi
  | LoadTextMessagesFailureApi
  | LoadTextMessageApi
  | LoadTextMessageSuccessApi
  | LoadTextMessageFailureApi
  | CreateTextMessageApi
  | CreateTextMessageSuccessApi
  | CreateTextMessageFailureApi
  | UpdateTextMessageApi
  | UpdateTextMessageSuccessApi
  | UpdateTextMessageFailureApi
  | DeleteTextMessageApi
  | DeleteTextMessageSuccessApi
  | DeleteTextMessageFailureApi;
