import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MultiSelectSearchModule } from '@frontend/common/multi-select-search';
import { SharedUiSkeletonFormModule } from '@frontend/common/skeletons';
import { WaitingFiltersDialogComponent } from './waiting-filters-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
    MatListModule,
    MatRadioModule,
    MatSelectModule,
    SharedUiSkeletonFormModule,
    MultiSelectSearchModule,
  ],
  declarations: [WaitingFiltersDialogComponent],
  exports: [WaitingFiltersDialogComponent],
})
export class WaitingFiltersDialogModule {}
