import { BaseSelectors } from '@frontend/shared/base-store';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { Customer } from '../customer.model';
import { CustomersState } from './customers.reducer';

class CustomersSelectors extends BaseSelectors<Customer, CustomersState> {
  constructor() {
    super(Customer);
    this.getCurrentCustomer = createSelector(
      this.getState,
      this.getEntities,
      (state, entities) => entities[state.currentCustomerId]
    );
    this.getCurrentCustomerLoading = createSelector(
      this.getState,
      (state) => state.loading
    );
    this.getCurrentCustomerLoaded = createSelector(
      this.getState,
      (state) => state.loaded
    );
    this.getTimezone = createSelector(
      this.getState,
      this.getEntities,
      (state, entities) => entities[state.currentCustomerId]?.default_timezone ?? ''
    );
  }

  getCurrentCustomer: MemoizedSelector<{}, Customer>;
  getCurrentCustomerLoading: MemoizedSelector<{}, boolean>;
  getCurrentCustomerLoaded: MemoizedSelector<{}, boolean>;
  getTimezone: MemoizedSelector<{}, string>;
}

export const customersSelectors = new CustomersSelectors();
