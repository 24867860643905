import { BaseSelectors } from '@frontend/shared/base-store';
import { createSelector, MemoizedSelector, MemoizedSelectorWithProps } from '@ngrx/store';
import { Location } from '../../locations/location.model';
import { locationsSelectors } from '../../locations/store/locations.selectors';
import { Department } from '../department.model';

class DepartmentsSelectors extends BaseSelectors<Department> {
  constructor() {
    super(Department);

    this.getCurrentDepartmentLocations = createSelector(
      this.getOneByStateParam,
      locationsSelectors.getAll,
      (department, locations) =>
        department && department.locationIds
          ? locations.filter((l) => department.locationIds.some((dl) => dl === l.id))
          : []
    );

    this.getDepartmentTimezone = createSelector(
      this.getOneByStateParam,
      (department) => department && department.site.default_timezone
    );

    this.getDepartmentById = createSelector(
      this.getEntities,
      (departments, id: string) => departments[id]
    );
  }

  public getCurrentDepartmentLocations: MemoizedSelector<{}, Location[]>;
  public getDepartmentTimezone: MemoizedSelector<{}, string>;
  public getDepartmentById: MemoizedSelectorWithProps<{}, string, Department>;
}

export const departmentsSelectors = new DepartmentsSelectors();
