import gql from 'graphql-tag';

export interface DepartmentSelectorDataQuery {
  department: DepartmentDataQuery[];
}

export interface DepartmentDataQuery {
  id: number;
  name: string;
  site: {
    id: number;
    name: string;
  };
}

export const DepartmentSelectorDataQuery = gql(`
query DepartmentSelectorDataQuery {
  department {
    id
    name
    site {
      id
      name
    }
  }
}
`);
