import { Visit } from './visit-detail.graphql';

export interface ModuleSelection {
  status: boolean;
  workflows: boolean;
  appointments: boolean;
  milestones: boolean;
  recipients: boolean;
}

export function preferencesManipulation(preferences?: ModuleSelection): ModuleSelection {
  if (!preferences) {
    return {
      status: true,
      workflows: true,
      appointments: true,
      milestones: true,
      recipients: true,
    };
  }
  return {
    status: !!preferences?.status,
    workflows: !!preferences?.workflows,
    appointments: !!preferences?.appointments,
    milestones: !!preferences?.milestones,
    recipients: !!preferences?.recipients,
  };
}

export interface CrossModuleData {
  visitCompleted: boolean;
}

export function visitManipulation(visit: Visit): CrossModuleData {
  const visitCompleted = !!visit.completed_utc || !!visit.deleted_utc;
  return { visitCompleted };
}
