import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserNetworkModel } from '../data-models';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class UsersCacheService {
  private cache: Map<string | number, UserNetworkModel> = new Map();
  private cacheExpiry: number = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
  private lastCacheUpdate: Date = new Date();

  constructor(private http: HttpClient) {}

  get(ids: (string | number)[]) {
    const currentTime = new Date();
    if (currentTime.getTime() - this.lastCacheUpdate.getTime() > this.cacheExpiry) {
      this.clearCache();
    }

    const newIds: string[] = [];
    const cachedUsers: UserNetworkModel[] = [];

    for (const id of ids) {
      const idStr = id.toString();
      const cached = this.cache.get(idStr);
      if (cached) {
        cachedUsers.push(cached);
      } else {
        newIds.push(idStr);
      }
    }

    if (newIds.length === 0) {
      return of(cachedUsers);
    }

    return this.getFromNetwork(newIds).pipe(
      tap((freshUsers) => this.updateCache(freshUsers)),
      map((freshUsers) => [...cachedUsers, ...freshUsers])
    );
  }

  private getFromNetwork(id: string[]): Observable<UserNetworkModel[]> {
    return this.http.get<UserNetworkModel[]>('users', { params: { id } });
  }

  private clearCache() {
    this.cache.clear();
  }

  private updateCache(users: UserNetworkModel[]) {
    for (const user of users) {
      this.cache.set(user.id, user);
    }
    this.lastCacheUpdate = new Date();
  }
}
