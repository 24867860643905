import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SocketService } from '@frontend/common/util';
import { merge, of, race, throwError } from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  first,
  ignoreElements,
  map,
  mergeMap,
  switchMap,
} from 'rxjs/operators';
import * as uuid from 'uuid/v4';
import { Patient } from '../patient/patient.model';

interface Securable {
  first_name?: string;
  last_name?: string;
  mrn?: string;
  phone_number?: string;
}

@Injectable({
  providedIn: 'root',
})
export class PatientApiService {
  constructor(private http: HttpClient, private socketSrv: SocketService) {}

  public create(entity: Patient) {
    const correlation_id = entity.id || 'correlation|' + uuid();
    return this.createSecureData(entity).pipe(
      map(({ id: securable_id }: any) => {
        const patientWithPHI: any = { ...entity, correlation_id, securable_id };
        const { last_name, mrn, phone_number, cellphone, ...rest } = patientWithPHI;
        return rest;
      }),
      concatMap((payload) =>
        merge(
          this.http.post('patients', payload).pipe(
            ignoreElements(),
            catchError((err) =>
              of(err?.error?.message).pipe(
                mergeMap((message) => throwError({ id: entity.id, message }))
              )
            )
          ),
          race(
            this.socketSrv
              .fromLocalSuccess<any>('patients.create')
              .pipe(filter((ws) => ws.correlation_id === correlation_id)),
            this.socketSrv.fromLocalError('patients.create').pipe(
              filter((ws) => ws.correlation_id === correlation_id),
              mergeMap(({ errorCode }) => throwError({ id: entity.id, errorCode }))
            )
          ).pipe(first())
        )
      )
    );
  }

  private createSecureData({ first_name, last_name, mrn, phone_number }: Securable) {
    return this.http.post('securable', { first_name, last_name, mrn, phone_number });
  }

  public delete(id) {
    const correlation_id = 'correlation|' + uuid();
    return merge(
      this.http.request('delete', `patients/${id}`, { body: { correlation_id } }).pipe(
        ignoreElements(),
        catchError((err) =>
          of(err?.error?.message).pipe(mergeMap((message) => throwError({ id, message })))
        )
      ),
      race(
        this.socketSrv
          .fromLocalSuccess<any>('patients.remove')
          .pipe(filter((ws) => ws.correlation_id === correlation_id)),
        this.socketSrv.fromLocalError('patients.remove').pipe(
          filter((ws) => ws.correlation_id === correlation_id),
          mergeMap(({ message }) => throwError({ id, message }))
        )
      ).pipe(first())
    );
  }

  public get(params?: { [key: string]: string | string[] }) {
    return this.http.get<Patient[]>('patients', { params });
  }

  public getCompleted(params?: { [key: string]: string | string[] }) {
    return this.http.get<Patient[]>('patients/history', { params }).pipe(
      switchMap((patients) =>
        this.getSecureMultiple(patients.map((p) => p.securable_id)).pipe(
          map((secData: any): Patient[] => [
            ...patients.map((p) => ({
              ...p,
              ...secData.find((s) => s.id === p.securable_id).body,
            })),
          ])
        )
      )
    );
  }

  public getOne(id) {
    return this.http.get<Patient>(`patients/${id}`).pipe(
      switchMap((patient) =>
        this.getSecure(patient.securable_id).pipe(
          map((sec: { body: any }) => ({
            ...patient,
            first_name: sec.body.first_name,
            last_name: sec.body.last_name,
          }))
        )
      )
    );
  }

  public getSecure(id: string) {
    return this.http.get(`securable/${id}`);
  }

  public getSecureMultiple(id: string[]) {
    return this.http.post('securable/get-bulk', { id });
  }

  public update(entity) {
    const correlation_id = 'correlation|' + uuid();
    return this.createSecureData(entity).pipe(
      map(({ id: securable_id }: any) => {
        const patientWithPHI: any = { ...entity, correlation_id, securable_id };
        const { last_name, mrn, phone_number, cellphone, ...rest } = patientWithPHI;
        return rest;
      }),
      concatMap((payload) =>
        merge(
          this.http.put(`patients/${entity.id}`, payload).pipe(
            ignoreElements(),
            catchError((err) =>
              of(err?.error?.message).pipe(
                mergeMap((message) => throwError({ id: entity.id, message }))
              )
            )
          ),
          race(
            this.socketSrv
              .fromLocalSuccess<any>('patients.update')
              .pipe(filter((ws) => ws.correlation_id === correlation_id)),
            this.socketSrv.fromLocalError('patients.update').pipe(
              filter((ws) => ws.correlation_id === correlation_id),
              mergeMap(({ message }) => throwError({ id: entity.id, message }))
            )
          ).pipe(first())
        )
      )
    );
  }
}
