import { WorkflowTypeId } from '@frontend/common/util';
import { BaseEntity, Entity, Feature } from '@frontend/shared/base-store';

@Entity('workflow')
@Feature('workflows')
export class Workflow extends BaseEntity {
  id: number;
  name: string;
  department_id: number;
  phase_ids: number[];
  is_activated: boolean;
  is_locked: boolean;
  is_notification_enabled: boolean;
  max_unattended_low_sec: number;
  max_unattended_high_sec: number;
  workflow_type_id: WorkflowTypeId;
  is_messaging_patient_enabled: boolean;
  is_department_switch_trigger: boolean;
}
