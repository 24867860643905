import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  Input,
} from '@angular/core';
import { Operation } from '../models/operation.model';

@Component({
  selector: 'bulk-uploader-operation',
  templateUrl: './bulk-uploader-operation.component.html',
  styleUrls: ['./bulk-uploader-operation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkUploaderOperationComponent {
  @Input() op: Operation;
  @Output() clearOperation: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() exportErrors: EventEmitter<Event> = new EventEmitter<Event>();
}
