import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { ConfirmationDialogsModule } from '@frontend/common/confirmation-dialog';
import { SharedPatientDataAccessModule } from '@frontend/shared/patient/data-access/src';
import { SharedQuickMessageDataAccessModule } from '@frontend/shared/quick-message/data-access/src';
import { SharedTextMessageDataAccessModule } from '@frontend/shared/text-message/data-access/src';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ChangePhaseService } from './change-phase.service';
import { ChatRoutedComponent } from './chat/chat-routed.component';
import { ChatSmartComponent } from './chat/chat-smart/chat-smart.component';
import { ChatSmartEffects } from './chat/chat-smart/chat-smart.effects';
import { NoConversationComponent } from './chat/no-conversation.component';
import { CreatePatientRoutedComponent } from './create-patient/create-patient-routed.component';
import { CreatePatientModule } from './create-patient/create-patient.module';
import { EditAppointmentRoutedComponent } from './edit-appointment/edit-appointment-routed.component';
import { EditAppointmentModule } from './edit-appointment/edit-appointment.module';
import { EndVisitService } from './end-visit.service';
import { ManageAppointmentsRoutedComponent } from './manage-appointments/manage-appointments-routed.component';
import { ManageAppointmentsModule } from './manage-appointments/manage-appointments.module';
import { ManageWorkflowsRoutedComponent } from './manage-workflows/manage-workflows-routed.component';
import { ManageWorkflowsModule } from './manage-workflows/manage-workflows.module';
import { PageComponent } from './page/page.component';
import { PageModule } from './page/page.module';
import { PageService } from './page/page.service';
import { PatientsService } from './page/patients.service';
import { SecurableService } from './page/securable.service';
import { metaReducers, reducers } from './reducers';
import { UpdatePatientRoutedComponent } from './update-patient/update-patient-routed.component';
import { UpdatePatientModule } from './update-patient/update-patient.module';

const workflowRtFeaturePatientsRoutes: Route[] = [
  {
    path: '',
    component: PageComponent,
    children: [
      {
        path: 'chat/staff',
        data: {
          parentRoute: '../../../../',
        },
        loadChildren: () =>
          import('@frontend/workflow-rt/feature/staff-chat').then(
            (m) => m.WorkflowRtFeatureStaffChatModule
          ),
      },
      {
        path: 'new',
        component: CreatePatientRoutedComponent,
      },
      {
        path: 'recipients-chat/:patient_id',
        data: {
          parentRoute: '../',
        },
        loadChildren: () =>
          import('@frontend/workflow-rt/feature/recipients-chat').then(
            (m) => m.WorkflowRtFeatureRecipientsChatModule
          ),
      },
      {
        path: 'chat',
        component: ChatRoutedComponent,
        children: [
          {
            path: '',
            component: NoConversationComponent,
          },
          {
            path: ':patient_id',
            component: ChatSmartComponent,
          },
        ],
      },
      {
        path: ':patient_id',
        component: UpdatePatientRoutedComponent,
      },
      {
        path: ':patient_id/manage-workflows',
        component: ManageWorkflowsRoutedComponent,
      },
      {
        path: ':patient_id/appointments',
        component: ManageAppointmentsRoutedComponent,
      },
      {
        path: ':patient_id/appointments/:appointment_id',
        component: EditAppointmentRoutedComponent,
      },
    ],
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(workflowRtFeaturePatientsRoutes),
    ConfirmationDialogsModule,
    SharedPatientDataAccessModule,
    SharedTextMessageDataAccessModule,
    SharedQuickMessageDataAccessModule,
    StoreModule.forFeature('patientsSection', reducers, { metaReducers }),
    EffectsModule.forFeature([ChatSmartEffects]),
    PageModule,
    CreatePatientModule,
    UpdatePatientModule,
    ManageWorkflowsModule,
    ManageAppointmentsModule,
    EditAppointmentModule,
  ],
  providers: [
    PageService,
    SecurableService,
    ChangePhaseService,
    PatientsService,
    EndVisitService,
  ],
})
export class WorkflowRtFeaturePatientsModule {}
