import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CurrentUserService } from '@frontend/common/util';
import { DepartmentSelectorService } from './department-selector.service';

export interface CmptData {
  departments: Department[];
}

export interface Department {
  id: number;
  name: string;
  siteName: string;
}

@Component({
  selector: 'wrt-department-selector',
  templateUrl: './department-selector.component.html',
  styleUrls: ['./department-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DepartmentSelectorService],
})
export class DepartmentSelectorComponent {
  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<DepartmentSelectorComponent>,
    private srv: DepartmentSelectorService,
    private currentUserSrv: CurrentUserService
  ) {}

  get filteredItems$() {
    return this.srv.filteredItems$;
  }

  get searchValue$() {
    return this.srv.searchValue$;
  }

  selectDepartment(dep) {
    this.currentUserSrv.save('department_id', dep.id);
    this.dialogRef.close();
    // TODO: Most of the sections are not ready to react properly when in the route just the department id changes.
    // const urlParts = this.router.url.split('/');
    // const urlPartsWithoutDepartment = urlParts.slice(2);
    // const newUrlParts = [dep.id].concat(urlPartsWithoutDepartment);
    // this.router.navigate(newUrlParts);
    this.router.navigate([dep.id]);
  }
}
