import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getParams } from '@frontend/common/ph-router-store';
import { ExpectedError, SocketService } from '@frontend/common/util';
import { createSelector, Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { utc } from 'moment-timezone';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  merge,
  Observable,
  of,
  race,
  throwError,
} from 'rxjs';
import {
  catchError,
  filter,
  first,
  ignoreElements,
  map,
  mapTo,
  mergeMap,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs/operators';
import * as uuid from 'uuid/v4';
import {
  AppointmentTypeItem,
  AppointmentTypesDataQuery,
  DepartmentDataQuery,
  Patient,
  PatientDataQuery,
  ProviderItem,
  ProvidersDataQuery,
} from './manage-appointments.graphql';
import { SecurableService } from './securable.service';

interface QueryVariables {
  department_id: number;
  patient_id: number;
}

export interface AppointmentItem {
  id: number;
  timeAndProvider: string;
  apptTypeName: string;
}

interface ManipulatedProviderItem extends ProviderItem {
  name: string;
}

const dialogInput = createSelector(getParams, ({ department_id, patient_id }) => ({
  department_id,
  patient_id,
}));

@Injectable()
export class ManageAppointmentsService {
  _param: Observable<QueryVariables>;
  _refresh$: BehaviorSubject<null> = new BehaviorSubject(null);
  loading: Observable<boolean>;
  patientRaw: Observable<Patient>;
  patient: Observable<Patient>;
  timezone: Observable<string>;
  providerList: Observable<ProviderItem[]>;
  appointmentTypeList: Observable<AppointmentTypeItem[]>;
  appointmentList: Observable<AppointmentItem[]>;

  constructor(
    private store: Store,
    private apollo: Apollo,
    private securableSrv: SecurableService,
    private http: HttpClient,
    private socketSrv: SocketService
  ) {
    this._param = this.store.select(dialogInput).pipe(first());
    this.patientRaw = this._param.pipe(
      switchMap((params) => this._refresh$.pipe(mapTo(params))),
      mergeMap((params) => this._getPatientById(params.patient_id)),
      shareReplay(1)
    );
    this.patient = this.patientRaw.pipe(catchError(() => EMPTY));
    this.timezone = this._param.pipe(
      switchMap((params) => this._getTimezone(params.department_id)),
      shareReplay(1)
    );
    this.providerList = this._param.pipe(
      switchMap((params) => this._getProviders(params.department_id)),
      shareReplay(1)
    );
    this.appointmentTypeList = this._param.pipe(
      switchMap((params) => this._getAppointmentTypes(params.department_id)),
      shareReplay(1)
    );
    this.appointmentList = combineLatest([
      this.patient,
      this.providerList,
      this.appointmentTypeList,
      this.timezone,
    ]).pipe(map(appointmentMaker), shareReplay(1));
    this.loading = merge(
      this._param.pipe(mapTo(true)),
      combineLatest([this.patient, this.providerList, this.appointmentTypeList]).pipe(
        mapTo(false)
      )
    ).pipe(startWith(true));
  }

  refreshAppointmentList() {
    this._refresh$.next(null);
  }

  createAppointment(appointment) {
    const correlation_id = 'correlation|' + uuid();
    const payload = { ...appointment, correlation_id };
    return merge(
      this.http.post('patient-appointments', payload).pipe(
        ignoreElements(),
        catchError((err) =>
          of(err?.error?.message).pipe(
            mergeMap((message) => throwError({ id: correlation_id, message }))
          )
        )
      ),
      race(
        this.socketSrv
          .fromLocalSuccess<any>('patient-appointments.create')
          .pipe(filter((ws) => ws.correlation_id === correlation_id)),
        this.socketSrv.fromLocalError('patient-appointments.create').pipe(
          filter((ws) => ws.correlation_id === correlation_id),
          mergeMap(({ message }) => throwError({ id: correlation_id, message }))
        )
      ).pipe(first())
    );
  }

  deleteAppointment(id: number) {
    const correlation_id = 'correlation|' + uuid();
    return merge(
      this.http
        .request('delete', `patient-appointments/${id}`, { body: { correlation_id } })
        .pipe(
          ignoreElements(),
          catchError((err) =>
            of(err?.error?.message).pipe(
              mergeMap((message) => throwError({ id, message }))
            )
          )
        ),
      race(
        this.socketSrv
          .fromLocalSuccess<any>('patient-appointments.remove')
          .pipe(filter((ws) => ws.correlation_id === correlation_id)),
        this.socketSrv.fromLocalError('patient-appointments.remove').pipe(
          filter((ws) => ws.correlation_id === correlation_id),
          mergeMap(({ message }) => throwError({ id, message }))
        )
      ).pipe(first())
    );
  }

  _getPatientById(patientId: number) {
    return this.apollo
      .subscribe<PatientDataQuery>({
        query: PatientDataQuery,
        variables: { patientId },
      })
      .pipe(
        map((result) => {
          if (!result.data.patient_department_visit.length) {
            throw new ExpectedError($localize`Patient does not exist.`);
          }
          return result.data.patient_department_visit[0];
        }),
        switchMap((patient) => this.securableSrv.get(patient))
      );
  }

  _getTimezone(departmentId: number) {
    return this.apollo
      .subscribe<DepartmentDataQuery>({
        query: DepartmentDataQuery,
        variables: { departmentId },
      })
      .pipe(map(({ data }) => data?.department?.[0].site.timezone.id));
  }

  _getProviders(departmentId) {
    return this.apollo
      .subscribe<ProvidersDataQuery>({
        query: ProvidersDataQuery,
        variables: { departmentId },
      })
      .pipe(map((patient) => providersManipulation(patient.data.staff)));
  }

  _getAppointmentTypes(departmentId) {
    return this.apollo
      .subscribe<AppointmentTypesDataQuery>({
        query: AppointmentTypesDataQuery,
        variables: { departmentId },
      })
      .pipe(map((patient) => patient.data.appointment_type));
  }
}

function appointmentMaker([patient, providers, apptTypes, timezone]: [
  Patient,
  ManipulatedProviderItem[],
  AppointmentTypeItem[],
  string
]): AppointmentItem[] {
  return patient.patient_appointments.map<AppointmentItem>((appt) => {
    const provider = providers.find(({ id }) => id === appt.provider_id);
    const apptType = apptTypes.find(({ id }) => id === appt.appointment_type_id);
    const apptTime = formatApptTime(appt.appointment_time_utc, timezone);
    const timeAndProvider = [apptTime, provider?.name].filter((item) => !!item).join(' ');
    return { id: appt.id, timeAndProvider, apptTypeName: apptType?.name };
  });
}

function formatApptTime(time: string, timezone: string): string {
  const m = utc(time).tz(timezone);
  if (time && m.isValid()) {
    return m.format('hh:mm A MM/DD/YYYY');
  }
}

function providersManipulation(providers: ProviderItem[]): ManipulatedProviderItem[] {
  return providers
    .map((provider) => {
      const name: string = [provider?.last_name, provider?.first_name]
        .filter((s) => !!s)
        .join(', ');
      return { ...provider, name };
    })
    .sort((a, b) => a.name.localeCompare(b.name));
}
