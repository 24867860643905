import { AbstractState, BaseAction, ReducerFactory } from '@frontend/shared/base-store';
import { Customer } from '../customer.model';
import { customersActions } from './customers.actions';

export interface CustomersState extends AbstractState<Customer> {
  currentCustomerId: number;
}

class CustomersReducerFactory extends ReducerFactory<Customer> {
  initialState = {
    loaded: false,
    loading: false,
    currentCustomerId: null,
  };

  constructor() {
    super(Customer);
  }

  getReducer() {
    return (
      state: CustomersState = this.adapter.getInitialState(this.initialState),
      a: BaseAction<Customer>
    ) => {
      switch (a.type) {
        case customersActions.types.TOGGLE:
          return this.adapter.updateOne(
            {
              id: a.payload.id,
              changes: { is_blocked: !a.payload.is_blocked },
            },
            state
          );
        case customersActions.types.LOAD_ONE_SUCCESS:
          state = { ...state, currentCustomerId: a.payload.id };
          return this.adapter.addOne(a.payload, state);
        default:
          return super.getReducer()(state, a);
      }
    };
  }
}

const reducerFunction = new CustomersReducerFactory().getReducer();

export function reducer(state, action) {
  return reducerFunction(state, action);
}
