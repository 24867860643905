import * as moment from 'moment';
import { Moment } from 'moment-timezone';

interface FormOutput {
  provider_id: number;
  appointment_type_id: number;
  date: Moment;
  time: string;
  period: string[];
}

export function manipulateFormOutput(
  { date, time, period, ...rest }: FormOutput,
  timezone: string
) {
  if (!date || !time || !period) {
    return { appointment_time_utc: null, ...rest };
  }
  const joined = [date.format('MM/DD/YYYY'), time, period[0]].join();
  const appointment_time_utc = moment
    .tz(joined, 'MM/DD/YYYY,hh:mm,A', true, timezone)
    .utc()
    .format();
  return { appointment_time_utc, ...rest };
}
