import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { AccountDisableGuard } from './account-disable.guard';
import { AccountDisableComponent } from './account-disable/account-disable.component';
@NgModule({
  imports: [CommonModule, MatIconModule],
  declarations: [AccountDisableComponent],
  exports: [AccountDisableComponent],
  providers: [AccountDisableGuard],
})
export class UiAccountDisableModule {}
