import gql from 'graphql-tag';

export interface VisitDataQuery {
  patient: Visit;
}

export interface Visit {
  id: number;
  completed_utc: string;
  deleted_utc: string;
}

export const VisitDataQuery = gql(`
query VisitDataQuery($visitId: String!) {
  patient(id: $visitId) {
    id
    completed_utc
    deleted_utc
  }
}
`);
