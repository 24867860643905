import { BaseSelectors } from '@frontend/shared/base-store';
import { Patient } from '../patient.model';
import { createSelector } from '@ngrx/store';
import { MemoizedSelectorWithProps } from '@ngrx/store/src/selector';

class PatientsSelectors extends BaseSelectors<Patient> {
  constructor() {
    super(Patient);
    this.getPatientById = createSelector(this.getAll, (patients, patientId) =>
      patients.find((patient) => patient.id === patientId)
    );
  }

  public getPatientById: MemoizedSelectorWithProps<{}, any, Patient>;
}

export const patientsSelectors = new PatientsSelectors();
