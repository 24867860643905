import { Action } from '@ngrx/store';
import * as uuid from 'uuid/v4';
import { Recipient } from './recipient.model';

function correlate<T>(obj: T): T & { correlation_id: string } {
  const correlation_id = 'correlation|' + uuid();
  // https://github.com/Microsoft/TypeScript/issues/10727
  // TODO: Replace with spread operator when typescript >=3.2
  return Object.assign({}, obj, { correlation_id });
}

export enum RecipientApiActionTypes {
  Load = '[Recipient/API] Load',
  LoadFailure = '[Recipient/API] Load Failure',
  Add = '[Recipient/API] Add',
  Update = '[Recipient/API] Update',
  Delete = '[Recipient/API] Delete',
}

export class Load implements Action {
  readonly type = RecipientApiActionTypes.Load;

  constructor(public payload: { patient_id: string }) {}
}

export class LoadFailure implements Action {
  readonly type = RecipientApiActionTypes.LoadFailure;
}

export class Add implements Action {
  readonly type = RecipientApiActionTypes.Add;

  constructor(public payload: { recipient: Recipient }) {
    const correlated: Recipient = correlate(payload.recipient);
    const recipient = { ...correlated, id: correlated.correlation_id };
    this.payload = { recipient };
  }
}

export class Update implements Action {
  readonly type = RecipientApiActionTypes.Update;

  constructor(public payload: { recipient: Recipient }) {
    const recipient: Recipient = correlate(payload.recipient);
    this.payload = { recipient };
  }
}

export class Delete implements Action {
  readonly type = RecipientApiActionTypes.Delete;

  constructor(
    public payload: {
      id: string;
      correlation_id: string;
    }
  ) {
    this.payload = correlate(payload);
  }
}

export type RecipientApiActions = Load | LoadFailure | Add | Update | Delete;
