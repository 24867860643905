import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'frontend-chat-routed',
  template: `
    <ng-template #tmplRef>
      <button
        mat-button
        mat-icon-button
        mat-dialog-close
        (click)="closeDialog()"
        tabindex="-1"
        style="position: absolute; right: 0; margin: 20px 24px;"
      >
        <mat-icon>close</mat-icon>
      </button>

      <frontend-chat-wrapper>
        <router-outlet></router-outlet>
      </frontend-chat-wrapper>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatRoutedComponent implements OnInit {
  @ViewChild('tmplRef') tmplRef: TemplateRef<any>;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.dialog
        .open(this.tmplRef, {
          width: '920px',
          height: '600px',
          panelClass: 'close-btn',
          closeOnNavigation: true,
        })
        .afterClosed()
        .subscribe(() => this.router.navigate(['..'], { relativeTo: this.route }));
    });
  }

  closeDialog() {
    this.dialog.closeAll();
  }
}
