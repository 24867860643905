import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { chirp, destroy, init, updateSearch } from './tag-chirping.actions';
import { getDepartmentId, getItems, getLoaded } from './tag-chirping.selectors';

@UntilDestroy()
@Component({
  selector: 'frontend-tag-chirping',
  templateUrl: './tag-chirping.component.html',
  styleUrls: ['./tag-chirping.component.scss'],
})
export class TagChirpingComponent implements OnDestroy, OnInit {
  loaded$ = this.store.select(getLoaded);
  items$ = this.store.select(getItems);

  constructor(private store: Store) {}

  ngOnDestroy() {
    this.store.dispatch(destroy());
  }

  ngOnInit() {
    this.store
      .pipe(select(getDepartmentId), untilDestroyed(this))
      .subscribe((department_id) => this.store.dispatch(init({ department_id })));
  }

  onChirp(tag_id) {
    this.store.dispatch(chirp({ tag_id }));
  }

  onSearchUpdate(data) {
    this.store.dispatch(updateSearch({ data }));
  }
}
