import { createSelector } from '@ngrx/store';

import { BulkUploaderState } from '../bulk-uploader.reducer';
import { getBulkUploaderState } from '../bulk-uploader.selectors';
import { ConfigState } from './config.reducer';

export const getConfigState = createSelector(
  getBulkUploaderState,
  (state: BulkUploaderState) => state.config
);

export const getOverlayCreated = createSelector(
  getConfigState,
  (state: ConfigState) => state.overlayCreated
);
export const getOpen = createSelector(getConfigState, (state: ConfigState) => state.open);
