import { AbstractState, BaseAction, ReducerFactory } from '@frontend/shared/base-store';
import { Site } from '../site.model';
import { sitesActions } from './sites.actions';

class SitesReducerFactory extends ReducerFactory<Site> {
  constructor() {
    super(Site);
  }

  getReducer() {
    return (
      state: AbstractState<Site> = this.adapter.getInitialState(this.initialState),
      a: BaseAction<Site>
    ) => {
      switch (a.type) {
        case sitesActions.types.SILENT_CREATE:
          return this.adapter.addOne(a.payload, state);
        default:
          return super.getReducer()(state, a);
      }
    };
  }
}

const reducerFunction = new SitesReducerFactory().getReducer();

export function sitesReducer(state, action) {
  return reducerFunction(state, action);
}
