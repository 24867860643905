import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, OnDestroy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, skip } from 'rxjs/operators';
import { BulkUploaderComponent } from './bulk-uploader.component';
import { Operation } from './models/operation.model';
import { BulkUploaderState } from './store/bulk-uploader.reducer';
import { getOverlayCreated } from './store/config/config.selectors';
import * as operationsActions from './store/operations/operations.actions';

@UntilDestroy()
@Injectable()
export class BulkUploaderService implements OnDestroy {
  overlayRef: OverlayRef;
  upload$: Subject<{
    source$: Observable<any>;
    operation: Partial<Operation>;
  }> = new Subject();
  finishedEntityOperation$: Subject<string> = new Subject();

  constructor(private overlay: Overlay, private store: Store<BulkUploaderState>) {
    this.upload$.pipe(untilDestroyed(this)).subscribe((upload) => {
      upload.source$
        .pipe(
          filter((ws) => upload.operation.correlation_id === ws.correlation_id),
          untilDestroyed(this)
        )
        .subscribe((res) => {
          this.store.dispatch(new operationsActions.ReceivedResponse(res));
        });
      this.store.dispatch(new operationsActions.CreateOperation(upload.operation));
    });
    this.store
      .pipe(select(getOverlayCreated), untilDestroyed(this), skip(1))
      .subscribe((open) => (open ? this.openOverlay() : this.destroyOverlay()));
  }

  ngOnDestroy() {}

  markAsFailed(correlation_id) {
    this.store.dispatch(new operationsActions.UploadFileFailed(correlation_id));
  }

  private destroyOverlay() {
    this.overlayRef.dispose();
  }

  private getOverlayConfig(): OverlayConfig {
    return new OverlayConfig({
      panelClass: 'bulk-uploader-dialog-panel',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy: this.overlay.position().global().bottom('16px').right('24px'),
    });
  }

  private openOverlay() {
    const userProfilePortal = new ComponentPortal(BulkUploaderComponent);
    const overlayConfig = this.getOverlayConfig();
    this.overlayRef = this.overlay.create(overlayConfig);
    this.overlayRef.attach(userProfilePortal);
  }
}
