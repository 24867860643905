import { Component, Inject, LOCALE_ID, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ExpectedError } from '@frontend/common/util';
import { EMPTY, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { manipulateFormOutput } from './appointment-manipulation';
import {
  AppointmentItem,
  ManageAppointmentsService,
} from './manage-appointments.service';
import { SecurableService } from './securable.service';

@Component({
  selector: 'frontend-app-manage-appointments',
  templateUrl: 'manage-appointments.component.html',
  styleUrls: ['manage-appointments.component.scss'],
  providers: [ManageAppointmentsService, SecurableService],
})
export class ManageAppointmentsComponent implements OnDestroy {
  _subscriptions: Subscription[] = [];
  _patientId: number;
  _timezone: string;
  form: FormGroup;

  constructor(
    @Inject(LOCALE_ID) public localeId: string,
    public srv: ManageAppointmentsService,
    private dialogRef: MatDialogRef<ManageAppointmentsComponent>,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder
  ) {
    this.form = this.formBuilder.group(
      {
        date: null,
        time: [null, Validators.pattern('^$|^(([01][0-9])|(2[0-3])):[0-5][0-9]$')],
        period: [null, Validators.maxLength(1)],
        provider_id: null,
        appointment_type_id: null,
      },
      { validators: [atLeastOneRequired, ifOneAllRequired] }
    );
    this._subscriptions.push(
      this.srv.patientRaw.subscribe({
        error: (err) => {
          this.dialogRef.close();
          const snackBarConfig = { duration: 10000 };
          if (err instanceof ExpectedError) {
            this.snackBar.open(err.expectedError, $localize`Close`, snackBarConfig);
            return EMPTY;
          }
          const message = $localize`There was an unexpected error.`;
          this.snackBar.open(message, $localize`Close`, snackBarConfig);
          throw err;
        },
      })
    );
    this._subscriptions.push(this.srv.patient.subscribe((p) => (this._patientId = p.id)));
    this._subscriptions.push(this.srv.timezone.subscribe((tz) => (this._timezone = tz)));
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  close(route) {
    this.dialogRef.close(route);
  }

  createAppointment() {
    const patient_department_visit_id = this._patientId.toString();
    const appt = manipulateFormOutput(this.form.value, this._timezone);
    const payload = { patient_department_visit_id, ...appt };
    this.form.disable();
    this.srv
      .createAppointment(payload)
      .pipe(
        catchError((err) => {
          const snackBarConfig = { duration: 10000 };
          if (err instanceof ExpectedError) {
            this.snackBar.open(err.expectedError, $localize`Close`, snackBarConfig);
            return EMPTY;
          }
          const message = $localize`There was an unexpected error.`;
          this.snackBar.open(message, $localize`Close`, snackBarConfig);
          throw err;
        })
      )
      .subscribe({
        next: () => {
          this.form.reset();
          this.srv.refreshAppointmentList();
        },
        complete: () => this.form.enable(),
      });
  }

  deleteAppointment(id: number) {
    this.srv
      .deleteAppointment(id)
      .pipe(
        catchError((err) => {
          const snackBarConfig = { duration: 10000 };
          if (err instanceof ExpectedError) {
            this.snackBar.open(err.expectedError, $localize`Close`, snackBarConfig);
            return EMPTY;
          }
          const message = $localize`There was an unexpected error.`;
          this.snackBar.open(message, $localize`Close`, snackBarConfig);
          throw err;
        })
      )
      .subscribe(() => this.srv.refreshAppointmentList());
  }

  onEdit(apptId: number) {
    this.close(apptId);
  }

  trackByFn(index: number, item: AppointmentItem) {
    return item.id;
  }
}

const atLeastOneRequired: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const date = control.get('date').value;
  const time = control.get('time').value;
  const period = control.get('period').value?.length;
  const provider = control.get('provider_id').value;
  const type = control.get('appointment_type_id').value;
  if (date || time || period || provider || type) {
    return null;
  }
  return { atLeastOneRequired: true };
};

const ifOneAllRequired: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const date = control.get('date').value;
  const time = control.get('time').value;
  const period = control.get('period').value?.length;
  const someFilled = [date, time, period].some((val) => !!val);
  const someEmpty = [date, time, period].some((val) => !val);
  if (someFilled && someEmpty) {
    return { ifOneAllRequired: true };
  }
  return null;
};
