import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedUiSkeletonFormModule } from '@frontend/common/skeletons';
import { PatientFormModule } from '../patient-form/patient-form.module';
import { CreatePatientRoutedComponent } from './create-patient-routed.component';
import { CreatePatientComponent } from './create-patient.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    SharedUiSkeletonFormModule,
    PatientFormModule,
  ],
  declarations: [CreatePatientRoutedComponent, CreatePatientComponent],
  exports: [CreatePatientRoutedComponent, CreatePatientComponent],
})
export class CreatePatientModule {}
