import { ChangeDetectionStrategy, Component } from '@angular/core';
import { StatusService } from './status.service';

export interface CardData {
  visitId: number;
  departmentName: string;
  siteName: string;
  departmentDuration: string;
  locationName?: string;
  locationDuration?: string;
  isInCareLocation: boolean;
  unattendedDuration?: string;
  attentions?: Attention[];
}

export interface Attention {
  id: number;
  indicatorColor: string;
  attentionDuration: string;
  status: string;
  staffFullName: string;
}

@Component({
  selector: 'frontend-visit-detail-status',
  templateUrl: './status.component.html',
  styleUrls: ['../common.scss', './status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [StatusService],
})
export class StatusComponent {
  constructor(public srv: StatusService) {}

  trackByFn(index: number, item: Attention) {
    return item.id;
  }
}
