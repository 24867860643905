import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { ConfirmationDialogsModule } from '@frontend/common/confirmation-dialog';
import { InformationBoxModule } from '@frontend/common/information-box';
import { SearchBoxModule } from '@frontend/common/search-box';
import { SharedUiSimpleToolbarModule } from '@frontend/common/simple-toolbar';
import { SharedUiSkeletonTableModule } from '@frontend/common/skeletons';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { RecipientsErrorPopoutModule } from './error-popout/error-popout.module';
import { RecipientDialogComponent } from './recipient-dialog/recipient-dialog.component';
import { RecipientFormComponent } from './recipient-form/recipient-form.component';
import { RecipientsListComponent } from './recipients-list/recipients-list.component';
import { RecipientsTableComponent } from './recipients-table/recipients-table.component';
import * as fromRecipientsSection from './reducers';
import { RecipientApiEffects } from './reducers/recipient/recipient-api.effects';
import { RecipientEffects } from './reducers/recipient/recipient.effects';
import { PatientsService } from './services/patients.service';
import { RecipientsService } from './services/recipients.service';

@NgModule({
  declarations: [
    RecipientsListComponent,
    RecipientDialogComponent,
    RecipientFormComponent,
    RecipientsTableComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    CdkTableModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    StoreModule.forFeature('recipientsSection', fromRecipientsSection.reducers, {
      metaReducers: fromRecipientsSection.metaReducers,
    }),
    EffectsModule.forFeature([RecipientEffects, RecipientApiEffects]),
    HttpClientModule,
    // Local
    InformationBoxModule,
    SearchBoxModule,
    ConfirmationDialogsModule,
    SharedUiSkeletonTableModule,
    RecipientsErrorPopoutModule,
    SharedUiSimpleToolbarModule,
  ],
  providers: [RecipientsService, PatientsService],
  exports: [RecipientsListComponent, RecipientDialogComponent, RecipientFormComponent],
})
export class PatientRecipientsModule {}
