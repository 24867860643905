import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Go } from '@frontend/common/ph-router-store';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, switchMap, tap } from 'rxjs/operators';
import { usersActions } from './store/users.actions';
import { usersSelectors } from './store/users.selectors';
import { User } from './user.model';

@Injectable()
export class UserResolver implements Resolve<User> {
  constructor(private store: Store<any>) {}

  resolve(): Observable<User> {
    return this.store.pipe(
      select(usersSelectors.getCollectionLoaded),
      tap((loaded) => loaded || this.store.dispatch(usersActions.load())),
      filter((loaded) => loaded),
      switchMap(() => this.store.pipe(select(usersSelectors.getOneByStateParam))),
      tap((user) => user || this.store.dispatch(new Go({ path: ['/users'] }))),
      filter((exist) => !!exist),
      first()
    );
  }
}
