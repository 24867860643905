<mat-card class="mat-typography">
  <mat-toolbar>
    <div class="breadcrumbs">
      <span>Select work area</span>
    </div>
    <span class="spacer"></span>
    <search-box (searchChanges)="searchValue$.next($event)"></search-box>
  </mat-toolbar>
  <mat-dialog-content>
    <mat-nav-list>
      <mat-list-item *ngFor="let item of filteredItems$ | async" (click)="selectDepartment(item)">
        <span class="mat-body-1 column-1" title="{{ item.name }}">{{item.name }}</span>
        <span class="mat-body-1 column-2" title="{{ item.siteName }}">{{item.siteName }}</span>
      </mat-list-item>
    </mat-nav-list>
  </mat-dialog-content>
</mat-card>