import { formatDurationFromUtc } from '@frontend/common/component-tools';
import { getParams } from '@frontend/common/ph-router-store';
import { createSelector } from '@ngrx/store';
import { getPatientsSectionState } from '../reducers';

export const getDepartmentId = createSelector(
  getParams,
  (params) => params.department_id
);

export const getStaffChatDialogSectionState = createSelector(
  getPatientsSectionState,
  (state) => state.unreadMessagesMenu
);

export const getFilter = createSelector(
  getStaffChatDialogSectionState,
  (state) => state.filter
);

export const getList = createSelector(
  getStaffChatDialogSectionState,
  (state) => state.list
);

export const getFilteredList = createSelector(getList, getFilter, (list, str) =>
  list
    .filter((item) => item.name.toLowerCase().indexOf(str.toLowerCase()) > -1)
    .map((item) => {
      const duration = formatDurationFromUtc(item.oldest_unseen_message_utc);
      return { ...item, duration };
    })
);

export const getUnread = createSelector(
  getStaffChatDialogSectionState,
  (state) => state.unread
);
