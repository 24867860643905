import gql from 'graphql-tag';

export interface AppointmentDataQuery {
  patient_appointment: Appointment[];
}

export interface Appointment {
  id: number;
  appointment_type_id: number;
  provider_id: number;
  appointment_time_utc: string;
}

export const AppointmentDataQuery = gql(`
query AppointmentDataQuery($appointmentId: Int!) {
  patient_appointment(where: {id: {_eq: $appointmentId}}) {
    id
    appointment_type_id
    provider_id
    appointment_time_utc
  }
}
`);

export interface DepartmentDataQuery {
  department: { site: { name: string; timezone: { id: string } } }[];
}

export const DepartmentDataQuery = gql(`
query DepartmentDataQuery($departmentId: Int!) {
    department(where: {id: {_eq: $departmentId}}) {
      site {
        timezone {
          id
        }
      }
    }
  }
`);

export interface ProvidersDataQuery {
  staff: ProviderItem[];
}

export interface ProviderItem {
  id: number;
  first_name: string;
  last_name: string;
}

export const ProvidersDataQuery = gql(`
query ProvidersDataQuery($departmentId: Int!, $parentStaffTypeId: smallint!) {
  staff(where: {
      departments: {department_id: {_eq: $departmentId}},
      staff_type: {parent_staff_type_id: {_eq: $parentStaffTypeId}},
      deleted_utc: {_is_null: true}
    },
    order_by: [{last_name: asc}, {first_name: asc}])
    {
      id
      last_name
      first_name
    }
}
`);

export interface AppointmentTypesDataQuery {
  appointment_type: AppointmentTypeItem[];
}

export interface AppointmentTypeItem {
  id: number;
  name: string;
}

export const AppointmentTypesDataQuery = gql(`
query AppointmentTypesDataQuery($departmentId: Int!) {
  appointment_type(where: {department_id: {_eq: $departmentId}}, order_by: {name: asc}) {
    name
    id
  }
}
`);
