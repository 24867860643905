import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { UserNotification } from '../util';

@Component({
  selector: 'frontend-downtime-notification',
  templateUrl: './downtime-notification.component.html',
  styleUrls: ['./downtime-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DowntimeNotificationComponent {
  title = 'Notification';
  message = 'No new notifications';

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { userNotification?: UserNotification },
    private bottomSheetRef: MatBottomSheetRef<DowntimeNotificationComponent>
  ) {
    if (this.data.userNotification) {
      this.title = this.data.userNotification?.notification_title;
      this.message = this.data.userNotification?.notification_message;
    }
  }

  dismiss() {
    this.bottomSheetRef.dismiss(this.data.userNotification?.id);
  }
}
