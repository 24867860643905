<ng-container
  *ngIf="srv.loading | async; then skeletonTmpl; else contentTmpl"
></ng-container>

<ng-template #skeletonTmpl>
  <skeleton-form></skeleton-form>
</ng-template>

<ng-template #contentTmpl>
  <h1 mat-dialog-title>
    <span i18n>{{ (srv.patient | async).fullName }}</span>
    <button mat-button mat-icon-button disableRipple (click)="close('../')">
      <mat-icon>edit</mat-icon>
    </button>
  </h1>

  <mat-dialog-content>
    <div [formGroup]="form">
      <!-- APPOINTMENT TYPE -->
      <mat-form-field appearance="outline" class="full-width">
        <mat-label i18n>Type</mat-label>
        <mat-select formControlName="appointment_type_id">
          <mat-option [value]="null" i18n>None</mat-option>
          <mat-option *ngFor="let p of srv.appointmentTypeList | async" [value]="p.id">{{
            p.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- APPOINTMENT TIME -->
      <ng-container [ngSwitch]="localeId">
        <ng-container *ngSwitchCase="'de'">
          <time-picker-24hs [form]="form"></time-picker-24hs>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <time-picker-ampm
            [form]="form"
            [timezone]="this.srv.timezone | async"
          ></time-picker-ampm>
        </ng-container>
      </ng-container>

      <!-- PROVIDER -->
      <mat-form-field appearance="outline" class="full-width">
        <mat-label i18n>Provider</mat-label>
        <mat-select formControlName="provider_id">
          <mat-option [value]="null" i18n>None</mat-option>
          <mat-option *ngFor="let p of srv.providerList | async" [value]="p.id">{{
            p.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <button
        mat-flat-button
        color="accent"
        [disabled]="form.invalid"
        (click)="createAppointment()"
        class="full-width"
      >
        Create Appointment
      </button>

      <ng-container *ngIf="srv.appointmentList | async as apptList">
        <div *ngIf="apptList.length" class="appointment-list">
          <ul>
            <li *ngFor="let appt of apptList" [trackBy]="trackByFn">
              <div class="line-wrapper">
                <div class="appt-data-wrapper">
                  <span>{{ appt.timeAndProvider || '-' }}</span>
                  <span>{{ appt.apptTypeName || '-' }}</span>
                </div>
                <div class="appt-btn-wrapper">
                  <button
                    class="edit-btn"
                    (click)="onEdit(appt.id)"
                  >
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button (click)="deleteAppointment(appt.id)">
                    <mat-icon>clear</mat-icon>
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </ng-container>
    </div>
  </mat-dialog-content>

  <mat-card-actions mat-dialog-actions align="end">
    <button mat-button mat-dialog-close="../manage-workflows" i18n>
      MANAGE WORKFLOWS
    </button>
  </mat-card-actions>
</ng-template>
