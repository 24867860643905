import { GenericFilterItem, GenericFilters } from '@frontend/common/util';
import {
  FiltersOptionsNetworkModel,
  LocationGroupNetworkModel,
} from './filters-options-network.model';

export function dataManipulation(filters: FiltersOptionsNetworkModel): GenericFilters {
  const { workflows, phases } = workflowManipulation(filters.workflow);
  const appointment_types = appointmentTypeManipulation(filters.appointment_type);
  const providers = staffManipulation(filters.staff);
  const locations = departmentLocationManipulation(filters.department_location);
  const location_groups = groupManipulation(filters.location_group);
  return { workflows, phases, appointment_types, providers, locations, location_groups };
}

export function groupManipulation(
  groups: LocationGroupNetworkModel[]
): GenericFilterItem[] {
  return groups.map((group) => {
    const parent = group.parent_location_group;
    const grandParent = parent?.parent_location_group;
    const grandGrandParent = grandParent?.parent_location_group;
    const secondaryLabel = [
      manipulateParentGroupName(parent?.name),
      manipulateParentGroupName(grandParent?.name),
      manipulateParentGroupName(grandGrandParent?.name),
    ]
      .filter((name) => !!name)
      .join(' ');
    return { ...group, label: group.name, secondaryLabel };
  });
}

function manipulateParentGroupName(name?: string): string | null {
  if (typeof name === 'string' && name.length > 0) {
    return `[${name}]`;
  }
  return null;
}

function workflowManipulation(workflow: FiltersOptionsNetworkModel['workflow']) {
  const workflows = workflow.map((w) => ({ id: w.id, label: w.name }));
  const phases = workflow.map((w) => {
    const children = workflowPhasesManipulation(w.workflow_phases);
    return { id: w.id, label: w.name, children };
  });
  return { workflows, phases };
}

function workflowPhasesManipulation(workflowPhases: { id: number; name: string }[]) {
  return workflowPhases.map((wp) => ({ id: wp.id, label: wp.name }));
}

function appointmentTypeManipulation(
  appointmentType: FiltersOptionsNetworkModel['appointment_type']
) {
  return appointmentType.map((at) => ({ ...at, label: at.name }));
}

function staffManipulation(staff: FiltersOptionsNetworkModel['staff']) {
  return staff
    .map((s) => {
      const label = [s.last_name, s.first_name].filter((t) => !!t).join(', ');
      return { ...s, label };
    })
    .sort((a, b) => a.label.localeCompare(b.label));
}

function departmentLocationManipulation(
  departmentLocation: FiltersOptionsNetworkModel['department_location']
) {
  return departmentLocation.map((dl) => ({ ...dl, label: dl.alias_name || dl.name }));
}
