import { Action } from '@ngrx/store';

export const CREATE_OVERLAY = '[Bulk Uploader] CREATE_OVERLAY';
export const DESTROY_OVERLAY = '[Bulk Uploader] DESTROY_OVERLAY';
export const OPEN = '[Bulk Uploader] OPEN';
export const TOGGLE = '[Bulk Uploader] TOGGLE';

export class CreateOverlay implements Action {
  readonly type = CREATE_OVERLAY;
}
export class DestroyOverlay implements Action {
  readonly type = DESTROY_OVERLAY;
}
export class Toggle implements Action {
  readonly type = TOGGLE;
}
export class Open implements Action {
  readonly type = OPEN;
}

export type ConfigActions = CreateOverlay | DestroyOverlay | Open | Toggle;
