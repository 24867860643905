import { Action } from '@ngrx/store';
import { Workflow } from '../workflow/workflow.model';

export enum WorkflowApiActionTypes {
  LoadWorkflowsApi = '[Workflow API] Load Workflows',
  LoadWorkflowsSuccessApi = '[Workflow API] Load Workflows Success',
  LoadWorkflowsFailureApi = '[Workflow API] Load Workflows Failure',
  LoadWorkflowApi = '[Workflow API] Load Workflow',
  LoadWorkflowSuccessApi = '[Workflow API] Load Workflow Success',
  LoadWorkflowFailureApi = '[Workflow API] Load Workflow Failure',
  CreateWorkflowApi = '[Workflow API] Create Workflow',
  CreateWorkflowSuccessApi = '[Workflow API] Create Workflow Success',
  CreateWorkflowFailureApi = '[Workflow API] Create Workflow Failure',
  UpdateWorkflowApi = '[Workflow API] Update Workflow',
  UpdateWorkflowSuccessApi = '[Workflow API] Update Workflow Success',
  UpdateWorkflowFailureApi = '[Workflow API] Update Workflow Failure',
  DeleteWorkflowApi = '[Workflow API] Delete Workflow',
  DeleteWorkflowSuccessApi = '[Workflow API] Delete Workflow Success',
  DeleteWorkflowFailureApi = '[Workflow API] Delete Workflow Failure',
  DuplicateWorkflowApi = '[Workflow API] Duplicate Workflow',
  DuplicateWorkflowSuccessApi = '[Workflow API] Duplicate Workflow Success',
  DuplicateWorkflowFailureApi = '[Workflow API] Duplicate Workflow Failure',
  EnableWorkflowApi = '[Workflow API] Enable Workflow',
  EnableWorkflowSuccessApi = '[Workflow API] Enable Workflow Success',
  EnableWorkflowFailureApi = '[Workflow API] Enable Workflow Failure',
  DisableWorkflowApi = '[Workflow API] Disable Workflow',
  DisableWorkflowSuccessApi = '[Workflow API] Disable Workflow Success',
  DisableWorkflowFailureApi = '[Workflow API] Disable Workflow Failure',
}

export class LoadWorkflowsApi implements Action {
  readonly type = WorkflowApiActionTypes.LoadWorkflowsApi;
  constructor(public payload: { params: any } = { params: {} }) {}
}

export class LoadWorkflowsSuccessApi implements Action {
  readonly type = WorkflowApiActionTypes.LoadWorkflowsSuccessApi;
  constructor(public payload: { workflows: Workflow[] }) {}
}

export class LoadWorkflowsFailureApi implements Action {
  readonly type = WorkflowApiActionTypes.LoadWorkflowsFailureApi;
  constructor(public payload: { message: string }) {}
}

export class LoadWorkflowApi implements Action {
  readonly type = WorkflowApiActionTypes.LoadWorkflowApi;
  constructor(public payload: { id: Workflow['id'] }) {}
}

export class LoadWorkflowSuccessApi implements Action {
  readonly type = WorkflowApiActionTypes.LoadWorkflowSuccessApi;
  constructor(public payload: { workflow: Workflow }) {}
}

export class LoadWorkflowFailureApi implements Action {
  readonly type = WorkflowApiActionTypes.LoadWorkflowFailureApi;
  constructor(public payload: { id: Workflow['id']; message: string }) {}
}

export class CreateWorkflowApi implements Action {
  readonly type = WorkflowApiActionTypes.CreateWorkflowApi;
  constructor(public payload: { workflow: Workflow }) {}
}

export class CreateWorkflowSuccessApi implements Action {
  readonly type = WorkflowApiActionTypes.CreateWorkflowSuccessApi;
  constructor(public payload: { workflow: Workflow }) {}
}

export class CreateWorkflowFailureApi implements Action {
  readonly type = WorkflowApiActionTypes.CreateWorkflowFailureApi;
  constructor(public payload: { id: Workflow['id']; message: string }) {}
}

export class UpdateWorkflowApi implements Action {
  readonly type = WorkflowApiActionTypes.UpdateWorkflowApi;
  constructor(public payload: { workflow: Workflow }) {}
}

export class UpdateWorkflowSuccessApi implements Action {
  readonly type = WorkflowApiActionTypes.UpdateWorkflowSuccessApi;
  constructor(public payload: { workflow: Workflow }) {}
}

export class UpdateWorkflowFailureApi implements Action {
  readonly type = WorkflowApiActionTypes.UpdateWorkflowFailureApi;
  constructor(public payload: { id: Workflow['id']; message: string }) {}
}

export class DeleteWorkflowApi implements Action {
  readonly type = WorkflowApiActionTypes.DeleteWorkflowApi;
  constructor(public payload: { id: Workflow['id'] }) {}
}

export class DeleteWorkflowSuccessApi implements Action {
  readonly type = WorkflowApiActionTypes.DeleteWorkflowSuccessApi;
  constructor(public payload: { id: Workflow['id'] }) {}
}

export class DeleteWorkflowFailureApi implements Action {
  readonly type = WorkflowApiActionTypes.DeleteWorkflowFailureApi;
  constructor(public payload: { id: Workflow['id']; message: string }) {}
}

export class DuplicateWorkflowApi implements Action {
  readonly type = WorkflowApiActionTypes.DuplicateWorkflowApi;
  constructor(public payload: { id: Workflow['id']; correlation_id: string }) {}
}

export class DuplicateWorkflowSuccessApi implements Action {
  readonly type = WorkflowApiActionTypes.DuplicateWorkflowSuccessApi;
  constructor(public payload: { workflow: Workflow }) {}
}

export class DuplicateWorkflowFailureApi implements Action {
  readonly type = WorkflowApiActionTypes.DuplicateWorkflowFailureApi;
  constructor(public payload: { id: Workflow['id']; message: string }) {}
}

export class EnableWorkflowApi implements Action {
  readonly type = WorkflowApiActionTypes.EnableWorkflowApi;
  constructor(public payload: { id: Workflow['id'] }) {}
}

export class EnableWorkflowSuccessApi implements Action {
  readonly type = WorkflowApiActionTypes.EnableWorkflowSuccessApi;
  constructor(public payload: { id: Workflow['id'] }) {}
}

export class EnableWorkflowFailureApi implements Action {
  readonly type = WorkflowApiActionTypes.EnableWorkflowFailureApi;
  constructor(public payload: { id: Workflow['id']; message: string }) {}
}

export class DisableWorkflowApi implements Action {
  readonly type = WorkflowApiActionTypes.DisableWorkflowApi;
  constructor(public payload: { id: Workflow['id'] }) {}
}

export class DisableWorkflowSuccessApi implements Action {
  readonly type = WorkflowApiActionTypes.DisableWorkflowSuccessApi;
  constructor(public payload: { id: Workflow['id'] }) {}
}

export class DisableWorkflowFailureApi implements Action {
  readonly type = WorkflowApiActionTypes.DisableWorkflowFailureApi;
  constructor(public payload: { id: Workflow['id']; message: string }) {}
}

export type WorkflowApiActions =
  | LoadWorkflowsApi
  | LoadWorkflowsSuccessApi
  | LoadWorkflowsFailureApi
  | LoadWorkflowApi
  | LoadWorkflowSuccessApi
  | LoadWorkflowFailureApi
  | CreateWorkflowApi
  | CreateWorkflowSuccessApi
  | CreateWorkflowFailureApi
  | UpdateWorkflowApi
  | UpdateWorkflowSuccessApi
  | UpdateWorkflowFailureApi
  | DeleteWorkflowApi
  | DeleteWorkflowSuccessApi
  | DeleteWorkflowFailureApi
  | DuplicateWorkflowApi
  | DuplicateWorkflowSuccessApi
  | DuplicateWorkflowFailureApi
  | EnableWorkflowApi
  | EnableWorkflowSuccessApi
  | EnableWorkflowFailureApi
  | DisableWorkflowApi
  | DisableWorkflowSuccessApi
  | DisableWorkflowFailureApi;
