<div [formGroup]="form">
  <mat-form-field appearance="outline" style="width: 80px">
    <mat-label i18n>Time</mat-label>
    <input matInput formControlName="time" [matAutocomplete]="auto" maxlength="5" />
    <mat-hint i18n>hh:mm</mat-hint>
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let ts of timestamps | async" [value]="ts">{{ ts }}</mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-button-toggle-group formControlName="period" class="time-picker-toggle" multiple>
    <mat-button-toggle value="AM" i18n>AM</mat-button-toggle>
    <mat-button-toggle value="PM" i18n>PM</mat-button-toggle>
  </mat-button-toggle-group>

  <mat-form-field appearance="outline" style="width: 128px">
    <mat-label>Date</mat-label>
    <input
      matInput
      [matDatepicker]="datePicker"
      formControlName="date"
      autocomplete="off"
    />
    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
    <mat-datepicker #datePicker></mat-datepicker>
  </mat-form-field>
</div>
