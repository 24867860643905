import { utc } from 'moment-timezone';
import { CardData } from './appointments.component';
import { Appointment, Visit } from './appointments.graphql';

export function dataManipulation(patient: Visit): CardData {
  const timezone = patient.department.site.timezone.id;
  const appointments = patient.patient_appointments.map((appointment: Appointment) =>
    _appointmentManipulation(appointment, timezone)
  );
  return {
    departmentId: patient.department.id,
    appointments,
  };
}

function _appointmentManipulation(pa: Appointment, timezone: string) {
  const id = pa.id;
  const provider = [pa.provider?.last_name, pa.provider?.first_name]
    .filter((s) => !!s)
    .join(', ');
  const type = pa.appointment_type?.name;
  if (!pa.appointment_time_utc) {
    return { id, provider, type };
  }
  const time = utc(pa.appointment_time_utc).tz(timezone).format('MM/DD/yyy hh:mm A');
  return { id, provider, type, time };
}
