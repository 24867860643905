import { Inject, Injectable, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Apollo } from 'apollo-angular';
import { EMPTY, Observable, ReplaySubject, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CardData } from './appointments.component';
import { AppointmentsDataQuery } from './appointments.graphql';
import { dataManipulation } from './data-manipulation';

@Injectable()
export class AppointmentsService implements OnDestroy {
  _subscriptions: Subscription[] = [];
  _cardDataRaw$: ReplaySubject<CardData> = new ReplaySubject(1);
  cardData$: Observable<CardData> = this._cardDataRaw$.pipe(catchError(() => EMPTY));

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { visitId: number },
    private apollo: Apollo,
    private snackBar: MatSnackBar
  ) {
    this._subscriptions.push(
      this._getVisit(this.data.visitId.toString()).subscribe(this._cardDataRaw$)
    );

    this._subscriptions.push(
      this._cardDataRaw$.subscribe({
        error: (err) => {
          const message = $localize`There was an unexpected error.`;
          this.snackBar.open(message, $localize`Close`, { duration: 10000 });
          throw err;
        },
      })
    );
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  _getVisit(visitId: string): Observable<CardData> {
    return this.apollo
      .query<AppointmentsDataQuery>({
        query: AppointmentsDataQuery,
        variables: { visitId },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((result) => {
          if (!result.data) {
            throw new Error("Error getting visit's information (result.data was falsy)");
          }
          return result.data.patient;
        }),
        map(dataManipulation)
      );
  }
}
