<ng-template #recipientFormWrapper>
  <h1 mat-dialog-title>
    <span *ngIf="!isEditOperation" i18n>Add Recipient</span>
    <span *ngIf="isEditOperation" i18n>Edit Recipient</span>
  </h1>
  <frontend-app-recipient-form
    #recipientForm
    [recipient]="recipient"
    (saved)="onSaved($event)"
  ></frontend-app-recipient-form>

  <div mat-dialog-actions align="end">
    <div style="display: flex; flex: 1">
      <button
        mat-button
        type="button"
        color="warn"
        (click)="discard()"
        [style.visibility]="recipient && recipient.error ? 'visible' : 'hidden'"
      >
        <span i18n>DISCARD</span>
      </button>
    </div>
    <button
      *ngIf="!isEditOperation"
      mat-button
      type="submit"
      (click)="another = true; recipientForm.submit()"
      [disabled]="recipientForm.recipientForm.invalid"
      [style.visibility]="recipient && recipient.error ? 'hidden' : 'visible'"
    >
      <span i18n>SAVE AND NEW</span>
    </button>
    <button
      mat-button
      type="submit"
      (click)="recipientForm.submit()"
      [disabled]="recipientForm.recipientForm.invalid"
    >
      <span i18n>SAVE</span>
    </button>
    <button type="button" mat-button mat-dialog-close i18n>CANCEL</button>
  </div>
</ng-template>
