import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import { Operation } from './models/operation.model';
import * as configActions from './store/config/config.actions';
import { getOpen } from './store/config/config.selectors';
import * as operationsActions from './store/operations/operations.actions';
import { OperationsState } from './store/operations/operations.reducer';
import {
  getOperationsAllReverse,
  getOperationsPending,
} from './store/operations/operations.selectors';

@Component({
  selector: 'app-bulk-uploader',
  templateUrl: './bulk-uploader.component.html',
  styleUrls: ['./bulk-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkUploaderComponent {
  operationsStats$: Observable<Operation[]>;
  pendingOperations$: Observable<number>;
  open$: Observable<boolean>;

  constructor(private store: Store<OperationsState>) {
    this.operationsStats$ = this.store.pipe(select(getOperationsAllReverse));
    this.pendingOperations$ = this.store.pipe(select(getOperationsPending));
    this.open$ = this.store.pipe(select(getOpen));
  }

  onClearOperation(correlation_id: string): void {
    this.store.dispatch(new operationsActions.RemoveOperation(correlation_id));
  }

  onExportErrors(errors: object[]) {
    this.store.dispatch(new operationsActions.ExportErrors(errors));
  }

  toggle() {
    this.store.dispatch(new configActions.Toggle());
  }

  trackByFn(index, item) {
    return item.id;
  }
}
