import { Action } from '@ngrx/store';
import { Workflow } from '../workflow/workflow.model';

export enum WorkflowWsActionTypes {
  LocalCreateWorkflowSuccessWs = '[Workflow WS] Local Create Workflows Success',
  LocalCreateWorkflowFailureWs = '[Workflow WS] Local Create Workflows Failure',
  LocalUpdateWorkflowSuccessWs = '[Workflow WS] Local Update Workflows Success',
  LocalUpdateWorkflowFailureWs = '[Workflow WS] Local Update Workflows Failure',
  LocalRemoveWorkflowSuccessWs = '[Workflow WS] Local Remove Workflows Success',
  LocalRemoveWorkflowFailureWs = '[Workflow WS] Local Remove Workflows Failure',
  LocalEnableWorkflowSuccessWs = '[Workflow WS] Local Enable Workflows Success',
  LocalEnableWorkflowFailureWs = '[Workflow WS] Local Enable Workflows Failure',
  LocalDisableWorkflowSuccessWs = '[Workflow WS] Local Disable Workflows Success',
  LocalDisableWorkflowFailureWs = '[Workflow WS] Local Disable Workflows Failure',
  GlobalCreateWorkflowSuccessWs = '[Workflow WS] Global Create Workflows Success',
  GlobalCopyWorkflowSuccessWs = '[Workflow WS] Global Copy Workflows Success',
  GlobalUpdateWorkflowSuccessWs = '[Workflow WS] Global Update Workflows Success',
  GlobalRemoveWorkflowSuccessWs = '[Workflow WS] Global Remove Workflows Success',
  GlobalActivateWorkflowSuccessWs = '[Workflow WS] Global Activate Workflows Success',
  GlobalDeactivateWorkflowSuccessWs = '[Workflow WS] Global Deactivate Workflows Success',
  GlobalTagStationUpdateWs = '[Workflow WS] Global Tag Station Update',
}

export class LocalCreateWorkflowSuccessWs implements Action {
  readonly type = WorkflowWsActionTypes.LocalCreateWorkflowSuccessWs;
  constructor(public payload: { id: string; correlation_id: string }) {}
}

export class LocalCreateWorkflowFailureWs implements Action {
  readonly type = WorkflowWsActionTypes.LocalCreateWorkflowFailureWs;
  constructor(public payload: { correlation_id: string; message: string }) {}
}

export class LocalUpdateWorkflowSuccessWs implements Action {
  readonly type = WorkflowWsActionTypes.LocalUpdateWorkflowSuccessWs;
  constructor(public payload: { id: string; correlation_id: string }) {}
}

export class LocalUpdateWorkflowFailureWs implements Action {
  readonly type = WorkflowWsActionTypes.LocalUpdateWorkflowFailureWs;
  constructor(public payload: { correlation_id: string; message: string }) {}
}

export class LocalRemoveWorkflowSuccessWs implements Action {
  readonly type = WorkflowWsActionTypes.LocalRemoveWorkflowSuccessWs;
  constructor(public payload: { correlation_id: string }) {}
}

export class LocalRemoveWorkflowFailureWs implements Action {
  readonly type = WorkflowWsActionTypes.LocalRemoveWorkflowFailureWs;
  constructor(public payload: { correlation_id: string; message: string }) {}
}

export class LocalEnableWorkflowSuccessWs implements Action {
  readonly type = WorkflowWsActionTypes.LocalEnableWorkflowSuccessWs;
  constructor(public payload: { correlation_id: string }) {}
}

export class LocalEnableWorkflowFailureWs implements Action {
  readonly type = WorkflowWsActionTypes.LocalEnableWorkflowFailureWs;
  constructor(public payload: { correlation_id: string; message: string }) {}
}

export class LocalDisableWorkflowSuccessWs implements Action {
  readonly type = WorkflowWsActionTypes.LocalDisableWorkflowSuccessWs;
  constructor(public payload: { correlation_id: string }) {}
}

export class LocalDisableWorkflowFailureWs implements Action {
  readonly type = WorkflowWsActionTypes.LocalDisableWorkflowFailureWs;
  constructor(public payload: { correlation_id: string; message: string }) {}
}

export class GlobalCreateWorkflowSuccessWs implements Action {
  readonly type = WorkflowWsActionTypes.GlobalCreateWorkflowSuccessWs;

  constructor(public payload: { workflow: Workflow }) {}
}

export class GlobalCopyWorkflowSuccessWs implements Action {
  readonly type = WorkflowWsActionTypes.GlobalCopyWorkflowSuccessWs;

  constructor(public payload: { workflow: Workflow }) {}
}

export class GlobalUpdateWorkflowSuccessWs implements Action {
  readonly type = WorkflowWsActionTypes.GlobalUpdateWorkflowSuccessWs;

  constructor(public payload: { workflow: Workflow }) {}
}

export class GlobalRemoveWorkflowSuccessWs implements Action {
  readonly type = WorkflowWsActionTypes.GlobalRemoveWorkflowSuccessWs;

  constructor(public payload: { id: Workflow['id'] }) {}
}

export class GlobalActivateWorkflowSuccessWs implements Action {
  readonly type = WorkflowWsActionTypes.GlobalActivateWorkflowSuccessWs;

  constructor(public payload: { id: Workflow['id'] }) {}
}

export class GlobalDeactivateWorkflowSuccessWs implements Action {
  readonly type = WorkflowWsActionTypes.GlobalDeactivateWorkflowSuccessWs;

  constructor(public payload: { id: Workflow['id'] }) {}
}

export class GlobalTagStationUpdateWs implements Action {
  readonly type = WorkflowWsActionTypes.GlobalTagStationUpdateWs;
}

export type WorkflowWsActions =
  | LocalCreateWorkflowSuccessWs
  | LocalCreateWorkflowFailureWs
  | LocalUpdateWorkflowSuccessWs
  | LocalUpdateWorkflowFailureWs
  | LocalRemoveWorkflowSuccessWs
  | LocalRemoveWorkflowFailureWs
  | GlobalCreateWorkflowSuccessWs
  | GlobalCopyWorkflowSuccessWs
  | GlobalUpdateWorkflowSuccessWs
  | GlobalRemoveWorkflowSuccessWs
  | GlobalActivateWorkflowSuccessWs
  | GlobalDeactivateWorkflowSuccessWs
  | GlobalTagStationUpdateWs;
