import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { QuickMessageApiEffects } from './quick-message-api/quick-message-api.effects';
import { QuickMessageWsEffects } from './quick-message-ws/quick-message-ws.effects';
import * as fromQuickMessage from './quick-message/quick-message.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('quickMessage', fromQuickMessage.reducer),
    EffectsModule.forFeature([QuickMessageApiEffects, QuickMessageWsEffects]),
  ],
})
export class SharedQuickMessageDataAccessModule {}
