<ng-template #labelRef>{{ label }}</ng-template>

<span *ngIf="!showBadge">
  <ng-container *ngTemplateOutlet="labelRef"></ng-container>
</span>

<span
  *ngIf="showBadge"
  [ngClass]="class"
  [matTooltip]="limit + ' min max was reached'"
  i18n-[matTooltip]
  matTooltipPosition="after"
  matTooltipHideDelay="100"
  matTooltipShowDelay="400"
>
  <ng-container *ngTemplateOutlet="labelRef"></ng-container>
</span>
