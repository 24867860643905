import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ConfirmationDialogService } from '@frontend/common/confirmation-dialog';
import { VisitDetailComponent } from '@frontend/workflow-rt/feature/visit-detail/src';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { EMPTY, Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { BulkUploadInstructionComponent } from '../bulk-upload-instructions/bulk-upload-instructions.component';
import { ChangeDepartmentComponent } from '../change-department/change-department.component';
import { ChangePhaseService } from '../change-phase.service';
import { ChangePhaseComponent } from '../change-phase/change-phase.component';
import { ConfirmEndVisitsDialogComponent } from '../confirm-end-visits-dialog/confirm-end-visits-dialog.component';
import { EndVisitService } from '../end-visit.service';
import { ReassignPatientDialogComponent } from '../reassign-patient-dialog/reassign-patient-dialog.component';
import { CareFiltersDialogComponent } from '../table-filters/care-filters-dialog/care-filters-dialog.component';
import { WaitingFiltersDialogComponent } from '../table-filters/waiting-filters-dialog/waiting-filters-dialog.component';
import { PageService } from './page.service';
import { clearCareData, loadCareData } from './state/care/care.actions';
import * as fromCare from './state/care/care.selectors';
import { clearWaitingData, loadWaitingData } from './state/waiting/waiting.actions';
import * as fromWaiting from './state/waiting/waiting.selectors';

@UntilDestroy()
@Component({
  selector: 'frontend-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageComponent implements OnDestroy, OnInit {
  private _subscriptions: Subscription[] = [];

  careLoading$ = this.store.select(fromCare.getLoading);
  careColumns$ = this.store.select(fromCare.getColumns);
  careCollection$ = this.store.select(fromCare.getCollection);

  waitingLoading$ = this.store.select(fromWaiting.getLoading);
  waitingColumns$ = this.store.select(fromWaiting.getColumns);
  waitingCollection$ = this.store.select(fromWaiting.getCollection);

  @ViewChild('careExpansionPanel', { static: true })
  careExpansionPanel: MatExpansionPanel;
  @ViewChild('patientsExpansionPanel', { static: true })
  patientsExpansionPanel: MatExpansionPanel;

  constructor(
    public srv: PageService,
    private store: Store,
    private changePhaseSrv: ChangePhaseService,
    private dialog: MatDialog,
    private confirmationDialog: ConfirmationDialogService,
    private snackBar: MatSnackBar,
    private endVisitSrv: EndVisitService,
    private router: Router
  ) {}

  ngOnDestroy() {
    this.srv.searchValue$.next('');
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  ngOnInit() {
    this._subscriptions.push(
      this.srv.patientsState$
        .pipe(filter((isOpen) => !isOpen))
        .subscribe(() => this.store.dispatch(clearWaitingData()))
    );
    this._subscriptions.push(
      this.srv.careState$
        .pipe(filter((isOpen) => !isOpen))
        .subscribe(() => this.store.dispatch(clearCareData()))
    );
    this._subscriptions.push(
      this.srv.careState$
        .pipe(switchMap((state) => (state ? this.srv.careData() : EMPTY)))
        .subscribe((data) => this.store.dispatch(loadCareData(data)), this._errorFn)
    );
    this._subscriptions.push(
      this.srv.patientsState$
        .pipe(switchMap((state) => (state ? this.srv.waitingData() : EMPTY)))
        .subscribe((data) => this.store.dispatch(loadWaitingData(data)), this._errorFn)
    );
  }

  advancePhase(data) {
    this.changePhaseSrv.advance(data).subscribe((nextPhase) => {
      if (nextPhase.isLastPhase) {
        const msg = $localize`Patient has completed ${data.workflowName} workflow`;
        this.snackBar.open(msg, null, { duration: 5000 });
        this.openReassignDialog(data.patientVisitId);
      }
    });
  }

  updateLocation({ id, newStatus }) {
    this.srv.updateLocationStatus(id, newStatus).subscribe();
  }

  openBulkUploadInstructions() {
    this.dialog
      .open(BulkUploadInstructionComponent, { closeOnNavigation: true, width: '492px' })
      .afterClosed()
      .subscribe((file) => file && this.srv.onFileUpload(file));
  }

  openCareFiltersDialog() {
    this.dialog.open(CareFiltersDialogComponent, {
      width: '744px',
      closeOnNavigation: true,
    });
  }

  openChangeDepartmentDialog(patientId) {
    this.dialog.open(ChangeDepartmentComponent, {
      data: { patientId },
      closeOnNavigation: true,
    });
  }

  openVisitDetailDialog(visitId: string) {
    this.dialog
      .open(VisitDetailComponent, {
        data: { visitId },
        minWidth: '1160px',
        width: '1160px',
        closeOnNavigation: true,
      })
      .afterClosed()
      .subscribe((commands) => {
        if (commands) {
          this.router.navigate(commands);
        }
      });
  }

  openChangePhaseDialog(data) {
    this.dialog.open(ChangePhaseComponent, { data, closeOnNavigation: true });
  }

  openEndVisitDialog(patientId) {
    this.endVisitSrv.openConfirmationDialog(patientId);
  }

  openDeletePatientDialog(patientId) {
    this.confirmationDialog
      .open(ConfirmationDialogService.defaultConfirm)
      .subscribe((result) => {
        if (!result) {
          return;
        }
        this.srv.deletePatient(patientId).subscribe(
          () =>
            this.snackBar.open($localize`Patient was successfully removed.`, null, {
              duration: 5000,
            }),
          this._errorFn
        );
      });
  }

  openReassignDialog(patientVisitId) {
    this.dialog.open(ReassignPatientDialogComponent, {
      width: '500px',
      data: { patientVisitId },
    });
  }

  openWaitingFiltersDialog() {
    this.dialog.open(WaitingFiltersDialogComponent, {
      width: '744px',
      closeOnNavigation: true,
    });
  }

  confirmEndUnassignedPatients() {
    this.dialog.open(ConfirmEndVisitsDialogComponent, { width: '500px' });
  }

  onCallPatient(data: { patientWorkflowId: number; phaseId: number }) {
    this.srv.onCallPatient(data.patientWorkflowId, data.phaseId);
  }

  _errorFn(error) {
    this.snackBar.open($localize`There was an unexpected error.`, $localize`Close`, {
      duration: 10000,
    });
    throw error;
  }
}
