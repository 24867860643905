import { Inject, Injectable, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CurrentUserService } from '@frontend/common/src/lib/util';
import { Apollo } from 'apollo-angular';
import { EMPTY, Observable, ReplaySubject, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  CrossModuleData,
  ModuleSelection,
  preferencesManipulation,
  visitManipulation,
} from './data-manipulation';
import { VisitDataQuery } from './visit-detail.graphql';

@Injectable()
export class VisitDetailService implements OnDestroy {
  _subscriptions: Subscription[] = [];
  _crossModuleDataRaw$: ReplaySubject<CrossModuleData> = new ReplaySubject(1);
  crossModuleData$: Observable<CrossModuleData> = this._crossModuleDataRaw$.pipe(
    catchError(() => EMPTY)
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { visitId: number },
    private apollo: Apollo,
    private currentUserSrv: CurrentUserService,
    private snackBar: MatSnackBar
  ) {
    this._subscriptions.push(
      this._getVisit(this.data.visitId).subscribe(this._crossModuleDataRaw$)
    );

    this._subscriptions.push(
      this._crossModuleDataRaw$.subscribe({
        error: (err) => {
          const message = $localize`There was an unexpected error.`;
          this.snackBar.open(message, $localize`Close`, { duration: 10000 });
          throw err;
        },
      })
    );
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  getSelectedModules(): Observable<ModuleSelection> {
    return this.currentUserSrv
      .getPreference('wrt_patient_visit_detail_modules')
      .pipe(map(preferencesManipulation));
  }

  saveSelectedModules(data: ModuleSelection): void {
    this.currentUserSrv.save('wrt_patient_visit_detail_modules', data);
  }

  _getVisit(visitId: number): Observable<CrossModuleData> {
    return this.apollo
      .query<VisitDataQuery>({
        query: VisitDataQuery,
        variables: { visitId },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((result) => {
          if (!result.data) {
            throw new Error("Error getting visit's information (result.data was falsy)");
          }
          return result.data.patient;
        }),
        map((data) => visitManipulation(data))
      );
  }
}
