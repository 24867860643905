import { formatDurationFromUtc } from '@frontend/common/component-tools';
import { duration, utc } from 'moment-timezone';
import { CardData } from './workflows.component';
import { CallEvent, PatientWorkflow, PhaseMilestone, Visit } from './workflows.graphql';

export function dataManipulation(visit: Visit): CardData {
  const patientWorkflows = visit.patient_department_visit_workflows.map(
    workflowMilestoneManipulation
  );
  return {
    departmentId: visit.department.id,
    patientWorkflows,
  };
}

export function workflowMilestoneManipulation(wMilestone: PatientWorkflow) {
  const workflowDuration = formatDurationFromUtc(
    wMilestone.created_utc,
    wMilestone.completed_utc
  );
  const activePhaseMilestone = wMilestone.patient_workflow_phase_milestones.map(
    phaseMilestoneManipulation
  )[0];
  return {
    id: wMilestone.id,
    name: wMilestone.workflow.name,
    workflowId: wMilestone.workflow.id,
    workflowDuration,
    ...activePhaseMilestone,
  };
}

export function callEventManipulation(event?: CallEvent): {
  calledBy?: string;
  stationName?: string;
  calledSinceDuration?: string;
} {
  if (!event) {
    return {};
  }
  let name = $localize`[deleted user]`;
  if (event.user) {
    name =
      [event.user.last_name, event.user.first_name].filter((s) => !!s).join(', ') ||
      event.user.email;
  }
  const calledBy = $localize`Called by ${name}`;
  const stationName = event.station.name;
  const calledSinceDuration = formatDurationFromUtc(event.created_utc);
  return { calledBy, stationName, calledSinceDuration };
}

export function phaseMilestoneManipulation(pMilestone: PhaseMilestone) {
  const {
    max_phase_duration_minutes_low,
    max_phase_duration_minutes_high,
    id,
    is_call_patient_enabled,
    name,
  } = pMilestone.workflow_phase;
  const phaseDuration = formatDurationFromUtc(
    pMilestone.created_utc,
    pMilestone.completed_utc
  );
  const { calledBy, stationName, calledSinceDuration } = callEventManipulation(
    pMilestone.call_events[0]
  );
  const phaseDurationMinutes =
    pMilestone.created_utc &&
    Math.floor(duration(utc().diff(utc(pMilestone.created_utc))).asMinutes());
  const reachedLow =
    max_phase_duration_minutes_low &&
    max_phase_duration_minutes_low <= phaseDurationMinutes;
  const reachedHigh =
    max_phase_duration_minutes_high &&
    max_phase_duration_minutes_high <= phaseDurationMinutes;
  const phaseState = reachedHigh ? 'red' : reachedLow ? 'yellow' : 'blank';
  return {
    phaseDuration,
    phaseName: name,
    calledBy,
    calledSinceDuration,
    stationName,
    phaseState,
    isCallablePhase: is_call_patient_enabled,
    phaseId: id,
  };
}
