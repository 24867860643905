import { Injectable } from '@angular/core';
import { CurrentUserService } from '@frontend/common/util';
import { Apollo } from 'apollo-angular';
import { combineLatest } from 'rxjs';
import { first, map, mapTo, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { PageService } from '../../page/page.service';
import { dataManipulation } from '../data-manipulation';
import { FiltersOptionsNetworkModel } from '../filters-options-network.model';
import { FILTERS_OPTIONS_QUERY } from './waiting-filters-dialog.graphql';

@Injectable()
export class WaitingFiltersDialogService {
  private options$ = this.pageSrv.departmentData$.pipe(
    first(),
    switchMap((department) =>
      this.apollo
        .subscribe<FiltersOptionsNetworkModel>({
          query: FILTERS_OPTIONS_QUERY,
          variables: {
            departmentId: department.id,
            siteId: department.site_id,
          },
        })
        .pipe(map((result) => dataManipulation(result.data)))
    )
  );

  filters$ = combineLatest([this.options$, this.pageSrv.waitingFilters$]).pipe(
    shareReplay(1),
    first()
  );

  loading$ = this.filters$.pipe(mapTo(false), startWith(true));

  constructor(
    private apollo: Apollo,
    private currentUserSrv: CurrentUserService,
    private pageSrv: PageService
  ) {}

  saveWaitingFilters(data) {
    this.pageSrv.departmentData$.subscribe((dep) =>
      this.currentUserSrv.save(`wrt_dep_${dep.id}_patients_waiting_filters`, data)
    );
  }
}
