import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import * as fromCare from './care/care.reducer';
import * as fromWaiting from './waiting/waiting.reducer';

export interface State {
  [fromCare.featureKey]: fromCare.State;
  [fromWaiting.featureKey]: fromWaiting.State;
}

export const reducers: ActionReducerMap<State> = {
  [fromCare.featureKey]: fromCare.reducer,
  [fromWaiting.featureKey]: fromWaiting.reducer,
};

export const metaReducers: MetaReducer<State>[] = [];

export const getPatientsPageState = createFeatureSelector<State>('patientsPage');
