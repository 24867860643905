export interface ActionableItem {
  correlation_id: string;
  pending: boolean;
  error: any;
  disabled: boolean;
  hidden: boolean;
}

export class BaseEntity implements ActionableItem {
  correlation_id: string;
  pending: boolean;
  error: any;
  disabled: boolean;
  hidden: boolean;
  id: string | number;
  old: object;
}
