import { utc } from 'moment-timezone';
import { CmptState, Result, SearchScope } from './patient-search';
import {
  PatientDataQuery,
  PatientSearchDataQuery,
  PatientSearchQueryParams,
} from './patient-search.graphql';

export function dataManipulation(data: PatientSearchDataQuery): CmptState {
  const results = data.patients.map(_patientManipulation);
  return { results };
}

export function _patientManipulation(patient: PatientDataQuery): Result {
  const id = patient.id;
  const ongoing = !patient.completed_utc && !patient.deleted_utc;
  const mrn = patient.securable.mrn;
  const fullName = [patient.securable.last_name, patient.securable.first_name]
    .filter((part) => !!part)
    .join(', ');
  const tag = [patient.tag?.tag_value, patient.tag?.label]
    .filter((part) => !!part)
    .join(' | ');
  const phoneNumber = patient.securable.phone_number;
  const departmentName = patient.department.name;
  const siteName = patient.department.site.name;
  const data = [mrn, fullName, tag, phoneNumber, departmentName, siteName].filter(
    (value) => !!value
  );
  const dataStr = data.join(' - ');
  return { id, ongoing, data, dataStr };
}

export function paramsManipulation([search, date, departmentId, siteId, scope]: [
  string,
  string,
  number,
  number,
  SearchScope
]): PatientSearchQueryParams {
  const minDate = utc(date).startOf('day').format();
  const maxDate = utc(date).endOf('day').format();

  return {
    search,
    minDate,
    maxDate,
    departmentId: scope === SearchScope.Department ? departmentId : undefined,
    siteId: scope === SearchScope.Site ? siteId : undefined,
  };
}
