export enum StaffTypeId {
  Parent_Admin = 1,
  Parent_Provider = 2,
  Parent_Nurse = 3,
  Parent_Assistants = 4,
  Parent_Environmental_Services = 5,
  Parent_Other = 6,
  Scheduler = 7,
  Registrar = 8,
  Advanced_Practice_Practitioner = 9,
  Anesthesiologist = 10,
  Nurse_Practitioner = 11,
  Physician = 12,
  Physician_Assistant = 13,
  Provider = 14,
  Surgeon = 15,
  Licensed_Practical_Nurse = 16,
  Nurse = 17,
  Registered_Nurse = 18,
  Certified_Nursing_Assistant = 19,
  Medical_Assistant = 20,
  Medical_Office_Specialist = 21,
  EVS = 22,
  Dietician = 23,
  General = 24,
  Lab = 25,
  Pharmacist = 26,
  Respiratory_Therapist = 27,
  Social_Worker = 28,
  Technician = 29,
  Technologist = 30,
  Radiologist = 31,
  Therapist = 32,
  ENT = 33,
  Fellow_Resident = 34,
  Unit_Secretary = 35,
  CRNA = 36,
  Sonographer = 37,
  Security = 38,
  OR_Assistant = 39,
  Radiology_Technician = 40,
  Surgery_Technician = 41,
  Patient_Care_Technician = 42,
  Cath_Lab_Technician = 43,
  Research = 44,
  Infusion_Nurse = 45,
  Clinical_Oncology_Associate = 46,
  Leadership = 47,
  Apheresis_Nurse = 48,
  Patient_Encounter_Associate = 49,
  Speech_Language_Pathologist = 50,
}
