import { BaseSelectors } from '@frontend/shared/base-store';
import { PatientWorkflow } from '../patient-workflow.model';
import { createSelector } from '@ngrx/store';
import { MemoizedSelectorWithProps } from '@ngrx/store/src/selector';

class PatientWorkflowsSelectors extends BaseSelectors<PatientWorkflow> {
  constructor() {
    super(PatientWorkflow);
    this.getPatientWorkflowsById = createSelector(
      this.getAll,
      (patient_workflows, patient_department_visit_id) =>
        patient_workflows.filter(
          (patient) => patient.patient_department_visit_id === patient_department_visit_id
        )
    );
  }
  public getPatientWorkflowsById: MemoizedSelectorWithProps<{}, any, PatientWorkflow[]>;
}

export const patientWorkflowsSelectors = new PatientWorkflowsSelectors();
