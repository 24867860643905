import { SortDirection } from '@angular/material/sort';
import { Moment } from 'moment';

type CustomSortingFn<T> = (
  active: string,
  direction: SortDirection
) => (a: { [active: string]: T }, b: { [active: string]: T }) => number;

export const sortString: CustomSortingFn<string> = (active, direction) => {
  const order = direction === 'asc' ? 1 : -1;
  return (a, b) => {
    const A = a[active];
    const B = b[active];
    if (A === B) {
      return 0;
    }
    if (A === null) {
      return 1;
    }
    if (B === null) {
      return -1;
    }
    return A.localeCompare(B) * order;
  };
};

export const sortMoment: CustomSortingFn<Moment> = (active, direction) => {
  const order = direction === 'asc' ? 1 : -1;
  return (a, b) => {
    const A = a[active];
    const B = b[active];
    if (A === B) {
      return 0;
    }
    if (A === null) {
      return 1;
    }
    if (B === null) {
      return -1;
    }
    return (A.isAfter(B) ? 1 : -1) * order;
  };
};

export const sortNumber: CustomSortingFn<number> = (active, direction) => {
  const order = direction === 'asc' ? 1 : -1;
  return (a, b) => {
    const A = a[active];
    const B = b[active];
    if (A === B) {
      return 0;
    }
    if (A === null) {
      return 1;
    }
    if (B === null) {
      return -1;
    }
    return (A - B) * order;
  };
};

export const sortBoolean: CustomSortingFn<boolean> = (active, direction) => {
  const order = direction === 'asc' ? 1 : -1;
  return (a, b) => {
    const A = a[active];
    const B = b[active];
    if (A === B) {
      return 0;
    }
    if (A === null) {
      return 1;
    }
    if (B === null) {
      return -1;
    }
    return A.toString().localeCompare(B.toString()) * order;
  };
};
