import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Go } from '@frontend/common/ph-router-store';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, switchMap, tap } from 'rxjs/operators';
import { Sensor } from './sensor.model';
import { sensorsActions } from './store/sensors.actions';
import { sensorsSelectors } from './store/sensors.selectors';

@Injectable()
export class SensorResolver implements Resolve<Sensor> {
  constructor(private store: Store<any>) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Sensor> {
    return this.store.pipe(
      select(sensorsSelectors.getCollectionLoaded),
      tap(
        (loaded) =>
          loaded ||
          this.store.dispatch(
            sensorsActions.load({ location_id: route.params.location_id })
          )
      ),
      filter((loaded) => loaded),
      switchMap(() => this.store.pipe(select(sensorsSelectors.getOneByStateParam))),
      tap((sensor) => sensor || this.store.dispatch(new Go({ path: ['/'] }))),
      filter((exist) => !!exist),
      first()
    );
  }
}
