import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Patient } from './manage-workflows-dialog.graphql';

@Injectable()
export class SecurableService {
  constructor(private http: HttpClient) {}

  get(patient: Patient) {
    if (!patient?.securable_id) {
      return of(patient);
    }
    return this.getSecure(patient.securable_id).pipe(
      map((securableItem) => extractSecurableData(patient, securableItem))
    );
  }

  getSecure(id) {
    return this.http.get<{ id: string; body: object }>(`securable/${id}`);
  }
}

function extractSecurableData(item, securableItem) {
  const securableData = manipulateSecurableData(securableItem?.body);
  return { ...item, ...securableData };
}

function manipulateSecurableData(data?: { first_name?: string; last_name?: string }) {
  const fullName = [data?.last_name, data?.first_name]
    .filter((part) => !!part)
    .join(', ');
  return { ...data, fullName };
}
