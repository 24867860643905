import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Recipient } from './recipients.graphql';

interface SecurableModel {
  id: string;
  body: SecurableData;
}

export interface SecurableData {
  first_name: string;
  last_name: string;
  relationship?: string;
}

export interface RecipientWithSecurable extends Recipient {
  securableData?: SecurableData;
}

@Injectable()
export class SecurableService {
  constructor(private http: HttpClient) {}

  getSecureData(items: Recipient[]): Observable<RecipientWithSecurable[]> {
    return of(getSecurableIds(items)).pipe(
      switchMap((securableIds) => this.getFromApi(securableIds, items))
    );
  }

  private getFromApi(securableIds: string[], items: Recipient[]) {
    return this.http
      .post<SecurableModel[]>('securable/get-bulk', { id: securableIds })
      .pipe(
        map((securableItems) =>
          items.map((item) => extractSecurableData(item, securableItems))
        )
      );
  }
}

function extractSecurableData(
  item: Recipient,
  securableItems: SecurableModel[]
): RecipientWithSecurable {
  const securableItemRaw = securableItems.find(
    (securableItem) => securableItem.id === item.securable_id.toString()
  );
  return { ...item, securableData: securableItemRaw?.body };
}

function getSecurableIds(items: Recipient[]) {
  return items.map((item) => item.securable_id);
}
