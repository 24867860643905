import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ConfirmationDialogConfigModel } from './confirmation-dialog-config.model';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: 'confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  data: ConfirmationDialogConfigModel['data'];
  isChecked$ = new BehaviorSubject(false);

  private defaultData: ConfirmationDialogConfigModel['data'] = {
    title: null,
    content: null,
    confirm: 'CONFIRM',
    withToggle: false,
    cancel: 'CANCEL',
    invertButtons: false,
  };

  constructor(@Inject(MAT_DIALOG_DATA) data: ConfirmationDialogConfigModel['data']) {
    this.data = Object.assign(this, this.defaultData, data);
  }
}
