import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-information-box',
  styleUrls: ['./information-box.component.scss'],
  templateUrl: './information-box.component.html',
})
export class InformationBoxComponent {
  @Input() message: string;
  @Input() icon: string;
}
