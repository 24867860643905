<a mat-menu-item [href]="srv.assetsURL$ | async" *ngIf="srv.assetsAvailable$ | async">
  <span>{{ ProductTranslation[ProductId.Assets] }}</span>
</a>
<a mat-menu-item [href]="srv.caURL$ | async" *ngIf="srv.caAvailable$ | async">
  <span>{{ ProductTranslation[ProductId.CentralAdmin] }}</span>
</a>
<a mat-menu-item [href]="srv.connectURL$ | async">
  <span>{{ ProductTranslation[ProductId.Connect] }}</span>
</a>
<a mat-menu-item [href]="srv.hhURL$ | async" *ngIf="srv.hhAvailable$ | async">
  <span>{{ ProductTranslation[ProductId.HandHygiene] }}</span>
</a>
