import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'wrt-duration-badge',
  templateUrl: './duration-badge.component.html',
  styleUrls: ['./duration-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DurationBadgeComponent implements OnInit {
  showBadge;
  limit;
  class;

  @Input() label: string;
  @Input() minutes: number;
  @Input() low: number;
  @Input() high: number;

  ngOnInit() {
    const reachedLow = this.low && this.low <= this.minutes;
    const reachedHigh = this.high && this.high <= this.minutes;
    this.showBadge = reachedLow || reachedHigh;
    this.limit = reachedHigh ? this.high : reachedLow ? this.low : null;
    this.class = reachedHigh ? 'high' : reachedLow ? 'low' : null;
  }
}
