import { ActionTypes, BaseActions } from '@frontend/shared/base-store';
import { PatientWorkflow } from '../patient-workflow.model';

export interface PatientWorkflowsActionTypes extends ActionTypes {
  COMPLETE_WORKFLOW: string;
  CALL: string;
  CALL_SUCCESS: string;
  CALL_FAILURE: string;
  UPDATE_CALLED_COUNT: string;
}

export class PatientWorkflowsActions extends BaseActions<PatientWorkflow> {
  public types: PatientWorkflowsActionTypes;

  constructor() {
    super(PatientWorkflow);
    this.types = {
      ...this.types,
      COMPLETE_WORKFLOW: this.getTypeString('COMPLETE_WORKFLOW'),
      CALL: this.getTypeString('CALL'),
      CALL_SUCCESS: this.getTypeString('CALL_SUCCESS'),
      CALL_FAILURE: this.getTypeString('CALL_FAILURE'),
      UPDATE_CALLED_COUNT: this.getTypeString('UPDATE_CALLED_COUNT'),
    };
  }

  completeWorkflow(payload) {
    return {
      type: this.types.COMPLETE_WORKFLOW,
      payload: { ...this.correlate(payload) },
    };
  }

  call(payload: { patient_workflow_id: string; station_id: number; phase_id: number }) {
    return { type: this.types.CALL, payload };
  }

  callSuccess(payload) {
    return { type: this.types.CALL_SUCCESS, payload };
  }

  callFailure(payload) {
    return { type: this.types.CALL_FAILURE, payload };
  }

  updateCalledCount(payload: { id: string; called_count: number }) {
    return { type: this.types.UPDATE_CALLED_COUNT, payload };
  }
}

export const patientWorkflowsActions = new PatientWorkflowsActions();
