import { Injectable } from '@angular/core';
import { downloadCsv } from '@frontend/common/component-tools';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import * as Papa from 'papaparse';
import { concatMap, first, map } from 'rxjs/operators';
import { getDepartmentId } from '../page/page.selectors';
import { ProvidersDataQuery } from './bulk-upload-instructions.graphql';
import { dataManipulation } from './data-manipulation';

@Injectable()
export class BulkUploadInstructionsService {
  constructor(private apollo: Apollo, private store: Store) {}

  downloadInstructions(): void {
    this.store
      .select(getDepartmentId)
      .pipe(
        concatMap((departmentId) => {
          return this.apollo
            .subscribe<ProvidersDataQuery>({
              query: ProvidersDataQuery,
              variables: { departmentId },
            })
            .pipe(
              map((result) => {
                if (!result.data) {
                  throw new Error(
                    "Error getting department's providers (result.data was falsy)"
                  );
                }
                return result.data;
              }),
              map(dataManipulation)
            );
        }),
        first()
      )
      .subscribe((data) =>
        downloadCsv(Papa.unparse(data), `patient-bulk-upload.instructions.csv`)
      );
  }
}
