import { ActionTypes, BaseActions } from '@frontend/shared/base-store';
import { Workflow } from '../workflow.model';

interface UsersActionTypes extends ActionTypes {
  TOGGLE: string;
  DUPLICATE: string;
}

class WorkflowsActions extends BaseActions<Workflow> {
  public types: UsersActionTypes;

  constructor() {
    super(Workflow);
    this.types = {
      ...this.types,
      TOGGLE: this.getTypeString('TOGGLE'),
      DUPLICATE: this.getTypeString('DUPLICATE'),
    };
  }

  toggle(payload: { id: number; is_activated: boolean }) {
    return { type: this.types.TOGGLE, payload: this.correlate(payload) };
  }

  duplicate(payload: { id: number }) {
    return { type: this.types.DUPLICATE, payload: this.correlate(payload) };
  }
}

export const workflowsActions = new WorkflowsActions();
