import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { ChangePhaseService } from '../change-phase.service';

@Component({
  selector: 'change-phase',
  templateUrl: './change-phase.component.html',
  styleUrls: ['./change-phase.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ChangePhaseService],
})
export class ChangePhaseComponent {
  subscriptions: Subscription[] = [];

  form: FormGroup = this._fb.group({
    phase: this._fb.control(null, [Validators.required]),
  });

  loading$ = this.changePhaseSrv.loading$;
  phases$ = this.changePhaseSrv.phases$;
  initialPhaseId = null;

  constructor(
    private _fb: FormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA)
    public data: { patientWorkflowId: number; workflowId: number },
    private changePhaseSrv: ChangePhaseService
  ) {
    this.changePhaseSrv.getData(this.data.patientWorkflowId, this.data.workflowId);
    this.subscriptions.push(
      this.changePhaseSrv.currentPhaseId$.pipe(first()).subscribe((id) => {
        this.form.controls.phase.setValue(id);
        this.initialPhaseId = id;
      })
    );
  }

  submit() {
    this.form.disable();
    const phaseId = this.form.value.phase;
    this.changePhaseSrv.changePhase(this.data.patientWorkflowId, phaseId).subscribe(
      () => this.dialog.closeAll(),
      () => {
        this.snackBar.open($localize`There was an unexpected error.`, $localize`Close`, {
          duration: 10000,
        });
        this.form.enable();
      }
    );
  }
}
