import { Injectable } from '@angular/core';
import { BaseAction, BaseActions } from '@frontend/shared/base-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Customer } from '../customer.model';
import { CustomersService } from '../customers.service';
import { customersActions } from './customers.actions';

@Injectable()
export class CustomersEffects {
  constructor(private actions$: Actions, private service: CustomersService) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customersActions.types.LOAD),
      mergeMap(() =>
        this.service.get().pipe(
          map((entities) => customersActions.loadSuccess(entities)),
          catchError((error) => of(customersActions.loadFail(error)))
        )
      )
    )
  );

  loadOne$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customersActions.types.LOAD_ONE),
      mergeMap(() =>
        this.service.getOne().pipe(
          map((entity) => customersActions.loadOneSuccess(entity)),
          catchError((error) => of(customersActions.loadOneFail(error)))
        )
      )
    )
  );

  add$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customersActions.types.ADD),
      mergeMap((action: BaseAction<Customer>) =>
        this.service.create(action.payload).pipe(
          map(() => BaseActions.void()),
          catchError((error) => of(customersActions.addFail(error)))
        )
      )
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customersActions.types.DELETE),
      mergeMap((action: BaseAction<Customer>) =>
        this.service.delete(action.payload).pipe(
          map(() => BaseActions.void()),
          catchError((error) => of(customersActions.deleteFail(error)))
        )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customersActions.types.UPDATE),
      mergeMap((action: BaseAction<Customer>) =>
        this.service.edit(action.payload).pipe(
          map(() => BaseActions.void()),
          catchError((error) => of(customersActions.updateFail(error)))
        )
      )
    )
  );

  toggleCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customersActions.types.TOGGLE),
      switchMap((action: BaseAction<Customer>) =>
        this.service.changeStatus(action.payload).pipe(
          map(() => BaseActions.void()),
          catchError((error) => of(customersActions.updateFail(error)))
        )
      )
    )
  );
}
