import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Recipient } from '../reducers/recipient/recipient.model';

@UntilDestroy()
@Component({
  selector: 'frontend-app-recipient-form',
  templateUrl: './recipient-form.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecipientFormComponent implements OnInit, OnDestroy {
  @Input() recipient: Recipient;
  @Output() saved = new EventEmitter<Recipient>();
  @Output() formValid = new EventEmitter<boolean>();
  recipientForm: FormGroup;
  private formControlConfig = {
    first_name: [null, [Validators.required]],
    last_name: [null, [Validators.required]],
    relationship: null,
    phone_number: [null, [this.phoneValidator(), Validators.required]],
  };

  constructor(private _fb: FormBuilder) {
    this.recipientForm = this._fb.group(this.formControlConfig);
    this.recipientForm.statusChanges
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(),
        map((s) => s === 'VALID')
      )
      .subscribe((v) => this.formValid.emit(v));
  }

  ngOnInit() {
    // Prefill form with data if there's any.
    if (this.recipient) {
      const formData = Object.keys(this.formControlConfig).reduce(
        (acc, curr) => ({ ...acc, [curr]: this.recipient[curr] }),
        {}
      );
      this.recipientForm.setValue(formData, { onlySelf: true });
    }
  }

  ngOnDestroy() {}

  submit() {
    if (this.recipientForm.status !== 'INVALID') {
      this.saved.emit(this.recipientForm.getRawValue());
    }
  }

  private phoneValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      return this.countNumbers(control.value) === 10
        ? null
        : { phoneNumberWrongFormat: { value: control.value } };
    };
  }

  countNumbers(phoneNumber: string): number {
    return phoneNumber.replace(/[^0-9]/g, '').length;
  }
}
