import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { VisitDetailComponent } from '@frontend/workflow-rt/feature/visit-detail/src/lib/visit-detail.component';
import * as moment from 'moment';
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators';
import { Result, SearchScope } from './patient-search';
import { PatientSearchService } from './patient-search.service';

@Component({
  selector: 'wrt-patient-search',
  templateUrl: './patient-search.component.html',
  styleUrls: ['./patient-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PatientSearchService],
})
export class PatientSearchComponent implements OnInit {
  SearchScope = SearchScope;
  dateCtrl = new FormControl({ value: moment(), disabled: true });
  searchCtrl = new FormControl();
  scopeSelectorCtrl = new FormControl(this.srv.scope$.value);

  constructor(
    public srv: PatientSearchService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<PatientSearchComponent>,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.searchCtrl.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((val) => this.srv.setSearch(val));
    this.dateCtrl.valueChanges
      .pipe(startWith(moment()))
      .subscribe((val) => this.srv.setDate(val));
    this.scopeSelectorCtrl.valueChanges.subscribe((val) => this.srv.setScope(val));
  }

  _clearSearch() {
    this.searchCtrl.setValue('');
  }

  onKeydown(event: KeyboardEvent) {
    if (event.code === 'Escape') {
      this._clearSearch();
      event.stopPropagation();
    }
  }

  openVisitDetailDialog(visitId: Result['id']) {
    this.dialogRef.close();
    this.dialog
      .open(VisitDetailComponent, {
        data: { visitId },
        minWidth: '1160px',
        width: '1160px',
        closeOnNavigation: true,
      })
      .afterClosed()
      .subscribe((commands) => {
        if (commands) {
          this.router.navigate(commands);
        }
      });
  }

  trackByFn(index: number, item: Result) {
    return item.id;
  }
}
