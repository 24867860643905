import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store } from '@ngrx/store';

import { AbstractState } from '@frontend/shared/base-store';
import { userTypesActions } from './store/user-types.actions';
import { UserType } from './user-type.model';

@Injectable()
export class UserTypesResolver implements Resolve<boolean> {
  constructor(private store: Store<AbstractState<UserType>>) {}

  resolve(): boolean {
    this.store.dispatch(userTypesActions.load());
    return true;
  }
}
