import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MilestoneTypes } from '@frontend/common/util';
import { MilestonesService } from './milestones.service';

@Component({
  selector: 'frontend-visit-detail-milestones',
  templateUrl: './milestones.component.html',
  styleUrls: ['../common.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MilestonesService],
})
export class MilestonesComponent {
  public MilestoneTypes = MilestoneTypes;

  constructor(public srv: MilestonesService) {}
}
