import { AbstractState, BaseAction, ReducerFactory } from '@frontend/shared/base-store';
import { departmentsActions } from '../../departments/store/departments.actions';
import { Location } from '../location.model';

class LocationsReducer extends ReducerFactory<Location> {
  constructor() {
    super(Location);
  }

  getReducer() {
    return (
      state: AbstractState<Location> = this.adapter.getInitialState(this.initialState),
      a: BaseAction<Location>
    ) => {
      switch (a.type) {
        case departmentsActions.types.LOAD_LOCATIONS_SUCCESS:
          return this.adapter.addMany(a.payload.locations, state);
        default:
          return super.getReducer()(state, a);
      }
    };
  }
}

const reducerFunction = new LocationsReducer().getReducer();

export function locationsReducer(state, action) {
  return reducerFunction(state, action);
}
