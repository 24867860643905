import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseEntity } from './base.entity';

// TODO: implement injectable route string and make AbstractServices to be @Injectable()

export class BaseReadonlyService<T extends BaseEntity> {
  constructor(protected readonly http: HttpClient, protected readonly route: string) {}

  getOne(id: string | number): Observable<T> {
    return this.http.get<T>(`${this.route}/${id}`);
  }

  get(search: Partial<T> = {}): Observable<T[]> {
    return this.http.get<T[]>(this.route, { params: search as any });
  }
}

export class BaseService<T extends BaseEntity> extends BaseReadonlyService<T> {
  create(entity: T): Observable<object> {
    return this.http.post(this.route, entity);
  }

  createBulk(entities: T[]) {
    return this.http.post(`${this.route}/bulk`, entities);
  }

  delete(entity: T): Observable<object> {
    return this.http.request('delete', `${this.route}/${entity.id}`, {
      body: { correlation_id: entity.correlation_id },
    });
  }

  edit(entity: T): Observable<object> {
    return this.http.put(`${this.route}/${entity.id}`, entity);
  }
}
