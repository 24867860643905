<div (click)="$event.stopPropagation()">
  <div id="search-wrapper">
    <search-box placeholder="Search for Staff" i18n-placeholder (searchChanges)="onSearchUpdate($event)"></search-box>
  </div>
  <mat-list *ngIf="loaded$ | async">
    <mat-list-item *ngFor="let item of items$ | async" (click)="onChirp(item.tag_id)">
      <span mat-line>{{ item.displayName }}</span>
      <button mat-icon-button>
        <mat-icon mat-list-icon>notifications_active</mat-icon>
      </button>
    </mat-list-item>
  </mat-list>
</div>