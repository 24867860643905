import { BaseEntity, Entity, Feature } from '@frontend/shared/base-store';

@Entity('patient')
@Feature('patients')
export class Patient extends BaseEntity {
  id: string;
  current_workflow_ids: number[];
  patient_department_visit_id: string;
  secured_patient_name: string;
  securable_id: string;
  first_name: string;
  last_name: string;
  mrn: string;
  notifications_sent_count: number;
  sms_recipient_count: number;
  current_phase_updated_utc: Date;
  tag_value: string;
  tag_label: string;
  created_utc: string;
  sensed_utc: string;
  appointment_time_utc: string;
  appointment_time: string;
  daytime_period: string;
  phone_number: string;
  last_sms_send_time: Date;
  note: string;
  additional_note: string;
  department_id: number;
  appointment_type_name: string;
  appointment_type_id: number;
  unseen_text_messages_count?: number;
}
