import { Injectable } from '@angular/core';
import { SocketService } from '@frontend/common/util';
import { createEffect } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import {
  GlobalCompletePatientSuccessWs,
  GlobalCreatePatientSuccessWs,
  GlobalMovePatientSuccessWs,
  GlobalNotificationSendSuccessWs,
  GlobalRemovePatientSuccessWs,
  GlobalUpdatePatientSuccessWs,
  LocalCompletePatientFailureWs,
  LocalCompletePatientSuccessWs,
  LocalCreatePatientFailureWs,
  LocalCreatePatientSuccessWs,
  LocalNotificationSendSuccessWs,
  LocalRemovePatientFailureWs,
  LocalRemovePatientSuccessWs,
  LocalUpdatePatientFailureWs,
  LocalUpdatePatientSuccessWs,
} from './patient-ws.actions';

@Injectable()
export class PatientWsEffects {
  LocalCreatePatientSuccessWs$ = createEffect(() =>
    this.socketSrv
      .fromLocalSuccess('patients.create')
      .pipe(
        map(
          ({ id, correlation_id }) =>
            new LocalCreatePatientSuccessWs({ id, correlation_id })
        )
      )
  );

  LocalCreatePatientFailureWs$ = createEffect(() =>
    this.socketSrv
      .fromLocalError('patients.create')
      .pipe(
        map(
          ({ correlation_id, message }) =>
            new LocalCreatePatientFailureWs({ correlation_id, message })
        )
      )
  );

  LocalUpdatePatientSuccessWs$ = createEffect(() =>
    this.socketSrv
      .fromLocalSuccess('patients.update')
      .pipe(
        map(
          ({ id, correlation_id }) =>
            new LocalUpdatePatientSuccessWs({ id, correlation_id })
        )
      )
  );

  LocalUpdatePatientFailureWs$ = createEffect(() =>
    this.socketSrv
      .fromLocalError('patients.update')
      .pipe(
        map(
          ({ correlation_id, message }) =>
            new LocalUpdatePatientFailureWs({ correlation_id, message })
        )
      )
  );

  LocalRemovePatientSuccessWs$ = createEffect(() =>
    this.socketSrv
      .fromLocalSuccess('patients.remove')
      .pipe(
        map(({ correlation_id }) => new LocalRemovePatientSuccessWs({ correlation_id }))
      )
  );

  LocalRemovePatientFailureWs$ = createEffect(() =>
    this.socketSrv
      .fromLocalError('patients.remove')
      .pipe(
        map(
          ({ correlation_id, message }) =>
            new LocalRemovePatientFailureWs({ correlation_id, message })
        )
      )
  );

  LocalCompletePatientSuccessWs$ = createEffect(() =>
    this.socketSrv
      .fromLocalSuccess('patients.complete')
      .pipe(
        map(({ correlation_id }) => new LocalCompletePatientSuccessWs({ correlation_id }))
      )
  );

  LocalCompletePatientFailureWs$ = createEffect(() =>
    this.socketSrv
      .fromLocalError('patients.remove')
      .pipe(
        map(
          ({ correlation_id, message }) =>
            new LocalCompletePatientFailureWs({ correlation_id, message })
        )
      )
  );

  LocalNotificationSendSuccessWs = createEffect(() =>
    this.socketSrv.fromLocalSuccess('notifications.send').pipe(
      map(
        ({ patient_department_visit_id, created_utc }) =>
          new LocalNotificationSendSuccessWs({
            patient_department_visit_id,
            created_utc,
          })
      )
    )
  );

  GlobalNotificationSendSuccessWs = createEffect(() =>
    this.socketSrv.fromGlobalSuccess('notifications.send').pipe(
      map(
        ({ patient_department_visit_id, created_utc }: any) =>
          new GlobalNotificationSendSuccessWs({
            patient_department_visit_id,
            created_utc,
          })
      )
    )
  );

  GlobalCreatePatientSuccessWs$ = createEffect(() =>
    this.socketSrv
      .fromGlobalSuccess('patients.create')
      .pipe(map((patient: any) => new GlobalCreatePatientSuccessWs(patient)))
  );

  GlobalUpdatePatientSuccessWs$ = createEffect(() =>
    this.socketSrv
      .fromGlobalSuccess('patients.update')
      .pipe(map((patient: any) => new GlobalUpdatePatientSuccessWs(patient)))
  );

  GlobalRemovePatientSuccessWs$ = createEffect(() =>
    this.socketSrv
      .fromGlobalSuccess('patients.remove')
      .pipe(map(({ id }: any) => new GlobalRemovePatientSuccessWs(id)))
  );

  GlobalCompletePatientSuccessWs$ = createEffect(() =>
    this.socketSrv
      .fromGlobalSuccess('patients.complete')
      .pipe(map(({ id }: any) => new GlobalCompletePatientSuccessWs(id)))
  );

  GlobalMovePatientSuccessWs$ = createEffect(() =>
    this.socketSrv
      .fromGlobalSuccess('patients.move')
      .pipe(map((patient: any) => new GlobalMovePatientSuccessWs(patient)))
  );

  constructor(private socketSrv: SocketService) {}
}
