import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationDialogService } from '@frontend/common/confirmation-dialog';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { CreatePatientService } from './create-patient.service';
import { SecurableService } from './securable.service';

@Component({
  selector: 'create-patient',
  templateUrl: './create-patient.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CreatePatientService, SecurableService],
})
export class CreatePatientComponent {
  form: FormGroup = new FormGroup({ patient: new FormControl() });

  subscriptions: Subscription[] = [];

  constructor(
    private srv: CreatePatientService,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<CreatePatientComponent>,
    private confirmationDialog: ConfirmationDialogService
  ) {}

  submit(route?: string, force?: boolean) {
    const phone_number =
      this.form.controls.patient.value.phone_number &&
      this.form.controls.patient.value.phone_number.replace(/[^0-9]/g, '');
    const data = {
      ...this.form.controls.patient.value,
      phone_number,
    };
    this.subscriptions.push(
      this.srv.create(data, force).subscribe(
        (result: { id: string }) => {
          this.snackBar.open($localize`Patient was successfully created.`, null, {
            duration: 3000,
          });
          const next = route && ['..', result.id, route];
          this.dialogRef.close(next);
        },
        (e) => {
          let message;
          if (
            e?.expectedError ||
            e?.message === $localize`This Badge is currently in use.`
          ) {
            message = e.expectedError || e.message;
          } else {
            console.error(e);
            message = $localize`There was an unexpected error.`;
          }
          if (message === 'tag_is_non_reporting' || message === 'tag_is_low_battery') {
            const content =
              message === 'tag_is_non_reporting'
                ? $localize`This Badge is non-reporting; we recommend you to select a different badge.`
                : $localize`This Badge has a low battery; we recommend you to select a different badge.`;
            this.confirmationDialog
              .open({
                title: $localize`Confirm operation`,
                content,
                cancel: $localize`NEW BADGE`,
                confirm: $localize`SAVE ANYWAY`,
                invertButtons: true,
              })
              .pipe(first())
              .subscribe((confirm) => confirm && this.submit(route, true));
          } else {
            this.snackBar.open(message, $localize`Close`, { duration: 10000 });
          }
          this.form.enable();
        }
      )
    );
    this.form.disable();
  }
}
