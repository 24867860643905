import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Station } from '@frontend/shared/station/data-access';
import { CallToStationDialogService } from './call-to-station-dialog.service';

@Component({
  selector: 'frontend-call-to-station-dialog',
  templateUrl: './call-to-station-dialog.component.html',
  styleUrls: ['./call-to-station-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CallToStationDialogService],
})
export class CallToStationDialogComponent {
  selectedStation: Station;
  saveAsDefault = false;
  constructor(
    public srv: CallToStationDialogService,
    private dialogRef: MatDialogRef<CallToStationDialogComponent>
  ) {}

  call() {
    this.dialogRef.close({
      selectedStation: this.selectedStation,
      saveAsDefault: this.saveAsDefault,
    });
  }
}
