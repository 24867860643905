import { MilestoneType, MilestoneTypes } from '@frontend/common/util';
import { Phase, User, Workflow } from './common-interfaces';

export interface StartPhaseFragment {
  __typename: MilestoneType;
  created_utc: string;
  created_by: string;
  workflow: Workflow;
  phase: Phase;
  user?: User;
}

export const START_PHASE_FRAGMENT = `
  fragment ${MilestoneTypes.StartPhaseMilestone} on ${MilestoneTypes.StartPhaseMilestone} {
    __typename
    created_utc
    created_by
    workflow(with_deleted: true) {
      name
    }
    phase(with_deleted: true) {
      name
    }
    user(with_deleted: true) {
      first_name
      last_name
      email
    }
  }
`;
