import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { WorkflowActions, WorkflowActionTypes } from './workflow.actions';
import { Workflow } from './workflow.model';

export interface State extends EntityState<Workflow> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Workflow> = createEntityAdapter<Workflow>({
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export function reducer(state = initialState, action: WorkflowActions): State {
  switch (action.type) {
    case WorkflowActionTypes.AddWorkflow: {
      return adapter.addOne(action.payload.workflow, state);
    }

    case WorkflowActionTypes.UpsertWorkflow: {
      return adapter.upsertOne(action.payload.workflow, state);
    }

    case WorkflowActionTypes.AddWorkflows: {
      return adapter.addMany(action.payload.workflows, state);
    }

    case WorkflowActionTypes.UpsertWorkflows: {
      return adapter.upsertMany(action.payload.workflows, state);
    }

    case WorkflowActionTypes.UpdateWorkflow: {
      return adapter.updateOne(action.payload.workflow, state);
    }

    case WorkflowActionTypes.UpdateWorkflows: {
      return adapter.updateMany(action.payload.workflows, state);
    }

    case WorkflowActionTypes.DeleteWorkflow: {
      return adapter.removeOne(action.payload.id, state);
    }

    case WorkflowActionTypes.DeleteWorkflows: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case WorkflowActionTypes.LoadWorkflows: {
      return adapter.setAll(action.payload.workflows, state);
    }

    case WorkflowActionTypes.ClearWorkflows: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
