import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PatientNetworkModel } from '../data-models';

export const CALLED_BY_COLUMN = 'calledBy';

const MULTIDIMENSIONAL_COLUMNS = [
  'workflowName',
  'timeInWorkflow',
  'phaseName',
  'phaseUpdatedUTC',
  'badgeStatus',
  CALLED_BY_COLUMN,
];

const SUPPORTED_COLUMNS = [
  ...MULTIDIMENSIONAL_COLUMNS,
  'locationName',
  'securedPatientName',
  'fullName',
  'durationInLocation',
  'mrn',
  'tagValue',
  'tagLabel',
  'appointmentTimeUTC',
  'appointmentType',
  'provider',
  'timeInDepartment',
  'estimatedWaitTime',
  'recipients',
  'note',
  'additional_note',
  'station',
];

@Component({
  selector: 'frontend-waiting-table',
  templateUrl: './waiting-table.component.html',
  styleUrls: ['./waiting-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WaitingTableComponent implements AfterViewInit, OnInit {
  columnSet = [];
  multidimensional = false;
  highlightPatientId: number = null;

  dataSource = new MatTableDataSource<PatientNetworkModel>();

  @ViewChild(MatPaginator) paginatorRef: MatPaginator;
  @ViewChild(MatSort) sortRef: MatSort;

  @Input() set columns(columns: string[]) {
    if (columns) {
      const cols = columns?.filter((c) => SUPPORTED_COLUMNS.indexOf(c) > -1);
      this.multidimensional = cols.some((c) => MULTIDIMENSIONAL_COLUMNS.indexOf(c) > -1);
      this.columnSet = [...cols, 'actionsColumn'];
    }
  }

  @Input() set collection(collection: PatientNetworkModel[]) {
    if (collection) {
      this.dataSource.data = collection;
    }
  }

  @Output() advancePhase = new EventEmitter();
  @Output() callPatient = new EventEmitter();
  @Output() changeDepartment = new EventEmitter();
  @Output() openVisitDetail = new EventEmitter();
  @Output() changePhase = new EventEmitter();
  @Output() endVisit = new EventEmitter();
  @Output() deletePatient = new EventEmitter();
  @Output() updateLocation = new EventEmitter();

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginatorRef;
    this.dataSource.sort = this.sortRef;
  }

  ngOnInit() {
    this.dataSource.sortData = (data, { active, direction }: MatSort) => {
      const order = direction === 'asc' ? 1 : -1;
      const inverseOrder = direction === 'asc' ? -1 : 1;
      switch (active) {
        case 'locationName': {
          return genericSort(
            data,
            (o) => o?.location?.alias_name,
            (A, B) => A.localeCompare(B),
            order
          );
        }
        case 'fullName': {
          return genericSort(
            data,
            (o) => o?.securableData?.fullName,
            (A, B) => A.localeCompare(B),
            order
          );
        }
        case 'workflowName': {
          const patients = data.map((patient) => {
            const patient_workflows = genericSort(
              patient.patient_workflows,
              (o) => o?.workflow?.name,
              (A, B) => A.localeCompare(B),
              order
            );
            return { ...patient, patient_workflows };
          });
          return genericSort(
            patients,
            (o) => o?.patient_workflows?.[0]?.workflow?.name,
            (A, B) => A.localeCompare(B),
            order
          );
        }
        case 'securedPatientName': {
          return genericSort(
            data,
            (o) => o?.secured_patient_name,
            (A, B) => A.localeCompare(B),
            order
          );
        }
        case 'note': {
          return genericSort(
            data,
            (o) => o?.note,
            (A, B) => A.localeCompare(B),
            order
          );
        }
        case 'additional_note': {
          return genericSort(
            data,
            (o) => o?.additional_note,
            (A, B) => A.localeCompare(B),
            order
          );
        }
        case 'mrn': {
          return genericSort(
            data,
            (o) => o?.securableData.mrn,
            (A, B) => A.localeCompare(B),
            order
          );
        }
        case 'tagValue': {
          return genericSort(
            data,
            (o) => o?.tag?.tag_value,
            (A, B) => A.localeCompare(B),
            order
          );
        }
        case 'tagLabel': {
          return genericSort(
            data,
            (o) => o?.tag?.label,
            (A, B) => A.localeCompare(B),
            order
          );
        }
        case 'phaseName': {
          const patients = data.map((patient) => {
            const patient_workflows = genericSort(
              patient.patient_workflows,
              (o) => o?.patient_workflow_phase_milestones?.[0]?.workflow_phase?.name,
              (A, B) => A.localeCompare(B),
              order
            );
            return { ...patient, patient_workflows };
          });
          return genericSort(
            patients,
            (o) =>
              o?.patient_workflows?.[0]?.patient_workflow_phase_milestones?.[0]
                ?.workflow_phase?.name,
            (A, B) => A.localeCompare(B),
            order
          );
        }
        case 'appointmentType': {
          const sortPatientWorkflows = function (patient: PatientNetworkModel) {
            if (!patient.patient_workflows.length) {
              return patient;
            }
            const patient_workflows = genericSort(
              patient.patient_workflows,
              (o) => {
                if (o.workflow.workflow_type_id === 1) {
                  return o?.patient_appointment?.appointment_type?.name;
                }
                return patient.nextAppointment?.appointment_type?.name;
              },
              (A, B) => A.localeCompare(B),
              order
            );
            return { ...patient, patient_workflows };
          };
          return genericSort(
            data.map(sortPatientWorkflows),
            (o) => {
              if (o?.patient_workflows?.[0]?.workflow?.workflow_type_id === 1) {
                return o.patient_workflows[0].patient_appointment?.appointment_type?.name;
              }
              return o?.nextAppointment?.appointment_type?.name;
            },
            (A, B) => A.localeCompare(B),
            order
          );
        }
        case 'provider': {
          const sortPatientWorkflows = function (patient: PatientNetworkModel) {
            if (!patient.patient_workflows.length) {
              return patient;
            }
            const patient_workflows = genericSort(
              patient.patient_workflows,
              (o) => {
                if (o.workflow.workflow_type_id === 1) {
                  return o?.patient_appointment?.provider?.fullName;
                }
                return patient.nextAppointment?.provider?.fullName;
              },
              (A, B) => A.localeCompare(B),
              order
            );
            return { ...patient, patient_workflows };
          };
          return genericSort(
            data.map(sortPatientWorkflows),
            (o) => {
              if (o?.patient_workflows?.[0]?.workflow?.workflow_type_id === 1) {
                return o.patient_workflows[0].patient_appointment?.provider?.fullName;
              }
              return o?.nextAppointment?.provider?.fullName;
            },
            (A, B) => A.localeCompare(B),
            order
          );
        }
        case 'recipients': {
          return genericSort(
            data,
            (o) => o?.recipientsNames,
            (A, B) => A.localeCompare(B),
            order
          );
        }
        case 'appointmentTimeUTC': {
          const sortPatientWorkflows = function (patient: PatientNetworkModel) {
            if (!patient.patient_workflows.length) {
              return patient;
            }
            const patient_workflows = genericSort(
              patient.patient_workflows,
              (o) => {
                if (o.workflow.workflow_type_id === 1) {
                  return o?.patient_appointment?.appointment_time_utc;
                }
                return patient.nextAppointment?.appointment_time_utc;
              },
              (A, B) => A.localeCompare(B),
              order
            );
            return { ...patient, patient_workflows };
          };
          return genericSort(
            data.map(sortPatientWorkflows),
            (o) => {
              if (o?.patient_workflows?.[0]?.workflow?.workflow_type_id === 1) {
                return o.patient_workflows[0].patient_appointment?.appointment_time_utc;
              }
              return o?.nextAppointment?.appointment_time_utc;
            },
            (A, B) => A.localeCompare(B),
            order
          );
        }
        case 'durationInLocation': {
          return genericSort(
            data,
            (o) => o?.sensed_utc,
            (A, B) => A.localeCompare(B),
            inverseOrder
          );
        }
        case 'timeInDepartment': {
          return genericSort(
            data,
            (o) => o?.created_utc,
            (A, B) => A.localeCompare(B),
            inverseOrder
          );
        }
        case 'timeInWorkflow': {
          const patients = data.map((patient) => {
            const patient_workflows = genericSort(
              patient.patient_workflows,
              (o) => o?.created_utc,
              (A, B) => A.localeCompare(B),
              inverseOrder
            );
            return { ...patient, patient_workflows };
          });
          return genericSort(
            patients,
            (o) => o?.patient_workflows?.[0]?.created_utc,
            (A, B) => A.localeCompare(B),
            inverseOrder
          );
        }
        case 'phaseUpdatedUTC': {
          const patients = data.map((patient) => {
            const patient_workflows = genericSort(
              patient.patient_workflows,
              (o) => o?.patient_workflow_phase_milestones?.[0]?.created_utc,
              (A, B) => A.localeCompare(B),
              inverseOrder
            );
            return { ...patient, patient_workflows };
          });
          return genericSort(
            patients,
            (o) =>
              o?.patient_workflows?.[0]?.patient_workflow_phase_milestones?.[0]
                ?.created_utc,
            (A, B) => A.localeCompare(B),
            inverseOrder
          );
        }
        case 'estimatedWaitTime': {
          const patients = data.map((patient) => {
            const patient_workflows = genericSort(
              patient.patient_workflows,
              (o) => o?.patient_workflow_phase_milestones?.[0]?.estimatedUtc,
              (A, B) => A.localeCompare(B),
              inverseOrder
            );
            return { ...patient, patient_workflows };
          });
          return genericSort(
            patients,
            (o) =>
              o?.patient_workflows?.[0]?.patient_workflow_phase_milestones?.[0]
                ?.estimatedUtc,
            (A, B) => A.localeCompare(B),
            inverseOrder
          );
        }
        case 'station': {
          const patients = data.map((patient) => {
            const patient_workflows = genericSort(
              patient.patient_workflows,
              (o) =>
                o?.patient_workflow_phase_milestones?.[0]?.call_events?.[0]?.station
                  ?.name,
              (A, B) => A.localeCompare(B),
              order
            );
            return { ...patient, patient_workflows };
          });
          return genericSort(
            patients,
            (o) =>
              o?.patient_workflows?.[0]?.patient_workflow_phase_milestones?.[0]
                ?.call_events?.[0]?.station?.name,
            (A, B) => A.localeCompare(B),
            order
          );
        }
        case 'badgeStatus': {
          return genericSort(
            data,
            (o) => o?.tag?.status,
            (A, B) => A.localeCompare(B),
            order
          );
        }
        case 'calledBy': {
          const patients = data.map((patient) => {
            const patient_workflows = genericSort(
              patient.patient_workflows,
              (o) => o?.patient_workflow_phase_milestones?.[0]?.calledBy,
              (A, B) => A.localeCompare(B),
              order
            );
            return { ...patient, patient_workflows };
          });
          return genericSort(
            patients,
            (o) =>
              o?.patient_workflows?.[0]?.patient_workflow_phase_milestones?.[0]?.calledBy,
            (A, B) => A.localeCompare(B),
            order
          );
        }
        default:
          console.warn(
            `A sorting function was not specified for "${active}" column. Using string by default.`
          );
          return data;
      }
    };
  }

  highlightPatient(patientId) {
    this.highlightPatientId = this.highlightPatientId === patientId ? null : patientId;
  }

  onAdvancePhase(patientWorkflowId, workflowId, workflowName, patientVisitId) {
    this.advancePhase.emit({
      patientWorkflowId,
      workflowId,
      workflowName,
      patientVisitId,
    });
  }

  onCallPatient(patientWorkflowId, phaseId) {
    this.callPatient.emit({ patientWorkflowId, phaseId });
  }

  onChangeDepartment(id) {
    this.changeDepartment.emit(id);
  }

  onOpenVisitDetail(id: number) {
    this.openVisitDetail.emit(id.toString());
  }

  onChangePhase(patientWorkflowId, workflowId) {
    this.changePhase.emit({ patientWorkflowId, workflowId });
  }

  onEndVisit(id) {
    this.endVisit.emit(id);
  }

  onDeletePatient(id) {
    this.deletePatient.emit(id);
  }

  onUpdateLocation(id, newStatus) {
    this.updateLocation.emit({ id, newStatus });
  }

  trackByFn(index, item) {
    return item.id;
  }
}

function genericSort<T = unknown, U = unknown>(
  data: T[],
  valueAccessor: (item: T) => U,
  sortFn: (a: U, b: U) => number,
  order: 1 | -1
): T[] {
  return data.slice().sort((a, b) => {
    const A = valueAccessor(a) || null;
    const B = valueAccessor(b) || null;
    if (A === B) {
      return 0;
    }
    if (A === null) {
      return 1;
    }
    if (B === null) {
      return -1;
    }
    return sortFn(A, B) * order;
  });
}
