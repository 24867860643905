import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'frontend-chat-list-search',
  templateUrl: './chat-list-search.component.html',
})
export class ChatListSearchComponent implements OnInit {
  @Output() searchChange = new EventEmitter<string>();

  searchCtrl = new FormControl(null);

  ngOnInit() {
    this.searchCtrl.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((val) => this.searchChange.emit(val));
  }

  onKeydown(event: KeyboardEvent) {
    if (event.code === 'Escape') {
      this.clear();
      event.stopPropagation();
    }
  }

  clear() {
    this.searchCtrl.setValue('');
  }
}
