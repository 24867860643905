import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SocketService } from '@frontend/common/util';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { EMPTY, forkJoin, merge, of, race, Subject, throwError } from 'rxjs';
import { catchError, filter, first, ignoreElements, map, mergeMap } from 'rxjs/operators';
import * as uuid from 'uuid/v4';

export interface WorkflowsNetworkModel {
  workflow: { id: number; name: string; workflow_type_id: number }[];
}

export const WORKFLOWS_DATA_QUERY = gql(`
query getWorkflowsByDepartmentId($departmentId: Int!) {
  workflow(where: {department_id: {_eq: $departmentId}, is_activated: {_eq: true}}, order_by: {name: asc}) {
    id
    name
    workflow_type_id
  }
}
`);

@Injectable()
export class ReassignPatientDialogService {
  private _data$ = new Subject<{ workflows: any[] }>();

  get loading$() {
    return this._data$.pipe(map((p) => !p));
  }

  get workflows$() {
    return this._data$.pipe(
      filter((data) => !!data?.workflows),
      map(({ workflows }) => workflows)
    );
  }

  constructor(
    private apollo: Apollo,
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private socketSrv: SocketService
  ) {}

  getData(departmentId) {
    forkJoin({
      workflows: this._getWorkflows(departmentId),
    }).subscribe(this._data$);
  }

  private _getWorkflows(departmentId) {
    return this.apollo
      .subscribe<WorkflowsNetworkModel>({
        query: WORKFLOWS_DATA_QUERY,
        variables: { departmentId },
      })
      .pipe(
        map(({ data }) => data?.workflow),
        catchError(() => {
          this.snackBar.open(
            $localize`There was an unexpected error.`,
            $localize`Close`,
            {
              duration: 10000,
            }
          );
          return EMPTY;
        })
      );
  }

  public assignToWorkflow(patient_department_visit_id, workflow_id) {
    const correlation_id = 'correlation|' + uuid();
    return merge(
      this.http
        .post('patient-workflows', {
          correlation_id,
          workflow_id,
          patient_department_visit_id,
        })
        .pipe(
          ignoreElements(),
          catchError((err) =>
            of(err?.error?.message).pipe(
              mergeMap((message) =>
                throwError({ id: patient_department_visit_id, message })
              )
            )
          )
        ),
      race(
        this.socketSrv
          .fromLocalSuccess<any>('patient-workflow.create')
          .pipe(filter((ws) => ws.correlation_id === correlation_id)),
        this.socketSrv.fromLocalError('patient-workflow.create').pipe(
          filter((ws) => ws.correlation_id === correlation_id),
          mergeMap(({ message }) =>
            throwError({ id: patient_department_visit_id, message })
          )
        )
      ).pipe(first())
    );
  }
}
