import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AppointmentsService } from './appointments.service';

export interface CardData {
  departmentId: string;
  appointments: Appointment[];
}

export interface Appointment {
  id: number;
  provider: string;
  type: string;
  time?: string;
}

@Component({
  selector: 'frontend-visit-detail-appointments',
  templateUrl: 'appointments.component.html',
  styleUrls: ['../common.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AppointmentsService],
})
export class AppointmentsComponent {
  @Input() visitCompleted?: boolean;

  constructor(public srv: AppointmentsService) {}

  trackByFn(index: number, item: Appointment) {
    return item.id;
  }
}
