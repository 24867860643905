import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BulkUploaderService } from '@frontend/common/bulk-uploader';
import { BaseService } from '@frontend/shared/base-store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { usersActions } from './store/users.actions';
import { User } from './user.model';

@UntilDestroy()
@Injectable()
export class UsersService extends BaseService<User> implements OnDestroy {
  constructor(
    http: HttpClient,
    private bulkUploaderSrv: BulkUploaderService,
    private store: Store<any>
  ) {
    super(http, 'users');

    this.bulkUploaderSrv.finishedEntityOperation$
      .pipe(
        untilDestroyed(this),
        filter((entity) => entity === 'users')
      )
      .subscribe(() => this.store.dispatch(usersActions.load()));
  }

  ngOnDestroy() {}

  changeStatus(user: User): Observable<object> {
    return this.http.patch(`users/${user.id}/${user.is_blocked ? 'block' : 'unblock'}`, {
      correlation_id: user.correlation_id,
    });
  }

  resetPassword(user: User): Observable<object> {
    return this.http.patch(`users/${user.id}/reset-password`, {
      correlation_id: user.correlation_id,
    });
  }
}
