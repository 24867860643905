<h2 mat-dialog-title *ngIf="data.title">{{ data.title }}</h2>
<div mat-dialog-content class="mat-body-1" *ngIf="data.content">
  <p>{{ data.content }}</p>
</div>
<mat-slide-toggle
  class="dialog-slide-toggle"
  *ngIf="data.withToggle"
  (change)="isChecked$.next($event.checked)"
  i18n
>
  Please turn on toggle to confirm
</mat-slide-toggle>
<div mat-dialog-actions align="end">
  <ng-container
    *ngIf="data.invertButtons; then reversedTemplate; else defaultTemplate"
  ></ng-container>
</div>
<ng-template #defaultTemplate>
  <ng-container *ngTemplateOutlet="confirmTemplate"></ng-container>
  <ng-container *ngTemplateOutlet="cancelTemplate"></ng-container>
</ng-template>
<ng-template #reversedTemplate>
  <ng-container *ngTemplateOutlet="cancelTemplate"></ng-container>
  <ng-container *ngTemplateOutlet="confirmTemplate"></ng-container>
</ng-template>
<ng-template #cancelTemplate>
  <button mat-button [mat-dialog-close]="false" *ngIf="data.cancel">
    {{ data.cancel }}
  </button>
</ng-template>
<ng-template #confirmTemplate>
  <button
    [disabled]="data.withToggle ? !(isChecked$ | async) : false"
    mat-button
    [mat-dialog-close]="true"
    *ngIf="data.confirm"
  >
    {{ data.confirm }}
  </button>
</ng-template>
