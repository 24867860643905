import gql from 'graphql-tag';

export interface WorkflowsDataQuery {
  patient: Visit;
}

export interface Visit {
  id: number;
  created_utc: string;
  department: Department;
  patient_department_visit_workflows: PatientWorkflow[];
}

interface Department {
  id: string;
}

export interface PatientWorkflow {
  id: number;
  workflow: { id: number; name: string };
  created_utc: string;
  completed_utc: string;
  patient_workflow_phase_milestones: PhaseMilestone[];
}

export interface PhaseMilestone {
  created_utc: string;
  completed_utc: string;
  called_count: number;
  workflow_phase: {
    id: number;
    name: string;
    max_phase_duration_minutes_low: number;
    max_phase_duration_minutes_high: number;
    is_call_patient_enabled: boolean;
  };
  call_events: CallEvent[];
}

export interface CallEvent {
  created_utc: string;
  station: {
    name: string;
  };
  user: {
    email:string;
    first_name: string;
    last_name: string;
  };
}

export const PatientWorkflowsDataQuery = gql(`
query PatientWorkflows($visitId: String!) {
  patient( id: $visitId ) {
    id
    created_utc
    department {
      id
    }
    patient_department_visit_workflows {
      workflow {
        id
        name
      }
      id
      created_utc
      completed_utc
      patient_workflow_phase_milestones {
        completed_utc
        created_utc
        workflow_phase {
          id
          name
          max_phase_duration_minutes_low
          max_phase_duration_minutes_high
          is_call_patient_enabled
        }
        called_count
        call_events {
          created_utc
          station(with_deleted: true) {
            name
          }
          user(with_deleted: true) {
            email
            first_name
            last_name
          }
        }
      }
    }
  }
}
`);
