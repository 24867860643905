import { Injectable } from '@angular/core';
import { PhAuthService } from '@frontend/common/src/lib/ph-auth';
import { PhConfigLoaderService } from '@frontend/common/src/lib/ph-config-loader';
import { CurrentCustomerService } from '@frontend/common/src/lib/util';
import { Customer } from '@frontend/entity';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

interface LicenseInfo {
  is_assets_rt_enabled: boolean;
  is_hand_hygiene_enabled: boolean;
  is_workflow_rt_enabled: boolean;
}
@Injectable()
export class AppSwitcherService {
  customer$ = this.currentCustomerSrv.customer$.pipe(shareReplay(1));
  licenses$: Observable<LicenseInfo>;
  subdomain$: Observable<string>;

  assetsAvailable$: Observable<boolean>;
  assetsTplURL$: Observable<string>;
  assetsURL$: Observable<string>;

  hhAvailable$: Observable<boolean>;
  hhTplURL$: Observable<string>;
  hhURL$: Observable<string>;

  caAvailable$: Observable<boolean>;
  caURL$: Observable<string>;
  caTplURL$: Observable<string>;

  connectTplURL$: Observable<string>;
  connectURL$: Observable<string>;

  constructor(
    private phAuthSrv: PhAuthService,
    private config: PhConfigLoaderService,
    private currentCustomerSrv: CurrentCustomerService
  ) {
    this.licenses$ = this.customer$.pipe(
      map((customerData: Customer) => customerData?.license_info)
    );
    this.subdomain$ = this.customer$.pipe(
      map((customerData: Customer) => customerData?.subdomain)
    );

    this.assetsTplURL$ = this.config.getConfig$('????????????');
    this.caTplURL$ = this.config.getConfig$('FRONTEND_CA_URL');
    this.hhTplURL$ = this.config.getConfig$('FRONTEND_HH_URL');
    this.connectTplURL$ = this.config.getConfig$('FRONTEND_CONNECT_RT_HOME_URL');

    this.assetsAvailable$ = combineLatest([
      this.phAuthSrv.scopes$.pipe(
        map((scopes) => {
          // TODO: Implement Assets roles and permissions (separate ticket)
          // return scopes.includes('access:art');
          return false;
        })
      ),
      this.licenses$.pipe(map((licenses) => !!licenses?.is_assets_rt_enabled)),
    ]).pipe(map(([permission, license]) => permission && license));

    this.assetsURL$ = combineLatest([this.assetsTplURL$, this.subdomain$]).pipe(
      map(([tpl, subdomain]) => tpl.replace('[CUSTOMER]', subdomain))
    );

    this.caAvailable$ = this.phAuthSrv.scopes$.pipe(
      map((scopes) => scopes.includes('access:ca'))
    );

    this.caURL$ = combineLatest([this.caTplURL$, this.subdomain$]).pipe(
      map(([tpl, subdomain]) => tpl.replace('[CUSTOMER]', subdomain))
    );

    this.hhAvailable$ = combineLatest([
      this.phAuthSrv.scopes$.pipe(
        map((scopes) => {
          // TODO: Implement HH roles and permissions (separate ticket)
          // return scopes.includes('access:hh');
          return true;
        })
      ),
      this.licenses$.pipe(map((licenses) => !!licenses?.is_hand_hygiene_enabled)),
    ]).pipe(map(([permission, license]) => permission && license));

    this.hhURL$ = combineLatest([this.hhTplURL$, this.subdomain$]).pipe(
      map(([tpl, subdomain]) => tpl.replace('[CUSTOMER]', subdomain))
    );

    this.connectURL$ = combineLatest([this.connectTplURL$, this.subdomain$]).pipe(
      map(([tpl, subdomain]) => tpl.replace('[CUSTOMER]', subdomain))
    );
  }
}
