import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PatientApiEffects } from './patient-api/patient-api.effects';
import { PatientWsEffects } from './patient-ws/patient-ws.effects';
import * as fromPatient from './patient/patient.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('patient', fromPatient.reducer),
    EffectsModule.forFeature([PatientApiEffects, PatientWsEffects]),
  ],
})
export class SharedPatientDataAccessModule {}
