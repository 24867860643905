import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SocketService } from '@frontend/common/util';
import { createEffect } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs/operators';
import { TextMessage } from '../text-message/text-message.model';
import {
  GlobalCreateTextMessageSuccessWs,
  GlobalRemoveTextMessageSuccessWs,
  GlobalUpdateTextMessageSuccessWs,
  LocalCreateTextMessageFailureWs,
  LocalCreateTextMessageSuccessWs,
  LocalRemoveTextMessageFailureWs,
  LocalRemoveTextMessageSuccessWs,
  LocalUpdateTextMessageFailureWs,
  LocalUpdateTextMessageSuccessWs,
} from './text-message-ws.actions';

@Injectable()
export class TextMessageWsEffects {
  LocalCreateTextMessageSuccessWs$ = createEffect(() =>
    this.socketSrv
      .fromLocalSuccess('text-messages.create')
      .pipe(
        map(
          (textMessage: TextMessage) => new LocalCreateTextMessageSuccessWs(textMessage)
        )
      )
  );

  LocalCreateTextMessageFailureWs$ = createEffect(() =>
    this.socketSrv
      .fromLocalError('text-messages.create')
      .pipe(
        map(
          ({ correlation_id, message }) =>
            new LocalCreateTextMessageFailureWs({ correlation_id, message })
        )
      )
  );

  LocalUpdateTextMessageSuccessWs$ = createEffect(() =>
    this.socketSrv
      .fromLocalSuccess('text-messages.update')
      .pipe(
        map(
          (textMessage: TextMessage) => new LocalUpdateTextMessageSuccessWs(textMessage)
        )
      )
  );

  LocalUpdateTextMessageFailureWs$ = createEffect(() =>
    this.socketSrv
      .fromLocalError('text-messages.update')
      .pipe(
        map(
          ({ correlation_id, message }) =>
            new LocalUpdateTextMessageFailureWs({ correlation_id, message })
        )
      )
  );

  LocalRemoveTextMessageSuccessWs$ = createEffect(() =>
    this.socketSrv
      .fromLocalSuccess('text-messages.remove')
      .pipe(
        map(
          ({ correlation_id }) => new LocalRemoveTextMessageSuccessWs({ correlation_id })
        )
      )
  );

  LocalRemoveTextMessageFailureWs$ = createEffect(() =>
    this.socketSrv
      .fromLocalError('text-messages.remove')
      .pipe(
        map(
          ({ correlation_id, message }) =>
            new LocalRemoveTextMessageFailureWs({ correlation_id, message })
        )
      )
  );

  GlobalCreateTextMessageSuccessWs$ = createEffect(() =>
    this.socketSrv.fromGlobalSuccess('text-messages.create').pipe(
      mergeMap((textMessage: any) =>
        this.http.get(`securable/${textMessage.body_securable_id}`).pipe(
          map((sec: { body: any }) => ({
            ...textMessage,
            text: sec.body.text,
          }))
        )
      ),
      map((textMessage: any) => new GlobalCreateTextMessageSuccessWs(textMessage))
    )
  );

  GlobalUpdateTextMessageSuccessWs$ = createEffect(() =>
    this.socketSrv
      .fromGlobalSuccess('text-messages.update')
      .pipe(map((textMessage: any) => new GlobalUpdateTextMessageSuccessWs(textMessage)))
  );

  GlobalRemoveTextMessageSuccessWs$ = createEffect(() =>
    this.socketSrv
      .fromGlobalSuccess('text-messages.remove')
      .pipe(map((id: any) => new GlobalRemoveTextMessageSuccessWs(id)))
  );

  constructor(private socketSrv: SocketService, private http: HttpClient) {}
}
