import { Injectable } from '@angular/core';
import { SocketService } from '@frontend/common/util';
import { createEffect } from '@ngrx/effects';
import { merge } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';
import {
  GlobalActivateWorkflowSuccessWs,
  GlobalCopyWorkflowSuccessWs,
  GlobalCreateWorkflowSuccessWs,
  GlobalDeactivateWorkflowSuccessWs,
  GlobalRemoveWorkflowSuccessWs,
  GlobalTagStationUpdateWs,
  GlobalUpdateWorkflowSuccessWs,
  LocalCreateWorkflowFailureWs,
  LocalCreateWorkflowSuccessWs,
  LocalDisableWorkflowFailureWs,
  LocalDisableWorkflowSuccessWs,
  LocalEnableWorkflowFailureWs,
  LocalEnableWorkflowSuccessWs,
  LocalRemoveWorkflowFailureWs,
  LocalRemoveWorkflowSuccessWs,
  LocalUpdateWorkflowFailureWs,
  LocalUpdateWorkflowSuccessWs,
} from './workflow-ws.actions';

@Injectable()
export class WorkflowWsEffects {
  LocalCreateWorkflowSuccessWs$ = createEffect(() =>
    this.socketSrv
      .fromLocalSuccess('workflows.create')
      .pipe(
        map(
          ({ id, correlation_id }) =>
            new LocalCreateWorkflowSuccessWs({ id, correlation_id })
        )
      )
  );

  LocalCreateWorkflowFailureWs$ = createEffect(() =>
    this.socketSrv
      .fromLocalError('workflows.create')
      .pipe(
        map(
          ({ correlation_id, message }) =>
            new LocalCreateWorkflowFailureWs({ correlation_id, message })
        )
      )
  );

  LocalUpdateWorkflowSuccessWs$ = createEffect(() =>
    this.socketSrv
      .fromLocalSuccess('workflows.update')
      .pipe(
        map(
          ({ id, correlation_id }) =>
            new LocalUpdateWorkflowSuccessWs({ id, correlation_id })
        )
      )
  );

  LocalUpdateWorkflowFailureWs$ = createEffect(() =>
    this.socketSrv
      .fromLocalError('workflows.update')
      .pipe(
        map(
          ({ correlation_id, message }) =>
            new LocalUpdateWorkflowFailureWs({ correlation_id, message })
        )
      )
  );

  LocalRemoveWorkflowSuccessWs$ = createEffect(() =>
    this.socketSrv
      .fromLocalSuccess('workflows.remove')
      .pipe(
        map(({ correlation_id }) => new LocalRemoveWorkflowSuccessWs({ correlation_id }))
      )
  );

  LocalRemoveWorkflowFailureWs$ = createEffect(() =>
    this.socketSrv
      .fromLocalError('workflows.remove')
      .pipe(
        map(
          ({ correlation_id, message }) =>
            new LocalRemoveWorkflowFailureWs({ correlation_id, message })
        )
      )
  );

  LocalEnableWorkflowSuccessWs$ = createEffect(() =>
    this.socketSrv
      .fromLocalSuccess('workflows.activate')
      .pipe(
        map(({ correlation_id }) => new LocalEnableWorkflowSuccessWs({ correlation_id }))
      )
  );

  LocalEnableWorkflowFailureWs$ = createEffect(() =>
    this.socketSrv
      .fromLocalError('workflows.activate')
      .pipe(
        map(
          ({ correlation_id, message }) =>
            new LocalEnableWorkflowFailureWs({ correlation_id, message })
        )
      )
  );

  LocalDisableWorkflowSuccessWs$ = createEffect(() =>
    this.socketSrv
      .fromLocalSuccess('workflows.deactivate')
      .pipe(
        map(({ correlation_id }) => new LocalDisableWorkflowSuccessWs({ correlation_id }))
      )
  );

  LocalDisableWorkflowFailureWs$ = createEffect(() =>
    this.socketSrv
      .fromLocalError('workflows.deactivate')
      .pipe(
        map(
          ({ correlation_id, message }) =>
            new LocalDisableWorkflowFailureWs({ correlation_id, message })
        )
      )
  );

  GlobalCreateWorkflowSuccessWs$ = createEffect(() =>
    this.socketSrv
      .fromGlobalSuccess('workflows.create')
      .pipe(map((workflow: any) => new GlobalCreateWorkflowSuccessWs({ workflow })))
  );

  GlobalCopyWorkflowSuccessWs$ = createEffect(() =>
    this.socketSrv
      .fromGlobalSuccess('workflows.copy')
      .pipe(map((workflow: any) => new GlobalCopyWorkflowSuccessWs({ workflow })))
  );

  GlobalUpdateWorkflowSuccessWs$ = createEffect(() =>
    this.socketSrv
      .fromGlobalSuccess('workflows.update')
      .pipe(map((workflow: any) => new GlobalUpdateWorkflowSuccessWs({ workflow })))
  );

  GlobalRemoveWorkflowSuccessWs$ = createEffect(() =>
    this.socketSrv
      .fromGlobalSuccess('workflows.remove')
      .pipe(map(({ id }: any) => new GlobalRemoveWorkflowSuccessWs({ id })))
  );

  GlobalActivateWorkflowSuccessWs$ = createEffect(() =>
    this.socketSrv
      .fromGlobalSuccess('workflows.activate')
      .pipe(map(({ id }: any) => new GlobalActivateWorkflowSuccessWs({ id })))
  );

  GlobalDeactivateWorkflowSuccessWs$ = createEffect(() =>
    this.socketSrv
      .fromGlobalSuccess('workflows.deactivate')
      .pipe(map(({ id }: any) => new GlobalDeactivateWorkflowSuccessWs({ id })))
  );

  GlobalTagStationUpdateWs$ = createEffect(() =>
    merge(
      this.socketSrv.fromGlobalSuccess('workflows.add-location'),
      this.socketSrv.fromGlobalSuccess('workflows.remove-location')
    ).pipe(mapTo(new GlobalTagStationUpdateWs()))
  );

  constructor(private socketSrv: SocketService) {}
}
