import { Component, OnDestroy } from '@angular/core';
import { PhAuthService } from '@frontend/common/ph-auth';
import { PhConfigLoaderService } from '@frontend/common/ph-config-loader';
import { CheckForUpdateService } from '@frontend/common/updater';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { LoadApp } from './app.actions';

@UntilDestroy()
@Component({
  selector: 'wrt-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnDestroy {
  disableAccount$: Observable<boolean>;
  isLoading$: Observable<boolean>;

  constructor(
    private store: Store<any>,
    private config: PhConfigLoaderService,
    private updater: CheckForUpdateService,
    private phAuthSrv: PhAuthService
  ) {
    this.phAuthSrv.user$.subscribe((user) => {
      if (user?.sub) {
        Sentry.configureScope((scope) => {
          scope.setUser({ id: user.sub });
        });
      }
    });

    this.disableAccount$ = of(this.config.getConfig('CUSTOMER_ID') === 'disabled');
    this.isLoading$ = this.disableAccount$.pipe(
      mergeMap((disable) =>
        disable
          ? of(false)
          : this.phAuthSrv.isAuthenticated$.pipe(
              map((isAuthenticated) => !isAuthenticated)
            )
      )
    );

    this.isLoading$
      .pipe(untilDestroyed(this))
      .subscribe((loading) => !loading && this.store.dispatch(new LoadApp()));
  }

  ngOnDestroy(): void {}
}
