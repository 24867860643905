import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class Document360Service {
  constructor(@Inject(DOCUMENT) private document) {}

  load(apiKey) {
    (function (w, d, s, o, f, js, fjs) {
      w['JS-Widget'] = o;
      w[o] =
        w[o] ||
        function () {
          (w[o].q = w[o].q || []).push(arguments);
        };
      (js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]);
      js.id = o;
      js.src = f;
      js.async = 1;
      fjs.parentNode.insertBefore(js, fjs);
    })(
      window,
      this.document,
      'script',
      'mw',
      'https://cdn.document360.io/static/js/widget.js'
    );
    (window as any).mw('init', { apiKey });
  }
}
