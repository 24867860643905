<mat-form-field class="full-width" appearance="outline">
  <button
    mat-mini-fab
    color="primary"
    matSuffix
    (click)="submit(msg.value)"
    style="margin-bottom: 14px"
    [disabled]="messageCtrl.invalid || messageCtrl.disabled || sendButtonDisabled"
  >
    <mat-icon>send</mat-icon>
  </button>

  <textarea
    matInput
    #msg
    placeholder="Type a message..."
    [formControl]="messageCtrl"
    cdkTextareaAutosize
    cdkAutosizeMinRows="2"
    cdkAutosizeMaxRows="3"
    [matAutocomplete]="auto"
  ></textarea>
  <mat-hint
    >Please remember that messages should not include any Personal Health Information
    (PHI).</mat-hint
  >
  <mat-hint align="end">{{ msg.value?.length || 0 }}/160</mat-hint>
</mat-form-field>

<mat-autocomplete #auto="matAutocomplete">
    <mat-option
      *ngFor="let quickMessage of filteredQuickMessages$ | async"
      [value]="quickMessage.message"
      [title]="quickMessage.message"
      >{{ quickMessage.staff_label ? quickMessage.staff_label : quickMessage.message }}
    </mat-option>
</mat-autocomplete>
