import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable()
export class SecurableService {
  constructor(private http: HttpClient) {}

  create(data) {
    const secure = {
      first_name: data.first_name,
      last_name: data.last_name,
      mrn: data.mrn,
      phone_number: data.phone_number,
    };
    return this.http
      .post('securable', secure)
      .pipe(
        map((securable: { id: string }) => ({ ...data, securable_id: securable.id }))
      );
  }
}
