import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Recipient } from './recipient.model';

export enum RecipientActionTypes {
  LoadRecipients = '[Recipient] Load Recipients',
  AddRecipient = '[Recipient] Add Recipient',
  UpsertRecipient = '[Recipient] Upsert Recipient',
  AddRecipients = '[Recipient] Add Recipients',
  UpsertRecipients = '[Recipient] Upsert Recipients',
  UpdateRecipient = '[Recipient] Update Recipient',
  UpdateRecipients = '[Recipient] Update Recipients',
  DeleteRecipient = '[Recipient] Delete Recipient',
  DeleteRecipients = '[Recipient] Delete Recipients',
  ClearRecipients = '[Recipient] Clear Recipients',
}

export class LoadRecipients implements Action {
  readonly type = RecipientActionTypes.LoadRecipients;

  constructor(public payload: { recipients: Recipient[] }) {}
}

export class AddRecipient implements Action {
  readonly type = RecipientActionTypes.AddRecipient;

  constructor(public payload: { recipient: Recipient }) {}
}

export class UpsertRecipient implements Action {
  readonly type = RecipientActionTypes.UpsertRecipient;

  constructor(public payload: { recipient: Recipient }) {}
}

export class AddRecipients implements Action {
  readonly type = RecipientActionTypes.AddRecipients;

  constructor(public payload: { recipients: Recipient[] }) {}
}

export class UpsertRecipients implements Action {
  readonly type = RecipientActionTypes.UpsertRecipients;

  constructor(public payload: { recipients: Recipient[] }) {}
}

export class UpdateRecipient implements Action {
  readonly type = RecipientActionTypes.UpdateRecipient;

  constructor(public payload: { recipient: Update<Recipient> }) {}
}

export class UpdateRecipients implements Action {
  readonly type = RecipientActionTypes.UpdateRecipients;

  constructor(public payload: { recipients: Update<Recipient>[] }) {}
}

export class DeleteRecipient implements Action {
  readonly type = RecipientActionTypes.DeleteRecipient;

  constructor(public payload: { id: string }) {}
}

export class DeleteRecipients implements Action {
  readonly type = RecipientActionTypes.DeleteRecipients;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearRecipients implements Action {
  readonly type = RecipientActionTypes.ClearRecipients;
}

export type RecipientActions =
  | LoadRecipients
  | AddRecipient
  | UpsertRecipient
  | AddRecipients
  | UpsertRecipients
  | UpdateRecipient
  | UpdateRecipients
  | DeleteRecipient
  | DeleteRecipients
  | ClearRecipients;
