import { Injectable } from '@angular/core';
import { SocketService } from '@frontend/common/util';
import { createEffect } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import {
  GlobalAddStaffDepartmentWs,
  GlobalCreateStaffSuccessWs,
  GlobalMoveStaffSuccessWs,
  GlobalRemoveStaffDepartmentWs,
  GlobalRemoveStaffSuccessWs,
  GlobalUpdateStaffSuccessWs,
  LocalCreateStaffFailureWs,
  LocalCreateStaffSuccessWs,
  LocalRemoveStaffFailureWs,
  LocalRemoveStaffSuccessWs,
  LocalUpdateStaffFailureWs,
  LocalUpdateStaffSuccessWs,
} from './staff-ws.actions';

@Injectable()
export class StaffWsEffects {
  LocalCreateStaffSuccessWs$ = createEffect(() =>
    this.socketSrv
      .fromLocalSuccess('staff.create')
      .pipe(
        map(
          ({ id, correlation_id }) =>
            new LocalCreateStaffSuccessWs({ id, correlation_id })
        )
      )
  );

  LocalCreateStaffFailureWs$ = createEffect(() =>
    this.socketSrv
      .fromLocalError('staff.create')
      .pipe(
        map(
          ({ correlation_id, message }) =>
            new LocalCreateStaffFailureWs({ correlation_id, message })
        )
      )
  );

  LocalUpdateStaffSuccessWs$ = createEffect(() =>
    this.socketSrv
      .fromLocalSuccess('staff.update')
      .pipe(
        map(
          ({ id, correlation_id }) =>
            new LocalUpdateStaffSuccessWs({ id, correlation_id })
        )
      )
  );

  LocalUpdateStaffFailureWs$ = createEffect(() =>
    this.socketSrv
      .fromLocalError('staff.update')
      .pipe(
        map(
          ({ correlation_id, message }) =>
            new LocalUpdateStaffFailureWs({ correlation_id, message })
        )
      )
  );

  LocalRemoveStaffSuccessWs$ = createEffect(() =>
    this.socketSrv
      .fromLocalSuccess('staff.remove')
      .pipe(
        map(({ correlation_id }) => new LocalRemoveStaffSuccessWs({ correlation_id }))
      )
  );

  LocalRemoveStaffFailureWs$ = createEffect(() =>
    this.socketSrv
      .fromLocalError('staff.remove')
      .pipe(
        map(
          ({ correlation_id, message }) =>
            new LocalRemoveStaffFailureWs({ correlation_id, message })
        )
      )
  );

  GlobalCreateStaffSuccessWs$ = createEffect(() =>
    this.socketSrv
      .fromGlobalSuccess('staff.create')
      .pipe(map((staff: any) => new GlobalCreateStaffSuccessWs(staff)))
  );

  GlobalUpdateStaffSuccessWs$ = createEffect(() =>
    this.socketSrv
      .fromGlobalSuccess('staff.update')
      .pipe(map((staff: any) => new GlobalUpdateStaffSuccessWs(staff)))
  );

  GlobalRemoveStaffSuccessWs$ = createEffect(() =>
    this.socketSrv
      .fromGlobalSuccess('staff.remove')
      .pipe(map(({ id }: any) => new GlobalRemoveStaffSuccessWs(id)))
  );

  GlobalMoveStaffSuccessWs$ = createEffect(() =>
    this.socketSrv
      .fromGlobalSuccess('staff.move')
      .pipe(
        map(
          ({ id, location_alias_name, sensed_utc }: any) =>
            new GlobalMoveStaffSuccessWs({ id, location_alias_name, sensed_utc })
        )
      )
  );

  GlobalAddStaffDepartmentSuccessWs$ = createEffect(() =>
    this.socketSrv
      .fromGlobalSuccess('staff.add-department')
      .pipe(
        map(
          ({ staff, ...staffDepartment }: any) =>
            new GlobalAddStaffDepartmentWs({ ...staffDepartment, staff })
        )
      )
  );

  GlobalRemoveStaffDepartmentSuccessWs$ = createEffect(() =>
    this.socketSrv
      .fromGlobalSuccess('staff.remove-department')
      .pipe(
        map(
          ({ staff, ...staffDepartment }: any) =>
            new GlobalRemoveStaffDepartmentWs({ ...staffDepartment, staff })
        )
      )
  );

  constructor(private socketSrv: SocketService) {}
}
