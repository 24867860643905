import { MilestoneType, MilestoneTypes } from '@frontend/common/util';
import { Workflow } from './common-interfaces';

export interface EndWorkflowFragment {
  __typename: MilestoneType;
  created_utc: string;
  created_by: string;
  workflow: Workflow;
}

export const END_WORKFLOW_FRAGMENT = `
  fragment ${MilestoneTypes.EndWorkflowMilestone} on ${MilestoneTypes.EndWorkflowMilestone} {
    __typename
    created_utc
    created_by
    workflow(with_deleted: true) {
      name
    }
  }
`;
