import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { PatientNetworkModel } from '../../../data-models';
import * as WaitingDataActions from './waiting.actions';

export const featureKey = 'waiting';

export const adapter: EntityAdapter<PatientNetworkModel> = createEntityAdapter<
  PatientNetworkModel
>();

export interface State extends EntityState<PatientNetworkModel> {
  loading: boolean;
  columns: string[];
}

export const initialState: State = adapter.getInitialState({
  loading: true,
  columns: null,
});

export const reducer = createReducer(
  initialState,
  on(WaitingDataActions.loadWaitingData, (state, { columns, collection }) => ({
    ...adapter.setAll(collection, state),
    loading: false,
    columns,
  })),
  on(WaitingDataActions.clearWaitingData, () => initialState)
);

export const { selectAll } = adapter.getSelectors();
