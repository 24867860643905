import { Action } from '@ngrx/store';
import { Staff } from '../staff/staff.model';

export enum StaffApiActionTypes {
  BulkUploadStaffApi = '[Staff API] Bulk Upload Staff',
  BulkUploadStaffSuccessApi = '[Staff API] Bulk Upload Staff Success',
  BulkUploadStaffFailureApi = '[Staff API] Bulk Upload Staff Failure',
  LoadStaffsApi = '[Staff API] Load Staffs',
  LoadStaffsSuccessApi = '[Staff API] Load Staffs Success',
  LoadStaffsFailureApi = '[Staff API] Load Staffs Failure',
  LoadStaffApi = '[Staff API] Load Staff',
  LoadStaffSuccessApi = '[Staff API] Load Staff Success',
  LoadStaffFailureApi = '[Staff API] Load Staff Failure',
  CreateStaffApi = '[Staff API] Create Staff',
  CreateStaffSuccessApi = '[Staff API] Create Staff Success',
  CreateStaffFailureApi = '[Staff API] Create Staff Failure',
  UpdateStaffApi = '[Staff API] Update Staff',
  UpdateStaffSuccessApi = '[Staff API] Update Staff Success',
  UpdateStaffFailureApi = '[Staff API] Update Staff Failure',
  DeleteStaffApi = '[Staff API] Delete Staff',
  DeleteStaffSuccessApi = '[Staff API] Delete Staff Success',
  DeleteStaffFailureApi = '[Staff API] Delete Staff Failure',
}

export class BulkUploadStaffApi implements Action {
  readonly type = StaffApiActionTypes.BulkUploadStaffApi;
  constructor(public payload: { items: Staff[]; correlation_id: string }) {}
}

export class BulkUploadStaffSuccessApi implements Action {
  readonly type = StaffApiActionTypes.BulkUploadStaffSuccessApi;
}

export class BulkUploadStaffFailureApi implements Action {
  readonly type = StaffApiActionTypes.BulkUploadStaffFailureApi;
  constructor(public payload: { correlation_id: string }) {}
}

export class LoadStaffsApi implements Action {
  readonly type = StaffApiActionTypes.LoadStaffsApi;
  constructor(public payload: { params: any } = { params: {} }) {}
}

export class LoadStaffsSuccessApi implements Action {
  readonly type = StaffApiActionTypes.LoadStaffsSuccessApi;
  constructor(public payload: { staffs: Staff[] }) {}
}

export class LoadStaffsFailureApi implements Action {
  readonly type = StaffApiActionTypes.LoadStaffsFailureApi;
  constructor(public payload: { message: string }) {}
}

export class LoadStaffApi implements Action {
  readonly type = StaffApiActionTypes.LoadStaffApi;
  constructor(public payload: { id: string }) {}
}

export class LoadStaffSuccessApi implements Action {
  readonly type = StaffApiActionTypes.LoadStaffSuccessApi;
  constructor(public payload: { staff: Staff }) {}
}

export class LoadStaffFailureApi implements Action {
  readonly type = StaffApiActionTypes.LoadStaffFailureApi;
  constructor(public payload: { id: string; message: string }) {}
}

export class CreateStaffApi implements Action {
  readonly type = StaffApiActionTypes.CreateStaffApi;
  constructor(public payload: { staff: Staff }) {}
}

export class CreateStaffSuccessApi implements Action {
  readonly type = StaffApiActionTypes.CreateStaffSuccessApi;
  constructor(public payload: { staff: Staff }) {}
}

export class CreateStaffFailureApi implements Action {
  readonly type = StaffApiActionTypes.CreateStaffFailureApi;
  constructor(public payload: { id: string; message: string }) {}
}

export class UpdateStaffApi implements Action {
  readonly type = StaffApiActionTypes.UpdateStaffApi;
  constructor(public payload: { staff: Staff }) {}
}

export class UpdateStaffSuccessApi implements Action {
  readonly type = StaffApiActionTypes.UpdateStaffSuccessApi;
  constructor(public payload: { staff: Staff }) {}
}

export class UpdateStaffFailureApi implements Action {
  readonly type = StaffApiActionTypes.UpdateStaffFailureApi;
  constructor(public payload: { id: string; message: string }) {}
}

export class DeleteStaffApi implements Action {
  readonly type = StaffApiActionTypes.DeleteStaffApi;
  constructor(public payload: { id: string }) {}
}

export class DeleteStaffSuccessApi implements Action {
  readonly type = StaffApiActionTypes.DeleteStaffSuccessApi;
  constructor(public payload: { id: string }) {}
}

export class DeleteStaffFailureApi implements Action {
  readonly type = StaffApiActionTypes.DeleteStaffFailureApi;
  constructor(public payload: { id: string; message: string }) {}
}

export type StaffApiActions =
  | LoadStaffsApi
  | LoadStaffsSuccessApi
  | LoadStaffsFailureApi
  | LoadStaffApi
  | LoadStaffSuccessApi
  | LoadStaffFailureApi
  | CreateStaffApi
  | CreateStaffSuccessApi
  | CreateStaffFailureApi
  | UpdateStaffApi
  | UpdateStaffSuccessApi
  | UpdateStaffFailureApi
  | DeleteStaffApi
  | DeleteStaffSuccessApi
  | DeleteStaffFailureApi
  | BulkUploadStaffApi
  | BulkUploadStaffSuccessApi
  | BulkUploadStaffFailureApi;
