import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedUiSkeletonFormModule } from '@frontend/common/skeletons';
import { TimePicker24hsModule } from '../time-picker-24hs/time-picker-24hs.module';
import { TimePickerAmPmModule } from '../time-picker-ampm/time-picker-ampm.module';
import { ManageAppointmentsRoutedComponent } from './manage-appointments-routed.component';
import { ManageAppointmentsComponent } from './manage-appointments.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    MatTooltipModule,
    SharedUiSkeletonFormModule,
    TimePicker24hsModule,
    TimePickerAmPmModule,
  ],
  declarations: [ManageAppointmentsRoutedComponent, ManageAppointmentsComponent],
  exports: [ManageAppointmentsRoutedComponent, ManageAppointmentsComponent],
})
export class ManageAppointmentsModule {}
