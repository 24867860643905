<skeleton-form *ngIf="srv.loading$ | async"></skeleton-form>

<ng-container *ngIf="!(srv.loading$ | async)">
  <h1 mat-dialog-title i18n>Select work station</h1>

  <div mat-dialog-content>
    <mat-card>
      <mat-icon>error</mat-icon>
      <span i18n>
        Patients you call will be directed to the work station you select next
      </span>
    </mat-card>
    <mat-list role="list">
      <mat-radio-group [(ngModel)]="selectedStation">
        <mat-list-item role="listitem" *ngFor="let station of srv.stations$ | async">
          <mat-radio-button [value]="station">
            <span [title]="station.name">{{ station.name }}</span>
          </mat-radio-button>
        </mat-list-item>
      </mat-radio-group>
    </mat-list>
  </div>

  <div mat-dialog-actions align="end">
    <mat-slide-toggle [(ngModel)]="saveAsDefault" i18n>Default work station</mat-slide-toggle>
    <button mat-button mat-dialog-close (click)="call()" [disabled]="!selectedStation" i18n>
      CALL
    </button>
    <button mat-button mat-dialog-close i18n>CANCEL</button>
  </div>
</ng-container>
