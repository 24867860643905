import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer,
} from '@ngrx/store';
import * as fromChat from './chat/chat-smart/chat-smart.reducer';
import * as fromTagChirping from './tag-chirping/tag-chirping.reducer';
import * as fromUnreadMessagesMenu from './unread-messages-menu/unread-messages-menu.reducer';

export interface State {
  [fromTagChirping.tagChirpingFeatureKey]: fromTagChirping.State;
  [fromUnreadMessagesMenu.unreadMessagesMenuFeatureKey]: fromUnreadMessagesMenu.State;
  [fromChat.chatFeatureKey]: fromChat.State;
}

export const reducers: ActionReducerMap<State> = {
  [fromTagChirping.tagChirpingFeatureKey]: fromTagChirping.reducer,
  [fromUnreadMessagesMenu.unreadMessagesMenuFeatureKey]: fromUnreadMessagesMenu.reducer,
  [fromChat.chatFeatureKey]: fromChat.reducer,
};

export const metaReducers: MetaReducer<State>[] = [];

export const getPatientsSectionState = createFeatureSelector<State>('patientsSection');

export const getChatState = createSelector(
  getPatientsSectionState,
  (state) => state.chat
);
