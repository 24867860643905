import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as moment from 'moment';
import { BaseAction, BaseActions, BaseReadonlyActions } from './base.actions';
import { BaseEntity } from './base.entity';

export interface AbstractState<T> extends EntityState<T> {
  loaded: boolean;
  loading: boolean;
}

export class ReadonlyReducerFactory<T extends BaseEntity> {
  readonly initialState = {
    loaded: false,
    loading: false,
  };

  protected readonly actions: BaseReadonlyActions<T>;
  protected readonly adapter: EntityAdapter<T> = createEntityAdapter<T>();

  constructor(entityType: new () => T) {
    this.actions = new BaseReadonlyActions(entityType);
  }

  getReducer() {
    return (
      state: AbstractState<T> = this.adapter.getInitialState(this.initialState),
      a: BaseAction<T>
    ) => {
      switch (a.type) {
        case this.actions.types.LOAD:
          return { ...state, loading: true };
        case this.actions.types.LOAD_SUCCESS:
          state = { ...state, loaded: true, loading: false };
          return this.adapter.setAll(a.payload, state);
        case this.actions.types.LOAD_FAIL:
          return { ...state, loading: false };
        case this.actions.types.LOAD_ONE:
          return this.adapter.updateOne(
            { id: a.payload, changes: { pending: true } as Partial<T> },
            state
          );
        case this.actions.types.LOAD_ONE_SUCCESS:
          return this.adapter.upsertOne({ ...a.payload, pending: false }, state);
        case this.actions.types.CLEAN:
          state = { ...state, loaded: false, loading: false };
          return this.adapter.removeAll(state);
        default:
          return state;
      }
    };
  }

  getSelectors() {
    return this.adapter.getSelectors();
  }
}

export class ReducerFactory<T extends BaseEntity> extends ReadonlyReducerFactory<T> {
  protected readonly actions: BaseActions<T>;

  constructor(entityType: new () => T) {
    super(entityType);
    this.actions = new BaseActions(entityType);
  }

  getReducer() {
    return (
      state: AbstractState<T> = this.adapter.getInitialState(this.initialState),
      a: BaseAction<T>
    ) => {
      switch (a.type) {
        case this.actions.types.ADD:
          return this.adapter.addOne(
            {
              ...a.payload,
              created_utc: moment.utc().toISOString(),
              updated_utc: moment.utc().toISOString(),
              pending: true,
            },
            state
          );
        case this.actions.types.ADD_SUCCESS:
          return this.adapter.updateOne(
            {
              id: a.payload.correlation_id,
              changes: { ...a.payload, pending: false, error: null },
            },
            state
          );
        case this.actions.types.ADD_FAIL:
          return this.adapter.updateOne(
            {
              id: a.payload.error.correlation_id,
              changes: { ...a.payload, pending: false },
            },
            state
          );

        case this.actions.types.ADD_GLOBAL:
          return this.adapter.addOne(
            {
              ...a.payload,
              pending: false,
              error: null,
            },
            state
          );
        case this.actions.types.UPDATE:
          return this.adapter.updateOne(
            {
              id: a.payload.id,
              changes: {
                ...a.payload,
                pending: true,
                error: null,
                old: state.entities[a.payload.id],
              },
            },
            state
          );
        case this.actions.types.DISCARD:
        case this.actions.types.UPDATE_SUCCESS:
          return this.adapter.updateOne(
            {
              id: a.payload.id,
              changes: { ...a.payload, pending: false, error: null },
            },
            state
          );
        case this.actions.types.UPDATE_FAIL:
          return this.adapter.updateOne(
            {
              id: a.payload.id || a.payload.error.payload.id,
              changes: { ...a.payload, pending: false },
            },
            state
          );
        case this.actions.types.DELETE:
          return this.adapter.updateOne(
            { id: a.payload.id, changes: { ...a.payload, hidden: true } },
            state
          );
        case this.actions.types.REMOVE:
        case this.actions.types.DELETE_SUCCESS:
          return this.adapter.removeOne(a.payload.id, state);
        default:
          return super.getReducer()(state, a);
      }
    };
  }
}
