import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mapTo } from 'rxjs/operators';
import { AppActionTypes, LoadAppSuccess } from './app.actions';

@Injectable()
export class AppEffects {
  constructor(private actions$: Actions) {}

  loadAppSuccess$ = createEffect(() =>
    this.actions$.pipe(ofType(AppActionTypes.LoadApp), mapTo(new LoadAppSuccess()))
  );
}
