import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  selectAll,
  selectEntities,
  State,
} from './lib/quick-message/quick-message.reducer';

export * from './lib/quick-message-api/quick-message-api.actions';
export * from './lib/quick-message-ws/quick-message-ws.actions';
export * from './lib/quick-message/quick-message.actions';
export * from './lib/quick-message/quick-message.model';
export * from './lib/shared-quick-message-data-access.module';

export const getState = createFeatureSelector<State>('quickMessage');

export const getQuickMessages = createSelector(getState, selectAll);

export const getQuickMessageEntities = createSelector(getState, selectEntities);
