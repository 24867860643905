import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DurationBadgeComponent } from './duration-badge.component';

@NgModule({
  imports: [CommonModule, MatTooltipModule],
  declarations: [DurationBadgeComponent],
  exports: [DurationBadgeComponent],
})
export class WorkflowRtUiDurationBadgeModule {}
