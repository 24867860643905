import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { TextMessage } from './text-message.model';

export enum TextMessageActionTypes {
  LoadTextMessages = '[TextMessage] Load TextMessages',
  AddTextMessage = '[TextMessage] Add TextMessage',
  UpsertTextMessage = '[TextMessage] Upsert TextMessage',
  AddTextMessages = '[TextMessage] Add TextMessages',
  UpsertTextMessages = '[TextMessage] Upsert TextMessages',
  UpdateTextMessage = '[TextMessage] Update TextMessage',
  UpdateTextMessages = '[TextMessage] Update TextMessages',
  DeleteTextMessage = '[TextMessage] Delete TextMessage',
  DeleteTextMessages = '[TextMessage] Delete TextMessages',
  ClearTextMessages = '[TextMessage] Clear TextMessages',
}

export class LoadTextMessages implements Action {
  readonly type = TextMessageActionTypes.LoadTextMessages;

  constructor(public payload: { textMessages: TextMessage[] }) {}
}

export class AddTextMessage implements Action {
  readonly type = TextMessageActionTypes.AddTextMessage;

  constructor(public payload: { textMessage: TextMessage }) {}
}

export class UpsertTextMessage implements Action {
  readonly type = TextMessageActionTypes.UpsertTextMessage;

  constructor(public payload: { textMessage: TextMessage }) {}
}

export class AddTextMessages implements Action {
  readonly type = TextMessageActionTypes.AddTextMessages;

  constructor(public payload: { textMessages: TextMessage[] }) {}
}

export class UpsertTextMessages implements Action {
  readonly type = TextMessageActionTypes.UpsertTextMessages;

  constructor(public payload: { textMessages: TextMessage[] }) {}
}

export class UpdateTextMessage implements Action {
  readonly type = TextMessageActionTypes.UpdateTextMessage;

  constructor(public payload: { textMessage: Update<TextMessage> }) {}
}

export class UpdateTextMessages implements Action {
  readonly type = TextMessageActionTypes.UpdateTextMessages;

  constructor(public payload: { textMessages: Update<TextMessage>[] }) {}
}

export class DeleteTextMessage implements Action {
  readonly type = TextMessageActionTypes.DeleteTextMessage;
  constructor(public payload: { id: string }) {}
}

export class DeleteTextMessages implements Action {
  readonly type = TextMessageActionTypes.DeleteTextMessages;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearTextMessages implements Action {
  readonly type = TextMessageActionTypes.ClearTextMessages;
}

export type TextMessageActions =
  | LoadTextMessages
  | AddTextMessage
  | UpsertTextMessage
  | AddTextMessages
  | UpsertTextMessages
  | UpdateTextMessage
  | UpdateTextMessages
  | DeleteTextMessage
  | DeleteTextMessages
  | ClearTextMessages;
