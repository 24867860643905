import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { QuickMessageActions, QuickMessageActionTypes } from './quick-message.actions';
import { QuickMessage } from './quick-message.model';

export interface State extends EntityState<QuickMessage> {}

export const adapter: EntityAdapter<QuickMessage> = createEntityAdapter<QuickMessage>();

export const initialState: State = adapter.getInitialState({});

export function reducer(state = initialState, action: QuickMessageActions): State {
  switch (action.type) {
    case QuickMessageActionTypes.AddQuickMessage: {
      return adapter.addOne(action.payload.quickMessage, state);
    }

    case QuickMessageActionTypes.UpsertQuickMessage: {
      return adapter.upsertOne(action.payload.quickMessage, state);
    }

    case QuickMessageActionTypes.AddQuickMessages: {
      return adapter.addMany(action.payload.quickMessages, state);
    }

    case QuickMessageActionTypes.UpsertQuickMessages: {
      return adapter.upsertMany(action.payload.quickMessages, state);
    }

    case QuickMessageActionTypes.UpdateQuickMessage: {
      return adapter.updateOne(action.payload.quickMessage, state);
    }

    case QuickMessageActionTypes.UpdateQuickMessages: {
      return adapter.updateMany(action.payload.quickMessages, state);
    }

    case QuickMessageActionTypes.DeleteQuickMessage: {
      return adapter.removeOne(action.payload.id, state);
    }

    case QuickMessageActionTypes.DeleteQuickMessages: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case QuickMessageActionTypes.LoadQuickMessages: {
      return adapter.setAll(action.payload.quickMessages, state);
    }

    case QuickMessageActionTypes.ClearQuickMessages: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
