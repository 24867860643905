import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'frontend-chat-mark-all',
  templateUrl: './chat-mark-all.component.html',
  styleUrls: ['./chat-mark-all.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMarkAllComponent {
  @Output() markAll: EventEmitter<any> = new EventEmitter(null);

  markAllSeen() {
    this.markAll.emit(null);
  }
}
