import { formatDuration, formatDurationFromUtc } from '@frontend/common/component-tools';
import {
  StaffTypeAliasTranslation,
  StaffTypeColorTranslation,
  StaffTypeId,
  StaffTypeTranslation,
} from '@frontend/common/util';
import { duration, utc } from 'moment-timezone';
import {
  CallEventNetworkModel,
  LocationNetworkModel,
  PatientAppointmentNetworkModel,
  PatientNetworkModel,
  PatientWorkflowNetworkModel,
  PatientWorkflowPhaseMilestoneNetworkModel,
  ProviderNetworkModel,
  RecipientNetworkModel,
  SingleCallableWorkflow,
  UserNetworkModel,
} from '../data-models';

export function dataManipulation(
  locations: LocationNetworkModel[],
  timezone: string
): LocationNetworkModel[] {
  return locations.map((location) => locationManipulation(location, timezone));
}

export function waitingDataManipulation(
  patients: PatientNetworkModel[],
  timezone: string
) {
  return patients.map((patient) => patientManipulation(patient, timezone));
}

function locationManipulation(
  loc: LocationNetworkModel,
  timezone: string
): LocationNetworkModel {
  const patients = loc.patients.map((patient) => patientManipulation(patient, timezone));
  return { ...loc, patients };
}

function getFullName(item: { first_name: string; last_name: string }): string {
  return [item?.last_name, item?.first_name].filter((s) => !!s).join(', ');
}

function contactManipulation(p) {
  if (p?.patient_attended_milestones?.length === 0) {
    return {};
  }
  const isWithStaff = p?.patient_attended_milestones?.[0].is_attended;
  const created_utc = p?.patient_attended_milestones?.[0].created_utc;
  if (!isWithStaff) {
    return {
      isWithStaff,
      unattendedSinceUtc: created_utc,
      unattendedDuration: formatDurationFromUtc(created_utc),
      unattendedDurationInMinutes: Math.floor(
        duration(utc().diff(utc(created_utc))).asMinutes()
      ),
      displayStaff: getFullName(p?.last_completed_patient_staff_contact?.[0]?.staff),
      securedStaff:
        p?.last_completed_patient_staff_contact?.[0]?.staff?.secured_staff_name,
      status: 'Waiting',
      statusBgColor: '#F7931E',
      tooltipDisplayStatuses: p?.last_completed_patient_staff_contact?.[0]?.staff
        ? 'Post ' +
          StaffTypeTranslation[
            p?.last_completed_patient_staff_contact?.[0]?.staff.staff_type_id
          ]
        : null,
    };
  }
  const staffCount = p?.current_patient_staff_contacts?.length;
  if (!staffCount) {
    return {
      isWithStaff,
      attendedSinceUtc: created_utc,
      attendedDuration: formatDurationFromUtc(created_utc),
    };
  }
  const newestAttendingStaff = p?.current_patient_staff_contacts?.[0]?.staff;
  let displayStaff = getFullName(newestAttendingStaff);
  let securedStaff = newestAttendingStaff.secured_staff_name;
  if (staffCount > 1) {
    displayStaff += ` (+${staffCount - 1})`;
    securedStaff += ` (+${staffCount - 1})`;
  }
  const tooltipDisplayStaff =
    staffCount > 1 &&
    p?.current_patient_staff_contacts
      .map((c) =>
        [getFullName(c.staff), StaffTypeTranslation[c.staff.staff_type_id]].join(' - ')
      )
      .join('\n');
  const tooltipSecuredStaff =
    staffCount > 1 &&
    p?.current_patient_staff_contacts
      .map((c) =>
        [c.staff?.secured_staff_name, StaffTypeTranslation[c.staff.staff_type_id]].join(
          ' - '
        )
      )
      .join('\n');
  const status =
    StaffTypeAliasTranslation[newestAttendingStaff.staff_type_id] ||
    StaffTypeTranslation[newestAttendingStaff.staff_type_id];
  const tooltipDisplayStatuses =
    staffCount > 1 &&
    p?.current_patient_staff_contacts
      .map((c) =>
        c.staff.staff_type_id === StaffTypeId.General
          ? 'Staff'
          : StaffTypeTranslation[c.staff.staff_type_id]
      )
      .join('\n');
  return {
    isWithStaff,
    attendedSinceUtc: created_utc,
    attendedDuration: formatDurationFromUtc(created_utc),
    displayStaff,
    securedStaff,
    tooltipDisplayStaff,
    tooltipSecuredStaff,
    status: 'With ' + status + `${staffCount > 1 ? ` +${staffCount - 1}` : ''}`,
    statusBgColor: StaffTypeColorTranslation[newestAttendingStaff.staff_type_id],
    statusTextColor: patientStatusTextColorManipulation(
      newestAttendingStaff.staff_type_id
    ),
    tooltipDisplayStatuses,
  };
}

function patientManipulation(
  patient: PatientNetworkModel,
  timezone: string
): PatientNetworkModel {
  if (!patient) {
    return patient;
  }
  const departmentDuration =
    patient.created_utc && formatDurationFromUtc(patient.created_utc);
  const tag = tagManipulation(patient?.tag, timezone);
  const {
    isWithStaff,
    attendedSinceUtc,
    attendedDuration,
    unattendedSinceUtc,
    unattendedDuration,
    unattendedDurationInMinutes,
    displayStaff,
    securedStaff,
    tooltipDisplayStaff,
    tooltipSecuredStaff,
    status,
    statusBgColor,
    statusTextColor,
    tooltipDisplayStatuses,
  } = contactManipulation(patient);
  const patient_workflows = patient.patient_workflows?.map((pw) =>
    patientWorkflowManipulation(pw, timezone)
  );
  const recipientsNames = recipientsManipulation(patient.recipients);
  const nextAppointment = patientAppointmentManipulation(
    patient.patient_appointments?.[0],
    timezone
  );
  const locationDuration =
    patient.sensed_utc && formatDurationFromUtc(patient.sensed_utc);
  const callablePhasesCount = patient.patient_workflows
    ?.map((pw) =>
      pw.patient_workflow_phase_milestones.some(
        (pwp) => pwp.workflow_phase?.is_call_patient_enabled
      )
    )
    .filter((callable) => !!callable).length;
  const isCallable = callablePhasesCount > 0;
  const isMultiCallable = isCallable && patient_workflows.length > 1;
  const wf = patient.patient_workflows.filter((pw) =>
    pw?.patient_workflow_phase_milestones.some(
      (pwp) => pwp.workflow_phase?.is_call_patient_enabled
    )
  );
  const phm = wf.map((pw) => pw?.patient_workflow_phase_milestones[0])[0];
  const singleCallableWorkflow: SingleCallableWorkflow = {
    workflow_id: wf[0]?.id,
    phase_id: phm?.workflow_phase?.id,
  };
  const callsCount = phm?.called_count;

  return {
    ...patient,
    tag,
    patient_workflows,
    departmentDuration,
    recipientsNames,
    locationDuration,
    isCallable,
    isMultiCallable,
    attendedSinceUtc,
    attendedDuration,
    unattendedSinceUtc,
    unattendedDuration,
    unattendedDurationInMinutes,
    displayStaff,
    securedStaff,
    tooltipDisplayStaff,
    tooltipSecuredStaff,
    isWithStaff,
    status,
    statusBgColor,
    statusTextColor,
    tooltipDisplayStatuses,
    singleCallableWorkflow,
    callsCount,
    nextAppointment,
  };
}

function patientWorkflowManipulation(
  patientWorkflow: PatientWorkflowNetworkModel,
  timezone: string
) {
  const workflowDuration =
    patientWorkflow.created_utc && formatDurationFromUtc(patientWorkflow.created_utc);
  const patient_workflow_phase_milestones = patientWorkflowPhaseMilestonesManipulation(
    patientWorkflow.patient_workflow_phase_milestones
  );
  const patient_appointment = patientAppointmentManipulation(
    patientWorkflow.patient_appointment,
    timezone
  );
  return {
    ...patientWorkflow,
    workflowDuration,
    patient_workflow_phase_milestones,
    patient_appointment,
    workflow: {
      ...patientWorkflow.workflow,
      max_unattended_low_min: patientWorkflow.workflow.max_unattended_low_sec
        ? patientWorkflow.workflow.max_unattended_low_sec / 60
        : null,
      max_unattended_high_min: patientWorkflow.workflow.max_unattended_high_sec
        ? patientWorkflow.workflow.max_unattended_high_sec / 60
        : null,
    },
  };
}

function patientWorkflowPhaseMilestonesManipulation(
  milestones: PatientWorkflowPhaseMilestoneNetworkModel[]
) {
  return milestones.map((milestone) => {
    const phaseDuration =
      milestone.created_utc && formatDurationFromUtc(milestone.created_utc);
    const phaseDurationMinutes =
      milestone.created_utc &&
      Math.floor(duration(utc().diff(utc(milestone.created_utc))).asMinutes());
    let estimated, estimatedUtc, estimatedFormatted;
    if (milestone.estimated_duration_min) {
      estimated = utc(milestone.created_utc).add(milestone.estimated_duration_min, 'm');
      estimatedUtc = estimated.format('YYYY-MM-DDTHH:mm:ss.SSS');
      const minDuration = duration(estimated.diff(utc()));
      estimatedFormatted = formatDuration(minDuration, '1m');
      if (milestone.estimation_range_min) {
        const maxDuration = minDuration.clone().add(milestone.estimation_range_min, 'm');
        estimatedFormatted += ` - ${formatDuration(maxDuration)}`;
      }
    }

    let calledBy = null;

    if (milestone.called_count) {
      if (
        milestone.call_events?.[0]?.user?.first_name !== null &&
        milestone.call_events?.[0]?.user?.first_name !== ''
      ) {
        calledBy = [
          milestone.call_events?.[0]?.user?.last_name,
          milestone.call_events?.[0]?.user?.first_name,
        ]
          .filter((s) => !!s)
          .join(', ');
      } else {
        calledBy = milestone.call_events?.[0]?.user?.email;
      }
    }

    return {
      ...milestone,
      phaseDuration,
      phaseDurationMinutes,
      estimatedUtc,
      estimatedFormatted,
      calledBy,
    };
  });
}

function patientStatusTextColorManipulation(staffTypeId) {
  switch (staffTypeId) {
    // Admin group
    case StaffTypeId.Scheduler:
    case StaffTypeId.Registrar:
    case StaffTypeId.Unit_Secretary:
    case StaffTypeId.Leadership:
    case StaffTypeId.Patient_Encounter_Associate:
    // Assistant group
    case StaffTypeId.Certified_Nursing_Assistant:
    case StaffTypeId.Medical_Assistant:
    case StaffTypeId.Medical_Office_Specialist:
    case StaffTypeId.OR_Assistant:
    case StaffTypeId.Clinical_Oncology_Associate:
    // EVS group
    case StaffTypeId.EVS:
      return '#FFFFFF';
    default:
      return '#000000';
  }
}

function patientAppointmentManipulation(
  appointment: PatientAppointmentNetworkModel,
  timezone: string
) {
  if (!appointment) {
    return null;
  }
  const provider = providerManipulation(appointment.provider);
  const appointmentTimeTz = appointmentTimeManipulation(
    appointment.appointment_time_utc,
    timezone
  );
  return { ...appointment, provider, appointmentTimeTz };
}

function providerManipulation(provider: ProviderNetworkModel) {
  if (!provider) {
    return null;
  }
  const fullName = [provider?.last_name, provider?.first_name]
    .filter((s) => !!s)
    .join(', ');
  return { ...provider, fullName };
}

function appointmentTimeManipulation(appointment_time_utc: string, timezone: string) {
  const at = utc(appointment_time_utc).tz(timezone);
  return at?.isValid() ? at?.format('hh:mm A') : null;
}

function recipientsManipulation(recipients: RecipientNetworkModel[]): string {
  if (!recipients) {
    return null;
  }
  return recipients
    .map((r) => r.securableData?.fullName)
    .filter((s) => !!s)
    .sort()
    .join('; ');
}

function tagManipulation(tag, timezone: string) {
  if (!tag) {
    return null;
  }
  let lowBatteryFormatted, nonReportingFormatted;
  if (tag?.current_tag_location) {
    const lbt = utc(tag?.current_tag_location?.low_battery_since_utc).tz(timezone);
    const nrt = utc(tag?.current_tag_location?.non_reporting_since_utc).tz(timezone);
    lowBatteryFormatted = lbt?.isValid() ? lbt?.format('MM/DD/YYYY') : null;
    nonReportingFormatted = nrt?.isValid() ? nrt?.format('MM/DD/YYYY') : null;
  }
  const status = nonReportingFormatted
    ? '3'
    : lowBatteryFormatted
    ? '2'
    : tag?.tag_value
    ? '1'
    : null;
  return {
    ...tag,
    lowBatteryFormatted,
    nonReportingFormatted,
    status,
  };
}

export function addUsersToPatients(
  patients: PatientNetworkModel[],
  users: UserNetworkModel[]
): PatientNetworkModel[] {
  patients.forEach((patient) => {
    patient.patient_workflows.map((patientWorkflow) =>
      manipulatePatientWorkflow(patientWorkflow, users)
    );
  });
  return patients;
}

export function manipulatePatientWorkflow(
  patientWorkflow: PatientWorkflowNetworkModel,
  users
) {
  patientWorkflow.patient_workflow_phase_milestones =
    patientWorkflow.patient_workflow_phase_milestones.map(
      (patientWorkflowPhaseMilestone) =>
        manipulatePatientWorkflowPhaseMilestones(patientWorkflowPhaseMilestone, users)
    );
  return patientWorkflow;
}

export function manipulatePatientWorkflowPhaseMilestones(
  patientWorkflowPhaseMilestone: PatientWorkflowPhaseMilestoneNetworkModel,
  users: UserNetworkModel[]
): PatientWorkflowPhaseMilestoneNetworkModel {
  patientWorkflowPhaseMilestone.call_events =
    patientWorkflowPhaseMilestone.call_events.map((callEvent) =>
      addUserToCallEvent(callEvent, users)
    );
  return patientWorkflowPhaseMilestone;
}

export function convertUsersArrayToHashMap(
  users: UserNetworkModel[]
): Map<string, UserNetworkModel> {
  const usersMap = new Map<string, UserNetworkModel>();
  users.forEach((user) => usersMap.set(user.id, user));
  return usersMap;
}

export function addUserToCallEvent(
  callEvent: CallEventNetworkModel,
  users: UserNetworkModel[]
) {
  const usersHashMap = convertUsersArrayToHashMap(users);
  callEvent.user = usersHashMap.get(callEvent.created_by);
  return callEvent;
}

export function getUniqueUserIdsFromPatients(patients: PatientNetworkModel[]): string[] {
  const ids = extractUserIds(patients);
  return Array.from(new Set(ids));
}

function extractUserIds(patients: PatientNetworkModel[]): string[] {
  const ids: string[] = [];
  patients.forEach((patient) => {
    extractIdsFromWorkflows(patient.patient_workflows, ids);
  });
  return ids;
}

function extractIdsFromWorkflows(
  patientWorkflows: PatientWorkflowNetworkModel[],
  ids: string[]
): void {
  patientWorkflows.forEach((workflow) => {
    extractIdsFromMilestones(workflow.patient_workflow_phase_milestones, ids);
  });
}

function extractIdsFromMilestones(
  milestones: PatientWorkflowPhaseMilestoneNetworkModel[],
  ids: string[]
): void {
  milestones.forEach((milestone) => {
    if (milestone.call_events[0]?.created_by) {
      ids.push(milestone.call_events[0].created_by);
    }
  });
}
