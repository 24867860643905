import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { filter, map, mapTo, startWith } from 'rxjs/operators';
import { ConfirmEndVisitsDialogService } from './confirm-end-visits-dialog.service';

@UntilDestroy()
@Component({
  selector: 'frontend-confirm-end-visits-dialog',
  templateUrl: './confirm-end-visits-dialog.component.html',
  styleUrls: ['./confirm-end-visits-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ConfirmEndVisitsDialogService],
})
export class ConfirmEndVisitsDialogComponent implements OnDestroy {
  saving = false;
  isChecked = false;

  plural$ = this.srv.amountToEnd$.pipe(
    map((amount) => (amount > 1 ? 'patients' : 'patient'))
  );

  loading$ = this.srv.amountToEnd$.pipe(
    filter((amount) => amount > 0),
    mapTo(false),
    startWith(true)
  );

  private subscriptions: Subscription[] = [];

  constructor(
    private dialogRef: MatDialogRef<ConfirmEndVisitsDialogComponent>,
    private srv: ConfirmEndVisitsDialogService,
    private snackBar: MatSnackBar
  ) {
    this.subscriptions.push(
      this.srv.amountToEnd$.subscribe((amount) => {
        if (amount === 0) {
          this.dialogRef.close();
          this.snackBar.open(
            $localize`There are no unassigned patients to end their visits.`,
            $localize`Close`,
            { duration: 10000 }
          );
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  confirm() {
    this.saving = true;
    this.srv.confirm().subscribe({
      next: () => {
        this.dialogRef.close();
        this.snackBar.open($localize`The patients have completed the visit`, null, {
          duration: 3000,
        });
      },
      error: () =>
        this.snackBar.open($localize`There was an unexpected error.`, $localize`Close`, {
          duration: 10000,
        }),
    });
  }
}
