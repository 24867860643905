import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ManageAppointmentsComponent } from './manage-appointments.component';

@Component({
  selector: 'manage-appointments-routed',
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageAppointmentsRoutedComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.dialog
      .open(ManageAppointmentsComponent, { width: '369px' })
      .afterClosed()
      .subscribe((route = '../..') =>
        this.router.navigate([route], { relativeTo: this.route })
      );
  }
}
