<mat-form-field class="search-box" floatLabel="always" appearance="outline">
  <mat-label>{{ entityName }}</mat-label>
  <input type="text" placeholder="Search..." matInput autocomplete="off" [formControl]="_searchCtrl"
    (keydown)="_onKeydown($event)" />
  <div matSuffix class="search-box-suffix">
    <button type="button" mat-icon-button [style.visibility]="_searchCtrl.value ? 'visible' : 'hidden'"
      [disabled]="_searchCtrl.disabled" (click)="_clear()">
      <mat-icon>close</mat-icon>
    </button>

    <mat-icon *ngIf="info" [matTooltip]="info" matTooltipPosition="right">info</mat-icon>
  </div>
</mat-form-field>

<div class="items-list-wrapper">
  <cdk-virtual-scroll-viewport itemSize="40" class="virtual-scroll-viewport">
    <ng-container *ngIf="enableAll">
      <label>
        <mat-checkbox [checked]="_allComplete$ | async" [indeterminate]="_someComplete$ | async"
          [disabled]="(_disabled$ | async) || (_disableAll$ | async)" (change)="_setAll($event.checked)" id="{{ _componentInstanceID }}-ALL"
          class="virtual-scroll-item"><span class="virtual-scroll-item-label">All</span>
        </mat-checkbox>
      </label>
      <mat-divider></mat-divider>
    </ng-container>
    <div class="empty-message" *ngIf="!(_filteredItems$ | async).length">
      <span class="mat-typography virtual-scroll-item-label">There are no records to show</span>
    </div>
    <label *cdkVirtualFor="let item of _filteredItems$ | async">
      <mat-checkbox [(ngModel)]="item.___selected" [disabled]="(_disabled$ | async) || item.___disabled"
        id="{{ _componentInstanceID }}-{{ item.id }}" (ngModelChange)="_update([item.id], $event)"
        class="virtual-scroll-item" [title]="item.label">
        <span class="virtual-scroll-item-label">{{ item.label }}</span>
        <span class="virtual-scroll-item-secondary-label" *ngIf="item.secondaryLabel">{{ item.secondaryLabel }}</span>
      </mat-checkbox>
    </label>
  </cdk-virtual-scroll-viewport>
</div>

<div class="helper mat-typography">
  <span>Selected: {{ _selectedCount$ | async }}</span>
  <span>Showing: {{ _showingCount$ | async }}/{{ _allCount$ | async }}</span>
</div>
