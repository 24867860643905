import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map } from 'rxjs/operators';
import { Staff } from './staff.model';

@Injectable()
export class TagChirpingService {
  constructor(private http: HttpClient, private snackBar: MatSnackBar) {}

  getStaffsByDep(department_id) {
    return this.http.get<Staff[]>('staff', { params: { department_id } }).pipe(
      map((items) =>
        items
          .map((item) => ({
            ...item,
            displayName: [item?.last_name, item?.first_name]
              .filter((str) => !!str)
              .join(', '),
          }))
          .filter((item) => item.tag_id && item.displayName)
          .sort((a, b) => a.displayName.localeCompare(b.displayName))
      )
    );
  }

  chirp(tag_id) {
    this.http.put(`tags/${tag_id}/chirp`, {}).subscribe(() =>
      this.snackBar.open($localize`Notification was successfully sent`, null, {
        duration: 3000,
      })
    );
  }
}
