import { Action } from '@ngrx/store';
import { Staff } from '../staff/staff.model';

export enum StaffWsActionTypes {
  LocalCreateStaffSuccessWs = '[Staff WS] Local Create Staff Success',
  LocalCreateStaffFailureWs = '[Staff WS] Local Create Staff Failure',
  LocalUpdateStaffSuccessWs = '[Staff WS] Local Update Staff Success',
  LocalUpdateStaffFailureWs = '[Staff WS] Local Update Staff Failure',
  LocalRemoveStaffSuccessWs = '[Staff WS] Local Remove Staff Success',
  LocalRemoveStaffFailureWs = '[Staff WS] Local Remove Staff Failure',
  GlobalCreateStaffSuccessWs = '[Staff WS] Global Create Staff Success',
  GlobalUpdateStaffSuccessWs = '[Staff WS] Global Update Staff Success',
  GlobalRemoveStaffSuccessWs = '[Staff WS] Global Remove Staff Success',
  GlobalMoveStaffSuccessWs = '[Staff WS] Global Move Staff Success',
  GlobalAddStaffDepartmentWs = '[Staff WS] Global Add Department Staff Success',
  GlobalRemoveStaffDepartmentWs = '[Staff WS] Global Remove Department Staff Success',
}

export class LocalCreateStaffSuccessWs implements Action {
  readonly type = StaffWsActionTypes.LocalCreateStaffSuccessWs;
  constructor(public payload: { id: string; correlation_id: string }) {}
}

export class LocalCreateStaffFailureWs implements Action {
  readonly type = StaffWsActionTypes.LocalCreateStaffFailureWs;
  constructor(public payload: { correlation_id: string; message: string }) {}
}

export class LocalUpdateStaffSuccessWs implements Action {
  readonly type = StaffWsActionTypes.LocalUpdateStaffSuccessWs;
  constructor(public payload: { id: string; correlation_id: string }) {}
}

export class LocalUpdateStaffFailureWs implements Action {
  readonly type = StaffWsActionTypes.LocalUpdateStaffFailureWs;
  constructor(public payload: { correlation_id: string; message: string }) {}
}

export class LocalRemoveStaffSuccessWs implements Action {
  readonly type = StaffWsActionTypes.LocalRemoveStaffSuccessWs;
  constructor(public payload: { correlation_id: string }) {}
}

export class LocalRemoveStaffFailureWs implements Action {
  readonly type = StaffWsActionTypes.LocalRemoveStaffFailureWs;
  constructor(public payload: { correlation_id: string; message: string }) {}
}

export class GlobalCreateStaffSuccessWs implements Action {
  readonly type = StaffWsActionTypes.GlobalCreateStaffSuccessWs;
  constructor(public payload: Staff) {}
}

export class GlobalUpdateStaffSuccessWs implements Action {
  readonly type = StaffWsActionTypes.GlobalUpdateStaffSuccessWs;
  constructor(public payload: Staff) {}
}

export class GlobalRemoveStaffSuccessWs implements Action {
  readonly type = StaffWsActionTypes.GlobalRemoveStaffSuccessWs;
  constructor(public payload: Staff['id']) {}
}

export class GlobalMoveStaffSuccessWs implements Action {
  readonly type = StaffWsActionTypes.GlobalMoveStaffSuccessWs;
  constructor(
    public payload: { id: string; location_alias_name: string; sensed_utc: string }
  ) {}
}

export class GlobalAddStaffDepartmentWs implements Action {
  readonly type = StaffWsActionTypes.GlobalAddStaffDepartmentWs;
  constructor(
    public payload: {
      staff: Staff;
      staff_id: number;
      department_id: number;
      customer_id: number;
      created_by: string;
    }
  ) {}
}

export class GlobalRemoveStaffDepartmentWs implements Action {
  readonly type = StaffWsActionTypes.GlobalRemoveStaffDepartmentWs;
  constructor(
    public payload: {
      staff: Staff;
      staff_id: number;
      department_id: number;
      customer_id: number;
    }
  ) {}
}

export type StaffWsActions =
  | LocalCreateStaffSuccessWs
  | LocalCreateStaffFailureWs
  | LocalUpdateStaffSuccessWs
  | LocalUpdateStaffFailureWs
  | LocalRemoveStaffSuccessWs
  | LocalRemoveStaffFailureWs
  | GlobalCreateStaffSuccessWs
  | GlobalUpdateStaffSuccessWs
  | GlobalRemoveStaffSuccessWs
  | GlobalMoveStaffSuccessWs
  | GlobalAddStaffDepartmentWs
  | GlobalRemoveStaffDepartmentWs;
