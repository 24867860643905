import * as uuid from 'uuid/v4';

import { BaseEntity } from './base.entity';

const ADD = 'ADD';
const ADD_SUCCESS = 'ADD_SUCCESS';
const ADD_FAIL = 'ADD_FAIL';
const ADD_GLOBAL = 'ADD_GLOBAL';
const BULK_UPLOAD = 'BULK_UPLOAD';
const LOAD = 'LOAD';
const LOAD_SUCCESS = 'LOAD_SUCCESS';
const LOAD_FAIL = 'LOAD_FAIL';
const CLEAN = 'CLEAN';
const DELETE = 'DELETE';
const DELETE_SUCCESS = 'DELETE_SUCCESS';
const DELETE_FAIL = 'DELETE_FAIL';
const DISCARD = 'DISCARD';
const LOAD_ONE = 'LOAD_ONE';
const LOAD_ONE_SUCCESS = 'LOAD_ONE_SUCCESS';
const LOAD_ONE_FAIL = 'LOAD_ONE_FAIL';
const REMOVE = 'REMOVE';
const UPDATE = 'UPDATE';
const UPDATE_SUCCESS = 'UPDATE_SUCCESS';
const UPDATE_FAIL = 'UPDATE_FAIL';
const VOID = 'VOID';

export class BaseAction<T> {
  type: string;
  payload?: T | T[] | string | number | any;
}

export interface ReadonlyActionTypes {
  LOAD: string;
  LOAD_SUCCESS: string;
  LOAD_FAIL: string;
  CLEAN: string;
  LOAD_ONE: string;
  LOAD_ONE_SUCCESS: string;
  LOAD_ONE_FAIL: string;
}

export interface ActionTypes extends ReadonlyActionTypes {
  ADD: string;
  ADD_SUCCESS: string;
  ADD_FAIL: string;
  ADD_GLOBAL: string;
  BULK_UPLOAD: string;
  DELETE: string;
  DELETE_SUCCESS: string;
  DELETE_FAIL: string;
  DISCARD: string;
  REMOVE: string;
  UPDATE: string;
  UPDATE_SUCCESS: string;
  UPDATE_FAIL: string;
}

export class BaseReadonlyActions<T extends BaseEntity> {
  readonly entityName: string;
  types: ReadonlyActionTypes;

  static void() {
    return { type: VOID };
  }
  protected getTypeString(key: string) {
    return `[${this.entityName}] ${this.entityName.toUpperCase()}_${key}`;
  }

  constructor(entityType: new () => T) {
    const sample = new entityType();
    this.entityName = Reflect.getMetadata('entityName', sample.constructor);
    this.types = {
      LOAD: this.getTypeString(LOAD),
      LOAD_SUCCESS: this.getTypeString(LOAD_SUCCESS),
      LOAD_FAIL: this.getTypeString(LOAD_FAIL),
      CLEAN: this.getTypeString(CLEAN),
      LOAD_ONE: this.getTypeString(LOAD_ONE),
      LOAD_ONE_SUCCESS: this.getTypeString(LOAD_ONE_SUCCESS),
      LOAD_ONE_FAIL: this.getTypeString(LOAD_ONE_FAIL),
    };
  }

  load(payload?: any) {
    return { type: this.types.LOAD, payload };
  }

  loadSuccess(payload: T[]) {
    return { type: this.types.LOAD_SUCCESS, payload };
  }

  loadFail(payload: { error: { message: string } }) {
    return { type: this.types.LOAD_FAIL, payload };
  }

  loadOne(payload: string | number) {
    return { type: this.types.LOAD_ONE, payload };
  }

  loadOneSuccess(payload: T) {
    return { type: this.types.LOAD_ONE_SUCCESS, payload };
  }

  loadOneFail(payload: any) {
    return { type: this.types.LOAD_ONE_FAIL, payload };
  }

  clean() {
    return { type: this.types.CLEAN };
  }
}

export class BaseActions<T extends BaseEntity> extends BaseReadonlyActions<T> {
  types: ActionTypes;

  protected correlate(payload: any) {
    return { ...payload, correlation_id: 'correlation|' + uuid() };
  }

  protected correlateBulk(payload: any) {
    return { ...payload, correlation_id: 'correlation|bulk|' + uuid() };
  }

  constructor(entityType: new () => T) {
    super(entityType);
    this.types = {
      ...this.types,
      ADD: this.getTypeString(ADD),
      ADD_SUCCESS: this.getTypeString(ADD_SUCCESS),
      ADD_FAIL: this.getTypeString(ADD_FAIL),
      ADD_GLOBAL: this.getTypeString(ADD_GLOBAL),
      BULK_UPLOAD: this.getTypeString(BULK_UPLOAD),
      DELETE: this.getTypeString(DELETE),
      DELETE_SUCCESS: this.getTypeString(DELETE_SUCCESS),
      DELETE_FAIL: this.getTypeString(DELETE_FAIL),
      DISCARD: this.getTypeString(DISCARD),
      REMOVE: this.getTypeString(REMOVE),
      UPDATE: this.getTypeString(UPDATE),
      UPDATE_SUCCESS: this.getTypeString(UPDATE_SUCCESS),
      UPDATE_FAIL: this.getTypeString(UPDATE_FAIL),
    };
  }

  add(payload: Partial<T>) {
    payload = this.correlate(payload);
    payload.id = payload.correlation_id;
    return { type: this.types.ADD, payload };
  }

  addSuccess(payload: T) {
    return { type: this.types.ADD_SUCCESS, payload };
  }

  addFail(payload: any) {
    return { type: this.types.ADD_FAIL, payload };
  }

  addGlobal(payload: Partial<T>) {
    return { type: this.types.ADD_GLOBAL, payload };
  }

  bulkUpload(payload) {
    const newPayload = this.correlateBulk({});
    const items = payload.map((item) => ({
      ...item,
      correlation_id: newPayload.correlation_id,
    }));
    return { type: this.types.BULK_UPLOAD, payload: { ...newPayload, items } };
  }

  remove(payload: { id: string | number }) {
    return { type: this.types.REMOVE, payload };
  }

  delete(payload: Partial<T>) {
    return { type: this.types.DELETE, payload: this.correlate(payload) };
  }

  deleteSuccess(payload: Partial<T>) {
    return { type: this.types.DELETE_SUCCESS, payload };
  }

  deleteFail(payload: any) {
    return { type: this.types.DELETE_FAIL, payload };
  }

  discard(payload: any) {
    return { type: this.types.DISCARD, payload };
  }

  update(payload: Partial<T>) {
    return { type: this.types.UPDATE, payload: this.correlate(payload) };
  }

  updateSuccess(payload: Partial<T>) {
    return { type: this.types.UPDATE_SUCCESS, payload };
  }

  updateFail(payload: any) {
    return { type: this.types.UPDATE_FAIL, payload };
  }
}
