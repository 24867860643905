<mat-form-field class="search-box" floatLabel="never">
  <input
    type="text"
    [placeholder]="placeholder"
    matInput
    autocomplete="off"
    [formControl]="searchCtrl"
    (keydown)="onKeydown($event)"
  />
  <button
    mat-icon-button
    matSuffix
    [style.visibility]="searchCtrl.value ? 'visible' : 'hidden'"
    (click)="clear()"
  >
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
