import { StaffTypeId, WorkflowTypeId } from '@frontend/common/util';
import gql from 'graphql-tag';

export interface CareNetworkModel {
  department_location: LocationNetworkModel[];
}

export interface WaitingNetworkModel {
  patient_with_location: PatientNetworkModel[];
}

export interface LocationNetworkModel {
  alias_name: string;
  id: number;
  name: string;
  location_status_id: number;
  patients?: PatientNetworkModel[];
  current_patients_count: { aggregate: { count: number } };
  patient_appointments: PatientAppointmentNetworkModel[];
}

export interface PatientAppointmentNetworkModel {
  id: number;
  appointment_time_utc: string;
  appointmentTimeTz: string;
  appointment_type: {
    id: number;
    name: string;
  };
  provider: ProviderNetworkModel;
}

export interface ProviderNetworkModel {
  id: number;
  first_name: string;
  last_name: string;
  fullName?: string;
}

export interface PatientNetworkModel {
  id: number;
  additional_note: string;
  note: string;
  securable_id: number;
  securableData?: {
    fullName: string;
    mrn: string;
    phone_number: number;
  };
  secured_patient_name: string;
  tag: {
    label: string;
    tag_value: string;
    current_tag_location: {
      non_reporting_since_utc: string;
      low_battery_since_utc: string;
    };
    lowBatteryFormatted?: string;
    nonReportingFormatted?: string;
    status?: string;
  };
  unseen_messages_aggregate: {
    aggregate: {
      count: number;
    };
  };
  text_messages_aggregate: {
    aggregate: {
      count: number;
      max: {
        created_utc: string;
      };
    };
  };
  patient_workflows: PatientWorkflowNetworkModel[];
  created_utc: string;
  sensed_utc: string;
  recipients: RecipientNetworkModel[];
  recipientsNames?: string;
  location?: LocationNetworkModel;
  isCallable?: boolean;
  singleCallableWorkflow: SingleCallableWorkflow;
  callsCount: number;
  isMultiCallable?: boolean;
  patient_attended_milestones: {
    created_utc: string;
    completed_utc: string;
    is_attended: boolean;
  }[];
  current_patient_staff_contacts?: {
    created_utc: string;
    staff: {
      first_name: string;
      last_name: string;
      staff_type_id: StaffTypeId;
      secured_staff_name?: string;
    };
  }[];
  last_completed_patient_staff_contact?: {
    created_utc: string;
    completed_utc: string;
    staff: {
      first_name: string;
      last_name: string;
      staff_type_id: StaffTypeId;
      secured_staff_name?: string;
    };
  }[];
  displayStaff?: string;
  securedStaff?: string;
  attendedSinceUtc?: string;
  unattendedSinceUtc?: string;
  status?: string;
  departmentDuration: string;
  locationDuration: string;
  attendedDuration: string;
  unattendedDuration: string;
  unattendedDurationInSeconds: number;
  unattendedDurationInMinutes: number;
  tooltipDisplayStaff: string;
  tooltipSecuredStaff: string;
  isWithStaff: boolean;
  statusBgColor: string;
  statusTextColor: string;
  tooltipDisplayStatuses: string;
  patient_appointments: PatientAppointmentNetworkModel[];
  nextAppointment: PatientAppointmentNetworkModel;
}

export interface PatientWorkflowNetworkModel {
  id: number;
  created_utc: string;
  workflow: {
    id: number;
    name: string;
    workflow_type_id: WorkflowTypeId;
    max_unattended_high_sec: number;
    max_unattended_low_sec: number;
  };
  patient_workflow_phase_milestones: PatientWorkflowPhaseMilestoneNetworkModel[];
  patient_appointment: PatientAppointmentNetworkModel;
}

export interface PatientWorkflowPhaseMilestoneNetworkModel {
  calledBy: string;
  called_count: number;
  created_utc: string;
  estimated_duration_min: number;
  estimation_range_min: number;
  workflow_phase: {
    id: number;
    name: string;
    max_phase_duration_minutes_high: number;
    max_phase_duration_minutes_low: number;
    is_call_patient_enabled: boolean;
  };
  call_events: CallEventNetworkModel[];
  estimatedUtc: string;
  estimatedFormatted: string;
}

export interface CallEventNetworkModel {
  station: { name: string };
  user: UserNetworkModel;
  created_by: string;
}

export interface UserNetworkModel {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
}

export interface UserPresentationModel extends UserNetworkModel {
  fullName: string;
}

export interface RecipientNetworkModel {
  securable_id: number;
  securableData?: {
    first_name?: string;
    last_name?: string;
    fullName: string;
  };
}

export interface SingleCallableWorkflow {
  workflow_id: number;
  phase_id: number;
}

export const CARE_DATA_QUERY = gql(`
  subscription careData($departmentId: Int!, $locationIds: _int4, $locationStatusIds: [smallint!], $appointmentTypeFilter: patient_department_visit_workflow_bool_exp!, $providerFilter: patient_department_visit_workflow_bool_exp!, $workflowIds: [Int!], $phaseIds: [Int!], $locationGroupIds: _int4) {
  department_location: search_care_patients(
    args: {
      location_ids: $locationIds
      location_group_ids: $locationGroupIds
    }
    where: {location_status_id: {_in: $locationStatusIds}, department_id: {_eq: $departmentId}}
  ) {
    alias_name
    id
    name
    location_status_id
    current_patients_count: patients_aggregate(where: {completed_utc: {_is_null: true}}) {
      aggregate {
        count(columns: id)
      }
    }
    patients(where: {
      _and: [
        { department_id: {_eq: $departmentId}},
        {
          patient_workflows: {
            _and:[
          	{completed_utc: {_is_null: true}},
          	{workflow_id: {_in: $workflowIds}},
          	{current_phase_id: {_in: $phaseIds}},
          	$appointmentTypeFilter,
          	$providerFilter
          	]
        	}
        }
      ]
    }) {
      id
      additional_note
      note
      securable_id
      secured_patient_name
      tag {
        id
        label
        tag_value
        current_tag_location {
          low_battery_since_utc
          non_reporting_since_utc
        }
      }
      unseen_messages_aggregate: text_messages_aggregate(where: {is_seen: {_eq: false}}) {
        aggregate {
          count
        }
      }
      text_messages_aggregate(where: {_or: [{is_to_patient: {_eq: true}}, {text_message_type_id: {_eq: 3}}]}) {
        aggregate {
          count
          max {
            created_utc
          }
        }
      }
      patient_workflows(where: {completed_utc: {_is_null: true}, workflow_id: {_in: $workflowIds}}) {
        id
        created_utc
        workflow {
          id
          name
          workflow_type_id
          max_unattended_high_sec
          max_unattended_low_sec
        }
        patient_workflow_phase_milestones(limit: 1, where: {completed_utc: {_is_null: true}}) {
          id
          created_utc
          estimated_duration_min
          workflow_phase {
            id
            name
            max_phase_duration_minutes_high
            max_phase_duration_minutes_low
          }
        }
        patient_appointment {
          id
          appointment_time_utc
          appointment_type {
            id
            name
          }
          provider {
            id
            first_name
            last_name
          }
        }
      }
      created_utc
      sensed_utc
      recipients {
        id
        securable_id
      }
      patient_attended_milestones(limit: 1, where: {completed_utc: {_is_null: true}}) {
        created_utc
        is_attended
      }
      current_patient_staff_contacts: patient_staff_contacts(where: {completed_utc: {_is_null: true}}, order_by: {created_utc: desc}) {
        created_utc
        staff {
          first_name
          last_name
          staff_type_id
          secured_staff_name
        }
      }
      last_completed_patient_staff_contact: patient_staff_contacts(where: {completed_utc: {_is_null: false}}, order_by: {completed_utc: desc}, limit: 1) {
        created_utc
        completed_utc
        staff {
          first_name
          last_name
          staff_type_id
          secured_staff_name
        }
      }
      patient_appointments(where: {is_applied: {_eq: false}}, order_by: {appointment_time_utc: asc_nulls_last}) {
        id
        appointment_time_utc
        appointment_type {
          id
          name
        }
        provider {
          id
          first_name
          last_name
        }
      }
    }
  }
}
`);

export const WAITING_DATA_QUERY = gql(`
subscription WaitingData($departmentId: Int!, $locationIds: _int4, $providerIds: _int4, $appointmentTypeIds: _int4, $workflowIds: _int4, $workflowIdsArray: [Int!], $phaseIds: _int4, $locationGroupIds: _int4) {
  patient_with_location: search_waiting_patients(args: {department_id: $departmentId, location_ids: $locationIds, provider_ids: $providerIds, appointment_type_ids: $appointmentTypeIds, workflow_ids: $workflowIds, phase_ids: $phaseIds, location_group_ids: $locationGroupIds}) {
    id
    additional_note
    note
    securable_id
    secured_patient_name
    tag {
      id
      label
      tag_value
      current_tag_location {
        low_battery_since_utc
        non_reporting_since_utc
      }
    }
    unseen_messages_aggregate: text_messages_aggregate(where: {is_seen: {_eq: false}}) {
      aggregate {
        count
      }
    }
    text_messages_aggregate(where: {_or: [{is_to_patient: {_eq: true}}, {text_message_type_id: {_eq: 3}}]}) {
      aggregate {
        count
        max {
          created_utc
        }
      }
    }
    patient_workflows(where: {workflow_id: {_in: $workflowIdsArray}, completed_utc: {_is_null: true}}) {
      id
      created_utc
      workflow {
        id
        name
        workflow_type_id
      }
      patient_workflow_phase_milestones(limit: 1, where: {completed_utc: {_is_null: true}}) {
        id
        called_count
        created_utc
        estimated_duration_min
        workflow_phase {
          id
          name
          max_phase_duration_minutes_high
          max_phase_duration_minutes_low
          is_call_patient_enabled
          estimation_range_min
        }
        call_events(order_by: {created_utc: desc}, limit: 1) {
          station {
            name
          }
          created_by
        }
      }
      patient_appointment {
        id
        appointment_time_utc
        appointment_type {
          id
          name
        }
        provider {
          id
          first_name
          last_name
        }
      }
    }
    sensed_utc
    created_utc
    recipients {
      id
      securable_id
    }
    location {
      id
      name
      alias_name
    }
    patient_appointments(where: {is_applied: {_eq: false}}, order_by: {appointment_time_utc: asc_nulls_last}) {
      id
      appointment_time_utc
      appointment_type {
        id
        name
      }
      provider {
        id
        first_name
        last_name
      }
    }
  }
}
`);
