import 'reflect-metadata';

// TODO: implement decorator for all string-values of entity, such as route, display name, entity name,
// so we have our string definitions defined with model, all the time in the same place

export function Entity(name: string) {
  return (target) => {
    Reflect.defineMetadata('entityName', name, target);
  };
}

export function Feature(name: string) {
  return (target) => {
    Reflect.defineMetadata('feature', name, target);
  };
}
