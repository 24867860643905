<mat-form-field appearance="outline" style="width: 100%; margin-bottom: -1.25em">
  <mat-label>Search...</mat-label>
  <input
    matInput
    autocomplete="off"
    [formControl]="searchCtrl"
    (keydown)="onKeydown($event)"
  />
  <button mat-icon-button matSuffix *ngIf="searchCtrl.value" (click)="clear()">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
