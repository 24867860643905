import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  ComponentToolsModule,
  TrimDirectiveModule,
} from '@frontend/common/component-tools';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BulkUploaderComponent } from './bulk-uploader.component';
import { BulkUploaderService } from './bulk-uploader.service';
import { BulkUploaderButtonComponent } from './button/bulk-uploader-button.component';
import { BulkUploaderOperationComponent } from './operation/bulk-uploader-operation.component';
import { reducers } from './store/bulk-uploader.reducer';
import { OperationsEffects } from './store/operations/operations.effects';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    OverlayModule,
    PortalModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatTooltipModule,
    ComponentToolsModule,
    TrimDirectiveModule,
    StoreModule.forFeature('bulk-uploader', reducers),
    EffectsModule.forFeature([OperationsEffects]),
    ReactiveFormsModule,
  ],
  declarations: [
    BulkUploaderComponent,
    BulkUploaderButtonComponent,
    BulkUploaderOperationComponent,
  ],
  exports: [BulkUploaderComponent, BulkUploaderButtonComponent],
})
export class BulkUploaderModule {
  static forRoot(): ModuleWithProviders<BulkUploaderModule> {
    return {
      ngModule: BulkUploaderModule,
      providers: [BulkUploaderService],
    };
  }
}
