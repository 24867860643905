import { MilestoneType, MilestoneTypes } from '@frontend/common/util';
import { User, Workflow } from './common-interfaces';

export interface StartWorkflowFragment {
  __typename: MilestoneType;
  created_utc: string;
  created_by: string;
  workflow: Workflow;
  user?: User;
}

export const START_WORKFLOW_FRAGMENT = `
  fragment ${MilestoneTypes.StartWorkflowMilestone} on ${MilestoneTypes.StartWorkflowMilestone} {
    __typename
    created_utc
    created_by
    workflow(with_deleted: true) {
      name
    }
    user(with_deleted: true) {
      first_name
      last_name
      email
    }
  }
`;
