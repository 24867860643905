import { ExpectedError } from '@frontend/common/util';
import * as Papa from 'papaparse';

export type SuccessCallback = (data: {}[]) => void;
export type FailureCallback = (error: ExpectedError) => void;

export interface ParseOptions {
  errorCb?: FailureCallback;
  transformHeader?: Papa.ParseConfig['transformHeader'];
  transform?: Papa.ParseConfig['transform'];
}

export function parseCsv(
  file: File,
  successCb: SuccessCallback,
  options: ParseOptions = {}
) {
  Papa.parse(file, {
    header: true,
    delimiter: ',',
    skipEmptyLines: true,
    transformHeader: options.transformHeader,
    transform: options.transform,
    complete: onComplete(successCb, options.errorCb),
  });
}

export function onComplete(
  successCb: SuccessCallback,
  errorCb: FailureCallback = () => {}
): Papa.ParseConfig['complete'] {
  return (obj: Papa.ParseResult<{}>): void => {
    if (!obj.data.length) {
      const msg = $localize`The CSV file provided is empty.`;
      errorCb(new ExpectedError(msg));
    } else if (obj.errors.length) {
      console.warn('CSV Parser', obj.errors);
      const lineNumber = obj.errors[0].row + 2;
      const msg = $localize`Check file’s content and try again. Error on line: ${lineNumber}`;
      errorCb(new ExpectedError(msg));
    } else {
      successCb(obj.data);
    }
  };
}
