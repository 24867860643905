<div style="flex-direction: column; flex-grow: 1">
  <div style="height: 80px; border-bottom: 1px solid #e8e9e8">
    <!-- Name -->
    <div style="padding: 0 24px; align-items: center">
      <skeleton-item style="width: 150px; height: 20px"></skeleton-item>
      <div style="width: 30px"></div>
      <skeleton-item style="width: 100px; height: 15px"></skeleton-item>
    </div>
  </div>

  <!-- Message -->
  <div style="flex-direction: column; padding: 16px 24px">
    <div style="flex-direction: column">
      <div style="justify-content: space-between">
        <skeleton-item
          style="height: 10px; margin: 16px 0 8px; width: 70px"
        ></skeleton-item>
        <skeleton-item
          style="height: 10px; margin: 16px 0 8px; width: 70px"
        ></skeleton-item>
      </div>
      <div style="flex-direction: column">
        <skeleton-item style="height: 16px; margin: 8px 0; width: 100%"></skeleton-item>
        <skeleton-item style="height: 16px; margin: 8px 0; width: 60%"></skeleton-item>
      </div>
    </div>
  </div>

  <!-- Message -->
  <div style="flex-direction: column; padding: 16px 24px">
    <div style="flex-direction: column">
      <div style="justify-content: space-between">
        <skeleton-item
          style="height: 10px; margin: 16px 0 8px; width: 70px"
        ></skeleton-item>
        <skeleton-item
          style="height: 10px; margin: 16px 0 8px; width: 70px"
        ></skeleton-item>
      </div>
      <div style="flex-direction: column">
        <skeleton-item style="height: 16px; margin: 8px 0; width: 100%"></skeleton-item>
        <skeleton-item style="height: 16px; margin: 8px 0; width: 30%"></skeleton-item>
      </div>
    </div>
  </div>

  <!-- Message -->
  <div style="flex-direction: column; padding: 16px 24px">
    <div style="flex-direction: column">
      <div style="justify-content: space-between">
        <skeleton-item
          style="height: 10px; margin: 16px 0 8px; width: 70px"
        ></skeleton-item>
        <skeleton-item
          style="height: 10px; margin: 16px 0 8px; width: 70px"
        ></skeleton-item>
      </div>
      <div style="flex-direction: column">
        <skeleton-item style="height: 16px; margin: 8px 0; width: 100%"></skeleton-item>
        <skeleton-item style="height: 16px; margin: 8px 0; width: 90%"></skeleton-item>
      </div>
    </div>
  </div>
</div>
