import { CanDeactivate } from '@angular/router';
import { ConfirmationDialogService } from '@frontend/common/confirmation-dialog';
import { select, Store } from '@ngrx/store';
import { combineLatest as observableCombineLatest, Observable, of } from 'rxjs';
import { first, mergeMap } from 'rxjs/operators';
import { BaseEntity } from './base.entity';
import { BaseSelectors } from './base.selectors';

export class BaseCanDeactivate<T extends BaseEntity> implements CanDeactivate<T> {
  protected readonly selectors: BaseSelectors<T>;

  constructor(
    entityType: new () => T,
    protected readonly confirmationDialog: ConfirmationDialogService,
    protected readonly store: Store<T>
  ) {
    this.selectors = new BaseSelectors(entityType);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    return observableCombineLatest(
      this.store.pipe(select(this.selectors.getAllWithError)),
      this.store.pipe(select(this.selectors.getAllPending))
    ).pipe(
      first(),
      mergeMap(([error, pending]) => {
        if (error.length + pending.length) {
          return this.confirmationDialog.open(ConfirmationDialogService.defaultNavigate);
        }
        return of(true);
      })
    );
  }
}
