import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { Operation } from '../../models/operation.model';
import * as operationsActions from './operations.actions';

export interface OperationsState extends EntityState<Operation> {
  pendingOp: number;
}

const entityAdapterOpts = { selectId: (obj) => obj.correlation_id };

export const operationAdapter: EntityAdapter<Operation> = createEntityAdapter<Operation>(
  entityAdapterOpts
);

export const operationInitialState: OperationsState = operationAdapter.getInitialState({
  pendingOp: 0,
});

export function operationsReducer(
  state = operationInitialState,
  action
): OperationsState {
  const p = action.payload;
  switch (action.type) {
    case operationsActions.CREATE_OPERATION: {
      return operationAdapter.addOne(p, state);
    }
    case operationsActions.OPERATION_UPDATE: {
      return operationAdapter.updateOne({ id: p.correlation_id, changes: p }, state);
    }
    case operationsActions.PENDING_OPERATIONS_UPDATE: {
      return { ...state, pendingOp: state.pendingOp + p };
    }
    case operationsActions.REMOVE_OPERATION: {
      return operationAdapter.removeOne(p, state);
    }
    default: {
      return state;
    }
  }
}
