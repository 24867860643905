import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ConfirmationDialogsModule } from '@frontend/common/confirmation-dialog';
import { SharedUiSkeletonFormModule } from '@frontend/common/skeletons';
import { PatientComponent } from './patient.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatMenuModule,
    SharedUiSkeletonFormModule,
    ConfirmationDialogsModule,
  ],
  declarations: [PatientComponent],
  exports: [PatientComponent],
})
export class PatientModule {}
