<div [formGroup]="form">
  <div class="patient-form-row">
    <!-- FIRST NAME -->
    <mat-form-field appearance="outline" class="patient-form-field">
      <mat-label i18n>First name</mat-label>
      <input matInput formControlName="first_name" (input)="calculateSecureName()" />
    </mat-form-field>

    <!-- LAST NAME -->
    <mat-form-field appearance="outline" class="patient-form-field">
      <mat-label i18n>Last name</mat-label>
      <input matInput formControlName="last_name" (input)="calculateSecureName()" />
    </mat-form-field>

    <!-- CELL PHONE -->
    <mat-form-field
      appearance="outline"
      class="patient-form-field"
      hintLabel="10 digit US format"
      i18n-hintLabel
    >
      <mat-label i18n>Cell phone</mat-label>
      <input matInput #cellPhone formControlName="phone_number" />
      <mat-hint align="end">{{ countNumbers(cellPhone.value) }}/10</mat-hint>
    </mat-form-field>
  </div>

  <div class="patient-form-row">
    <!-- MRN -->
    <mat-form-field appearance="outline" class="patient-form-field">
      <mat-label i18n>MRN</mat-label>
      <input matInput formControlName="mrn" />
    </mat-form-field>

    <!-- TAG ID -->
    <mat-form-field appearance="outline" class="patient-form-field">
      <mat-label i18n>Badge ID</mat-label>
      <input matInput formControlName="tag_id" />
    </mat-form-field>

    <!-- DISPLAY NAME -->
    <mat-form-field
      appearance="outline"
      class="patient-form-field"
      hintLabel="This name will show on the display board"
      i18n-hintLabel
    >
      <mat-label i18n>Display name</mat-label>
      <input
        matInput
        #displayNameRef
        maxlength="5"
        formControlName="secured_patient_name"
      />
      <mat-hint align="end">{{ displayNameRef.value?.length || 0 }}/5</mat-hint>
    </mat-form-field>
  </div>

  <div class="patient-form-row">
    <!-- NOTE -->
    <mat-form-field appearance="outline" class="full-width">
      <mat-label i18n>Notes</mat-label>
      <input matInput #noteRef formControlName="note" />
      <mat-hint align="end">{{ noteRef.value?.length || 0 }}/250</mat-hint>
    </mat-form-field>
  </div>

  <div class="patient-form-row">
    <!-- ADDITIONAL NOTE -->
    <mat-form-field appearance="outline" class="full-width">
      <mat-label i18n>Additional Notes</mat-label>
      <input matInput #additionalNoteRef formControlName="additional_note" />
      <mat-hint align="end">{{ additionalNoteRef.value?.length || 0 }}/250</mat-hint>
    </mat-form-field>
  </div>
</div>
