import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BulkUploaderService } from '@frontend/common/bulk-uploader';
import { getRouterState } from '@frontend/common/ph-router-store';
import { BaseService } from '@frontend/shared/base-store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { filter, withLatestFrom } from 'rxjs/operators';
import { Sensor } from './sensor.model';
import { sensorsActions } from './store/sensors.actions';

@UntilDestroy()
@Injectable()
export class SensorsService extends BaseService<Sensor> implements OnDestroy {
  constructor(
    http: HttpClient,
    private bulkUploaderSrv: BulkUploaderService,
    private store: Store<any>
  ) {
    super(http, 'sensors');

    this.bulkUploaderSrv.finishedEntityOperation$
      .pipe(
        untilDestroyed(this),
        filter((entity) => entity === 'sensors'),
        withLatestFrom(this.store.pipe(select(getRouterState)))
      )
      .subscribe(([_, router]) =>
        this.store.dispatch(
          sensorsActions.load({ location_id: router.state.params.location_id })
        )
      );
  }

  ngOnDestroy() {}
}
