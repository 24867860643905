import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import {
  CreatePatientApi,
  CreatePatientFailureApi,
  CreatePatientSuccessApi,
  DeletePatientApi,
  DeletePatientFailureApi,
  DeletePatientSuccessApi,
  LoadCompletedPatientsApi,
  LoadCompletedPatientsFailureApi,
  LoadCompletedPatientsSuccessApi,
  LoadPatientApi,
  LoadPatientFailureApi,
  LoadPatientsApi,
  LoadPatientsFailureApi,
  LoadPatientsSuccessApi,
  LoadPatientSuccessApi,
  PatientApiActionTypes,
  UpdatePatientApi,
  UpdatePatientFailureApi,
  UpdatePatientSuccessApi,
} from './patient-api.actions';
import { PatientApiService } from './patient-api.service';

@Injectable()
export class PatientApiEffects {
  loadPatientsApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PatientApiActionTypes.LoadPatientsApi),
      switchMap(({ payload }: LoadPatientsApi) =>
        this.srv.get(payload.params).pipe(
          map((patients) => new LoadPatientsSuccessApi({ patients })),
          catchError((err) => of(new LoadPatientsFailureApi(err)))
        )
      )
    )
  );

  loadCompletedPatientsApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PatientApiActionTypes.LoadCompletedPatientsApi),
      switchMap(({ payload }: LoadCompletedPatientsApi) =>
        this.srv.getCompleted(payload.params).pipe(
          map((patients) => new LoadCompletedPatientsSuccessApi({ patients })),
          catchError((err) => of(new LoadCompletedPatientsFailureApi(err)))
        )
      )
    )
  );

  loadOnePatientsApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PatientApiActionTypes.LoadPatientApi),
      switchMap(({ payload: { id } }: LoadPatientApi) =>
        this.srv.getOne(id).pipe(
          map((patient) => new LoadPatientSuccessApi({ patient })),
          catchError(({ message }) => of(new LoadPatientFailureApi({ id, message })))
        )
      )
    )
  );

  createPatientApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PatientApiActionTypes.CreatePatientApi),
      mergeMap(({ payload }: CreatePatientApi) =>
        this.srv.create(payload.patient).pipe(
          map((patient) => new CreatePatientSuccessApi({ patient })),
          catchError((error) => of(new CreatePatientFailureApi(error)))
        )
      )
    )
  );

  updatePatientApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PatientApiActionTypes.UpdatePatientApi),
      mergeMap(({ payload }: UpdatePatientApi) =>
        this.srv.update(payload.patient).pipe(
          map((patient) => new UpdatePatientSuccessApi({ patient })),
          catchError((error) => of(new UpdatePatientFailureApi(error)))
        )
      )
    )
  );

  deletePatientApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PatientApiActionTypes.DeletePatientApi),
      mergeMap(({ payload }: DeletePatientApi) =>
        this.srv.delete(payload.id).pipe(
          map(({ id }) => new DeletePatientSuccessApi({ id: payload.id })),
          catchError((err) => of(new DeletePatientFailureApi(err)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private srv: PatientApiService) {}
}
