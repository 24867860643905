<skeleton-form *ngIf="loading$ | async"></skeleton-form>

<ng-container *ngIf="!(loading$ | async)">
  <mat-card class="mat-typography">
    <h2 mat-card-title i18n>Assign patient to next workflow(s)</h2>
    <mat-dialog-content>
      <mat-selection-list [formControl]="workflowsCtrl">
        <mat-list-option
          class="mat-body-1"
          checkboxPosition="before"
          *ngFor="let w of workflows$ | async"
          [title]="w.name"
          [value]="w.id"
        >
          <div class="list-option-wrapper">
            <span>{{ w.name }}</span>
            <span class="workflow-type" [ngSwitch]="w.workflow_type_id">
              <ng-container *ngSwitchCase="1" i18n>Care</ng-container>
              <ng-container *ngSwitchCase="2" i18n>Registration</ng-container>
              <ng-container *ngSwitchCase="3" i18n>Checkout</ng-container>
            </span>
          </div>
        </mat-list-option>
      </mat-selection-list>
    </mat-dialog-content>
    <mat-card-actions mat-dialog-actions align="end">
      <button
        mat-button
        mat-dialog-close
        (click)="assignToWorkflows()"
        [disabled]="!workflowsCtrl.value?.length"
        i18n
      >
        ASSIGN TO WORKFLOW
      </button>
      <button
        mat-button
        mat-dialog-close
        (click)="endVisit()"
        [disabled]="!!workflowsCtrl.value?.length"
        i18n
      >
        END PATIENT'S VISIT
      </button>
      <button mat-button mat-dialog-close i18n>SKIP</button>
    </mat-card-actions>
  </mat-card>
</ng-container>
