<mat-table
  [dataSource]="dataSource"
  [trackBy]="trackBy"
  matSort
  matSortDisableClear="true"
  matSortActive="fullName"
  matSortDirection="asc"
>
  <ng-container matColumnDef="fullName">
    <mat-header-cell *matHeaderCellDef>
      <span mat-sort-header start="asc" i18n>Recipient</span>
    </mat-header-cell>
    <mat-cell *cdkCellDef="let row">
      <span>{{ row['fullName'] }}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="relationship">
    <mat-header-cell *matHeaderCellDef>
      <span mat-sort-header start="asc" i18n>Relationship</span>
    </mat-header-cell>
    <mat-cell *cdkCellDef="let row">
      <span>{{ row['relationship'] }}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="cellPhone">
    <mat-header-cell *matHeaderCellDef>
      <span mat-sort-header start="asc" i18n>Cell Phone</span>
    </mat-header-cell>
    <mat-cell *cdkCellDef="let row">
      <span>{{ row['cellPhone'] }}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="actions_column">
    <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
    <mat-cell *cdkCellDef="let row" class="end">
      <span
        *ngIf="row.pending"
        style="
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <mat-progress-spinner
          [diameter]="25"
          [strokeWidth]="3"
          mode="indeterminate"
        ></mat-progress-spinner>
      </span>
      <ng-container *ngIf="!row.pending">
        <ng-container *ngIf="row.error">
          <button
            mat-button
            mat-icon-button
            disableRipple
            color="warn"
            [disabled]="row.disabled"
            [routerLink]="row.id"
          >
            <mat-icon>error</mat-icon>
          </button>
          <button
            mat-button
            mat-icon-button
            disableRipple
            color="warn"
            [disabled]="row.disabled"
            (click)="discardItem(row)"
          >
            <mat-icon>close</mat-icon>
          </button>
        </ng-container>
        <ng-container *ngIf="!row.error">
          <button
            mat-button
            mat-icon-button
            disableRipple
            [disabled]="row.disabled"
            [matMenuTriggerFor]="menu"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item [routerLink]="row.id">
              <span i18n>Edit recipient</span>
            </button>
            <button mat-menu-item (click)="removeRecipient(row)">
              <span i18n>Remove recipient</span>
            </button>
          </mat-menu>
        </ng-container>
      </ng-container>
    </mat-cell>
  </ng-container>

  <mat-header-row *cdkHeaderRowDef="columnNames"> </mat-header-row>
  <mat-row
    *cdkRowDef="let row; columns: columnNames"
    [ngClass]="{ 'row-disabled': row.pending || row.error || row.disabled }"
  >
  </mat-row>
</mat-table>

<div class="empty" *ngIf="!dataSource.filteredData.length">
  <span class="mat-body-1" i18n>There are no records here yet.</span>
</div>

<mat-paginator
  [style.display]="dataSource.filteredData.length ? 'initial' : 'none'"
  pageSize="25"
  [pageSizeOptions]="[5, 25, 100]"
></mat-paginator>
