import { getParams } from '@frontend/common/ph-router-store';
import { createSelector } from '@ngrx/store';
import { RecipientTableItem } from '../recipients-table/recipients-table-item.model';
import * as fromRoot from '../reducers';

export const selectRecipientTableItems = createSelector(
  fromRoot.selectAllRecipients,
  getParams,
  (recipients, { patient_id }): RecipientTableItem[] =>
    recipients
      .filter((r) => r.patient_department_visit_id === patient_id?.toString())
      .map((r) => ({
        id: r.id,
        fullName: r.last_name + ', ' + r.first_name,
        relationship: r.relationship,
        cellPhone: r.phone_number,
        pending: r.pending,
        error: r.error,
      }))
);
