import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { first, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { PageService } from '../page/page.service';
import { UNASSIGNED_VISITS_DATA_QUERY } from './confirm-end-visits-dialog.graphql';
import { UnassignedVisitsNetworkModel } from './confirm-end-visits-dialog.model';
import { CompletePatientTypes } from '../complete-patient-type-selector-option.model';

@Injectable()
export class ConfirmEndVisitsDialogService {
  depId: number;

  amountToEnd$ = this.pageSrv.departmentId$.pipe(
    first(),
    tap((departmentId) => (this.depId = departmentId)),
    switchMap((departmentId) =>
      this.apollo
        .subscribe<UnassignedVisitsNetworkModel>({
          query: UNASSIGNED_VISITS_DATA_QUERY,
          variables: { departmentId },
        })
        .pipe(
          map(
            (result) => result.data.patient_department_visit_aggregate?.aggregate?.count
          )
        )
    ),
    shareReplay(1)
  );

  constructor(
    private apollo: Apollo,
    private http: HttpClient,
    private pageSrv: PageService
  ) {}

  confirm() {
    return this.http.put('patients/cleanup', {
      department_id: this.depId,
      completed_type_id: CompletePatientTypes.Manual,
    });
  }
}
