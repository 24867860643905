import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DowntimeNotificationComponent } from './downtime-notification.component';

@NgModule({
  declarations: [DowntimeNotificationComponent],
  imports: [CommonModule, MatBottomSheetModule, MatButtonModule, MatIconModule],
  exports: [DowntimeNotificationComponent],
})
export class SharedUiDowntimeNotificationModule {}
