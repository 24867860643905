import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getParams } from '@frontend/common/ph-router-store';
import { Store } from '@ngrx/store';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';
import { EndVisitService } from '../end-visit.service';
import { ReassignPatientDialogService } from './reassign-patient-dialog.service';

@Component({
  selector: 'frontend-reassign-patient-dialog',
  templateUrl: 'reassign-patient-dialog.component.html',
  styleUrls: ['reassign-patient-dialog.component.scss'],
  providers: [ReassignPatientDialogService, EndVisitService],
})
export class ReassignPatientDialogComponent {
  workflowsCtrl = new FormControl();

  loading$ = this.srv.loading$;
  workflows$ = this.srv.workflows$;

  constructor(
    private store: Store,
    private srv: ReassignPatientDialogService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: { patientVisitId: number },
    private endVisitSrv: EndVisitService
  ) {
    this.store
      .select(getParams)
      .pipe(first())
      .subscribe(
        (p) => this.srv.getData(p?.department_id),
        () =>
          this.snackBar.open(
            $localize`There was an unexpected error.`,
            $localize`Close`,
            {
              duration: 10000,
            }
          )
      );
  }

  assignToWorkflows() {
    const workflows: any[] = this.workflowsCtrl.value;
    forkJoin(
      workflows.map((wId) =>
        this.srv.assignToWorkflow(this.data.patientVisitId.toString(), wId)
      )
    ).subscribe();
  }

  endVisit() {
    this.endVisitSrv.openConfirmationDialog(this.data.patientVisitId);
  }
}
