import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SocketService } from '@frontend/common/util';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { BehaviorSubject, forkJoin, merge, of, race, Subject, throwError } from 'rxjs';
import {
  catchError,
  filter,
  first,
  ignoreElements,
  map,
  mergeMap,
  switchMap,
} from 'rxjs/operators';
import * as uuid from 'uuid/v4';
import { CompletePatientTypes } from '../complete-patient-type-selector-option.model';

export interface DepartmentNetworkModel {
  department: { id: number; name: string }[];
}

export interface PatientDepartmentVisitNetworkModel {
  patient_department_visit: PatientNetworkModel[];
}

export interface PatientNetworkModel {
  department_id: number;
}

export const DEPARTMENTS_DATA_QUERY = gql(`
query departmentsData {
  department(where: {deleted_utc: {_is_null: true}}, order_by: {name: asc}) {
    id
    name
  }
}
`);

export const PATIENT_DATA_QUERY = gql(`
query Patient($patientId: bigint!) {
  patient_department_visit(where: {id: {_eq: $patientId}}) {
    department_id
  }
}
`);

@Injectable()
export class ChangeDepartmentService {
  private _data$ = new BehaviorSubject(null);
  private _requestedIds$ = new Subject();

  get loading$() {
    return this._data$.asObservable().pipe(map((p) => !p));
  }

  get patient$() {
    return this._data$.pipe(
      filter((data) => data?.patient),
      map(({ patient }) => patient)
    );
  }

  get departments$() {
    return this._data$.pipe(
      filter((data) => data?.departments),
      map(({ departments }) => departments)
    );
  }

  constructor(
    private apollo: Apollo,
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private socketSrv: SocketService
  ) {
    this._requestedIds$
      .pipe(
        switchMap((patientId) =>
          forkJoin({
            departments: this._getDepartments(),
            patient: this._getPatient(patientId),
          })
        )
      )
      .subscribe(
        (data) => this._data$.next(data),
        () =>
          this.snackBar.open(
            $localize`There was an unexpected error.`,
            $localize`Close`,
            {
              duration: 10000,
            }
          )
      );
  }

  private _getDepartments() {
    return this.apollo
      .subscribe<DepartmentNetworkModel>({ query: DEPARTMENTS_DATA_QUERY })
      .pipe(map(({ data }) => data?.department));
  }

  private _getPatient(patientId) {
    return this.apollo
      .subscribe<PatientDepartmentVisitNetworkModel>({
        query: PATIENT_DATA_QUERY,
        variables: { patientId },
      })
      .pipe(map(({ data }) => data?.patient_department_visit?.[0]));
  }

  getData(patientId) {
    this._requestedIds$.next(patientId);
  }

  changeDepartment(id, department_id) {
    const correlation_id = 'correlation|' + uuid();
    return merge(
      this.http
        .patch(
          `patients/${id}/complete`,
          {
            correlation_id,
            reassign_department_id: department_id,
            completed_type_id: CompletePatientTypes.Manual,
          },
          { params: { id } }
        )
        .pipe(
          ignoreElements(),
          catchError((err) =>
            of(err?.error?.message).pipe(
              mergeMap((message) => throwError({ id, message }))
            )
          )
        ),
      race(
        this.socketSrv
          .fromLocalSuccess<any>('patients.complete')
          .pipe(filter((ws) => ws.correlation_id === correlation_id)),
        this.socketSrv.fromLocalError('patients.complete').pipe(
          filter((ws) => ws.correlation_id === correlation_id),
          mergeMap(({ message }) => throwError({ id, message }))
        )
      ).pipe(first())
    );
  }
}
