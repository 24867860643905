import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { GenericFilterItem } from '@frontend/common/util';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WaitingFiltersDialogService } from './waiting-filters-dialog.service';

@UntilDestroy()
@Component({
  selector: 'frontend-waiting-filters-dialog',
  templateUrl: './waiting-filters-dialog.component.html',
  styleUrls: ['./waiting-filters-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [WaitingFiltersDialogService],
})
export class WaitingFiltersDialogComponent implements OnDestroy {
  providers: GenericFilterItem[];
  appointment_types: GenericFilterItem[];
  workflows: GenericFilterItem[];
  phases: GenericFilterItem[];
  allPhases: GenericFilterItem[];
  locations: GenericFilterItem[];
  current_department_id: number;
  location_groups: GenericFilterItem[];

  form: FormGroup = this._fb.group({
    providers: [],
    appointment_types: [],
    workflows: [],
    phases: [],
    locations: [],
    location_groups: [],
  });

  loading$ = this.srv.loading$;

  constructor(
    private _fb: FormBuilder,
    private dialogRef: MatDialogRef<WaitingFiltersDialogComponent>,
    private srv: WaitingFiltersDialogService
  ) {
    this.srv.filters$.pipe(untilDestroyed(this)).subscribe(([options, preselected]) => {
      this.locations = options.locations;
      this.providers = options.providers;
      this.appointment_types = options.appointment_types;
      this.workflows = options.workflows;
      this.allPhases = this.phases = options.phases;
      this.location_groups = options.location_groups;
      const location_groups = preselected?.location_groups?.filter((groupId) =>
        options.location_groups.some((group) => group.id === groupId)
      );
      preselected && this.form.patchValue({ ...preselected, location_groups });
    });
    this.form.controls.workflows.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((selectedWorkflows: number[]) => {
        this.phases = this.allPhases?.filter(
          (w) => !selectedWorkflows?.length || selectedWorkflows.indexOf(w.id) > -1
        );
        const selectedPhases = this.form.controls.phases.value;
        const filteredPhases = selectedPhases?.filter((id) =>
          this.phases?.some((w) => w.children.map((p) => p.id).indexOf(id) > -1)
        );
        this.form.controls.phases.setValue(filteredPhases);
      });
  }

  ngOnDestroy() {}

  save(data?) {
    this.form.disable();
    this.form.updateValueAndValidity();
    this.srv.saveWaitingFilters(data);
    this.dialogRef.close();
  }
}
