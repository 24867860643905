import { AbstractState, BaseAction, ReducerFactory } from '@frontend/shared/base-store';
import { Department } from '../department.model';
import { departmentsActions } from './departments.actions';

class DepartmentsReducerFactory extends ReducerFactory<Department> {
  constructor() {
    super(Department);
  }

  getReducer() {
    return (
      state: AbstractState<Department> = this.adapter.getInitialState(this.initialState),
      a: BaseAction<Department>
    ) => {
      switch (a.type) {
        case departmentsActions.types.LOAD_AVAILABLE_SUCCESS:
          return this.adapter.upsertMany(a.payload, { ...state, loaded: true });
        case departmentsActions.types.LOAD_LOCATIONS_SUCCESS:
          return this.adapter.updateOne(
            {
              id: a.payload.id,
              changes: { locationIds: a.payload.locations.map((l) => l.id) },
            },
            state
          );
        case departmentsActions.types.ADD_LOCATION:
          return this.adapter.updateOne(
            {
              id: a.payload.department.id,
              changes: {
                locationIds: [
                  ...state.entities[a.payload.department.id].locationIds,
                  a.payload.location.id,
                ],
              },
            },
            state
          );
        case departmentsActions.types.REMOVE_LOCATION:
          return this.adapter.updateOne(
            {
              id: a.payload.department.id,
              changes: {
                locationIds: [
                  ...state.entities[a.payload.department.id].locationIds.filter(
                    (lid) => lid !== a.payload.location.id
                  ),
                ],
              },
            },
            state
          );
        default:
          return super.getReducer()(state, a);
      }
    };
  }
}

const reducerFunction = new DepartmentsReducerFactory().getReducer();

export function departmentsReducer(state, action) {
  return reducerFunction(state, action);
}
