import { MilestoneType, MilestoneTypes } from '@frontend/common/util';
import { Tag, User } from './common-interfaces';

export interface UpdateTagFragment {
  __typename: MilestoneType;
  created_utc: string;
  created_by: string;
  tag: Tag;
  user?: User;
}

export const UPDATE_TAG_FRAGMENT = `
  fragment ${MilestoneTypes.UpdateTagMilestone} on ${MilestoneTypes.UpdateTagMilestone} {
    __typename
    created_utc
    created_by
    tag(with_deleted: true) {
      label
      tag_value
    }
    user(with_deleted: true) {
      first_name
      last_name
      email
    }
  }
`;
