import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import {
  CreateQuickMessageApi,
  CreateQuickMessageFailureApi,
  CreateQuickMessageSuccessApi,
  DeleteQuickMessageApi,
  DeleteQuickMessageFailureApi,
  DeleteQuickMessageSuccessApi,
  QuickMessageApiActionTypes,
  LoadQuickMessageApi,
  LoadQuickMessageFailureApi,
  LoadQuickMessagesApi,
  LoadQuickMessagesFailureApi,
  LoadQuickMessagesSuccessApi,
  LoadQuickMessageSuccessApi,
  UpdateQuickMessageApi,
  UpdateQuickMessageFailureApi,
  UpdateQuickMessageSuccessApi,
} from './quick-message-api.actions';
import { QuickMessageApiService } from './quick-message-api.service';

@Injectable()
export class QuickMessageApiEffects {
  loadQuickMessagesApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuickMessageApiActionTypes.LoadQuickMessagesApi),
      switchMap(({ payload }: LoadQuickMessagesApi) =>
        this.srv.get(payload.params).pipe(
          map((quickMessages) => new LoadQuickMessagesSuccessApi({ quickMessages })),
          catchError((err) => of(new LoadQuickMessagesFailureApi(err)))
        )
      )
    )
  );

  loadOneQuickMessagesApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuickMessageApiActionTypes.LoadQuickMessageApi),
      switchMap(({ payload: { id } }: LoadQuickMessageApi) =>
        this.srv.getOne(id).pipe(
          map((quickMessage) => new LoadQuickMessageSuccessApi({ quickMessage })),
          catchError(({ message }) => of(new LoadQuickMessageFailureApi({ id, message })))
        )
      )
    )
  );

  createQuickMessageApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuickMessageApiActionTypes.CreateQuickMessageApi),
      mergeMap(({ payload }: CreateQuickMessageApi) =>
        this.srv.create(payload.quickMessage).pipe(
          map((quickMessage) => new CreateQuickMessageSuccessApi({ quickMessage })),
          catchError((error) => of(new CreateQuickMessageFailureApi(error)))
        )
      )
    )
  );

  updateQuickMessageApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuickMessageApiActionTypes.UpdateQuickMessageApi),
      mergeMap(({ payload }: UpdateQuickMessageApi) =>
        this.srv.update(payload.quickMessage).pipe(
          map((quickMessage) => new UpdateQuickMessageSuccessApi({ quickMessage })),
          catchError((error) => of(new UpdateQuickMessageFailureApi(error)))
        )
      )
    )
  );

  deleteQuickMessageApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuickMessageApiActionTypes.DeleteQuickMessageApi),
      mergeMap(({ payload }: DeleteQuickMessageApi) =>
        this.srv.delete(payload.id).pipe(
          map(({ id }) => new DeleteQuickMessageSuccessApi({ id: payload.id })),
          catchError((err) => of(new DeleteQuickMessageFailureApi(err)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private srv: QuickMessageApiService) {}
}
