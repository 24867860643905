import { Injectable } from '@angular/core';
import { ConfirmationDialogService } from '@frontend/common/confirmation-dialog';
import { BaseCanDeactivate } from '@frontend/shared/base-store';
import { Store } from '@ngrx/store';
import { Department } from './department.model';

@Injectable()
export class DepartmentsCanDeactivate extends BaseCanDeactivate<Department> {
  constructor(confirmationDialog: ConfirmationDialogService, store: Store<Department>) {
    super(Department, confirmationDialog, store);
  }
}
