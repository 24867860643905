import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Add, Update, RecipientApiActionTypes, Delete } from './recipient-api.actions';
import { AddRecipient, DeleteRecipient, UpdateRecipient } from './recipient.actions';

@Injectable()
export class RecipientEffects {
  addRecipient$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(RecipientApiActionTypes.Add),
      map((a: Add) => ({
        ...a.payload.recipient,
        pending: true,
        error: undefined,
      })),
      map((recipient) => new AddRecipient({ recipient }))
    )
  );

  updateRecipient$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(RecipientApiActionTypes.Update),
      map((a: Update) => ({
        id: a.payload.recipient.id,
        changes: {
          ...a.payload.recipient,
          pending: true,
          error: undefined,
        },
      })),
      map((recipient) => new UpdateRecipient({ recipient }))
    )
  );

  deleteRecipient$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(RecipientApiActionTypes.Delete),
      map((a: Delete) => new DeleteRecipient(a.payload))
    )
  );

  constructor(private actions$: Actions) {}
}
