<a
  mat-button
  mat-icon-button
  *ngIf="(errors$ | async).length > 0"
  (click)="openOverlay()"
>
  <mat-icon>error</mat-icon>
</a>

<ng-template #errorListTmpl>
  <mat-card>
    <mat-list role="list">
      <mat-list-item
        role="listitem"
        *ngFor="let item of errors$ | async"
        [routerLink]="[item.id]"
        (click)="closeOverlay()"
      >
        <div class="line-container">{{ item.last_name }}</div>
        <div class="icon-container">
          <mat-icon>error</mat-icon>
        </div>
      </mat-list-item>
    </mat-list>
  </mat-card>
</ng-template>
