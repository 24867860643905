import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CallToStationService } from '@frontend/common/call-to-station';
import { ChangePhaseComponent } from '@frontend/workflow-rt/feature/patients';
import { VisitDetailComponent } from '../visit-detail.component';
import { WorkflowsService } from './workflows.service';

export interface CardData {
  departmentId: string;
  patientWorkflows: Workflow[];
}

export interface Workflow {
  id: number;
  name: string;
  workflowDuration: string;
  workflowId: number;
  calledBy?: string;
  calledSinceDuration?: string;
  stationName?: string;
  phaseState: string;
  phaseDuration: string;
  phaseName: string;
  phaseId: number;
  isCallablePhase: boolean;
}

@Component({
  selector: 'frontend-visit-detail-workflows',
  templateUrl: './workflows.component.html',
  styleUrls: ['../common.scss', './workflows.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [WorkflowsService],
})
export class WorkflowsComponent {
  constructor(
    public srv: WorkflowsService,
    private callToStationService: CallToStationService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<VisitDetailComponent>
  ) {}

  openChangePhaseDialog(patientWorkflowId: number, workflowId: number) {
    this.dialogRef.close();
    this.dialog.open(ChangePhaseComponent, {
      data: { patientWorkflowId, workflowId },
      closeOnNavigation: true,
    });
  }

  onCallPatient(patientWorkflowId: number, phaseId: number) {
    if (!this.callToStationService.defaultStation) {
      this.dialogRef.close();
    }
    this.callToStationService.phaseCallPatient(patientWorkflowId, phaseId).subscribe();
  }

  trackByFn(index: number, item: Workflow) {
    return item.id;
  }
}
