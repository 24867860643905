import { combineReducers, createReducer, on } from '@ngrx/store';
import { MenuItem, MenuList } from './menu.model';
import * as Actions from './unread-messages-menu.actions';

export const unreadMessagesMenuFeatureKey = 'unreadMessagesMenu';

export interface State {
  filter: string;
  list: MenuList;
  unread: number;
}

const listReducer = createReducer<MenuItem[]>(
  [],
  on(Actions.loadData, (_, { list }) => list),
  on(Actions.reset, () => [])
);

const unreadReducer = createReducer<number>(
  0,
  on(Actions.loadData, (_, { unread }) => unread),
  on(Actions.reset, (_) => 0)
);

const filterReducer = createReducer<string>(
  '',
  on(Actions.setFilter, (_, { str }) => str),
  on(Actions.reset, (_) => '')
);

export const reducer = combineReducers({
  list: listReducer,
  unread: unreadReducer,
  filter: filterReducer,
});
