import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SocketService } from '@frontend/common/util';
import { forkJoin, merge, Observable, of, race, throwError } from 'rxjs';
import {
  catchError,
  defaultIfEmpty,
  filter,
  first,
  ignoreElements,
  map,
  mapTo,
  mergeMap,
} from 'rxjs/operators';
import * as uuid from 'uuid/v4';
import { Staff } from '../staff/staff.model';

@Injectable({
  providedIn: 'root',
})
export class StaffApiService {
  constructor(private http: HttpClient, private socketSrv: SocketService) {}

  public create(entity: Staff) {
    const correlation_id = entity.id;
    return merge(
      this.http.post('staff', { ...entity, correlation_id }).pipe(
        ignoreElements(),
        catchError((ws) =>
          of(ws?.error?.message).pipe(
            map((msg) =>
              msg === 'Validation failed' ? $localize`Tag ID does not exist.` : msg
            ),
            mergeMap((message) => throwError({ id: entity.id, message }))
          )
        )
      ),
      race(
        this.socketSrv
          .fromLocalSuccess<any>('staff.create')
          .pipe(filter((ws) => ws.correlation_id === correlation_id)),
        this.socketSrv.fromLocalError('staff.create').pipe(
          filter((ws) => ws.correlation_id === correlation_id),
          mergeMap(({ message }) => throwError({ id: entity.id, message }))
        )
      ).pipe(first())
    ).pipe(
      mergeMap((staff) =>
        this.saveRelationships(staff.id, staff.department_ids).pipe(
          mapTo(staff),
          catchError(() => staff)
        )
      )
    );
  }

  public createBulk(payload: { items: Staff[]; correlation_id: string }) {
    return this.http.post('staff/bulk', payload);
  }

  public delete(id) {
    const correlation_id = 'correlation|' + uuid();
    return merge(
      this.http
        .request('delete', `staff/${id}`, { body: { correlation_id } })
        .pipe(ignoreElements()),
      race(
        this.socketSrv
          .fromLocalSuccess<any>('staff.remove')
          .pipe(filter((ws) => ws.correlation_id === correlation_id)),
        this.socketSrv.fromLocalError('staff.remove').pipe(
          filter((ws) => ws.correlation_id === correlation_id),
          mergeMap((ws) => throwError(ws))
        )
      ).pipe(first())
    ).pipe(catchError(({ message }) => throwError({ id, message })));
  }

  public get(params?: { [key: string]: string | string[] }): Observable<Staff[]> {
    return this.http.get<Staff[]>('staff', { params });
  }

  public getOne(id) {
    return this.http.get<Staff>(`staff/${id}`);
  }

  public update(entity) {
    const correlation_id = 'correlation|' + uuid();
    return merge(
      this.http.put(`staff/${entity.id}`, { ...entity, correlation_id }).pipe(
        ignoreElements(),
        catchError((ws) =>
          of(ws?.error?.message).pipe(
            map((msg) =>
              msg === 'Validation failed' ? $localize`Tag ID does not exist.` : msg
            ),
            mergeMap((message) => throwError({ id: entity.id, message }))
          )
        )
      ),
      race(
        this.socketSrv
          .fromLocalSuccess<any>('staff.update')
          .pipe(filter((ws) => ws.correlation_id === correlation_id)),
        this.socketSrv.fromLocalError('staff.update').pipe(
          filter((ws) => ws.correlation_id === correlation_id),
          mergeMap(({ message }) => throwError({ id: entity.id, message }))
        )
      ).pipe(first())
    ).pipe(
      mergeMap((staff) =>
        this.saveRelationships(staff.id, staff.department_ids).pipe(
          mapTo(staff),
          catchError(() => staff)
        )
      )
    );
  }

  private saveRelationships(id: Staff['id'], department_ids: number[]) {
    return this.get().pipe(
      map((staffs) => staffs.find((s) => s.id === id)),
      mergeMap((staff) => {
        const unlinkRequests = staff.department_ids
          .filter((oldId) => department_ids.indexOf(oldId) === -1)
          .map((department_id) => this.unlinkDepartment(id, department_id));
        const linkRequests = department_ids
          .filter((newId) => staff.department_ids.indexOf(newId) === -1)
          .map((department_id) => this.linkDepartment(id, department_id));
        return forkJoin(unlinkRequests.concat(linkRequests)).pipe(defaultIfEmpty());
      })
    );
  }

  private linkDepartment(id: Staff['id'], department_id: number) {
    const correlation_id = 'correlation|' + uuid();
    return this.http.post(`staff/${id}/departments/${department_id}`, { correlation_id });
  }

  private unlinkDepartment(id: Staff['id'], department_id: number) {
    const correlation_id = 'correlation|' + uuid();
    return this.http.request('delete', `staff/${id}/departments/${department_id}`, {
      body: { correlation_id },
    });
  }
}
