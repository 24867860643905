import { apiFetchReducer, ApiFetchState } from '@frontend/common/util';
import { combineReducers, createReducer, on } from '@ngrx/store';
import { Staff } from './staff.model';
import * as TagChirpingActions from './tag-chirping.actions';

export const tagChirpingFeatureKey = 'tagChirping';

export interface State {
  apiFetch: ApiFetchState;
  search: string;
  items: Staff[];
}

const itemsReducer = createReducer<Staff[]>(
  [],
  on(TagChirpingActions.init, TagChirpingActions.destroy, () => []),
  on(TagChirpingActions.loadItems, (_, actions) => actions.data)
);

const searchReducer = createReducer<string>(
  '',
  on(TagChirpingActions.init, TagChirpingActions.destroy, (_) => ''),
  on(TagChirpingActions.updateSearch, (_, actions) => actions.data)
);

export const reducer = combineReducers({
  apiFetch: apiFetchReducer('TAG_CHIRPING'),
  search: searchReducer,
  items: itemsReducer,
});
