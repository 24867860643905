import { Action } from '@ngrx/store';
import { QuickMessage } from '../quick-message/quick-message.model';

export enum QuickMessageApiActionTypes {
  LoadQuickMessagesApi = '[QuickMessage API] Load QuickMessages',
  LoadQuickMessagesSuccessApi = '[QuickMessage API] Load QuickMessages Success',
  LoadQuickMessagesFailureApi = '[QuickMessage API] Load QuickMessages Failure',
  LoadQuickMessageApi = '[QuickMessage API] Load QuickMessage',
  LoadQuickMessageSuccessApi = '[QuickMessage API] Load QuickMessage Success',
  LoadQuickMessageFailureApi = '[QuickMessage API] Load QuickMessage Failure',
  CreateQuickMessageApi = '[QuickMessage API] Create QuickMessage',
  CreateQuickMessageSuccessApi = '[QuickMessage API] Create QuickMessage Success',
  CreateQuickMessageFailureApi = '[QuickMessage API] Create QuickMessage Failure',
  UpdateQuickMessageApi = '[QuickMessage API] Update QuickMessage',
  UpdateQuickMessageSuccessApi = '[QuickMessage API] Update QuickMessage Success',
  UpdateQuickMessageFailureApi = '[QuickMessage API] Update QuickMessage Failure',
  DeleteQuickMessageApi = '[QuickMessage API] Delete QuickMessage',
  DeleteQuickMessageSuccessApi = '[QuickMessage API] Delete QuickMessage Success',
  DeleteQuickMessageFailureApi = '[QuickMessage API] Delete QuickMessage Failure',
}

export class LoadQuickMessagesApi implements Action {
  readonly type = QuickMessageApiActionTypes.LoadQuickMessagesApi;
  constructor(public payload: { params: any } = { params: {} }) {}
}

export class LoadQuickMessagesSuccessApi implements Action {
  readonly type = QuickMessageApiActionTypes.LoadQuickMessagesSuccessApi;
  constructor(public payload: { quickMessages: QuickMessage[] }) {}
}

export class LoadQuickMessagesFailureApi implements Action {
  readonly type = QuickMessageApiActionTypes.LoadQuickMessagesFailureApi;
  constructor(public payload: { message: string }) {}
}

export class LoadQuickMessageApi implements Action {
  readonly type = QuickMessageApiActionTypes.LoadQuickMessageApi;
  constructor(public payload: { id: string }) {}
}

export class LoadQuickMessageSuccessApi implements Action {
  readonly type = QuickMessageApiActionTypes.LoadQuickMessageSuccessApi;
  constructor(public payload: { quickMessage: QuickMessage }) {}
}

export class LoadQuickMessageFailureApi implements Action {
  readonly type = QuickMessageApiActionTypes.LoadQuickMessageFailureApi;
  constructor(public payload: { id: string; message: string }) {}
}

export class CreateQuickMessageApi implements Action {
  readonly type = QuickMessageApiActionTypes.CreateQuickMessageApi;
  constructor(public payload: { quickMessage: QuickMessage }) {}
}

export class CreateQuickMessageSuccessApi implements Action {
  readonly type = QuickMessageApiActionTypes.CreateQuickMessageSuccessApi;
  constructor(public payload: { quickMessage: QuickMessage }) {}
}

export class CreateQuickMessageFailureApi implements Action {
  readonly type = QuickMessageApiActionTypes.CreateQuickMessageFailureApi;
  constructor(public payload: { id: string; message: string }) {}
}

export class UpdateQuickMessageApi implements Action {
  readonly type = QuickMessageApiActionTypes.UpdateQuickMessageApi;
  constructor(public payload: { quickMessage: QuickMessage }) {}
}

export class UpdateQuickMessageSuccessApi implements Action {
  readonly type = QuickMessageApiActionTypes.UpdateQuickMessageSuccessApi;
  constructor(public payload: { quickMessage: QuickMessage }) {}
}

export class UpdateQuickMessageFailureApi implements Action {
  readonly type = QuickMessageApiActionTypes.UpdateQuickMessageFailureApi;
  constructor(public payload: { id: string; message: string }) {}
}

export class DeleteQuickMessageApi implements Action {
  readonly type = QuickMessageApiActionTypes.DeleteQuickMessageApi;
  constructor(public payload: { id: string }) {}
}

export class DeleteQuickMessageSuccessApi implements Action {
  readonly type = QuickMessageApiActionTypes.DeleteQuickMessageSuccessApi;
  constructor(public payload: { id: string }) {}
}

export class DeleteQuickMessageFailureApi implements Action {
  readonly type = QuickMessageApiActionTypes.DeleteQuickMessageFailureApi;
  constructor(public payload: { id: string; message: string }) {}
}

export type QuickMessageApiActions =
  | LoadQuickMessagesApi
  | LoadQuickMessagesSuccessApi
  | LoadQuickMessagesFailureApi
  | LoadQuickMessageApi
  | LoadQuickMessageSuccessApi
  | LoadQuickMessageFailureApi
  | CreateQuickMessageApi
  | CreateQuickMessageSuccessApi
  | CreateQuickMessageFailureApi
  | UpdateQuickMessageApi
  | UpdateQuickMessageSuccessApi
  | UpdateQuickMessageFailureApi
  | DeleteQuickMessageApi
  | DeleteQuickMessageSuccessApi
  | DeleteQuickMessageFailureApi;
