import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { duration, utc } from 'moment-timezone';
import { Observable, of } from 'rxjs';
import { everyMinute, formatDuration } from './format-diff';

// TODO: everyMinute should be out of this pipe and solved by parent component selectors
// TODO: "calc" logic will be moved to "transform"
@Pipe({ name: 'estimatedDuration' })
@Injectable()
export class EstimatedDurationPipe implements PipeTransform {
  transform(value, range?): Observable<string> {
    if (!value) {
      return of('-');
    }
    return everyMinute(() => this.calc(value, range), utc());
  }

  calc(value, range?, now = utc()) {
    const m = utc(value, 'YYYY-MM-DD-HH-mm-ss.SSSZ');
    let lowD = duration(m.diff(now));
    lowD = lowD.asMinutes() >= 1 ? lowD : duration(1, 'm');
    if (!range || lowD.asHours() >= 100) {
      return formatDuration(lowD, '1m');
    }
    const highD = lowD.clone().add(range, 'm');
    return `${formatDuration(lowD, '1m')} - ${formatDuration(highD, '1m')}`;
  }
}
