import { UserTypeId } from '@frontend/common/util';
import { BaseEntity, Entity, Feature } from '@frontend/shared/base-store';

@Entity('user')
@Feature('users')
export class User extends BaseEntity {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  is_blocked: boolean;
  roles: UserTypeId[];
}
