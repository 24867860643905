import { ActionTypes, BaseActions } from '@frontend/shared/base-store';
import { Customer } from '../customer.model';

export interface CustomersActionTypes extends ActionTypes {
  TOGGLE: string;
}

export class CustomersActionFactory extends BaseActions<Customer> {
  public types: CustomersActionTypes;

  constructor() {
    super(Customer);
    this.types = {
      ...this.types,
      TOGGLE: this.getTypeString('TOGGLE'),
    };
  }

  toggle(payload: Customer) {
    return { type: this.types.TOGGLE, payload: this.correlate(payload) };
  }
}

export const customersActions = new CustomersActionFactory();
