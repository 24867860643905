import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SocketService } from '@frontend/common/util';
import { merge, Observable, of, race, throwError } from 'rxjs';
import { catchError, filter, first, ignoreElements, mergeMap } from 'rxjs/operators';
import * as uuid from 'uuid/v4';
import { QuickMessage } from '../quick-message/quick-message.model';

@Injectable({
  providedIn: 'root',
})
export class QuickMessageApiService {
  constructor(private http: HttpClient, private socketSrv: SocketService) {}

  public create(entity: QuickMessage) {
    const correlation_id = entity.id;
    return merge(
      this.http.post('quick-messages', { ...entity, correlation_id }).pipe(
        ignoreElements(),
        catchError((err) =>
          of(err?.error?.message).pipe(
            mergeMap((message) => throwError({ id: entity.id, message }))
          )
        )
      ),
      race(
        this.socketSrv
          .fromLocalSuccess<any>('quick-messages.create')
          .pipe(filter((ws) => ws.correlation_id === correlation_id)),
        this.socketSrv.fromLocalError('quick-messages.create').pipe(
          filter((ws) => ws.correlation_id === correlation_id),
          mergeMap(({ message }) => throwError({ id: entity.id, message }))
        )
      ).pipe(first())
    );
  }

  public delete(id) {
    const correlation_id = 'correlation|' + uuid();
    return merge(
      this.http
        .request('delete', `quick-messages/${id}`, { body: { correlation_id } })
        .pipe(
          ignoreElements(),
          catchError((err) =>
            of(err?.error?.message).pipe(
              mergeMap((message) => throwError({ id, message }))
            )
          )
        ),
      race(
        this.socketSrv
          .fromLocalSuccess<any>('quick-messages.remove')
          .pipe(filter((ws) => ws.correlation_id === correlation_id)),
        this.socketSrv.fromLocalError('quick-messages.remove').pipe(
          filter((ws) => ws.correlation_id === correlation_id),
          mergeMap(({ message }) => throwError({ id, message }))
        )
      ).pipe(first())
    );
  }

  public get(params?: { [key: string]: string | string[] }): Observable<QuickMessage[]> {
    return this.http.get<QuickMessage[]>('quick-messages', { params });
  }

  public getOne(id) {
    return this.http.get<QuickMessage>(`quick-messages/${id}`);
  }

  public update(entity) {
    const correlation_id = 'correlation|' + uuid();
    return merge(
      this.http.put(`quick-messages/${entity.id}`, { ...entity, correlation_id }).pipe(
        ignoreElements(),
        catchError((err) =>
          of(err?.error?.message).pipe(
            mergeMap((message) => throwError({ id: entity.id, message }))
          )
        )
      ),
      race(
        this.socketSrv
          .fromLocalSuccess<any>('quick-messages.update')
          .pipe(filter((ws) => ws.correlation_id === correlation_id)),
        this.socketSrv.fromLocalError('quick-messages.update').pipe(
          filter((ws) => ws.correlation_id === correlation_id),
          mergeMap(({ message }) => throwError({ id: entity.id, message }))
        )
      ).pipe(first())
    );
  }
}
