import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { setFilter } from './unread-messages-menu.actions';
import { getFilteredList, getUnread } from './unread-messages-menu.selectors';
import { UnreadMessagesMenuService } from './unread-messages-menu.service';

@Component({
  selector: 'frontend-unread-messages-menu',
  templateUrl: 'unread-messages-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UnreadMessagesMenuService],
})
export class UnreadMessagesMenuComponent {
  list$ = timer(0, 60 * 1000).pipe(
    switchMap((i) => this.store.select(getFilteredList, i))
  );
  unread$ = this.store.select(getUnread);

  constructor(private srv: UnreadMessagesMenuService, private store: Store) {}

  onSearchUpdate(str) {
    this.store.dispatch(setFilter({ str }));
  }
}
