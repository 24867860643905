import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RecipientsService } from './recipients.service';
import { SecurableService } from './securable.service';

export interface CardData {
  departmentId: string;
  recipients: Recipient[];
}

interface Recipient {
  id: number;
  name: string;
  relationship?: string;
}

@Component({
  selector: 'frontend-visit-detail-recipients',
  templateUrl: 'recipients.component.html',
  styleUrls: ['../common.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RecipientsService, SecurableService],
})
export class RecipientsComponent {
  constructor(public srv: RecipientsService) {}

  trackByFn(index: number, item: Recipient) {
    return item.id;
  }
}
