export enum ProductId {
  CentralAdmin,
  Workflow,
  DisplayBoards,
  Kiosks,
  Assets,
  Pulse,
  Connect,
  HandHygiene,
  MapManager,
  Duress,
}
