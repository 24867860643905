import { MilestoneType, MilestoneTypes } from '@frontend/common/util';

export interface EnterLocationFragment {
  __typename: MilestoneType;
  created_utc: string;
  alias_name: string;
  location_name: string;
}

export const ENTER_LOCATION_FRAGMENT = `
  fragment ${MilestoneTypes.EnterLocationMilestone} on ${MilestoneTypes.EnterLocationMilestone} {
    __typename
    created_utc
    alias_name
    location_name
  }
`;
