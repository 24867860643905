import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { State } from './unread-messages-menu.reducer';

@Component({
  selector: 'frontend-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent {
  @Input() list: State['list'];
  @Input() unread: State['unread'];
  @Output() searchUpdate = new EventEmitter<string>();

  onSearchUpdate(val) {
    this.searchUpdate.emit(val);
  }
}
