import { Locale } from '@frontend/entity';
import { BaseEntity, Entity, Feature } from '@frontend/shared/base-store';

@Entity('customer')
@Feature('customers')
export class Customer extends BaseEntity {
  id: number;
  alias_name: string;
  full_name: string;
  subdomain: string;
  is_ad_enabled: boolean;
  is_blocked: boolean;
  default_timezone: string;
  locale_id: number;
  locale: Locale;
  auto_logout_seconds: number;
  auto_logout_min: number;
  twilio_account_sid?: string;
  twilio_auth_token?: string;
  twilio_messaging_service_id?: string;
  twilio_area_code?: string;
  enrollment_message?: string;
  asap_addon_url?: string;
  theme: { id?: number };
  license_info: {
    is_assets_rt_enabled: boolean;
    is_hand_hygiene_enabled: boolean;
    is_workflow_rt_enabled: boolean;
  };
  crm_account_id: string;
  crm_site_id: string;
}
