import { utc } from 'moment-timezone';
import { CardData, Patient } from './patient.component';
import { Visit } from './patient.graphql';
import { SecureBody } from './patient.service';

interface VisitWithSecureData extends Visit, SecureBody {
  fullName?: string;
}

export function dataManipulation(visit: VisitWithSecureData): CardData {
  const timezone = visit.department.site.timezone.id;
  const fullName = [visit.last_name, visit.first_name]
    .filter((part) => !!part)
    .join(', ');
  const nameVersions = [fullName, visit.secured_patient_name]
    .filter((part) => !!part)
    .join(' - ');
  const { tagState, tagStateSince } = _tagManipulation(visit.tag, timezone);
  const tagVersions = [visit.tag?.tag_value, visit.tag?.label]
    .filter((part) => !!part)
    .join(' | ');
  const patient = {
    visitId: visit.id,
    departmentId: visit.department.id,
    nameVersions,
    tagState,
    tagStateSince,
    tagVersions,
    mrn: visit.mrn,
    phone_number: visit.phone_number,
    note1: visit.note,
    note2: visit.additional_note,
  };
  return { patient };
}

export function _tagManipulation(
  tag: Visit['tag'],
  timezone: string
): Pick<Patient, 'tagState' | 'tagStateSince'> {
  if (!tag) {
    return {};
  }
  const nrt = utc(tag.current_tag_location?.non_reporting_since_utc || null).tz(timezone);
  const lbt = utc(tag.current_tag_location?.low_battery_since_utc || null).tz(timezone);
  if (nrt.isValid()) {
    return {
      tagState: 'red',
      tagStateSince: nrt.format('MM/DD/YYYY'),
    };
  }
  if (lbt.isValid()) {
    return {
      tagState: 'yellow',
      tagStateSince: lbt.format('MM/DD/YYYY'),
    };
  }
  return { tagState: 'green' };
}
