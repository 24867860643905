import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { QuickMessageTypeId } from '@frontend/common/util';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

interface QuickMessage {
  quick_message_template_type_id: QuickMessageTypeId;
  message: string;
  staff_label?: string;
}

@Component({
  selector: 'frontend-chat-form',
  styleUrls: ['./chat-form.component.scss'],
  templateUrl: './chat-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatFormComponent implements OnInit {
  @Input() private quickMessages: QuickMessage[] = [];
  @Input() set disabled(disabled) {
    disabled ? this.disable() : this.enable();
  }
  @Input() sendButtonDisabled: boolean;
  @Output() send: EventEmitter<string> = new EventEmitter();

  filteredQuickMessages$: Observable<QuickMessage[]>;
  messageCtrl = new FormControl(null, [Validators.required, Validators.maxLength(160)]);

  ngOnInit() {
    this.filteredQuickMessages$ = this.messageCtrl.valueChanges.pipe(
      startWith(''),
      map((str) =>
        this.quickMessages.filter(
          (qm) =>
            qm.message?.toLowerCase().includes(str?.toLowerCase()) ||
            qm.staff_label?.toLowerCase().includes(str?.toLowerCase())
        )
      )
    );
  }

  disable() {
    this.messageCtrl.disable();
  }

  enable() {
    this.messageCtrl.enable();
  }

  reset() {
    this.messageCtrl.reset();
  }

  submit(message: string) {
    this.send.emit(message);
  }
}
