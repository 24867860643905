import { createSelector } from '@ngrx/store';

import { BulkUploaderState } from '../bulk-uploader.reducer';
import { getBulkUploaderState } from '../bulk-uploader.selectors';
import { operationAdapter } from './operations.reducer';

export const getOperationsState = createSelector(
  getBulkUploaderState,
  (state: BulkUploaderState) => state.operations
);

export const getOperationsIds = createSelector(
  getOperationsState,
  operationAdapter.getSelectors().selectIds
);
export const getOperationsEntities = createSelector(
  getOperationsState,
  operationAdapter.getSelectors().selectEntities
);
export const getOperationsAll = createSelector(
  getOperationsState,
  operationAdapter.getSelectors().selectAll
);
export const getOperationsAllReverse = createSelector(getOperationsAll, (arr) =>
  arr.reverse()
);
export const getOperationsTotal = createSelector(
  getOperationsState,
  operationAdapter.getSelectors().selectTotal
);

export const getOperationsPending = createSelector(
  getOperationsState,
  (state) => state.pendingOp
);
