import { Action } from '@ngrx/store';
import { TextMessage } from '../text-message/text-message.model';

export enum TextMessageWsActionTypes {
  LocalCreateTextMessageSuccessWs = '[TextMessage WS] Local Create TextMessages Success',
  LocalCreateTextMessageFailureWs = '[TextMessage WS] Local Create TextMessages Failure',
  LocalUpdateTextMessageSuccessWs = '[TextMessage WS] Local Update TextMessages Success',
  LocalUpdateTextMessageFailureWs = '[TextMessage WS] Local Update TextMessages Failure',
  LocalRemoveTextMessageSuccessWs = '[TextMessage WS] Local Remove TextMessages Success',
  LocalRemoveTextMessageFailureWs = '[TextMessage WS] Local Remove TextMessages Failure',
  GlobalCreateTextMessageSuccessWs = '[TextMessage WS] Global Create TextMessages Success',
  GlobalUpdateTextMessageSuccessWs = '[TextMessage WS] Global Update TextMessages Success',
  GlobalRemoveTextMessageSuccessWs = '[TextMessage WS] Global Remove TextMessages Success',
}

export class LocalCreateTextMessageSuccessWs implements Action {
  readonly type = TextMessageWsActionTypes.LocalCreateTextMessageSuccessWs;

  constructor(public payload: TextMessage) {}
}

export class LocalCreateTextMessageFailureWs implements Action {
  readonly type = TextMessageWsActionTypes.LocalCreateTextMessageFailureWs;

  constructor(public payload: { correlation_id: string; message: string }) {}
}

export class LocalUpdateTextMessageSuccessWs implements Action {
  readonly type = TextMessageWsActionTypes.LocalUpdateTextMessageSuccessWs;

  constructor(public payload: TextMessage) {}
}

export class LocalUpdateTextMessageFailureWs implements Action {
  readonly type = TextMessageWsActionTypes.LocalUpdateTextMessageFailureWs;

  constructor(public payload: { correlation_id: string; message: string }) {}
}

export class LocalRemoveTextMessageSuccessWs implements Action {
  readonly type = TextMessageWsActionTypes.LocalRemoveTextMessageSuccessWs;

  constructor(public payload: { correlation_id: string }) {}
}

export class LocalRemoveTextMessageFailureWs implements Action {
  readonly type = TextMessageWsActionTypes.LocalRemoveTextMessageFailureWs;

  constructor(public payload: { correlation_id: string; message: string }) {}
}

export class GlobalCreateTextMessageSuccessWs implements Action {
  readonly type = TextMessageWsActionTypes.GlobalCreateTextMessageSuccessWs;
  constructor(public payload: TextMessage) {}
}

export class GlobalUpdateTextMessageSuccessWs implements Action {
  readonly type = TextMessageWsActionTypes.GlobalUpdateTextMessageSuccessWs;
  constructor(public payload: TextMessage) {}
}

export class GlobalRemoveTextMessageSuccessWs implements Action {
  readonly type = TextMessageWsActionTypes.GlobalRemoveTextMessageSuccessWs;
  constructor(public payload: TextMessage['id']) {}
}

export type TextMessageWsActions =
  | LocalCreateTextMessageSuccessWs
  | LocalCreateTextMessageFailureWs
  | LocalUpdateTextMessageSuccessWs
  | LocalUpdateTextMessageFailureWs
  | LocalRemoveTextMessageSuccessWs
  | LocalRemoveTextMessageFailureWs
  | GlobalCreateTextMessageSuccessWs
  | GlobalUpdateTextMessageSuccessWs
  | GlobalRemoveTextMessageSuccessWs;
