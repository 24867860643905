import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SiteResolver } from './site.resolver';
import { SitesCanDeactivate } from './sites.can-deactivate';
import { SitesResolver } from './sites.resolver';
import { SitesService } from './sites.service';
import { SitesEffects } from './store/sites.effects';
import { sitesReducer } from './store/sites.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('sites', sitesReducer),
    EffectsModule.forFeature([SitesEffects]),
  ],
  providers: [SitesService, SiteResolver, SitesResolver, SitesCanDeactivate],
})
export class SitesModule {}
