export interface CmptState {
  loading?: boolean;
  results?: Result[];
}

export interface Result {
  id: number;
  ongoing: boolean;
  data: string[];
  dataStr: string;
}

export enum SearchScope {
  Department,
  Site,
  Organization,
}
