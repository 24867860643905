<ng-container *ngIf="loading$ | async">
  <skeleton-form></skeleton-form>
</ng-container>

<ng-container *ngIf="!(loading$ | async)">
  <div [formGroup]="form">
    <div mat-dialog-title i18n>Change Phase</div>

    <mat-dialog-content>
      <mat-radio-group formControlName="phase" class="full-width">
        <mat-list role="list">
          <ng-container *ngFor="let phase of phases$ | async">
            <mat-list-item role="listitem">
              <mat-radio-button
                [value]="phase.id"
                [disabled]="initialPhaseId === phase.id"
              >
                {{ phase.name }}
              </mat-radio-button>
            </mat-list-item>
          </ng-container>
        </mat-list>
      </mat-radio-group>
    </mat-dialog-content>

    <div mat-dialog-actions align="end">
      <button mat-button [disabled]="form.disabled || form.invalid" (click)="submit()">
        <span i18n>CHANGE</span>
      </button>
      <button type="button" mat-button mat-dialog-close i18n>CANCEL</button>
    </div>
  </div>
</ng-container>
