import { MilestoneType, MilestoneTypes } from '@frontend/common/util';
import { Station, User } from './common-interfaces';

export interface CallPatientFragment {
  __typename: MilestoneType;
  created_utc: string;
  station_id: number;
  station: Station;
  user: User;
}

export const CALL_PATIENT_FRAGMENT = `
  fragment ${MilestoneTypes.CallPatientMilestone} on ${MilestoneTypes.CallPatientMilestone} {
    __typename
    created_utc
    station_id
    station(with_deleted: true) {
      name
    }
    user(with_deleted: true) {
      first_name
      last_name
      email
    }
  }
`;
