import { ActionTypes, BaseActions } from '@frontend/shared/base-store';
import { User } from '../user.model';

export interface UsersActionTypes extends ActionTypes {
  TOGGLE: string;
  TOGGLE_SUCCESS: string;
  RESET_PASSWORD: string;
}

export class UsersActions extends BaseActions<User> {
  public types: UsersActionTypes;

  constructor() {
    super(User);
    this.types = {
      ...this.types,
      TOGGLE: this.getTypeString('TOGGLE'),
      TOGGLE_SUCCESS: this.getTypeString('TOGGLE_SUCCESS'),
      RESET_PASSWORD: this.getTypeString('RESET_PASSWORD'),
    };
  }

  resetPassword(payload: User) {
    return { type: this.types.RESET_PASSWORD, payload: this.correlate(payload) };
  }

  toggle(payload: User) {
    return { type: this.types.TOGGLE, payload: this.correlate(payload) };
  }

  toggleSuccess() {
    return { type: this.types.TOGGLE_SUCCESS, payload: { pending: false } };
  }
}

export const usersActions = new UsersActions();
