import { MilestoneTypes } from '@frontend/common/util';
import gql from 'graphql-tag';
import {
  CallPatientFragment,
  CALL_PATIENT_FRAGMENT,
  CompletePatientFragment,
  COMPLETE_PATIENT_FRAGMENT,
  CreatePatientFragment,
  CREATE_PATIENT_FRAGMENT,
  EndWorkflowFragment,
  END_WORKFLOW_FRAGMENT,
  EnterLocationFragment,
  ENTER_LOCATION_FRAGMENT,
  StartPhaseFragment,
  StartWorkflowFragment,
  START_PHASE_FRAGMENT,
  START_WORKFLOW_FRAGMENT,
  UpdateTagFragment,
  UPDATE_TAG_FRAGMENT,
} from './fragments';
import {
  DeletePatientFragment,
  DELETE_PATIENT_FRAGMENT,
} from './fragments/delete-patient-fragment.graphql';

export type Milestone =
  | CreatePatientFragment
  | StartWorkflowFragment
  | EndWorkflowFragment
  | StartPhaseFragment
  | UpdateTagFragment
  | EnterLocationFragment
  | CallPatientFragment
  | CompletePatientFragment
  | DeletePatientFragment;

export interface MilestonesDataQuery {
  patient: {
    department: { site: { default_timezone: string } };
    milestones: Milestone[];
  };
}

export const MilestonesDataQuery = gql(`
${CREATE_PATIENT_FRAGMENT}
${START_WORKFLOW_FRAGMENT}
${END_WORKFLOW_FRAGMENT}
${START_PHASE_FRAGMENT}
${UPDATE_TAG_FRAGMENT}
${ENTER_LOCATION_FRAGMENT}
${CALL_PATIENT_FRAGMENT}
${COMPLETE_PATIENT_FRAGMENT}
${DELETE_PATIENT_FRAGMENT}
query MilestonesDataQuery($visitId: String!) {
  patient(id: $visitId) {
    department {
      site {
        default_timezone
      }
    }
    milestones {
      ... ${MilestoneTypes.CreatePatientMilestone}
      ... ${MilestoneTypes.StartWorkflowMilestone}
      ... ${MilestoneTypes.EndWorkflowMilestone}
      ... ${MilestoneTypes.StartPhaseMilestone}
      ... ${MilestoneTypes.UpdateTagMilestone}
      ... ${MilestoneTypes.EnterLocationMilestone}
      ... ${MilestoneTypes.CallPatientMilestone}
      ... ${MilestoneTypes.CompletePatientMilestone}
      ... ${MilestoneTypes.DeletePatientMilestone}
    }
  }
}
`);
