<span class="mat-typography">
  <b>{{ op.entity }} </b>
  <ng-container *ngIf="!op.failed">
    <span *ngIf="op.pending" i18n
      >{{ op.pending }} pending operation{{ op.pending === 1 ? '' : 's' }}</span
    >
    <span *ngIf="!op.pending" i18n>{{ op.success }} Success / {{ op.error }} Errors</span>
  </ng-container>
  <span *ngIf="op.failed" i18n>Check file's content and try again.</span>
</span>
<div id="buttons-wrapper">
  <div
    class="spinner-wrapper"
    *ngIf="(op.processed === 0 || op.pending) && !op.failed"
    i18n-matTooltip
    matTooltip="{{
      op.processed === 0
        ? ''
        : op.success +
          ' successful. ' +
          op.error +
          ' error' +
          (op.error === 1 ? '' : 's') +
          '.'
    }}"
    matTooltipPosition="left"
  >
    <mat-progress-spinner
      mode="{{ op.processed === 0 ? 'in' : '' }}determinate"
      diameter="25"
      strokeWidth="4"
      [value]="op.progress"
    ></mat-progress-spinner>
  </div>
  <button
    mat-button
    mat-icon-button
    *ngIf="!op.pending && op.errorItems.length"
    (click)="exportErrors.next(op.errorItems)"
  >
    <mat-icon>file_download</mat-icon>
  </button>
  <button
    mat-button
    mat-icon-button
    *ngIf="!op.pending || op.failed"
    (click)="clearOperation.next(op.correlation_id)"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
