import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SocketService } from '@frontend/common/util';
import { UntilDestroy } from '@ngneat/until-destroy';
import { tz, utc } from 'moment-timezone';
import { from, merge, Observable, race } from 'rxjs';
import { concatMap, filter, first, ignoreElements, map, mergeMap } from 'rxjs/operators';
import * as uuid from 'uuid/v4';

type Correlated<T> = T & {
  correlation_id: string;
};

interface SecurableData {
  mrn?: string;
  first_name?: string;
  last_name?: string;
  phone_number?: string;
}

interface RawCsvItem extends SecurableData {
  appointment_type_id?: string;
  appointment_date?: string;
  appointment_time?: string;
  provider_id?: string;
  note?: string;
  additional_note?: string;
}

type OmittedKeys =
  | 'appointment_type_id'
  | 'appointment_date'
  | 'appointment_time'
  | 'provider_id';

interface ManipulatedCsvItem extends Required<Omit<RawCsvItem, OmittedKeys>> {
  correlation_id_item: string;
  department_id: number;
  secured_patient_name: string;
  appointment_time_utc: string;
  appointment_type_id: number;
  provider_id: number;
  original_data: RawCsvItem;
}

type PatientWithoutPhi = Omit<ManipulatedCsvItem, keyof SecurableData>;

interface SecuredPatient extends PatientWithoutPhi {
  securable_id: number;
}

interface FrontendValidationError {
  payload: RawCsvItem;
  errorCode: string;
  field: string;
  limit?: number;
}

type BackendValidationError = Exclude<FrontendValidationError, 'payload'> & {
  payload: any;
};

@UntilDestroy()
@Injectable()
export class PatientsService {
  constructor(private http: HttpClient, private socketSrv: SocketService) {}

  bulkCreate(
    correlation_id: string,
    csvItems: RawCsvItem[],
    department_id: number,
    timezone: string
  ): Observable<FrontendValidationError | BackendValidationError> {
    const [passed, itemsWithErrors] = _validateItems(csvItems);
    const manipulated = passed.map(_itemManipulation(department_id, timezone));
    const clientSideValidationError$ = from(itemsWithErrors);
    const creationResultFromApi$ = this._bulkCreateSecurables(manipulated).pipe(
      concatMap((items) => this._bulkCreatePatients({ correlation_id, items })),
      map((result) => {
        if (!result.errorCode) {
          return result;
        }
        const item = manipulated.find(
          (p) => result.payload.correlation_id_item === p.correlation_id_item
        );
        return { ...result, payload: item.original_data };
      })
    );
    return merge(clientSideValidationError$, creationResultFromApi$);
  }

  _bulkCreateSecurables(patients: ManipulatedCsvItem[]) {
    type PhiPartition = [Correlated<SecurableData>[], Correlated<PatientWithoutPhi>[]];
    const [body, patientsWithoutPhi] = patients.reduce(
      ([secAcc, noPhiAcc]: PhiPartition, patient) => {
        const correlation_id = 'correlation|' + uuid();
        const { mrn, first_name, last_name, phone_number, ...rest } = patient;
        const secData = { correlation_id, mrn, first_name, last_name, phone_number };
        const noPhi = { correlation_id, ...rest };
        return [secAcc.concat(secData), noPhiAcc.concat(noPhi)];
      },
      [[], []]
    );
    return this.http.post('securable/create-bulk', body).pipe(
      map((secItems: Correlated<{ id: number }>[]) =>
        patientsWithoutPhi.map((patient): SecuredPatient => {
          const { correlation_id, ...rest } = patient;
          const sec = secItems.find((item) => item.correlation_id === correlation_id);
          return { ...rest, securable_id: sec.id };
        })
      )
    );
  }

  _bulkCreatePatients(
    body: Correlated<{ items: SecuredPatient[] }>
  ): Observable<BackendValidationError> {
    return merge(
      this.http.post<never>('patients/bulk', body).pipe(ignoreElements()),
      from(body.items).pipe(
        mergeMap((item) => {
          const success$ = this.socketSrv
            .fromEvent(`patients.create.success`)
            .pipe(
              filter((ws: any) => ws.correlation_id_item === item.correlation_id_item)
            );
          const failure$ = this.socketSrv.fromEvent(`patients.create.error`).pipe(
            filter(
              (ws: any) => ws.payload.correlation_id_item === item.correlation_id_item
            ),
            map((ws) => ({ ...ws, error: true }))
          );
          return race(success$, failure$).pipe(first());
        })
      )
    );
  }
}

function _validateItems(item: RawCsvItem[]): [RawCsvItem[], FrontendValidationError[]] {
  return item.reduce(
    ([passed, errors], curr) => {
      const result = _validateItem(curr);
      if ((result as FrontendValidationError).errorCode) {
        return [passed, errors.concat(result)];
      }
      return [passed.concat(result), errors];
    },
    [[], []]
  );
}

export function _validateItem(item: RawCsvItem): RawCsvItem | FrontendValidationError {
  if (!item.last_name) {
    return {
      payload: item,
      field: 'last_name',
      errorCode: 'IS_NOT_VALID',
    };
  }
  if (!item.first_name) {
    return {
      payload: item,
      field: 'first_name',
      errorCode: 'IS_NOT_VALID',
    };
  }
  if (!item.phone_number || item.phone_number.length !== 10) {
    return {
      payload: item,
      field: 'phone_number',
      errorCode: 'IS_NOT_VALID',
    };
  }
  if (!utc(item.appointment_date, 'M/D/YYYY', true).isValid()) {
    return {
      payload: item,
      field: 'appointment_date',
      errorCode: 'IS_NOT_VALID',
    };
  }
  if (!utc(item.appointment_time, 'h:mm A', true).isValid()) {
    return {
      payload: item,
      field: 'appointment_time',
      errorCode: 'IS_NOT_VALID',
    };
  }
  if (item.note && item.note.length > 250) {
    return {
      payload: item,
      field: 'note',
      limit: 250,
      errorCode: 'MAX_LENGTH',
    };
  }
  if (item.additional_note && item.additional_note.length > 250) {
    return {
      payload: item,
      field: 'additional_note',
      limit: 250,
      errorCode: 'MAX_LENGTH',
    };
  }
  return item;
}

export function _itemManipulation(department_id: number, timezone: string) {
  return (item: RawCsvItem): ManipulatedCsvItem => {
    const correlation_id_item = 'correlation|bulk|' + uuid();
    const appointment_time_utc = tz(
      [item.appointment_date, item.appointment_time].join('_'),
      'M/D/YYYY_h:mm A',
      true,
      timezone
    )
      .utc()
      .format();
    const secured_patient_name = [
      item.first_name?.substring(0, 3),
      item.last_name?.substring(0, 1),
    ]
      .join(' ')
      .trim();
    const appointment_type_id = parseInt(item.appointment_type_id, 10);
    const provider_id = parseInt(item.provider_id, 10);
    return {
      mrn: item.mrn,
      first_name: item.first_name,
      last_name: item.last_name,
      phone_number: item.phone_number,
      note: item.note,
      additional_note: item.additional_note,
      original_data: item,
      department_id,
      appointment_time_utc,
      secured_patient_name,
      appointment_type_id,
      provider_id,
      correlation_id_item,
    };
  };
}
