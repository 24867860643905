import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { DepartmentLocationsResolver } from './department-locations.resolver';
import { DepartmentResolver } from './department.resolver';
import { DepartmentsCanDeactivate } from './departments.can-deactivate';
import { DepartmentsResolver } from './departments.resolver';
import { DepartmentsAvailableResolver } from './departments-available.resolver';
import { DepartmentsService } from './departments.service';
import { DepartmentsEffects } from './store/departments.effects';
import { departmentsReducer } from './store/departments.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('departments', departmentsReducer),
    EffectsModule.forFeature([DepartmentsEffects]),
  ],
  providers: [
    DepartmentsService,
    DepartmentResolver,
    DepartmentsResolver,
    DepartmentsAvailableResolver,
    DepartmentLocationsResolver,
    DepartmentsCanDeactivate,
  ],
})
export class DepartmentsModule {}
