import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  constructor() {
    super();

    this.itemsPerPageLabel = $localize`Items per page`;
    this.nextPageLabel = $localize`Next page`;
    this.previousPageLabel = $localize`Prev page`;
    this.getRangeLabel = this.matRangeLabelIntl;
  }

  matRangeLabelIntl(page: number, pageSize: number, length: number) {
    const startIndex = page * pageSize;
    const endIndex = Math.min(startIndex + pageSize, length);

    return $localize`${startIndex + 1} – ${endIndex} of ${length}`;
  }
}
