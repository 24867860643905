import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { concatMap, map, mergeMap } from 'rxjs/operators';
import { Recipient } from '../reducers/recipient/recipient.model';
import { Securable } from './patients.service';

@Injectable()
export class RecipientsService {
  constructor(private http: HttpClient) {}

  public create(entity: Recipient): Observable<object> {
    return this.saveSecureData(entity).pipe(
      concatMap((securable_id: { id: string }) =>
        this.http.post('recipients', {
          securable_id: securable_id.id,
          correlation_id: entity.correlation_id,
          patient_department_visit_id: entity.patient_department_visit_id,
        })
      )
    );
  }

  public delete({ id, correlation_id }): Observable<object> {
    return this.http.request('delete', `recipients/${id}`, {
      body: { correlation_id },
    });
  }

  public update(entity): Observable<object> {
    return this.saveSecureData(entity).pipe(
      concatMap((securable_id: { id: string }) =>
        this.http.put(`recipients/${entity.id}`, {
          securable_id: securable_id.id,
          correlation_id: entity.correlation_id,
          patient_department_visit_id: entity.patient_department_visit_id,
        })
      )
    );
  }

  public getSecurableRecipientByPatient(patient_department_visit_id: string) {
    return this.http
      .get<Recipient[]>('recipients', { params: { patient_department_visit_id } })
      .pipe(
        mergeMap((recipients) => {
          const ids = recipients.map((r) => r.securable_id);
          return this.getSecureMultiple(ids).pipe(
            map((securableRecipients) =>
              securableRecipients.map((sr) => {
                const recipient = recipients.find((r) => r.securable_id === sr.id);
                return { ...recipient, ...sr.body };
              })
            )
          );
        })
      );
  }

  public getSecureMultiple(id: string[]) {
    return this.http.get<Securable<Recipient>[]>('securable', {
      params: { id },
    });
  }

  private getSecure(id) {
    return this.http.get<Securable<Recipient>>(`securable/${id}`);
  }

  private saveSecureData(data): Observable<object> {
    const { first_name, last_name, relationship, phone_number } = data;
    return this.http.post('securable', {
      first_name,
      last_name,
      relationship,
      phone_number,
    });
  }
}
