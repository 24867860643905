import { CardData } from './recipients.component';
import { Visit } from './recipients.graphql';
import { RecipientWithSecurable } from './securable.service';

interface Data {
  patient: Visit;
  recipients: RecipientWithSecurable[];
}

export function dataManipulation(data: Data): CardData {
  const recipients = data.recipients.map((recipient) => {
    const id = recipient.id;
    const name = [recipient.securableData?.last_name, recipient.securableData?.first_name]
      .filter((part) => !!part)
      .join(', ');
    return {
      id,
      name,
      relationship: recipient.securableData?.relationship,
    };
  });
  return {
    departmentId: data.patient.department_id,
    recipients,
  };
}
