import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  CreateOperation,
  ReceivedResponse,
  RemoveOperation,
} from '@frontend/common/bulk-uploader';
import { CallToStationService, Station } from '@frontend/common/call-to-station';
import {
  CurrentUserService,
  FailureCallback,
  parseCsv,
  ParseOptions,
  SocketService,
  SuccessCallback,
} from '@frontend/common/util';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import {
  BehaviorSubject,
  combineLatest,
  merge,
  Observable,
  of,
  race,
  ReplaySubject,
  Subscription,
  throwError,
  timer,
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  first,
  ignoreElements,
  map,
  mapTo,
  mergeMap,
  shareReplay,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import * as uuid from 'uuid/v4';
import {
  CareNetworkModel,
  CARE_DATA_QUERY,
  PatientNetworkModel,
  WaitingNetworkModel,
  WAITING_DATA_QUERY,
} from '../data-models';
import { DepartmentNetworkModel, DEPARTMENT_DATA_QUERY } from '../department-data.model';
import { CALLED_BY_COLUMN } from '../waiting-table/waiting-table.component';
import { filterCareData, filterWaitingData } from './data-filtering';
import {
  addUsersToPatients,
  dataManipulation,
  getUniqueUserIdsFromPatients,
  waitingDataManipulation,
} from './data-manipulation';
import {
  careFiltersManipulation,
  waitingFiltersManipulation,
} from './filters-manipulation';
import { getDepartmentId } from './page.selectors';
import { PatientsService } from './patients.service';
import { SecurableService } from './securable.service';
import { clearCareData } from './state/care/care.actions';
import { clearWaitingData } from './state/waiting/waiting.actions';
import { UsersCacheService } from './users.cache.service';

const DEFAULT_PANEL_STATE = true;

@Injectable()
export class PageService implements OnDestroy {
  private _subscriptions: Subscription[] = [];

  searchValue$ = new BehaviorSubject<string>('');

  defaultStation$: Observable<Station> = this.callToStationService.defaultStation$;

  departmentId$ = this.store.select(getDepartmentId).pipe(
    filter((id) => !!id),
    distinctUntilChanged(),
    tap(() => {
      this.store.dispatch(clearCareData());
      this.store.dispatch(clearWaitingData());
    }),
    shareReplay(1)
  );

  departmentData$ = this.departmentId$.pipe(
    switchMap((departmentId) =>
      this.apollo.default().subscribe<DepartmentNetworkModel>({
        query: DEPARTMENT_DATA_QUERY,
        variables: { departmentId },
      })
    ),
    map((dep) => dep.data.department?.[0]),
    shareReplay(1)
  );

  private isCalledByColumnSelected$ = this.departmentData$.pipe(
    map(({ waiting_table_configuration }) =>
      waiting_table_configuration.some((col) => col === CALLED_BY_COLUMN)
    )
  );

  careFilters$ = this.departmentId$.pipe(
    switchMap((depId) =>
      this.currentUserSrv.getPreference(`wrt_dep_${depId}_patients_care_filters`)
    )
  );

  careFiltersApplied$ = this.careFilters$.pipe(
    map(
      (data) =>
        data?.locations?.length ||
        data?.location_status?.length ||
        data?.providers?.length ||
        data?.appointment_types?.length ||
        data?.workflows?.length ||
        data?.phases?.length ||
        data?.location_groups?.length
    )
  );

  waitingFilters$ = this.departmentId$.pipe(
    switchMap((depId) =>
      this.currentUserSrv.getPreference(`wrt_dep_${depId}_patients_waiting_filters`)
    )
  );

  waitingFiltersApplied$ = this.waitingFilters$.pipe(
    map(
      (data) =>
        data?.locations?.length ||
        data?.providers?.length ||
        data?.appointment_types?.length ||
        data?.workflows?.length ||
        data?.phases?.length ||
        data?.show_unassigned ||
        data?.location_groups?.length
    )
  );

  _careStateManual$ = new ReplaySubject(1);
  _careStatePreference$ = this.departmentId$.pipe(
    switchMap((depId) =>
      this.currentUserSrv.getPreference(`wrt_dep_${depId}_care_panel_state`).pipe(
        first(),
        map((state) => (typeof state === 'boolean' ? state : DEFAULT_PANEL_STATE))
      )
    )
  );
  careState$ = merge(this._careStateManual$, this._careStatePreference$).pipe(
    distinctUntilChanged()
  );

  _patientsStateManual$ = new ReplaySubject(1);
  _patientsStatePreference$ = this.departmentId$.pipe(
    switchMap((depId) =>
      this.currentUserSrv.getPreference(`wrt_dep_${depId}_patient_panel_state`).pipe(
        first(),
        map((state) => (typeof state === 'boolean' ? state : DEFAULT_PANEL_STATE))
      )
    )
  );
  patientsState$ = merge(this._patientsStateManual$, this._patientsStatePreference$).pipe(
    distinctUntilChanged()
  );

  constructor(
    private apollo: Apollo,
    private snackBar: MatSnackBar,
    private securableSrv: SecurableService,
    private usersCacheService: UsersCacheService,
    private store: Store,
    private http: HttpClient,
    private socketSrv: SocketService,
    private patientsSrv: PatientsService,
    private callToStationService: CallToStationService,
    private currentUserSrv: CurrentUserService
  ) {
    this._subscriptions.push(
      this._careStateManual$
        .pipe(withLatestFrom(this.departmentId$))
        .subscribe(([state, depId]) =>
          this.currentUserSrv.save(`wrt_dep_${depId}_care_panel_state`, state)
        )
    );

    this._subscriptions.push(
      this._patientsStateManual$
        .pipe(withLatestFrom(this.departmentId$))
        .subscribe(([state, depId]) =>
          this.currentUserSrv.save(`wrt_dep_${depId}_patient_panel_state`, state)
        )
    );
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  setCarePanelState(state: boolean) {
    this._careStateManual$.next(state);
  }

  setPatientsPanelState(state: boolean) {
    this._patientsStateManual$.next(state);
  }

  careData() {
    return combineLatest([this.departmentData$, this.careFilters$]).pipe(
      switchMap(([departmentData, filters]) =>
        this.apollo
          .subscribe<CareNetworkModel>({
            query: CARE_DATA_QUERY,
            variables: {
              departmentId: departmentData?.id,
              ...careFiltersManipulation(filters),
            },
          })
          .pipe(
            switchMap((statusSummary) =>
              this.securableSrv.getSecureDataFromLocations(
                statusSummary.data.department_location
              )
            ),
            switchMap((data) => timer(0, 60 * 1000).pipe(mapTo(data))),
            map((statusSummary) => ({
              columns: departmentData?.care_table_configuration,
              collection: dataManipulation(
                statusSummary,
                departmentData?.site?.timezone?.id
              ),
            })),
            switchMap(({ columns, collection }) =>
              this.searchValue$.pipe(
                map((str) => ({
                  columns,
                  collection: filterCareData(collection, str),
                }))
              )
            )
          )
      )
    );
  }

  waitingData() {
    return combineLatest([this.departmentData$, this.waitingFilters$]).pipe(
      switchMap(([departmentData, filters]) =>
        this.apollo
          .subscribe<WaitingNetworkModel>({
            query: WAITING_DATA_QUERY,
            variables: {
              departmentId: departmentData?.id,
              ...waitingFiltersManipulation(filters),
            },
          })
          .pipe(
            switchMap((waitingData) =>
              this.securableSrv.getSecureDataFromPatients(
                waitingData.data?.patient_with_location
              )
            ),
            withLatestFrom(this.isCalledByColumnSelected$),
            switchMap(([data, isCalledByColumnSelected]) =>
              !isCalledByColumnSelected
                ? of(data)
                : this.getUserDataFromPatientsWorkflows(data)
            ),
            switchMap((data) => timer(0, 60 * 1000).pipe(mapTo(data))),
            map((waitingData) => ({
              columns: departmentData?.waiting_table_configuration,
              collection: waitingDataManipulation(
                waitingData,
                departmentData?.site?.timezone?.id
              ),
            })),
            switchMap(({ columns, collection }) =>
              this.searchValue$.pipe(
                map((str) => ({
                  columns,
                  collection: filterWaitingData(collection, str),
                }))
              )
            )
          )
      )
    );
  }

  deletePatient(id) {
    const correlation_id = 'correlation|' + uuid();
    return merge(
      this.http.request('delete', `patients/${id}`, { body: { correlation_id } }).pipe(
        ignoreElements(),
        catchError((err) =>
          of(err?.error?.message).pipe(mergeMap((message) => throwError({ id, message })))
        )
      ),
      race(
        this.socketSrv
          .fromLocalSuccess<any>('patients.remove')
          .pipe(filter((ws) => ws.correlation_id === correlation_id)),
        this.socketSrv.fromLocalError('patients.remove').pipe(
          filter((ws) => ws.correlation_id === correlation_id),
          mergeMap(({ message }) => throwError({ id, message }))
        )
      ).pipe(first())
    );
  }

  updateLocationStatus(id, location_status_id) {
    const correlation_id = 'correlation|' + uuid();
    return merge(
      this.http
        .put(`locations/${id}/update-status`, {
          location_status_id,
          correlation_id,
        })
        .pipe(
          ignoreElements(),
          catchError((err) =>
            of(err?.error?.message).pipe(
              mergeMap((message) => throwError({ id, message }))
            )
          )
        ),
      race(
        this.socketSrv
          .fromLocalSuccess<any>('locations.update-status')
          .pipe(filter((ws) => ws.correlation_id === correlation_id)),
        this.socketSrv.fromLocalError('locations.update-status').pipe(
          filter((ws) => ws.correlation_id === correlation_id),
          mergeMap(({ message }) => throwError({ id, message }))
        )
      ).pipe(first())
    );
  }

  onFileUpload(file) {
    this.departmentData$.pipe(first()).subscribe(
      (departmentData) => {
        const department_id = departmentData?.id;
        const timezone = departmentData?.site?.timezone?.id;
        const successCb = this._onFileUploadSuccess(department_id, timezone);
        const options = this._fileUploadOptions();
        parseCsv(file, successCb, options);
      },
      (error) => {
        this.snackBar.open($localize`There was an unexpected error.`, $localize`Close`, {
          duration: 10000,
        });
        throw error;
      }
    );
  }

  _fileUploadOptions(): ParseOptions {
    const errorCb: FailureCallback = (err) =>
      this.snackBar.open(err.expectedError, $localize`Close`);
    return { errorCb };
  }

  _onFileUploadSuccess(department_id: number, timezone: string): SuccessCallback {
    return (items: {}[]) => {
      const correlation_id = 'correlation|bulk|' + uuid();
      this.store.dispatch(
        new CreateOperation({ correlation_id, items, entity: 'patient' })
      );
      this.patientsSrv
        .bulkCreate(correlation_id, items, department_id, timezone)
        .subscribe(
          (result) => {
            this.store.dispatch(new ReceivedResponse({ correlation_id, ...result }));
          },
          (error) => {
            this.snackBar.open(
              $localize`There was an unexpected error.`,
              $localize`Close`,
              {
                duration: 10000,
              }
            );
            this.store.dispatch(new RemoveOperation(correlation_id));
            throw error;
          }
        );
    };
  }

  setDefaultStation(station: Station) {
    this.callToStationService.setDefaultStation(station);
  }

  onCallPatient(patientWorkflowId: number, phaseId: number) {
    this.callToStationService.phaseCallPatient(patientWorkflowId, phaseId).subscribe();
  }

  getUserDataFromPatientsWorkflows(patients: PatientNetworkModel[]) {
    const userIds = getUniqueUserIdsFromPatients(patients);

    if (!userIds.length) {
      return of(patients);
    }

    return this.usersCacheService
      .get(userIds)
      .pipe(map((users) => addUsersToPatients(patients, users)));
  }
}
