import { CmptData, Department } from './department-selector.component';
import {
  DepartmentDataQuery,
  DepartmentSelectorDataQuery,
} from './department-selector.graphql';

export function dataManipulation(data: DepartmentSelectorDataQuery): CmptData {
  const departments = data.department.map(_departmentManipulation);
  return { departments };
}

export function _departmentManipulation(dep: DepartmentDataQuery): Department {
  const id = dep.id;
  const name = dep.name;
  const siteName = dep.site.name;
  return { id, name, siteName };
}
