import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { AbstractState } from '@frontend/shared/base-store';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';
import { Department } from './department.model';
import { departmentsActions } from './store/departments.actions';
import { departmentsSelectors } from './store/departments.selectors';

@Injectable()
export class DepartmentResolver implements Resolve<Department> {
  constructor(private store: Store<AbstractState<Department>>) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Department> {
    this.store.dispatch(departmentsActions.load({ site_id: route.params.site_id }));
    return this.store.pipe(
      select(departmentsSelectors.getCollectionLoaded),
      filter((loaded) => loaded),
      switchMap(() => this.store.pipe(select(departmentsSelectors.getOneByStateParam))),
      filter((exist) => !!exist),
      first()
    );
  }
}
