import gql from 'graphql-tag';

export const FILTERS_OPTIONS_QUERY = gql(`
query patientsSectionFilters($departmentId: Int!, $siteId: Int!) {
  workflow(where: {department_id: {_eq: $departmentId}, is_activated: { _eq: true }}, order_by: {name: asc}) {
    id
    name
    workflow_phases(order_by: {sort_order: asc}) {
      id
      name
    }
  }
  appointment_type(where: {department_id: {_eq: $departmentId}}, order_by: {name: asc}) {
    id
    name
  }
  staff(where: {departments: {department_id: {_eq: $departmentId}}, staff_type_id: {_in: [9,10,11,12,13,14,31,33,34,36]}}, order_by: {last_name: asc, first_name: asc}) {
    id
    first_name
    last_name
  }
  department_location(where: {department_id: {_eq: $departmentId}, location_type_id: {_eq: "3"}}, order_by: {alias_name: asc}) {
    id
    alias_name
    name
  }
  location_group(
    where: { site_id: { _eq: $siteId } }
    order_by: { name: asc }
  ) {
    id
    name
    parent_location_group {
      id
      name
      parent_location_group {
        id
        name
        parent_location_group {
          id
          name
        }
      }
    }
  }
}
`);
