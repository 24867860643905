import { patientsActions } from '@frontend/entity/src/lib/patients/store/patients.actions';
import { AbstractState, BaseAction, ReducerFactory } from '@frontend/shared/base-store';
import { Patient } from '../patient.model';

class PatientsReducerFactory extends ReducerFactory<Patient> {
  constructor() {
    super(Patient);
  }

  getReducer() {
    return (
      state: AbstractState<Patient> = this.adapter.getInitialState(this.initialState),
      a: BaseAction<Patient>
    ) => {
      switch (a.type) {
        case patientsActions.types.MOVE:
          return this.adapter.updateOne({ id: a.payload.id, changes: a.payload }, state);
        case patientsActions.types.UPDATE_LAST_MSG_SENT:
          return this.adapter.updateOne({ id: a.payload.id, changes: a.payload }, state);
        case patientsActions.types.END_VISIT:
          return this.adapter.updateOne(
            { id: a.payload.id, changes: { ...a.payload, pending: true } },
            state
          );
        case patientsActions.types.INCREMENT_NOTIFICATION_COUNT:
          if (!state.entities[a.payload.id]) {
            console.warn(
              'entity (patient) was not found with id',
              a.payload.id,
              patientsActions.types.INCREMENT_NOTIFICATION_COUNT,
              state.entities
            );
            return state;
          }
          return this.adapter.updateOne(
            {
              id: a.payload.id,
              changes: {
                notifications_sent_count:
                  state.entities[a.payload.id].notifications_sent_count + 1,
              },
            },
            state
          );
        case patientsActions.types.INCREMENT_RECIPIENTS_COUNTER:
          if (!state.entities[a.payload.id]) {
            console.warn(
              'entity (patient) was not found with id',
              a.payload.id,
              patientsActions.types.INCREMENT_RECIPIENTS_COUNTER,
              state.entities
            );
            return state;
          }
          return this.adapter.updateOne(
            {
              id: a.payload.id,
              changes: {
                sms_recipient_count: state.entities[a.payload.id].sms_recipient_count + 1,
              },
            },
            state
          );
        case patientsActions.types.DECREMENT_RECIPIENTS_COUNTER:
          if (!state.entities[a.payload.id]) {
            console.warn(
              'entity (patient) was not found with id',
              a.payload.id,
              patientsActions.types.DECREMENT_RECIPIENTS_COUNTER,
              state.entities
            );
            return state;
          }
          return this.adapter.updateOne(
            {
              id: a.payload.id,
              changes: {
                sms_recipient_count: state.entities[a.payload.id].sms_recipient_count - 1,
              },
            },
            state
          );
        case patientsActions.types.UPDATE_CHAT_NOTIFICATION_COUNT:
          if (!state.entities[a.payload.id]) {
            console.warn(
              'entity (patient) was not found with id',
              a.payload.id,
              patientsActions.types.UPDATE_CHAT_NOTIFICATION_COUNT,
              state.entities
            );
            return state;
          }
          return this.adapter.updateOne(
            {
              id: a.payload.id,
              changes: {
                unseen_text_messages_count: a.payload.unseen_text_messages_count,
              },
            },
            state
          );
        default:
          return super.getReducer()(state, a);
      }
    };
  }
}

const reducerFunction = new PatientsReducerFactory().getReducer();

export function patientsReducer(state, action) {
  return reducerFunction(state, action);
}
