import { StaffTypeId } from '@frontend/common/util';
import gql from 'graphql-tag';

export interface ProvidersDataQuery {
  staff: Provider[];
}

interface Provider {
  id: number;
  first_name: string;
  last_name: string;
  staff_type: { id: StaffTypeId };
}

export const ProvidersDataQuery = gql(`
query ProvidersDataQuery($departmentId: Int!) {
  staff(where: {
    departments: {department_id: {_eq: $departmentId}},
    staff_type: {parent_staff_type_id: {_eq: 2}},
    deleted_utc: {_is_null: true}}, order_by: [{last_name: asc}, {first_name: asc}]) {
    id
    last_name
    first_name
    staff_type {
      id
    }
  }
}
`);
