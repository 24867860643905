import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { ReplaySubject } from 'rxjs';
import { filter, map, mapTo, startWith, switchMap } from 'rxjs/operators';
import { getDepartmentId } from './call-to-station-dialog.selectors';
import {
  CallToStationDialogDataQuery,
  STATIONS_QUERY,
  Station,
} from './call-to-station-dialog.graphql';

@Injectable()
export class CallToStationDialogService {
  stations$: ReplaySubject<Station[]> = new ReplaySubject(1);
  loading$ = this.stations$.pipe(mapTo(false), startWith(true));
  departmentId$ = this.store.select(getDepartmentId);

  constructor(private apollo: Apollo, private store: Store) {
    this.departmentId$
      .pipe(
        filter((departmentId: number) => !!departmentId),
        switchMap((departmentId) => this._getStations(departmentId))
      )
      .subscribe(this.stations$);
  }

  _getStations(departmentId: number) {
    return this.apollo
      .query<CallToStationDialogDataQuery>({
        query: STATIONS_QUERY,
        variables: { departmentId },
      })
      .pipe(map((response) => response.data?.station));
  }
}
