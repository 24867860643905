import { ActionTypes, BaseActions } from '@frontend/shared/base-store';
import { Site } from '../site.model';

export interface SitesActionTypes extends ActionTypes {
  SILENT_CREATE: string;
}

export class SiteActions extends BaseActions<Site> {
  public types: SitesActionTypes;

  constructor() {
    super(Site);
    this.types = {
      ...this.types,
      SILENT_CREATE: this.getTypeString('SILENT_CREATE'),
    };
  }

  silentCreate(payload) {
    return { type: this.types.SILENT_CREATE, payload };
  }
}

export const sitesActions = new SiteActions();
