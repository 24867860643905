<ng-container *ngIf="loading$ | async">
  <skeleton-form></skeleton-form>
</ng-container>

<ng-container *ngIf="!(loading$ | async)">
  <div [formGroup]="form">
    <div mat-dialog-title i18n>Change Department</div>

    <mat-card>
      <mat-icon>info</mat-icon>
      <span i18n
        >Changing the patient to another department will end the visit in the current
        one.</span
      >
    </mat-card>

    <mat-dialog-content>
      <mat-radio-group formControlName="department" class="full-width">
        <mat-list role="list">
          <ng-container *ngFor="let dep of departments$ | async">
            <mat-list-item role="listitem" *ngIf="initialDepartmentId !== dep.id">
              <mat-radio-button [value]="dep">
                {{ dep.name }}
              </mat-radio-button>
            </mat-list-item>
          </ng-container>
        </mat-list>
      </mat-radio-group>
    </mat-dialog-content>

    <div mat-dialog-actions align="end">
      <button mat-button [disabled]="form.disabled || form.invalid" (click)="submit()">
        <span i18n>CHANGE</span>
      </button>
      <button type="button" mat-button mat-dialog-close i18n>CANCEL</button>
    </div>
  </div>
</ng-container>
