<ng-template #skeletonTmpl>
  <skeleton-visit-detail-module></skeleton-visit-detail-module>
</ng-template>

<ng-container *ngIf="srv.cardData$ | async as cardData; else skeletonTmpl">
  <div class="module-content">
    <div class="header-line">
      <h4 i18n class="module-title">Milestones</h4>
    </div>

    <div>
      <div *ngFor="let m of cardData.milestones; let first = first">
        <div class="spacer" *ngIf="!first"></div>

        <ng-container [ngSwitch]="m.type">

          <ng-container *ngSwitchCase="MilestoneTypes.CreatePatientMilestone">
            <span class="secondary-text">{{m.datetime}}</span>
            <div class="value primary-text">
              <span i18n>Created patient <ng-container *ngIf="m.tag">with badge <span class="bold">{{m.tag}}</span>
                </ng-container> in
                <span class="bold">{{m.department}}</span> department
                <span class="secondary-text">
                  <ng-container *ngIf="!m.manual">(Auto)</ng-container>
                  <ng-container *ngIf="m.manual">
                    <ng-container *ngIf="!m.triggerBy">(Manual)</ng-container>
                    <ng-container *ngIf="m.triggerBy">(Manual by {{m.triggerBy}})</ng-container>
                  </ng-container>
                </span>
              </span>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="MilestoneTypes.StartWorkflowMilestone">
            <span class="secondary-text">{{m.datetime}}</span>
            <div class="value primary-text">
              <span i18n>Started <span class="bold">{{m.workflow}}</span> workflow
                <span class="secondary-text">
                  <ng-container *ngIf="!m.manual">(Auto)</ng-container>
                  <ng-container *ngIf="m.manual">
                    <ng-container *ngIf="!m.triggerBy">(Manual)</ng-container>
                    <ng-container *ngIf="m.triggerBy">(Manual by {{m.triggerBy}})</ng-container>
                  </ng-container>
                </span>
              </span>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="MilestoneTypes.EndWorkflowMilestone">
            <span class="secondary-text">{{m.datetime}}</span>
            <div class="value primary-text">
              <span i18n>Ended <span class="bold">{{m.workflow}}</span> workflow
                <span class="secondary-text">
                  <ng-container *ngIf="!m.manual">(Auto)</ng-container>
                  <ng-container *ngIf="m.manual">
                    <ng-container *ngIf="!m.triggerBy">(Manual)</ng-container>
                    <ng-container *ngIf="m.triggerBy">(Manual by {{m.triggerBy}})</ng-container>
                  </ng-container>
                </span>
              </span>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="MilestoneTypes.StartPhaseMilestone">
            <span class="secondary-text">{{m.datetime}}</span>
            <div class="value primary-text">
              <span i18n>Started <span class="bold">{{m.phase}}</span> phase in <span class="bold">{{m.workflow}}</span>
                workflow
                <span class="secondary-text">
                  <ng-container *ngIf="!m.manual">(Auto)</ng-container>
                  <ng-container *ngIf="m.manual">
                    <ng-container *ngIf="!m.triggerBy">(Manual)</ng-container>
                    <ng-container *ngIf="m.triggerBy">(Manual by {{m.triggerBy}})</ng-container>
                  </ng-container>
                </span>
              </span>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="MilestoneTypes.UpdateTagMilestone">
            <span class="secondary-text">{{m.datetime}}</span>
            <div class="value primary-text">
              <span i18n>Assigned badge <span class="bold">{{m.tag}} </span>
                <span class="secondary-text">
                  <ng-container *ngIf="!m.manual">(Auto)</ng-container>
                  <ng-container *ngIf="m.manual">
                    <ng-container *ngIf="!m.triggerBy">(Manual)</ng-container>
                    <ng-container *ngIf="m.triggerBy">(Manual by {{m.triggerBy}})</ng-container>
                  </ng-container>
                </span>
              </span>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="MilestoneTypes.EnterLocationMilestone">
            <span class="secondary-text">{{m.datetime}}</span>
            <div class="value primary-text">
              <span i18n>Entered <span class="bold">{{m.location}}</span> location</span>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="MilestoneTypes.CallPatientMilestone">
            <span class="secondary-text">{{m.datetime}}</span>
            <div class="value primary-text">
              <span i18n>Called to <span class="bold">{{m.station}} </span>
                <span class="secondary-text">
                  <ng-container>
                    <ng-container *ngIf="!m.triggerBy">(Manual)</ng-container>
                    <ng-container *ngIf="m.triggerBy">(Manual by {{m.triggerBy}})</ng-container>
                  </ng-container>
                </span>
              </span> 
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="MilestoneTypes.CompletePatientMilestone">
            <span class="secondary-text">{{m.datetime}}</span>
            <div class="value primary-text">
              <span i18n>Ended <span class="bold">{{m.department}}</span> department visit
                <span class="secondary-text">
                  <ng-container *ngIf="!m.manual">(Auto)</ng-container>
                  <ng-container *ngIf="m.manual">
                    <ng-container *ngIf="!m.triggerBy">(Manual)</ng-container>
                    <ng-container *ngIf="m.triggerBy">(Manual by {{m.triggerBy}})</ng-container>
                  </ng-container>
                </span>
              </span>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="MilestoneTypes.DeletePatientMilestone">
            <span class="secondary-text">{{m.datetime}}</span>
            <div class="value primary-text">
              <span i18n>Deleted visit
                <span class="secondary-text">
                  <ng-container *ngIf="!m.manual">(Auto)</ng-container>
                  <ng-container *ngIf="m.manual">
                    <ng-container *ngIf="!m.triggerBy">(Manual)</ng-container>
                    <ng-container *ngIf="m.triggerBy">(Manual by {{m.triggerBy}})</ng-container>
                  </ng-container>
                </span>
              </span>
            </div>
          </ng-container>

        </ng-container>
      </div>
    </div>

  </div>
</ng-container>