import { AbstractState, BaseAction, ReducerFactory } from '@frontend/shared/base-store';
import { PatientWorkflow } from '../patient-workflow.model';
import { patientWorkflowsActions } from './patient-workflows.actions';

class PatientWorkflowsReducer extends ReducerFactory<PatientWorkflow> {
  constructor() {
    super(PatientWorkflow);
  }

  getReducer() {
    return (
      state: AbstractState<PatientWorkflow> = this.adapter.getInitialState(
        this.initialState
      ),
      a: BaseAction<PatientWorkflow>
    ) => {
      switch (a.type) {
        case patientWorkflowsActions.types.CALL:
          return this.adapter.updateOne(
            { id: a.payload.patient_workflow_id, changes: { pending: true } },
            state
          );
        case patientWorkflowsActions.types.CALL_SUCCESS:
          return this.adapter.updateOne(
            { id: a.payload.id, changes: { pending: false } },
            state
          );
        case patientWorkflowsActions.types.CALL_FAILURE:
          return this.adapter.updateOne(
            {
              id: a.payload.patient_department_visit_workflow_id,
              changes: { pending: false },
            },
            state
          );
        case patientWorkflowsActions.types.UPDATE_CALLED_COUNT:
          return this.adapter.updateOne(
            { id: a.payload.id, changes: { called_count: a.payload.called_count } },
            state
          );
        default:
          return super.getReducer()(state, a);
      }
    };
  }
}

const reducerFunction = new PatientWorkflowsReducer().getReducer();

export function patientWorkflowsReducer(state, action) {
  return reducerFunction(state, action);
}
