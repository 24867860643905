import { ActionTypes, BaseActions } from '@frontend/shared/base-store';
import { Patient } from '../patient.model';

export interface PatiensActionTypes extends ActionTypes {
  MOVE: string;
  END_VISIT: string;
  INCREMENT_NOTIFICATION_COUNT: string;
  INCREMENT_RECIPIENTS_COUNTER: string;
  DECREMENT_RECIPIENTS_COUNTER: string;
  UPDATE_LAST_MSG_SENT: string;
  END_VISIT_AND_REASSIGN_DEPARTMENT: string;
  UPDATE_CHAT_NOTIFICATION_COUNT: string;
}

export class PatientActions extends BaseActions<Patient> {
  public types: PatiensActionTypes;

  constructor() {
    super(Patient);
    this.types = {
      ...this.types,
      MOVE: this.getTypeString('MOVE'),
      END_VISIT: this.getTypeString('END_VISIT'),
      INCREMENT_NOTIFICATION_COUNT: this.getTypeString('INCREMENT_NOTIFICATION_COUNT'),
      INCREMENT_RECIPIENTS_COUNTER: this.getTypeString('INCREMENT_RECIPIENTS_COUNTER'),
      DECREMENT_RECIPIENTS_COUNTER: this.getTypeString('DECREMENT_RECIPIENTS_COUNTER'),
      UPDATE_LAST_MSG_SENT: this.getTypeString('UPDATE_LAST_MSG_SENT'),
      END_VISIT_AND_REASSIGN_DEPARTMENT: this.getTypeString(
        'END_VISIT_AND_REASSIGN_DEPARTMENT'
      ),
      UPDATE_CHAT_NOTIFICATION_COUNT: this.getTypeString(
        'UPDATE_CHAT_NOTIFICATION_COUNT'
      ),
    };
  }

  move(payload) {
    return { type: this.types.MOVE, payload };
  }

  endVisit(payload) {
    return {
      type: this.types.END_VISIT,
      payload: { ...this.correlate(payload), pending: true },
    };
  }

  endVisitAndReassignDepartment(payload) {
    return {
      type: this.types.END_VISIT_AND_REASSIGN_DEPARTMENT,
      payload: { ...this.correlate(payload), pending: true },
    };
  }

  incrementNotificationCount(payload: { id: string }) {
    return { type: this.types.INCREMENT_NOTIFICATION_COUNT, payload };
  }

  incrementRecipientsCount(payload) {
    return {
      type: this.types.INCREMENT_RECIPIENTS_COUNTER,
      payload,
    };
  }

  decrementRecipientsCount(payload) {
    return {
      type: this.types.DECREMENT_RECIPIENTS_COUNTER,
      payload,
    };
  }

  updateLastMsgSent(payload: { id: string; last_sms_send_time: Date }) {
    return { type: this.types.UPDATE_LAST_MSG_SENT, payload };
  }

  updateChatNotificationCount(payload) {
    return {
      type: this.types.UPDATE_CHAT_NOTIFICATION_COUNT,
      payload,
    };
  }
}

export const patientsActions = new PatientActions();
