import { BaseSelectors } from '@frontend/shared/base-store';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { Workflow } from '../workflow.model';
import { MemoizedSelectorWithProps } from '@ngrx/store/src/selector';

class WorkflowsSelectors extends BaseSelectors<Workflow> {
  constructor() {
    super(Workflow);

    this.getActiveUnlockedWorkflows = createSelector(this.getAll, (workflows) =>
      workflows.filter((w) => w.is_activated).sort((a, b) => a.name.localeCompare(b.name))
    );

    this.getWorkflowById = createSelector(
      this.getActiveUnlockedWorkflows,
      (workflows, workflowId) => workflows.find((workflow) => workflow.id === workflowId)
    );
  }

  public getWorkflowById: MemoizedSelectorWithProps<{}, {}, Workflow>;
  public getActiveUnlockedWorkflows: MemoizedSelector<{}, Workflow[]>;
}

export const workflowsSelectors = new WorkflowsSelectors();
