import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { ConfirmationDialogService } from './confirmation-dialog.service';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatDialogModule, MatSlideToggleModule],
  providers: [ConfirmationDialogService],
  declarations: [ConfirmationDialogComponent],
})
export class ConfirmationDialogsModule {}
