import { duration, Duration, Moment, utc } from 'moment';
import { Observable, of, timer } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export interface DurationParts {
  h: number;
  m: number;
  s: number;
}

export function everyMinute(cb: () => any, source: Moment | Duration): Observable<any> {
  const diffSecs = source.seconds() - utc().seconds();
  const dueSecs = diffSecs > 0 ? diffSecs : 60 + diffSecs;
  return timer(dueSecs * 1000 + 1000, 60 * 1000).pipe(map(cb), startWith(cb()));
}

function getDurationFromDiff(a: 'live' | Moment, b: Moment) {
  const A = a === 'live' ? utc() : a;
  return duration(A.diff(b));
}

function getPartsFromDuration(d): DurationParts {
  const h = Math.floor(d.asHours());
  const m = d.minutes();
  const s = d.seconds();
  return { h, m, s };
}

// TODO: Split into two functions to generate static and live results
export function formatDiff<T = string>(a: 'live' | Moment, b: Moment): Observable<T> {
  function calc() {
    const d = getDurationFromDiff(a, b);
    return formatDuration(d);
  }
  return a === 'live' ? everyMinute(() => calc(), b) : of(calc());
}

export function formatDuration(d: Duration, minimum: string = '<1m'): string {
  const { h, m } = getPartsFromDuration(d);
  const parts = [];
  if (h < 0 || m < 0) {
    return minimum;
  }
  if (h > 99) {
    return '>99h';
  }
  h && parts.push(h + 'h');
  (h || m) && parts.push(m + 'm');
  parts.length || parts.push(minimum);
  return parts.join(' ');
}

export function formatDurationFromUtc(fromUtc: string, toUtc?: string): string {
  return formatDuration(duration(utc(toUtc || undefined).diff(utc(fromUtc))));
}

export function formatDurationAsDays(d: Duration) {
  const days = Math.floor(d.asDays());
  if (days < 1) {
    return '<1 day';
  }
  if (days === 1) {
    return '1 day';
  }
  if (days > 90) {
    return '+90 days';
  }
  return `${days} days`;
}
