import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store } from '@ngrx/store';

import { AbstractState } from '@frontend/shared/base-store';
import { Customer } from './customer.model';
import { customersActions } from './store/customers.actions';

@Injectable()
export class CustomersResolver implements Resolve<boolean> {
  constructor(private store: Store<AbstractState<Customer>>) {}

  resolve(): boolean {
    this.store.dispatch(customersActions.load());
    return true;
  }
}
