import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ConfirmationDialogConfigModel } from './confirmation-dialog-config.model';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';

@Injectable()
export class ConfirmationDialogService {
  static defaultNavigate = {
    title: 'Navigate away',
    content:
      'There are pending operations in course, you may lose recent changes by navigating away.',
    confirm: 'NAVIGATE AWAY',
    cancel: 'STAY',
  };

  static defaultConfirm = {
    title: 'Confirm operation',
    content: 'This action cannot be undone. Are you sure you want to proceed?',
    confirm: 'PROCEED',
  };

  static defaultConfirmWithToggle = {
    title: 'Confirm operation',
    content:
      'You are about to remove this entity and all its content. ' +
      'This action cannot be undone. Are you sure you want to proceed?',
    withToggle: true,
    confirm: 'PROCEED',
  };

  private dialogRef: MatDialogRef<ConfirmationDialogComponent>;
  private dialogConfig: ConfirmationDialogConfigModel = { width: '500px' };

  constructor(private dialog: MatDialog) {}

  open(data) {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      ...this.dialogConfig,
      data,
    });
    return this.dialogRef.afterClosed();
  }
}
