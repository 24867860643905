import gql from 'graphql-tag';

export interface AppointmentsDataQuery {
  patient: Visit;
}

export interface Visit {
  department: Department;
  patient_appointments: Appointment[];
}

interface Department {
  id: string;
  site: {
    timezone: {
      id: string;
    };
  };
}

export interface Appointment {
  id: number;
  appointment_time_utc: string;
  appointment_type_id: number;
  appointment_type: {
    name: string;
  };
  provider: {
    first_name: string;
    last_name: string;
  };
}

export const AppointmentsDataQuery = gql(`
query AppointmentsDataQuery($visitId: String!) {
  patient(id: $visitId) {
    patient_appointments {
      id
      appointment_time_utc
      provider {
        id
        last_name
        first_name
      }
      appointment_type {
        id
        name
      }
    }
    department {
      id
      site {
        timezone {
          id
        }
      }
    }
  }
}
`);
