export type MilestoneType =
  | 'CreatePatientMilestone'
  | 'StartWorkflowMilestone'
  | 'EndWorkflowMilestone'
  | 'StartPhaseMilestone'
  | 'UpdateTagMilestone'
  | 'EnterLocationMilestone'
  | 'CallPatientMilestone'
  | 'CompletePatientMilestone'
  | 'DeletePatientMilestone';

export const MilestoneTypes: { [key in MilestoneType]: MilestoneType } = {
  CreatePatientMilestone: 'CreatePatientMilestone',
  StartWorkflowMilestone: 'StartWorkflowMilestone',
  EndWorkflowMilestone: 'EndWorkflowMilestone',
  StartPhaseMilestone: 'StartPhaseMilestone',
  UpdateTagMilestone: 'UpdateTagMilestone',
  EnterLocationMilestone: 'EnterLocationMilestone',
  CallPatientMilestone: 'CallPatientMilestone',
  CompletePatientMilestone: 'CompletePatientMilestone',
  DeletePatientMilestone: 'DeletePatientMilestone',
};
