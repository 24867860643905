import { Injectable, OnDestroy } from '@angular/core';
import { SecurableCacheService } from '@frontend/common/util';
import { select, Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { MenuItem } from './menu.model';
import { loadData } from './unread-messages-menu.actions';
import { getDepartmentId } from './unread-messages-menu.selectors';

type Secure<T = {}> = T & {
  securable_id: string;
};

interface SecureData<T = {}> {
  id: string;
  body: T;
}

interface Patient {
  first_name: string;
  last_name: string;
}

interface Conversations {
  patient_department_visit: {
    id: number;
    securable_id: number;
    unseen_messages_count: {
      aggregate: {
        count: number;
      };
    };
    oldest_unseen_message_utc: { created_utc: string }[];
  }[];
}

const unreadConversationsQuery = gql(`
subscription unreadConversations($departmentId: Int!) {
  patient_department_visit(where: {text_messages: {is_seen: {_eq: false}}, _and: {department_id: {_eq: $departmentId}}}) {
    id
    securable_id
    unseen_messages_count: text_messages_aggregate(where: {is_seen: {_eq: false}}) {
      aggregate {
        count
      }
    }
    oldest_unseen_message_utc: text_messages(where: {is_seen: {_eq: false}}, order_by: {created_utc: asc}, limit: 1) {
      created_utc
    }
  }
}
`);

@Injectable()
export class UnreadMessagesMenuService implements OnDestroy {
  subscription: Subscription;

  constructor(
    private securableCache: SecurableCacheService,
    private apollo: Apollo,
    private store: Store
  ) {
    this.subscription = this.store
      .pipe(
        select(getDepartmentId),
        switchMap((departmentId) => this.execSubscription(departmentId))
      )
      .subscribe((state) => this.store.dispatch(loadData(state)));
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  execSubscription(departmentId: number) {
    return this.apollo
      .subscribe<Conversations>({
        query: unreadConversationsQuery,
        variables: { departmentId },
      })
      .pipe(switchMap((result) => this.processResult(result.data)));
  }

  processResult(data: Conversations) {
    let unread = 0;
    const conversations = data.patient_department_visit.map((conv) => {
      unread += conv.unseen_messages_count.aggregate.count;
      return {
        id: conv.id,
        securable_id: conv.securable_id.toString(),
        unread: conv.unseen_messages_count.aggregate.count,
        oldest_unseen_message_utc: conv.oldest_unseen_message_utc.pop().created_utc,
      };
    });
    return this.replaceSecurableIds(conversations).pipe(
      map((list) => ({ list, unread }))
    );
  }

  replaceSecurableIds(data: Secure<Omit<MenuItem, 'name'>>[]) {
    const securableIds = data.map(({ securable_id }) => securable_id);
    return this.securableCache
      .get(securableIds)
      .pipe(map((secData) => this.extractSecurableData(data, secData)));
  }

  extractSecurableData(
    data: Secure<Omit<MenuItem, 'name'>>[],
    secData: SecureData<Patient>[]
  ) {
    return data.map(({ securable_id, ...item }) => {
      const secItem = secData.find(({ id }) => id === securable_id);
      const name = [secItem.body.last_name, secItem.body.first_name]
        .filter((str) => !!str)
        .join(', ');
      return { ...item, name };
    });
  }
}
