import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { CurrentUserService } from '@frontend/common/util';
import { departmentsActions, departmentsSelectors } from '@frontend/entity/src';
import { select, Store } from '@ngrx/store';
import { filter, first, map, mergeMap, tap } from 'rxjs/operators';

@Injectable()
export class DepartmentSelectedGuard implements CanActivate {
  constructor(
    private router: Router,
    private currentUserSrv: CurrentUserService,
    private store: Store<any>
  ) {}

  canActivate() {
    return this.store.pipe(
      select(departmentsSelectors.getCollectionLoaded),
      tap((loaded) => loaded || this.store.dispatch(departmentsActions.loadAvailable())),
      filter((loaded) => loaded),
      first(),
      mergeMap(() =>
        this.currentUserSrv.getPreference('department_id').pipe(
          mergeMap((depId) =>
            this.store.pipe(
              select(departmentsSelectors.getDepartmentById, depId),
              map((dep) => (dep ? this.router.createUrlTree([depId]) : true))
            )
          )
        )
      )
    );
  }
}
