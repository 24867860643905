import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'recipients-error-popout',
  templateUrl: './error-popout.component.html',
  styleUrls: ['./error-popout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorPopoutComponent implements AfterViewInit, OnDestroy {
  @Input() errors$: Observable<any[]>;

  overlayRef: OverlayRef;
  portal: TemplatePortal<unknown>;
  @ViewChild('errorListTmpl') errorListTmpl;

  constructor(
    private overlay: Overlay,
    private element: ElementRef,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngAfterViewInit() {
    this.portal = new TemplatePortal(this.errorListTmpl, this.viewContainerRef);
    this.overlayRef = this.overlay.create(this.getOverlayConfig());
    this.overlayRef.backdropClick().subscribe(() => this.closeOverlay());
  }

  ngOnDestroy() {
    this.overlayRef.dispose();
  }

  getOverlayConfig(): OverlayConfig {
    return new OverlayConfig({
      hasBackdrop: true,
      backdropClass: '',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.element)
        .withPositions([
          { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' },
        ]),
    });
  }

  openOverlay() {
    this.overlayRef.attach(this.portal);
  }

  closeOverlay() {
    this.overlayRef.detach();
  }
}
