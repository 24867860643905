import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  selectAll,
  selectEntities,
  State,
} from './lib/text-message/text-message.reducer';

export * from './lib/text-message-api/text-message-api.actions';
export * from './lib/text-message-ws/text-message-ws.actions';
export * from './lib/text-message/text-message.actions';
export * from './lib/text-message/text-message.model';
export * from './lib/shared-text-message-data-access.module';

export const getState = createFeatureSelector<State>('textMessage');

export const getTextMessages = createSelector(getState, selectAll);

export const getTextMessageEntities = createSelector(getState, selectEntities);
