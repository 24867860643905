import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { SharedUiSkeletonVisitDetailModuleModule } from '@frontend/common/skeletons';
import { RecipientsComponent } from './recipients.component';

@NgModule({
  imports: [
    CommonModule,
    SharedUiSkeletonVisitDetailModuleModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
  ],
  declarations: [RecipientsComponent],
  exports: [RecipientsComponent],
})
export class RecipientsModule {}
