import { getRouterState } from '@frontend/common/ph-router-store';
import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer,
} from '@ngrx/store';
import { Recipient } from './recipient/recipient.model';
import * as fromRecipient from './recipient/recipient.reducer';

export interface State {
  recipients: fromRecipient.State;
}

export const reducers: ActionReducerMap<State> = {
  recipients: fromRecipient.reducer,
};

export const metaReducers: MetaReducer<State>[] = [];

export const selectRecipientsSectionState =
  createFeatureSelector<State>('recipientsSection');

export const selectRecipientsState = createSelector(
  selectRecipientsSectionState,
  (state) => state.recipients
);

export const selectEntities = createSelector(
  selectRecipientsState,
  fromRecipient.selectEntities
);

export const selectAllRecipients = createSelector(
  selectRecipientsState,
  fromRecipient.selectAll
);

export const selectOneByStateParam = createSelector(
  selectEntities,
  getRouterState,
  (entities, router): Recipient => {
    if (entities) {
      return entities[router.state.params.recipient_id];
    }
    return null;
  }
);

export const getRecipientsLoading = createSelector(
  selectRecipientsState,
  fromRecipient.getLoading
);

export const getAllWithError = createSelector(selectAllRecipients, (items) =>
  items.filter((item) => item.error)
);
