import { getParams } from '@frontend/common/ph-router-store';
import { createSelector } from '@ngrx/store';
import { getPatientsSectionState } from '../reducers';
import * as fromTagChirping from '../tag-chirping/tag-chirping.reducer';

export const getDepartmentId = createSelector(
  getParams,
  (params) => params.department_id
);

const getMainState = createSelector(
  getPatientsSectionState,
  (state) => state[fromTagChirping.tagChirpingFeatureKey]
);

export const getItems = createSelector(getMainState, ({ items, search }) =>
  items.filter(
    (item) => item.displayName.toLowerCase().indexOf(search.toLowerCase()) > -1
  )
);

export const getApiState = createSelector(getMainState, (state) => state.apiFetch);
export const getLoaded = createSelector(getApiState, ({ loaded }) => loaded);
