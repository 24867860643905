import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ManageWorkflowsComponent } from './manage-workflows.component';

@Component({
  selector: 'manage-workflows-routed',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageWorkflowsRoutedComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.dialog
      .open(ManageWorkflowsComponent, { width: '369px' })
      .afterClosed()
      .subscribe((route = '../..') =>
        this.router.navigate([route], { relativeTo: this.route })
      );
  }
}
