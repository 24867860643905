import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { GenericFilterItem } from '@frontend/common/util';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CareFiltersDialogService } from './care-filters-dialog.service';

@UntilDestroy()
@Component({
  selector: 'frontend-care-filters-dialog',
  templateUrl: './care-filters-dialog.component.html',
  styleUrls: ['./care-filters-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CareFiltersDialogService],
})
export class CareFiltersDialogComponent implements OnDestroy {
  locations: GenericFilterItem[];
  providers: GenericFilterItem[];
  appointment_types: GenericFilterItem[];
  workflows: GenericFilterItem[];
  phases: GenericFilterItem[];
  allPhases: GenericFilterItem[];
  current_department_id: number;
  location_groups: GenericFilterItem[];

  form: FormGroup = new FormGroup({
    locations: new FormControl([]),
    location_status: new FormControl([]),
    providers: new FormControl([]),
    appointment_types: new FormControl([]),
    workflows: new FormControl([]),
    phases: new FormControl([]),
    location_groups: new FormControl([]),
  });

  loading$ = this.srv.loading$;

  constructor(
    private dialogRef: MatDialogRef<CareFiltersDialogComponent>,
    private srv: CareFiltersDialogService
  ) {
    this.srv.filters$.pipe(untilDestroyed(this)).subscribe(([options, preselected]) => {
      this.locations = options.locations;
      this.providers = options.providers;
      this.appointment_types = options.appointment_types;
      this.workflows = options.workflows;
      this.allPhases = this.phases = options.phases;
      this.location_groups = options.location_groups;
      const location_groups = preselected?.location_groups?.filter((groupId) =>
        options.location_groups.some((group) => group.id === groupId)
      );
      preselected && this.form.patchValue({ ...preselected, location_groups });
    });
    this.form.controls.workflows.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((selectedWorkflows: number[]) => {
        this.phases = this.allPhases?.filter(
          (w) => !selectedWorkflows?.length || selectedWorkflows.indexOf(w.id) > -1
        );
        const selectedPhases = this.form.controls.phases.value;
        const filteredPhases = selectedPhases?.filter((id) =>
          this.phases?.some((w) => w.children.map((p) => p.id).indexOf(id) > -1)
        );
        this.form.controls.phases.setValue(filteredPhases);
      });
  }

  ngOnDestroy() {}

  save(data?) {
    this.form.disable();
    this.form.updateValueAndValidity();
    this.srv.saveCareFilters(data);
    this.dialogRef.close();
  }
}
