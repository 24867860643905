import { Site } from '@frontend/entity';
import { BaseEntity, Entity, Feature } from '@frontend/shared/base-store';

@Entity('department')
@Feature('departments')
export class Department extends BaseEntity {
  id: number;
  name: string;
  locationIds?: number[];
  site?: Site;
  announcement_text?: string;
  table_configuration: string[];
}
