import { Action } from '@ngrx/store';
import { AppActionTypes } from './app.actions';

export interface State {
  loading: boolean;
  loaded: boolean;
}

export const initialState: State = {
  loading: false,
  loaded: false,
};

export function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case AppActionTypes.LoadApp: {
      return { ...state, loading: true };
    }
    case AppActionTypes.LoadAppSuccess: {
      return { ...state, loading: false, loaded: true };
    }
    default:
      return state;
  }
}
