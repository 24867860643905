import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectAll, selectEntities, State } from './lib/staff/staff.reducer';

export * from './lib/shared-staff-data-access.module';
export * from './lib/staff-api/staff-api.actions';
export * from './lib/staff-api/staff-api.effects';
export * from './lib/staff-ws/staff-ws.actions';
export * from './lib/staff-ws/staff-ws.effects';
export * from './lib/staff/staff-statuses.model';
export * from './lib/staff/staff.actions';
export * from './lib/staff/staff.model';

export const getState = createFeatureSelector<State>('staff');

export const getStaffs = createSelector(getState, selectAll);

export const getStaffEntities = createSelector(getState, selectEntities);
