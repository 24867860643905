<ng-container
  *ngIf="srv.loading | async; then skeletonTmpl; else contentTmpl"
></ng-container>

<ng-template #skeletonTmpl>
  <skeleton-form></skeleton-form>
</ng-template>

<ng-template #contentTmpl>
  <h1 mat-dialog-title>
    <span i18n>{{ (srv.patient | async).fullName }} </span>
    <button mat-button mat-icon-button disableRipple (click)="close('../')">
      <mat-icon>edit</mat-icon>
    </button>
  </h1>

  <div class="mat-typography info-box">
    <mat-icon>info</mat-icon>
    <p i18n>
      Assign patient to a workflow to start the workflow visit.
      <br />
      Selecting a care location will assign that location to this patient.
    </p>
  </div>

  <mat-dialog-content>
    <div [formGroup]="form">
      <!-- WORKFLOWS -->
      <div class="field-wrapper">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label i18n>Workflow</mat-label>
          <mat-select formControlName="current_workflow_ids" multiple>
            <mat-option *ngFor="let w of srv.workflowList | async" [value]="w.id">
              {{ w.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- LOCATIONS -->
      <div class="field-wrapper">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label i18n>Care Location</mat-label>
          <mat-select formControlName="assigned_location_id">
            <mat-option [value]="null" i18n>None</mat-option>
            <mat-option *ngFor="let l of srv.locationList | async" [value]="l.id">
              {{ l.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>

  <mat-card-actions mat-dialog-actions align="end">
    <button mat-button (click)="save()" i18n>SAVE</button>
    <button mat-button (click)="save('../appointments')" i18n>MANAGE APPOINTMENTS</button>
  </mat-card-actions>
</ng-template>
