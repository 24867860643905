import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  CurrentUserService,
  UserNotificationService,
} from '@frontend/common/src/lib/util';
import { DepartmentSelectorComponent } from './department-selector.component';

@Component({
  selector: 'wrt-routed-create',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CurrentUserService, UserNotificationService],
})
export class RoutedDepartmentSelectorComponent implements OnInit {
  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    this._openDialog();
  }

  _openDialog() {
    this.dialog.open(DepartmentSelectorComponent, {
      width: '500px',
      panelClass: ['no-padding'],
    });
  }
}
