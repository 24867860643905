import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { SharedUiSkeletonFormModule } from '@frontend/common/skeletons';
import { ChangeDepartmentComponent } from './change-department.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatIconModule,
    MatListModule,
    MatRadioModule,
    SharedUiSkeletonFormModule,
  ],
  declarations: [ChangeDepartmentComponent],
  exports: [ChangeDepartmentComponent],
})
export class ChangeDepartmentModule {}
