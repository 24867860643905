import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SharedUiSkeletonFormModule } from '@frontend/common/skeletons';
import { ReassignPatientDialogComponent } from './reassign-patient-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatListModule,
    MatSnackBarModule,
    SharedUiSkeletonFormModule,
  ],
  exports: [ReassignPatientDialogComponent],
  declarations: [ReassignPatientDialogComponent],
})
export class ReassignPatientDialogModule {}
