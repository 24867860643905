import { PatientNetworkModel, VisitNetworkModel } from './update-patient.service';

export function patientManipulation(patient: PatientNetworkModel) {
  const current_workflow_ids = patient.patient_department_visit_workflows.map((visit) =>
    visitManipulation(visit)
  );
  const label = patient.tag?.id;
  const tag_id = patient.tag?.tag_value;
  return { ...patient, current_workflow_ids, label, tag_id };
}

function visitManipulation(visit: VisitNetworkModel) {
  return visit.workflow_id;
}
