import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { StaffActions, StaffActionTypes } from './staff.actions';
import { Staff } from './staff.model';

export interface State extends EntityState<Staff> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Staff> = createEntityAdapter<Staff>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export function reducer(state = initialState, action: StaffActions): State {
  switch (action.type) {
    case StaffActionTypes.AddStaff: {
      return adapter.addOne(action.payload.staff, state);
    }

    case StaffActionTypes.UpsertStaff: {
      return adapter.upsertOne(action.payload.staff, state);
    }

    case StaffActionTypes.AddStaffs: {
      return adapter.addMany(action.payload.staffs, state);
    }

    case StaffActionTypes.UpsertStaffs: {
      return adapter.upsertMany(action.payload.staffs, state);
    }

    case StaffActionTypes.UpdateStaff: {
      return adapter.updateOne(action.payload.staff, state);
    }

    case StaffActionTypes.UpdateStaffs: {
      return adapter.updateMany(action.payload.staffs, state);
    }

    case StaffActionTypes.DeleteStaff: {
      return adapter.removeOne(action.payload.id, state);
    }

    case StaffActionTypes.DeleteStaffs: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case StaffActionTypes.LoadStaffs: {
      return adapter.setAll(action.payload.staffs, state);
    }

    case StaffActionTypes.ClearStaffs: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
