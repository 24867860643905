import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedUiSkeletonVisitDetailModuleModule } from '@frontend/common/skeletons';
import { StatusComponent } from './status.component';

@NgModule({
  imports: [CommonModule, MatDialogModule, SharedUiSkeletonVisitDetailModuleModule],
  declarations: [StatusComponent],
  exports: [StatusComponent],
})
export class StatusModule {}
