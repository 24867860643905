import { BaseEntity, Entity, Feature } from '@frontend/shared/base-store';

@Entity('tag')
@Feature('tags')
export class Tag extends BaseEntity {
  id: string;
  tag_value: string;
  label: string;
  rtls_vendor_id: string;
}
