import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Staff } from './staff.model';

export enum StaffActionTypes {
  LoadStaffs = '[Staff] Load Staffs',
  AddStaff = '[Staff] Add Staff',
  UpsertStaff = '[Staff] Upsert Staff',
  AddStaffs = '[Staff] Add Staffs',
  UpsertStaffs = '[Staff] Upsert Staffs',
  UpdateStaff = '[Staff] Update Staff',
  UpdateStaffs = '[Staff] Update Staffs',
  DeleteStaff = '[Staff] Delete Staff',
  DeleteStaffs = '[Staff] Delete Staffs',
  ClearStaffs = '[Staff] Clear Staffs',
}

export class LoadStaffs implements Action {
  readonly type = StaffActionTypes.LoadStaffs;

  constructor(public payload: { staffs: Staff[] }) {}
}

export class AddStaff implements Action {
  readonly type = StaffActionTypes.AddStaff;

  constructor(public payload: { staff: Staff }) {}
}

export class UpsertStaff implements Action {
  readonly type = StaffActionTypes.UpsertStaff;

  constructor(public payload: { staff: Staff }) {}
}

export class AddStaffs implements Action {
  readonly type = StaffActionTypes.AddStaffs;

  constructor(public payload: { staffs: Staff[] }) {}
}

export class UpsertStaffs implements Action {
  readonly type = StaffActionTypes.UpsertStaffs;

  constructor(public payload: { staffs: Staff[] }) {}
}

export class UpdateStaff implements Action {
  readonly type = StaffActionTypes.UpdateStaff;

  constructor(public payload: { staff: Update<Staff> }) {}
}

export class UpdateStaffs implements Action {
  readonly type = StaffActionTypes.UpdateStaffs;

  constructor(public payload: { staffs: Update<Staff>[] }) {}
}

export class DeleteStaff implements Action {
  readonly type = StaffActionTypes.DeleteStaff;

  constructor(public payload: { id: string }) {}
}

export class DeleteStaffs implements Action {
  readonly type = StaffActionTypes.DeleteStaffs;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearStaffs implements Action {
  readonly type = StaffActionTypes.ClearStaffs;
}

export type StaffActions =
  | LoadStaffs
  | AddStaff
  | UpsertStaff
  | AddStaffs
  | UpsertStaffs
  | UpdateStaff
  | UpdateStaffs
  | DeleteStaff
  | DeleteStaffs
  | ClearStaffs;
