import { createAction, props } from '@ngrx/store';
import { Staff } from './staff.model';

export const init = createAction(
  '[Tag chirping] Init',
  props<{ department_id: number }>()
);

export const destroy = createAction('[Tag chirping] Destroy');

export const updateSearch = createAction(
  '[Tag chirping] Update Search',
  props<{ data: string }>()
);

export const loadItems = createAction(
  '[Tag chirping] Load Items',
  props<{ data: Staff[] }>()
);

export const chirp = createAction('[Tag chirping] Chirp', props<{ tag_id: number }>());
