import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CustomerResolver } from './customer.resolver';
import { CustomersResolver } from './customers.resolver';
import { CustomersService } from './customers.service';
import { CustomersEffects } from './store/customers.effects';
import { reducer } from './store/customers.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('customers', reducer),
    EffectsModule.forFeature([CustomersEffects]),
  ],
  providers: [CustomersService, CustomersResolver, CustomerResolver],
})
export class CustomersModule {}
