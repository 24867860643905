import * as moment from 'moment';
import { Moment, utc } from 'moment-timezone';
import { Appointment } from './edit-appointment.graphql';

export interface FormGroupValue {
  id: number;
  provider_id: number;
  appointment_type_id: number;
  date?: Moment;
  time?: string;
  period?: string[];
}

export function appointmentManipulation(
  data: Appointment,
  timezone: string
): FormGroupValue {
  const { appointment_time_utc, ...apptWithoutDatetime } = data;
  if (!appointment_time_utc) {
    return apptWithoutDatetime;
  }
  const date = utc(appointment_time_utc).tz(timezone);
  const time = utc(appointment_time_utc).tz(timezone).format('hh:mm');
  const period = [utc(appointment_time_utc).tz(timezone).format('A')];
  return { ...apptWithoutDatetime, date, time, period };
}

export function manipulateFormOutput(
  { date, time, period, ...rest }: FormGroupValue,
  timezone: string
): Appointment {
  if (!date || !time || !period) {
    return { appointment_time_utc: null, ...rest };
  }
  const joined = [date.format('MM/DD/YYYY'), time, period[0]].join();
  const appointment_time_utc = moment
    .tz(joined, 'MM/DD/YYYY,hh:mm,A', true, timezone)
    .utc()
    .format();
  return { appointment_time_utc, ...rest };
}
