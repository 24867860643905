import { MilestoneType, MilestoneTypes } from '@frontend/common/util';
import { User } from './common-interfaces';

export interface DeletePatientFragment {
  __typename: MilestoneType;
  created_utc: string;
  created_by: string;
  user?: User;
}

export const DELETE_PATIENT_FRAGMENT = `
  fragment ${MilestoneTypes.DeletePatientMilestone} on ${MilestoneTypes.DeletePatientMilestone} {
    __typename
    created_utc
    created_by
    user(with_deleted: true) {
      last_name
      first_name
      email
    }
  }
`;
