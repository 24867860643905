import gql from 'graphql-tag';

export interface PatientDataQuery {
  patient_department_visit: Patient[];
}

export interface Patient {
  id: number;
  securable_id: number;
  assigned_location_id: number;
  patient_appointments: {
    id: number;
    appointment_type_id: number;
    provider_id: number;
    appointment_time_utc: string;
  }[];
}

export const PatientDataQuery = gql(`
query PatientDataQuery($patientId: bigint!) {
  patient_department_visit(where: { id: { _eq: $patientId } }) {
    id
    securable_id
    patient_appointments(order_by: { created_utc: desc }) {
      id
      appointment_type_id
      provider_id
      appointment_time_utc
    }
  }
}
`);

export interface DepartmentDataQuery {
  department: { site: { name: string; timezone: { id: string } } }[];
}

export const DepartmentDataQuery = gql(`
query DepartmentDataQuery($departmentId: Int!) {
    department(where: {id: {_eq: $departmentId}}) {
      site {
        timezone {
          id
        }
      }
    }
  }
`);

export interface ProvidersDataQuery {
  staff: ProviderItem[];
}

export interface ProviderItem {
  id: number;
  first_name: string;
  last_name: string;
}

export const ProvidersDataQuery = gql(`
query ProvidersDataQuery($departmentId: Int!) {
  staff(where: {departments: {department: {id: {_eq: $departmentId}}}, staff_type_id: {_in: [9,10,11,12,13,14,15,31,33,34,36]}}, order_by: {last_name: asc}) {
    id
    first_name
    last_name
  }
}
`);

export interface AppointmentTypesDataQuery {
  appointment_type: AppointmentTypeItem[];
}

export interface AppointmentTypeItem {
  id: number;
  name: string;
}

export const AppointmentTypesDataQuery = gql(`
query AppointmentTypesDataQuery($departmentId: Int!) {
  appointment_type(where: {department_id: {_eq: $departmentId}}, order_by: {name: asc}) {
    name
    id
  }
}
`);
