import { ChatMessage } from '@frontend/common/chat';
import { getParams } from '@frontend/common/ph-router-store';
import { TextMessageTypeId } from '@frontend/common/util';
import { departmentsSelectors } from '@frontend/entity/src';
import { getQuickMessages } from '@frontend/shared/quick-message/data-access/src';
import { getTextMessages } from '@frontend/shared/text-message/data-access/src';
import { createSelector } from '@ngrx/store';
import { utc } from 'moment-timezone';
import { getChatState } from '../../reducers';

export const getChatLoading = createSelector(getChatState, (state) => state.loading);

export const getChatLoaded = createSelector(getChatState, (state) => state.loaded);

export const getChatSending = createSelector(getChatState, (state) => state.sending);

export const getState = createSelector(
  getChatState,
  getTextMessages,
  departmentsSelectors.getDepartmentTimezone,
  getQuickMessages,
  (state, items, timezone, quickMessages) => {
    const messages: ChatMessage[] = items
      .slice()
      .filter(
        (tm) => tm.patient_department_visit_id === state.patient_department_visit_id
      )
      .reverse()
      .map((item) => ({
        id: item.id,
        from:
          item.text_message_type_id === TextMessageTypeId.Sent_By_User
            ? item.department_id === item.original_department_id
              ? [item.sent_by_last_name, item.sent_by_first_name]
                  .filter((str) => !!str)
                  .join(', ')
              : item.original_department_name
            : item.text_message_type_id === TextMessageTypeId.Sent_By_System
            ? 'Auto'
            : state.patientName,
        created_utc: item.created_utc,
        sentFormatted: utc(item.created_utc).tz(timezone).format('MMM DD hh:mm A'),
        unseen:
          item.text_message_type_id === TextMessageTypeId.Sent_By_Patient
            ? !item.is_seen
            : undefined,
        body: item.text,
      }));
    return {
      fullName: state.patientName,
      messages,
      quickMessages,
      totalMessages: messages.length,
      totalUnseen: messages.filter((item) => item.unseen).length,
    };
  }
);

export const getPatientId = createSelector(getParams, (params) => params.patient_id);
