import gql from 'graphql-tag';

export interface PatientDataQuery {
  patient_department_visit: Patient[];
}

export interface Patient {
  id: number;
  securable_id: number;
  assigned_location_id: number;
  patient_department_visit_workflows: {
    id: number;
    workflow_id: number;
    patient_department_visit_id: number;
  }[];
}

export const PatientDataQuery = gql(`
query PatientDataQuery($patientId: bigint!) {
  patient_department_visit(where: {id: {_eq: $patientId}}) {
    id
    securable_id
    assigned_location_id
    patient_department_visit_workflows(where: {completed_utc: {_is_null: true}}) {
      id
      workflow_id
      patient_department_visit_id
    }
  }
}
`);

export interface WorkflowDataQuery {
  workflow: WorkflowItem[];
}
export interface WorkflowItem {
  id: number;
  name: string;
}

export const WorkflowDataQuery = gql(`
query WorkflowDataQuery($departmentId: Int!) {
  workflow(where: {department_id: {_eq: $departmentId}, is_activated: {_eq: true}}, order_by: {name: asc}) {
    id
    name
  }
}
`);

export interface LocationDataQuery {
  department_location: LocationItem[];
}
export interface LocationItem {
  id: number;
  alias_name: string;
  name: string;
}

export const LocationDataQuery = gql(`
query LocationDataQuery($departmentId: Int!, $currentLocationId: [Int!]) {
  department_location(where: {_or: [{department_id: {_eq: $departmentId}, location_status_id: {_eq: 1}, location_type_id: {_eq: 3}}, {id: {_in: $currentLocationId}}]}, order_by: {alias_name: asc}) {
    id
    alias_name
    name
  }
}
`);
