import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { Workflow } from './workflow.model';

export enum WorkflowActionTypes {
  LoadWorkflows = '[Workflow] Load Workflows',
  AddWorkflow = '[Workflow] Add Workflow',
  UpsertWorkflow = '[Workflow] Upsert Workflow',
  AddWorkflows = '[Workflow] Add Workflows',
  UpsertWorkflows = '[Workflow] Upsert Workflows',
  UpdateWorkflow = '[Workflow] Update Workflow',
  UpdateWorkflows = '[Workflow] Update Workflows',
  DeleteWorkflow = '[Workflow] Delete Workflow',
  DeleteWorkflows = '[Workflow] Delete Workflows',
  ClearWorkflows = '[Workflow] Clear Workflows',
}

export class LoadWorkflows implements Action {
  readonly type = WorkflowActionTypes.LoadWorkflows;

  constructor(public payload: { workflows: Workflow[] }) {}
}

export class AddWorkflow implements Action {
  readonly type = WorkflowActionTypes.AddWorkflow;

  constructor(public payload: { workflow: Workflow }) {}
}

export class UpsertWorkflow implements Action {
  readonly type = WorkflowActionTypes.UpsertWorkflow;

  constructor(public payload: { workflow: Workflow }) {}
}

export class AddWorkflows implements Action {
  readonly type = WorkflowActionTypes.AddWorkflows;

  constructor(public payload: { workflows: Workflow[] }) {}
}

export class UpsertWorkflows implements Action {
  readonly type = WorkflowActionTypes.UpsertWorkflows;

  constructor(public payload: { workflows: Workflow[] }) {}
}

export class UpdateWorkflow implements Action {
  readonly type = WorkflowActionTypes.UpdateWorkflow;

  constructor(public payload: { workflow: Update<Workflow> }) {}
}

export class UpdateWorkflows implements Action {
  readonly type = WorkflowActionTypes.UpdateWorkflows;

  constructor(public payload: { workflows: Update<Workflow>[] }) {}
}

export class DeleteWorkflow implements Action {
  readonly type = WorkflowActionTypes.DeleteWorkflow;

  constructor(public payload: { id: Workflow['id'] }) {}
}

export class DeleteWorkflows implements Action {
  readonly type = WorkflowActionTypes.DeleteWorkflows;

  constructor(public payload: { ids: Workflow['id'][] }) {}
}

export class ClearWorkflows implements Action {
  readonly type = WorkflowActionTypes.ClearWorkflows;
}

export type WorkflowActions =
  | LoadWorkflows
  | AddWorkflow
  | UpsertWorkflow
  | AddWorkflows
  | UpsertWorkflows
  | UpdateWorkflow
  | UpdateWorkflows
  | DeleteWorkflow
  | DeleteWorkflows
  | ClearWorkflows;
