<div class="customerDisableMsg">
  <div>
    <mat-icon>warning</mat-icon>
  </div>
  <div>
    <p class="mat-title" i18n>Account Disabled</p>
    <p class="mat-subheading-1" i18n>
      This account has been suspended temporarily. Please contact your system
      administrator regarding the status of you account.
    </p>
  </div>
</div>
