<ng-container *ngIf="srv.loading | async; then skeletonTmpl; else contentTmpl"></ng-container>

<ng-template #skeletonTmpl>
  <skeleton-form></skeleton-form>
</ng-template>

<ng-template #contentTmpl>
  <h1 mat-dialog-title>
    <span i18n>Edit appointment</span>
  </h1>

  <mat-dialog-content>
    <div [formGroup]="form">
      <!-- APPOINTMENT TYPE -->
      <mat-form-field appearance="outline" class="full-width">
        <mat-label i18n>Type</mat-label>
        <mat-select formControlName="appointment_type_id">
          <mat-option [value]="null" i18n>None</mat-option>
          <mat-option *ngFor="let p of srv.appointmentTypeList | async" [value]="p.id">{{
            p.name
            }}</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- APPOINTMENT TIME -->
      <ng-container [ngSwitch]="localeId">
        <ng-container *ngSwitchCase="'de'">
          <time-picker-24hs [form]="form"></time-picker-24hs>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <time-picker-ampm [form]="form" [timezone]="this.srv.timezone | async"></time-picker-ampm>
        </ng-container>
      </ng-container>

      <!-- PROVIDER -->
      <mat-form-field appearance="outline" class="full-width">
        <mat-label i18n>Provider</mat-label>
        <mat-select formControlName="provider_id">
          <mat-option [value]="null" i18n>None</mat-option>
          <mat-option *ngFor="let p of srv.providerList | async" [value]="p.id">{{
            p.name
            }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-card-actions mat-dialog-actions align="end">
    <button mat-button type="submit" [disabled]="form.disabled || form.invalid" (click)="updateAppointment()" i18n>
      SAVE
    </button>
    <button type="button" mat-button mat-dialog-close (click)="close()" i18n>
      CANCEL
    </button>
  </mat-card-actions>
</ng-template>