<div style="flex-direction: column; padding: 0 8px">
  <div class="row">
    <skeleton-item style="width: 100px"></skeleton-item>
    <skeleton-item style="width: 50px"></skeleton-item>
    <skeleton-item style="width: 65px"></skeleton-item>
    <skeleton-item style="width: 90px"></skeleton-item>
    <skeleton-item style="width: 110px"></skeleton-item>
  </div>
  <div class="row">
    <skeleton-item style="width: 105px"></skeleton-item>
    <skeleton-item style="width: 80px"></skeleton-item>
    <skeleton-item style="width: 135px"></skeleton-item>
    <skeleton-item style="width: 80px"></skeleton-item>
    <skeleton-item style="width: 45px"></skeleton-item>
  </div>
  <div class="row">
    <skeleton-item style="width: 120px"></skeleton-item>
    <skeleton-item style="width: 110px"></skeleton-item>
    <skeleton-item style="width: 30px"></skeleton-item>
    <skeleton-item style="width: 120px"></skeleton-item>
    <skeleton-item style="width: 95px"></skeleton-item>
  </div>
  <div class="row">
    <skeleton-item style="width: 95px"></skeleton-item>
    <skeleton-item style="width: 85px"></skeleton-item>
    <skeleton-item style="width: 180px"></skeleton-item>
    <skeleton-item style="width: 110px"></skeleton-item>
    <skeleton-item style="width: 50px"></skeleton-item>
  </div>
  <div class="row">
    <skeleton-item style="width: 100px"></skeleton-item>
    <skeleton-item style="width: 50px"></skeleton-item>
    <skeleton-item style="width: 65px"></skeleton-item>
    <skeleton-item style="width: 90px"></skeleton-item>
    <skeleton-item style="width: 110px"></skeleton-item>
  </div>
  <div class="row">
    <skeleton-item style="width: 105px"></skeleton-item>
    <skeleton-item style="width: 80px"></skeleton-item>
    <skeleton-item style="width: 135px"></skeleton-item>
    <skeleton-item style="width: 80px"></skeleton-item>
    <skeleton-item style="width: 45px"></skeleton-item>
  </div>
  <div class="row">
    <skeleton-item style="width: 100px"></skeleton-item>
    <skeleton-item style="width: 50px"></skeleton-item>
    <skeleton-item style="width: 65px"></skeleton-item>
    <skeleton-item style="width: 90px"></skeleton-item>
    <skeleton-item style="width: 110px"></skeleton-item>
  </div>
</div>